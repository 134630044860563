import React from "react";
import "./testimonial.css";
import user from "../../assets/images/user.png"
import group from"../../assets/images/Group 10.png"

const Testimonials = ({remark,name,designation}) => {
  return (
    <div className="testimonial mt-5 mb-3">
      <img className="mb-5" src={user}/>
      <img  src={group}/>
      <h4>
        {
          remark
        }
      </h4>
      <h3>{name}</h3>
      <h5>{designation}</h5>
    </div>
  );
};

export default Testimonials;
