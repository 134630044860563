/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, used } from "react-router-dom";
import { Button, Row, Col, Form, InputGroup } from "react-bootstrap";
import { Alert } from "reactstrap";
import FontAwesome from "react-fontawesome";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import sha256 from "sha256";
import api from "../api";
import FacebookLogin from "react-facebook-login";
import "./signin.css";
import OBlogo from "../assets/logo/branding-2@2x.png";
import GgleBtnImg from "../assets/logo/social-button-2.png";
import { green } from "@material-ui/core/colors";
import Logout from "../logOut/Logout";
import Loader from "react-js-loader";
import { FacebookProvider, LoginButton } from "react-facebook";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [alertClr, setAlertClr] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const isSessionExpired = useSelector((state) => state.isSessionExpired);
  const logOutClick = useSelector((state) => state.logOutClick);
  const fbref = useRef();

  const loadFbLoginApi = () => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: 466367218319436,
        cookie: true, // enable cookies to allow the server to access
        // the session
        xfbml: true, // parse social plugins on this page
        version: "v2.1", // use version 2.1
      });
    };

    console.log("Loading fb api");
    // Load the SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  };

  const testAPI = () => {
    console.log("Welcome!  Fetching your information.... ");
    window.FB.api("/me", function (response) {
      console.log("Successful login for: " + response.name);
      document.getElementById("status").innerHTML =
        "Thanks for logging in, " + response.name + "!";
    });
  };

  const statusChangeCallback = (response) => {
    console.log("statusChangeCallback");
    console.log(response);
    // The response object is returned with a status field that lets the
    // app know the current login status of the person.
    // Full docs on the response object can be found in the documentation
    // for FB.getLoginStatus().
    if (response.status === "connected") {
      // Logged into your app and Facebook.
      testAPI();
    } else if (response.status === "not_authorized") {
      // The person is logged into Facebook, but not your app.
      document.getElementById("status").innerHTML =
        "Please log " + "into this app.";
    } else {
      // The person is not logged into Facebook, so we're not sure if
      // they are logged into this app or not.
      document.getElementById("status").innerHTML =
        "Please log " + "into Facebook.";
    }
  };
  useEffect(() => {
    dispatch({ type: "setCurrentLink", currentLink: "/signIn" });

    if (isSessionExpired === true) {
      setAlertClr("danger");
      setShowAlert(true);
      setMessage(
        "Oops! Looks like your session has expired. Please sign in again"
      );
    } else if (isSessionExpired === false) {
      setAlertClr("success");
      setShowAlert(true);
      setMessage("Logged out Successfully");
    }
    setTimeout(() => {
      dispatch({ type: "isSessionExpired/set", isSessionExpired: "" });

      // dispatch({ type: "isLogout/set", logOutClick: '' });
    }, 3000);

    // loadFbLoginApi()
    // window.FB.logout()

    //  console.log("SIGNIN FB Obj",window.FB)
    //  let res = window.FB.getLoginStatus()
    //  console.log(res,"INTERNAL")
  }, []);

  const checkLoginState = () => {
    window.FB.getLoginStatus((res) => {
      statusChangeCallback(res);
    });
  };

  const facebookLogin = () => {
    // window.FB.login(checkLoginState)
  };

  const handleResponse = (data) => {
    console.log(data);
  };

  const handleError = (error) => {
    console.error(error);
  };

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setMessage("");
        setAlertClr("");
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  function decodeJWT(token) {
    const [headerEncoded, payloadEncoded] = token.split(".").slice(0, 2);
    const header = JSON.parse(atob(headerEncoded));
    const payload = JSON.parse(atob(payloadEncoded));
    return { header, payload };
  }

  const responseGoogle = (response) => {
    console.log("RESPONSE GOOGLE", response);
    const userDetails = decodeJWT(response.credential);
    console.log("userDetails --> ", userDetails);
    if (userDetails && userDetails?.payload) {
      var googleData = {
        loginType: 1,
        socialMediaUserId: userDetails?.payload?.sub,
        firstName: userDetails?.payload?.given_name, //
        lastName: userDetails?.payload?.family_name, //
        email: userDetails?.payload?.email, //
        mobile: null, //
        deviceType: 3, //
        deviceFcmToken: `WEB_TKN_${userDetails?.payload?.email}`,
        isEmailReceivedFromSocialMedia: true,
      };
    }

    api.auth
      .socialMedia(googleData)
      .then((result) => {
        setIsLoading(true);
        console.log("RESULT => SOCIAL ", result);
        console.log("RESULT => SOCIAL pro", result.resultData.profile);
        if (result.resultCode === 1 || result.resultCode === 2) {
          // localStorage.setItem("titleName", JSON.stringify(true));
          if (result.resultData.profile.lastLoginOn) {
            setMessage(`Welcome back ${userDetails?.payload?.given_name}!`);
          } else {
            setMessage(
              `Welcome ${userDetails?.payload?.given_name}! Start exploring ypur opportunities `
            );
          }
          setAlertClr("success");
          setShowAlert(true);
          window.scrollTo(0, 0);
          setTimeout(() => {
            history.push({
              pathname: "/home",
              state: {
                status: "fromSignIn",
              },
            });
          }, 3000);
          dispatch({
            type: "set_auth_token",
            auth_token: result.resultData.authToken,
          });
          dispatch({
            type: "set_profile_data",
            profileData: result.resultData.profile,
          });
        } else if (result.resultCode === -1) {
          setMessage(result.resultMsg);
          setAlertClr("danger");
          setShowAlert(true);
        } else {
          setMessage(result.resultMsg);
          setAlertClr("danger");
          setShowAlert(true);
          window.scrollTo(0, 0);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        // if (
        //   !!error.response &&
        //   !!error.response.status &&
        //   error.response.status === 401
        // ) {
        //   Logout(dispatch, history,false);
        // }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
          window.scrollTo(0, 0);
        }
      });
  };

  const responseFacebook = (response) => {
    console.log("I WAS CALLED", response);

    var first_name;
    var last_name;
    if (response.name) {
      var splitArr = response.name.split(" ");
      first_name = splitArr[0];
      last_name = splitArr[1];
    }

    var fbData = {
      loginType: 2,
      socialMediaUserId: response.id,
      firstName: first_name,
      lastName: last_name,
      mobile: null,
      deviceType: 3,
      deviceFcmToken: response.accessToken,
      // isEmailReceivedFromSocialMedia: true
    };

    if (response.email) {
      fbData.isEmailReceivedFromSocialMedia = true;
      fbData.email = response.email;
    } else {
      fbData.isEmailReceivedFromSocialMedia = false;
    }

    console.log("FB DATA ", fbData);

    api.auth
      .socialMedia(fbData)
      .then((result) => {
        setIsLoading(true);
        console.log("RESULT => SOCIAL ", result);
        if (result.resultCode === 1) {
          if (result.resultData.profile.lastLoginOn) {
            setMessage(`Welcome back ${fbData.firstName}!`);
          } else {
            setMessage(
              `Welcome ${fbData.firstName}! Start exploring ypur opportunities `
            );
          }

          setAlertClr("success");
          setShowAlert(true);
          window.scrollTo(0, 0);
          setTimeout(() => {
            history.push({
              pathname: "/home",
              state: {
                status: "fromSignIn",
              },
            });
          }, 3000);
          dispatch({
            type: "set_auth_token",
            auth_token: result.resultData.authToken,
          });
          dispatch({
            type: "set_profile_data",
            profileData: result.resultData.profile,
          });
        } else if (result.resultCode === -1) {
          history.push({
            pathname: "/passwordResetPage",
            state: {
              from: "facebookbtn",
              fbData,
            },
          });
        } else {
          setMessage(result.resultMsg);
          setAlertClr("danger");
          setShowAlert(true);
          window.scrollTo(0, 0);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        // if (
        //   !!error.response &&
        //   !!error.response.status &&
        //   error.response.status === 401
        // ) {
        //   Logout(dispatch, history,fasle);
        // }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
          window.scrollTo(0, 0);
        }
      });
  };

  const UserSignIn = () => {
    setIsLoading(true);
    var regEx = new RegExp(/^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i);

    if (email.length === 0) {
      setEmailErr("Please enter your email");
      setIsLoading(false);
    } else if (!regEx.test(email)) {
      setEmailErr("Please enter valid email");
      setIsLoading(false);
    } else {
      setEmailErr("");
      setIsLoading(false);
    }
    if (password.length === 0) {
      setPasswordErr("Please enter your password");
      setIsLoading(false);
    } else {
      setPasswordErr("");
      setIsLoading(false);
    }

    var data = {
      email: email,
      passwordHash: sha256(password),
      deviceType: 3,
      deviceFcmToken: `WEB_TKN_${email}`,
    };

    api.auth
      .signIn(data)
      .then((result) => {
        console.log("RESULT sign in -->", result);

        if (result.resultCode === 1) {
          // localStorage.setItem("titleName", JSON.stringify(true));
          if (result.resultData.profile.lastLoginOn) {
            setMessage(`Welcome back ${result.resultData.profile.firstName}!`);
          } else {
            setMessage(
              `Welcome ${result.resultData.profile.firstName}! Start exploring your opportunities`
            );
          }
          setAlertClr("success");
          setShowAlert(true);
          window.scrollTo(0, 0);
          dispatch({ type: "isLoggedIn", isLoggedIn: true });
          setTimeout(() => {
            history.push({
              pathname: "/home",
              state: {
                status: "fromSignIn",
              },
            });

            setEmail("");
            setPassword("");
          }, 3000);

          dispatch({
            type: "token/save",
            authToken: result.resultData.authToken,
          });
          dispatch({
            type: "profile/save",
            profile: result.resultData.profile,
          });
          dispatch({
            type: "set_auth_token",
            auth_token: result.resultData.authToken,
          });
          dispatch({
            type: "set_profile_data",
            profileData: result.resultData.profile,
          });
        } else {
          if (result.resultData.error === "Invalid login credentials") {
            setMessage(
              "Email address and/or password entered is incorrect. Please try again!"
            );
            setAlertClr("danger");
            setShowAlert(true);
          }
          window.scrollTo(0, 0);
        }
        setIsLoading(false);
        setTimeout(() => {
          setMessage("");
          setAlertClr("");
          setShowAlert(false);
        }, 3000);
      })
      .catch((error) => {
        console.log("error", error);
        // if (
        //   !!error.response &&
        //   !!error.response.status &&
        //   error.response.status === 401
        // ) {
        //   Logout(dispatch, history,false);
        // }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
          window.scrollTo(0, 0);
        }
      });
  };

  return (
    <React.Fragment>
      {isLoading && (
        <Loader
          type="spinner-cub"
          bgColor={"rgb(53, 50, 50)"}
          title={"Loading..."}
          size={100}
        />
      )}

      {/* <i class="fa fa-spinner fa-spin" style={{ fontSize: "24px"}}></i> */}
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Alert className="alert-position" isOpen={showAlert} color={alertClr}>
          {message}{" "}
        </Alert>
        <div className="container form-container mt-5">
          <img src={OBlogo} className="loginLogo mt-2" />

          <Form className="login-form">
            <Form.Group
              className="d-flex justify-content-center mt-3"
              style={{ color: "#5B5A5A" }}
            >
              <h3>Sign In</h3>
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label className="cust-label shiftToLeft">
                Email Address
              </Form.Label>
              <Form.Control
                className="cust-inputStyle"
                value={email}
                onChange={(e) => {
                  if (!e.target.value.includes(" ")) {
                    console.log("SPACE not ALLOW");
                    setEmail(e.target.value);
                  }
                  if (e.target.value) {
                    setEmailErr("");
                  }
                }}
                type="email"
              />
              <Form.Text
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "start",
                  fontFamily: "emoji",
                }}
              >
                {emailErr}
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label className="cust-label shiftToLeft">
                Password
              </Form.Label>
              <div className="password-position">
                <Form.Control
                  className="pass-cust-inputStyle"
                  style={{ paddingLeft: "1.3rem" }}
                  type={showPass ? "text" : "password"}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (e.target.value) {
                      setPasswordErr("");
                    }
                  }}
                  value={password}
                />

                {/* <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>{
                    setShowPass(!showPass)
                  }}
                >
                  {showPass ? <Visibility /> : <VisibilityOff />}
                </IconButton> */}

                {showPass ? (
                  <span
                    className="material-icons"
                    onClick={() => {
                      setShowPass(false);
                    }}
                  >
                    visibility
                  </span>
                ) : (
                  <span
                    className="material-icons"
                    onClick={() => {
                      setShowPass(true);
                    }}
                  >
                    visibility_off
                  </span>
                )}
              </div>
              <Form.Text
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "start",
                  fontFamily: "emoji",
                }}
              >
                {passwordErr}
              </Form.Text>
              <a
                href="/passwordResetPage"
                style={{
                  color: "#344256",
                  marginTop: "10px",
                  fontSize: "13px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                Forgot Password
              </a>
            </Form.Group>
            <Form.Group
            //  className="mt-2"
            >
              <Button className="auth-btn mt-5 " onClick={UserSignIn}>
                Sign in
              </Button>
            </Form.Group>
            <Form.Group>
              <Form.Group
                className="d-flex justify-content-center"
                style={{ color: "#5B5A5A" }}
              >
                <p>or</p>
              </Form.Group>

              <div className="d-flex justify-content-center">
                <FacebookLogin
                  appId="466367218319436"
                  // appId="1563255950526879"
                  // appId="149679610557181"
                  // appId="511988876674409"
                  autoLoad={false}
                  id="facebook"
                  textButton="f"
                  callback={responseFacebook}
                  buttonStyle={{
                    padding: "0px 0px 0px 0px",
                    width: "40px",
                    height: "36px",
                    borderRadius: "50%",
                    background: "#3b5998",
                    textTransform: "lowercase !important",
                    marginRight: "10px",
                  }}
                  ref={fbref}
                  fields="name,email"
                  // scope="public_profile,user_friends"
                  // disableMobileRedirect={true}
                />
                {/* <FacebookLoginWithButton
                appId="1563255950526879"
                fields="link,first_name,middle_name,name,birthday,last_name,email,gender,locale,verified,picture.height(2048),age_range"
                callback={responseFacebook}
                autoLoad={true}
                disableMobileRedirect={true}
                textButton="Iniciar con facebook"
                isMobile={false}
              /> */}
                {/* <button className="fb-auth" onClick={facebookLogin}>
                Facebook
              </button> */}
                {/* <div className="facebook-auth-btn">
                <FacebookProvider appId="1563255950526879">
                  <LoginButton
                    scope="email"
                    onCompleted={responseFacebook}
                    onError={handleError}
                  >
                    <span>F</span>
                  </LoginButton>
                </FacebookProvider>
                </div> */}
               
                <div className="google-container">
                  <GoogleOAuthProvider clientId="435320871069-a455d7obkklm371o8oubf0jqeuff6b1e.apps.googleusercontent.com">
                    <GoogleLogin
                      onSuccess={responseGoogle}
                      onError={() => {
                        console.log("Login Failed");
                      }}
                      shape="pill"
                      size="large"
                      type="icon"
                      width="100"
                      // render={(renderProps) => (
                      //   <img
                      //     src={GgleBtnImg}
                      //     className="google-style mb-2"
                      //     onClick={renderProps.onClick}
                      //     disabled={renderProps.disabled}
                      //   />
                      // )}
                    />
                  </GoogleOAuthProvider>
                </div>
              </div>
            </Form.Group>
            <Form.Group className="d-flex justify-content-center pointer">
              <span className="mr-2 mb-3">Need an account?</span>
              <span
                style={{ color: "blue" }}
                onClick={() => {
                  history.push("/signUp");
                }}
              >
                Sign up
              </span>
            </Form.Group>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}
