import React, { useEffect } from 'react'
// import {useDispatch} from 'react-redux'
import { useHistory } from 'react-router-dom'
import { API_IMAGE_URL } from '../../app_constants'

import './DirectoryCard.css'

const DirectoryCard=({data})=>{
	let history= useHistory()
	useEffect(()=>{
		console.log(data.imagePathCard)
	},[])
	// console.log(item)

	return(
	   <div className='card directory-card' 
	   style={{color:"white", borderRadius:"15px", backgroundColor:"transparent"}}
	   >
		   <div className="cust-overlay"></div>
		 <img src={API_IMAGE_URL+data.imagePathCard.toString()}/>
		 <p>{data.jobTitle}</p>
	   </div>
	)
	
}

export default DirectoryCard 