import React, { useState, useEffect } from "react";
import Popup from "./Popup";
import "./index.css";

function DisplayPopup() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, 1000);
  }, []);

  return <div className="container-1">{isOpen && <Popup  setIsOpen={setIsOpen} isOpen={isOpen} />}</div>;
}

export default DisplayPopup;
