import React, { useEffect, useState } from 'react'
import api from '../../../api'
import { Form, Card, Button, Badge, Col, Row } from 'react-bootstrap';
import { isNull } from 'lodash'
import './skill.css'
import Logout from '../../../logOut/Logout';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'reactstrap';
import Loader from "react-js-loader";


const Skills = ({ nextStep, previousStep, onSkillAction }) => {

  const [skillList, setSkillList] = useState([]);
  const [userSkillList, setUserSkillList] = useState([]);
  const [displaySkillList, setDisplaySkillList] = useState([]);
  const [filteredSkillList, setFilteredSkillList] = useState([]);
  const [addSkill, setAddSkill] = useState('');
  const [alertClr, setAlertClr] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const history = useHistory();
  const dispatch = useDispatch();

  const authToken = useSelector(state=>state.auth_token)


  useEffect(() => {
    console.log(authToken)
    setIsLoading(true)
    getMasterSkillList();
    getUserSkillList();
  }, [])


  useEffect(() => {
    // setIsLoading(true)
    let updatedSkillList = JSON.parse(JSON.stringify(skillList));
    console.log('updatedSkillList', updatedSkillList);
    (userSkillList).map(ss => {
      let matchFound = false;
      (updatedSkillList).map(sn => {
        if (ss === sn.skillName) {
          matchFound = true;
          // updatedSkillList.push({skillName: sn.skill, isSelected: true});
          sn.isSelected = true;
          // passToStepThree(true)
        }
      })
      if (!matchFound) {
        updatedSkillList.push({ skillName: ss, isSelected: true });
        // passToStepThree(true)
      }
    })
    console.log('updatedSkillList', updatedSkillList);
    setDisplaySkillList(updatedSkillList)
  }, [skillList, userSkillList])


  useEffect(() => {
    let searchList = JSON.parse(JSON.stringify(displaySkillList));
    if (addSkill) {
      // searchList = displaySkillList.filter((item, idx) => {
      searchList = searchList.filter((item, idx) => {
        return (
          // console.log("item ", item.skillName ,"and ",addSkill, )
          (((item.skillName && (item.skillName).toLowerCase()).includes(addSkill.toLowerCase()) ||
            (item.isSelected)))
        )
      })
      console.log("SEARCH LIST ", searchList)
    } else {
      // searchList = [...displaySkillList];
      // console.log("SEARCH LIST else", searchList)
    }
    // setSearchList(searchList)
    setFilteredSkillList(searchList)
  }, [addSkill, displaySkillList])

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setMessage("");
        setAlertClr("");
        setShowAlert(false);
      }, 2500);
    }
  }, [showAlert])

  const getMasterSkillList = () => {
    api.onlineCV.getMasterSkills(authToken).
      then(result => {
        console.log("RESULT => skill ", result)
        var newArr = [];
        if (result.resultCode === 1) {
          (result.resultData.skillList).map((sn, idx) => {
            // console.log(sn.skill)
            newArr.push({ skillName: sn.skill, isSelected: false })
          })
          console.log('newArr ', newArr);
          setSkillList(newArr)
          // setFilteredSkillList(newArr)
        } else {
          setSkillList([])
        }
        setIsLoading(false)
        // console.log("ARR ", newArr)
      }).catch(error => {
        console.log(error)
        if (!!error.response && !!error.response.status && error.response.status === 401) {
          Logout(dispatch, history, false);
        }
        if (error.message === 'Network Error') {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      })
  }

  const getUserSkillList = () => {
    api.onlineCV.getSkills(authToken).
      then(result => {
        console.log("GET SKILL selected", result)
        if (result.resultCode === 1) {
          // temp.push(result.resultData.userSkillList)
          setUserSkillList(result.resultData.userSkillList)
          // passToStepThree(true)
        } else {
          // passToStepThree(false)
        }
        setIsLoading(false)
      }).catch(error => {
        setIsLoading(false)
        console.log(error)
        if (!!error.response && !!error.response.status && error.response.status === 401) {
          Logout(dispatch, history, false);
        }
        if (error.message === 'Network Error') {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      })
  }

  const selectedSkillFromList = (sk, idx) => {
    console.log('sk.skillName ', sk.skillName);
    let sIndex = userSkillList.indexOf(sk.skillName);
    console.log('sIndex ', sIndex);
    let updatedUserSkillList = JSON.parse(JSON.stringify(userSkillList));
    console.log('updatedUserSkillList.length ', updatedUserSkillList.length);
    if (sIndex === -1) {
      updatedUserSkillList.push(sk.skillName);
    } else {
      updatedUserSkillList.splice(sIndex, 1);
      console.log('updatedUserSkillList ', updatedUserSkillList);
    }
    setUserSkillList(updatedUserSkillList);
    // let list = [...filteredSkillList];
    // const index = list.findIndex(i => 
    // console.log("find index", i.skillName === sk.skillName)

    // )
    // console.log("index :bfore  ", list[idx], index);
    // list[idx].isSelected = !sk.isSelected;
    // console.log("index : ", list[idx]);
    // setFilteredSkillList(list)
  }

  const addMoreSkill = () => {
    if (addSkill) {
      const list = JSON.parse(JSON.stringify(userSkillList));
      list.push(addSkill);
      setUserSkillList(list);
      setAddSkill('');
      // console.log("LIST ", list)
      // list.push({ skillName: addSkill, isSelected: true });
      // console.log("LIST ", list)
      // setSkillList(list);
    }
  }

  const saveSkills = () => {
    // var selectedArr = [];
    // skillList.filter((item, idx) => {

    //   if (item.isSelected) {
    //     selectedArr.push(item.skillName)
    //   }
    // })
    setIsLoading(true)
    var data = {
      userSkillList: userSkillList
    }

    if (userSkillList.length === 0) {
      setIsLoading(false)
      console.log("DATA if", data)
    }
    console.log("DATA ", data)
    api.onlineCV.saveSkill(data, authToken).
      then(result => {
        console.log("SAVE SKIL ", result)
        if (result.resultCode === 1) {
          setMessage("Skills saved successfully");
          setAlertClr("success");
          setShowAlert(true);
          setTimeout(() => {
            if (userSkillList.length > 0) {
              onSkillAction("skill");
            } else {
              onSkillAction("emptySkill");
            }
          }, 2000);
          // passToStepThree(true)
        } else {
          if (result.resultMsg === 'Nothing to save. No changes found!') {
            setMessage("Can not update, No change found");
            setAlertClr("danger");
            setShowAlert(true);
          } else {
            setMessage(result.resultMsg);
            setAlertClr("danger");
            setShowAlert(true);
          }
          // passToStepThree(false)
        }
        // setTimeout(() => {
        //   history.push({
        //     pathname: '/createCV'
        //   })
        // }, 2500);
        setIsLoading(false)
        window.scrollTo(0, 850);;
      }).catch(error => {
        setIsLoading(false)
        // passToStepThree(false)
        console.log(error)
        if (!!error.response && !!error.response.status && error.response.status === 401) {
          Logout(dispatch, history, false);
        }
        if (error.message === 'Network Error') {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      })
  }

  const short = (str, allowedLength = 10) => {
    if (str && str.length > allowedLength) {
      return str.slice(0, allowedLength) + "..."
    }
    return str
  }


  return (
    <div className="card profile-edit-container mt-5">
      {isLoading &&
        <div style={{ zIndex: '1500' }} className="d-flex justify-content-center">
          < Loader type="spinner-cub" bgColor={"rgb(53, 50, 50)"} title={"Loading..."} size={100} />
        </div>
      }

      <Form className="form-style p-5" style={{ fontSize: "larger" }}>
        <Alert className='alert-position' isOpen={showAlert} color={alertClr}>{message} </Alert>
        <Form.Group >
          {/* <Form.Label style={{ color: 'black', fontSize: '13px' }} className="cust-label shiftToLeft mt-4">Skills</Form.Label> */}
          <div className="password-position" >
            <Form.Control className="cv-skill-inputStyle"
              type="text"
              onChange={(e) => {
                setAddSkill(e.target.value);
              }}
              value={addSkill}
            />
            <span className="material-icons">
              <i class="fa fa-plus" aria-hidden="true" onClick={addMoreSkill}></i>
            </span>
          </div>
        </Form.Group>

        <div style={{ paddingBottom: '5rem' }} className="mt-5">
          {
            filteredSkillList.map((s, idx) => {

              return (
                <>
                  <div key={s.skillName} className="inline-skill">
                    <Badge className="mt-2 c-badge" pill bg="light"
                      style={s.isSelected ?
                        { backgroundColor: '#007bff', color: 'white', cursor: 'pointer', height: '25px', padding: '10px' }
                        :
                        { backgroundColor: '#cde1e8', color: 'black', cursor: 'pointer', height: '25px', padding: '10px' }
                      }
                      onClick={() => selectedSkillFromList(s, idx)}
                    >
                      {(s.skillName)}
                      {/* {short(s.skillName, 15)} */}
                    </Badge>
                  </div>

                  {/* <div key={s.skillName} className="in-single-line-skill">
                    <div className="box-row-size">
                      <Card className='card-style-skill' >
                        <Card.Body className="card-box-skill"
                          style={s.isSelected ?
                            { backgroundColor: '#007bff', color: 'white', cursor: 'pointer' }
                            :
                            { backgroundColor: '', color: 'black', cursor: 'pointer' }}
                          onClick={() => selectedSkillFromList(s, idx)}>{short(s.skillName, 15)}</Card.Body>
                      </Card>
                    </div>
                  </div> */}
                </>
              )
            })
          }

        </div>
        <div className="d-flex justify-content-center">
          {/* <Row>
          <Col xl="4" lg="3 mr-5 ml-1" md="4" sm="12" xs="12"> */}
          {/* <Button
            onClick={() => {
              previousStep(1)
            }}
            className="my-cust-button prev-btn mt-2 pl-5 pt-1 pb-1 pr-5"
          >
            Previous
          </Button> */}
          {/* </Col>
          <Col xl="3 mr-5" lg="3" md="4" sm="12" xs="12"> */}
          <Button
            onClick={saveSkills}
            className="my-cust-button mt-2 pl-5 pt-1 pb-1 pr-5 "
          >
            Save
          </Button>
          {/* </Col>
          <Col xl="3" lg="3" md="4" sm="12" xs="12"> */}
          {/* <Button
            onClick={() => {
              saveSkills();
              nextStep(3)
            }}
            // style={{ width: '100%' }}
            className="my-cust-button ml-4 mt-2  pl-5 pt-1 pb-1 pr-5"
          >
            Save & Next
          </Button> */}
          {/* </Col>
        </Row> */}
        </div>
      </Form>
    </div>
  )
}

export default Skills;

