import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { typography } from "../../assets/design_rules";
import { useDeviceSize } from "../../helpers/customHooks";
import "./theme-popup.scss";
import ThemeButton from "../theme-button/ThemeButton";
const ThemePopup = ({
  open,
  height = "30vh",
  titleFontSize = typography.x_large,
  descriptionFontSize = typography.large,
  title = "Congrats!",
  subtitle ="Bla bla bla",
  positive,
  negative,
  onClickPositive,
  onClickNegative,
  deviceSize,
}) => {
  const screenSizes = {
    sm: "30vw",
    md: "50vw",
    lg: "75vw",
    xl: "90vw",
  };

  console.log("Device Size", deviceSize);

  useEffect(() => {
    console.log(deviceSize);
  });

  const { sm, md, lg, xl } = screenSizes;
  //   const getWidth = (deviceSize) => {
  //     switch (deviceSize) {
  //       case "isXs":
  //         return screenSizes.xl;
  //       case "isSm":
  //         return screenSizes.lg;
  //       case "isMd":
  //         return screenSizes.lg;
  //       case "isL":
  //         return screenSizes.sm;
  //       case "isXl":
  //         return screenSizes.sm;
  //       default:
  //         return screenSizes.sm;
  //     }
  //   };

  //   const getFontSizeTitle = (deviceSize) =>{
  //     switch (deviceSize) {
  //         case "isXs":
  //           return typography.large;
  //         case "isSm":
  //           return typography.large;
  //         case "isMd":
  //           return typography.xx_large;
  //         case "isL":
  //           return typography.xx_large;
  //         case "isXl":
  //           return typography.xx_large;
  //         default:
  //           return screenSizes.sm;
  //       }
  //   }

  //   const getFontSizeSub = (deviceSize) =>{
  //     switch (deviceSize) {
  //         case "isXs":
  //           return typography.secondary;
  //         case "isSm":
  //           return typography.primary;
  //         case "isMd":
  //           return typography.large;
  //         case "isL":
  //           return typography.large;
  //         case "isXl":
  //           return typography.large;
  //         default:
  //           return screenSizes.primary;
  //       }
  //   }

  const [customStyles, setCustomStyles] = useState({
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "30px",
      padding: "3rem 5rem",
      width: "30vw",
      // height: height,
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      // overflow:"visible",
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    },
  });

  // useEffect(()=>{
  //     setCustomStyles({
  //         ...customStyles,
  //         content:{
  //             ...customStyles.content,
  //             width:getWidth(deviceSize)
  //         }
  //     })
  // },[deviceSize])

  //   const titleStyle = {
  //     fontSize: getFontSizeTitle(deviceSize),
  //   };

  //   const subStyle = {
  //     fontSize: getFontSizeSub(deviceSize),
  //   };

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  
  return (
    <div className="theme-popup">
      <Modal
        isOpen={open}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h4 >{title}</h4>
        <p >{subtitle}</p>
        <div className="button-grp">
          <ThemeButton onClick={onClickPositive}>{positive}</ThemeButton>
          <ThemeButton onClick={onClickNegative} textOnly={true}>
            {negative}
          </ThemeButton>
        </div>
      </Modal>
    </div>
  );
};

export default ThemePopup;
