import React from "react";
import { Row, Col } from "reactstrap";
import "../Pages/Jobs/Jobs.css";

const JobCard = () => {
  return (
    <div className="custom-card">
      <Row>
        <Col>
          <img src="#" />
        </Col>
        <Col>
          <h6>HR EXECUTIVE</h6>
          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <p>location</p>
            <h6>Hong Kong SAR</h6>
          </div>
        </Col>
      </Row>
      <Row>
      <p className="mt-5" style={{marginLeft:"auto",marginRight:"auto",width:"60%"}}>
              Labor Laws, PF Selection, 
              Employee Training,Payroll,
              Processing,Gratuity,Bonus
            </p>
      </Row>

    </div>
  );
};

export default JobCard;
