import React from 'react'
import './fixBanner.css'
import Carousel from "react-bootstrap/Carousel";
import banner from '../../assets/logo/Banner@3x.png'
import Loader from "react-js-loader";
// import banner from '../../assets/logo/job-banner.jpg'

const FixBanner = ({ headContent, content }) => {

    return (
        <React.Fragment>
          
            <Carousel className="book-carousel">
                <Carousel.Item
                    className="car-item"

                // style={{backgroundImage:`url(${item.imgsrc})`,height:"80vh",  backgroundRepeat:"no-repeat", backgroundAttachment:"contain",backgroundSize:"cover"}}
                // interval={4000}
                >
                    <img className="" src={banner} alt="First slide" />

                    <Carousel.Caption
                        // className="car-caption"
                        style={{
                            top: "20%",
                            // transform: "translateY(-50%)",
                        }}
                    >
                        <h5 style={{ display: 'flex', justifyContent: 'flex-start' }}>{headContent}</h5>
                        <h3 style={{ display: 'flex', justifyContent: 'flex-start' }}>{content}</h3>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </React.Fragment>
    )
}

export default FixBanner;