import React, { useEffect, useState } from "react";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Col,
  PopoverHeader,
  PopoverBody,
  Row,
  Alert,
} from "reactstrap";
import "./Profile.css";
import "../../assets/Media-queries.css";
import { useMediaQuery } from "react-responsive";
import EditProfile from "./EditProfile";
import RightTick from "../../svg_objects/RightTick";
import Dropzone from "react-dropzone";
import Drawer from "react-bottom-drawer";
import api from "../../api";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Logout from "../../logOut/Logout";
import { Modal, OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import { API_IMAGE_URL, API_SERVER_URL } from "../../app_constants";
import CustModal from "../../components/cust-modal/CustModal";
import CustDialog from "../../components/cust-dialog/CustDialog";
import CvViewer from "../../Component/forMobileCv/CvViewer";
import Loader from "react-js-loader";
import { useSelector } from "react-redux";
import { useDeviceSize } from "../../helpers/customHooks";

const Profile = () => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [viewCvClick, setViewCvClick] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const deviceSize = useDeviceSize();
  console.log("DEVICE SIZE", deviceSize);
  const history = useHistory();
  const dispatch = useDispatch();
  const toggle = () => setPopoverOpen(!popoverOpen);

  const [mode, setMode] = useState(
    history.location.state && history.location.state.status === "EDITREWARD"
      ? "EDITREWARD"
      : "DISPLAY"
  );

  const profileData = useSelector((state) => state.profileData);

  const [alertClr, setAlertClr] = useState();
  const [deviceType, setDeviceType] = useState("");
  const [popUpStatus, setPopUpStatus] = useState(false);
  const [viewCVLink, setViewCVLink] = useState("");
  const acceptedFileType = "application/pdf";
  const imageMaxSize = 2097152; //bytes
  const auth = useSelector((state) => state.auth_token);
  const [isErr, setIsErr] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setOpen] = React.useState(false);
  const [dialogStatus, setDialogStatus] = useState(false);
  const [isViewCvClick, setIsViewCvClick] = useState(false);
  const [isCvComplete, setIsCvComplete] = useState(false);
  const [isBtnDisable, setIsBtnDisable] = useState(false);
  const [createdCvId, setCreatedCvId] = useState(null);
  const [dialogMsg, setDialogMsg] = useState("");

  const handleOpenDialog = () => {
    setDialogStatus(true);
  };
  const handleCloseDialog = () => {
    setDialogStatus(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    checkDevice();
    isCvDone();
    console.log(
      "hiostory_Location",
      history.location.state && history.location.state.status
    );
  }, []);

  const checkDevice = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setDeviceType("Mobile");
    } else {
      setDeviceType("Desktop");
    }
  };

  const isCvDone = () => {
    // check is CV compleyte
    setIsLoading(true);
    api.onlineCV
      .isCvComplete(auth)
      .then((result) => {
        console.log("is CV comleted ", result);
        if (result.resultCode === 1) {
          setIsCvComplete(result.resultData.isComplete);
          setIsBtnDisable(result.resultData.enableGeneratePdfButton);
          setCreatedCvId(result.resultData.userCvDetails.profile.cvId);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setIsErr(true);
          setAlertClr("danger");
          window.scrollTo(0, 0);
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 3000);
        }
      });
    console.log("profile ", profileData);
    setViewCVLink(profileData && profileData.cvLink);
  };

  useEffect(() => {
    console.log("Profile DATA", profileData);
    setViewCVLink(profileData && profileData.cvLink);
  }, [profileData]);

  const onClose = React.useCallback(() => {
    setPopUpStatus(false);
  }, []);

  useEffect(() => {
    setViewCvClick(true);
  }, [viewCvClick]);

  const handleCVOnDrop = (files, rejectedFiles) => {
    console.log("rejectedFiles ....", rejectedFiles);
    if (rejectedFiles && rejectedFiles.length > 0) {
      switch (rejectedFiles[0].errors[0].code) {
        case "file-invalid-type":
          setMessage("File type must be pdf");
          setIsErr(true);
          window.scrollTo(0, 0);
          setAlertClr("danger");
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 3000);
          break;

        case "file-too-large":
          setMessage("CV file size should be less than 2 MB");
          setIsErr(true);
          setAlertClr("danger");
          window.scrollTo(0, 0);
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 3000);
          break;

        default:
          break;
      }
    }

    console.log("FILE ", files);

    const data = new FormData();
    data.append("cv", files[0]);
    console.log(data);
    console.log(files[0]);

    console.log(auth);
    setIsLoading(true);
    api.jobApplication
      .uploadCV(data, auth)
      .then((result) => {
        console.log("PATH CV PROFILE", result.resultData.profile);
        if (result.resultCode === 1) {
          setMessage("Your CV has been uploaded successfully");
          setIsErr(false);
          setAlertClr("success");
          window.scrollTo(0, 0);
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 3000);

          dispatch({
            type: "set_profile_data",
            profileData: result.resultData.profile,
          });
          dispatch({
            type: "shouldeReloadJobList/save",
            shouldeReloadJobList: true,
          });
          // setCVLink(result.resultData.profile.cvLink)
        } else {
          setMessage(result.resultMsg);
          setIsErr(true);
          setAlertClr("danger");
          window.scrollTo(0, 0);
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 3000);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setIsErr(true);
          setAlertClr("danger");
          window.scrollTo(0, 0);
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 3000);
        }
      });
    console.log("CV NEW CV ", profileData.cvLink);

    setPopUpStatus(false);
  };
  const uploadeCVWithUploaded = () => {
    console.log("CV NEW CV ", profileData.cvLink);
    setPopUpStatus(false);
    // setCVLink(profile.cvLink)
    // applyJob(profileData.cvLink);
  };

  const showCvPdf = () => {
    api.onlineCV
      .userCv(auth)
      .then((result) => {
        console.log("user CV ", result);
        setViewCVLink(result.resultData.userCvProfile.createdCvLink);
        if (deviceType === "Mobile") {
          setIsViewCvClick(true);
        } else {
          window.open(
            API_SERVER_URL + result.resultData.userCvProfile.createdCvLink
          );
        }
        setDialogStatus(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setIsErr(true);
          setAlertClr("danger");
          window.scrollTo(0, 0);
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 3000);
        }
      });
  };

  return (
    <div className="profile-container">
      <Alert className="alert-position" isOpen={open} color={alertClr}>
        {message}{" "}
      </Alert>
      {isLoading && (
        <div
          style={{ zIndex: "1500" }}
          className="d-flex justify-content-center"
        >
          <Loader
            type="spinner-cub"
            bgColor={"rgb(53, 50, 50)"}
            title={"Loading..."}
            size={100}
          />
        </div>
      )}
      <CustDialog
        open={dialogStatus}
        handleClose={handleCloseDialog}
        message={
          profileData && profileData.status === 0
            ? "Kindly Login first to Edit your profile"
            : dialogMsg
        }
        agree={profileData && profileData.status === 0 ? "Login" : "Verify Now"}
        disagree={"Close"}
        routepath={profileData && profileData.status === 0 ? "/signIn" : "/OTP"}
        title={"Email verification needed"}
      />
      <CvViewer
        showCV={isViewCvClick}
        setShowCv={setIsViewCvClick}
        url={API_SERVER_URL + viewCVLink}
      />
      {/* <CustModal 
        open={open}
        isErr={isErr}
        handleClose={handleClose}
        message={message}
      /> */}

      {deviceType === "Mobile" ? (
        <Drawer
          style={{ height: "70%", zIndex: "15" }}
          isVisible={popUpStatus}
          onClose={onClose}
        >
          <h5>Upload Your CV</h5>
          {profileData && profileData.cvLink && (
            <Button
              className="button-cv-style border-cv"
              // href={(profile && API_SERVER_URL + profile.cvLink)}
              onClick={() => {
                setPopUpStatus(false);
                setViewCVLink(profileData.cvLink);
              }}
            >
              View CV
            </Button>
          )}
          <div className="dropzone-div">
            <Dropzone
              onDrop={handleCVOnDrop}
              className="dropzone-size"
              multiple={false}
              maxSize={imageMaxSize}
              accept={acceptedFileType}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  className="for_sm_text"
                  {...getRootProps({ className: "dropzone" })}
                  style={{ marginTop: "1rem" }}
                >
                  <section
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <input {...getInputProps()} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {profileData && profileData.cvLink && (
                        <Button className="button-cv-style border-cv">
                          Upload New CV{" "}
                        </Button>
                      )}
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {profileData && !profileData.cvLink && (
                        <Button className="button-cv-style border-cv">
                          Upload CV
                        </Button>
                      )}
                    </div>
                  </section>
                </div>
              )}
            </Dropzone>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "-7px",
                marginTop: "10px",
              }}
            >
              <p>Supported file format: PDF</p>
            </div>
            <div
              style={
                profileData && !profileData.cvLink
                  ? {
                      marginBottom: "10rem",
                      display: "flex",
                      justifyContent: "center",
                    }
                  : { display: "flex", justifyContent: "center" }
              }
            >
              <p>Maximum file size: 2 MB</p>
            </div>
          </div>

          {/* <CVDropzone
           
            //  acceptedFileType={acceptedFileType} 
            handleCVOnDrop={handleCVOnDrop} /> */}
        </Drawer>
      ) : (
        <Modal style={{ marginTop: "110px" }} show={popUpStatus}>
          <Modal.Header className="CV-header">
            <h5>Submit CV</h5>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {profileData && profileData.cvLink && (
                <Button
                  className="button-cv-style border-cv"
                  href={profileData && API_SERVER_URL + profileData.cvLink}
                  target="_blank"
                  onClick={() => {
                    setPopUpStatus(false);
                    // setIsViewCvClick(true)
                    // setViewCVLink(profile.cvLink)
                  }}
                >
                  View CV
                </Button>
              )}
            </div>

            <div className="dropzone-div">
              <Dropzone
                onDrop={handleCVOnDrop}
                className="dropzone-size"
                multiple={false}
                maxSize={imageMaxSize}
                accept={acceptedFileType}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    className="for_sm_text"
                    {...getRootProps({ className: "dropzone" })}
                    style={{ marginTop: "1rem" }}
                  >
                    <section
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <input {...getInputProps()} />
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {profileData && profileData.cvLink && (
                          <Button className="button-cv-style border-cv">
                            Upload New CV{" "}
                          </Button>
                        )}
                      </div>

                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {profileData && !profileData.cvLink && (
                          <Button className="button-cv-style border-cv">
                            Upload CV
                          </Button>
                        )}
                      </div>
                    </section>
                  </div>
                )}
              </Dropzone>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "-7px",
                  marginTop: "10px",
                }}
              >
                <p>Supported file format: PDF</p>
              </div>
              <div
                style={
                  profileData && !profileData.cvLink
                    ? {
                        marginBttom: "9rem",
                        display: "flex",
                        justifyContent: "center",
                      }
                    : { display: "flex", justifyContent: "center" }
                }
              >
                <p>Maximum file size: 2 MB</p>
              </div>

              {/* <CVDropzone

               //  acceptedFileType={acceptedFileType} 
               handleCVOnDrop={handleCVOnDrop} /> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: "#c5c4c4", color: "black" }}
              onClick={() => {
                setPopUpStatus(false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <div
        className=" p-5 card"
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {mode === "EDIT" && (
          <i
            onClick={() => {
              setMode("DISPLAY");
            }}
            className="fas fa-chevron-left pointer fa-2x mr-auto"
          ></i>
        )}
        {mode === "DISPLAY" ? (
          <h1 className="ml-auto mr-auto">Profile</h1>
        ) : (
          <h1 className="mr-auto">Personal Info</h1>
        )}
      </div>
      {mode === "DISPLAY" ? (
        <div className={`text-center details-container mt-5`}>
          <div className=" w-100 mt-5 p-5 pb-0  d-flex justify-content-center align-items-center">
            <div className=" d-flex w-100">
              {profileData && profileData.status === 0 ? (
                <h2 className="mr-5 ml-auto ">Guest User</h2>
              ) : (
                <>
                  <h2>{profileData && profileData.firstName}</h2>
                  <h2>{profileData && profileData.lastName}</h2>
                </>
              )}

              <i
                onMouseEnter={() => {
                  setPopoverOpen(true);
                }}
                onMouseLeave={() => {
                  setPopoverOpen(false);
                }}
                className="fas fa-pen  fa-2x text-primary pointer mr-auto"
                // style={{ marginBottom: '13px' }}
                onClick={
                  (profileData && profileData.status === 1) ||
                  (profileData && profileData.status === 0)
                    ? () => {
                        setDialogStatus(true);
                        setDialogMsg(
                          "You need to verify email first to update profile"
                        );
                      }
                    : () => {
                        setMode("EDIT");
                      }
                }
              ></i>
            </div>
          </div>

          {profileData && profileData.status === 0 ? (
            <h3 className="text-muted">
              You need to Register/Login to see profile
            </h3>
          ) : (
            <>
              <span>
                <div className=" ml-auto d-flex justify-content-between    mr-auto w-75 mt-3">
                  <div className="d-flex ">
                    <i class="far fa-envelope  fa-2x m-0 mr-3 p-0"></i>
                    <h3>{profileData && profileData.email}</h3>
                  </div>
                  <div className=" w-100 d-flex justify-content-end align-items-center">
                    <h3 style={{ color: "#8bc34a", height: "fit-content" }}>
                      {profileData && profileData.status === 2 ? (
                        <>Verified</>
                      ) : (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Popover className="p-3" id="popover-contained">
                              Please verify your email address
                            </Popover>
                          }
                        >
                          <div
                            className="d-flex pointer"
                            onClick={() => {
                              history.push("/OTP");
                            }}
                            style={{ color: "#499cff" }}
                          >
                            Verify
                            <i class="fas fa-exclamation non-verify"></i>
                          </div>
                        </OverlayTrigger>
                      )}
                    </h3>
                  </div>
                </div>
              </span>
              <span>
                <div className=" ml-auto d-flex justify-content-between mr-auto w-75 mt-5">
                  <div className="d-flex">
                    {deviceSize === "isSm" || deviceSize === "isXs" ? (
                      <i class="fas fa-phone-alt fa-2x mr-3 mb-3"></i>
                    ) : (
                      <i class="fas fa-phone-alt fa-2x  mr-3 mb-3"></i>
                    )}
                    <h3>{profileData && profileData.mobile}</h3>
                  </div>

                  <div
                    lg="4"
                    className=" d-flex justify-content-start align-items-center"
                  >
                    {" "}
                  </div>
                </div>
              </span>
              <hr className="cv_border w-75 mt-5" />
              <span>
                <div className=" ml-auto mr-auto w-75 mt-5 pt-5">
                  <h1>Curriculum Vitae</h1>
                </div>
              </span>
              <span>
                <div className=" ml-auto mr-auto w-50 mt-5">
                  <div
                    lg="4"
                    className="d-flex justify-content-center mt-5 align-items-center"
                  >
                    <h3
                      style={
                        profileData && !profileData.cvLink
                          ? { marginBottom: "" }
                          : { marginBottom: "-3rem" }
                      }
                    >
                      Create Your CV
                    </h3>
                  </div>
                </div>
              </span>
              <span className="mt-2">
                {/* <Col
                      lg="4"
                      className="d-flex justify-content-center align-items-center"
                    > */}
                {isCvComplete ? (
                  <>
                    <div className="mt-5  pt-5 pb-3 mb-5">
                      <Button
                        className="my-cust-button mr-3"
                        // href={deviceType === "Desktop" && (API_SERVER_URL + createdCvLink)} target='_blank'
                        disabled={isBtnDisable}
                        // onClick={() => {
                        //   if (deviceType === "Mobile") {
                        //     setIsPopUpShow(false);
                        //     setViewCVLink(createdCvLink)
                        //     setIsViewCvClick(true)
                        //   }
                        // }}
                        onClick={showCvPdf}
                      >
                        View Created CV
                      </Button>

                      <Button
                        className="my-cust-button"
                        onClick={() => {
                          history.push("/createCV");
                        }}
                      >
                        Edit CV
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex justify-content-center mt-4">
                      <Button
                        className="my-cust-button mt-5"
                        onClick={() => {
                          if (profileData && profileData.status === 1) {
                            setDialogStatus(true);
                            setDialogMsg(
                              "You need to verify email first to create online CV"
                            );
                          } else {
                            history.push("/createCV");
                          }
                        }}
                      >
                        Create CV
                      </Button>
                    </div>
                  </>
                )}
                {/* </Col> */}
                <Col lg="4"> </Col>
              </span>
              <span>
                <div className=" ml-auto mr-auto w-50 mt-5">
                  {!createdCvId && (
                    <>
                      <h3 className="">Earn 30 Rewards points</h3>
                      <h3 className="text-muted mb-5 pb-5">
                        by creating your CV with Opportunity Bank
                      </h3>
                    </>
                  )}
                  <hr
                    className="w-100 mt-5 text-muted"
                    style={{ border: "1px solid ", backgroundColor: "grey" }}
                  />
                </div>
              </span>
              <span>
                <div className=" ml-auto mr-auto w-75 mt-5 mt-5">
                  <h3
                    style={
                      profileData && !profileData.cvLink
                        ? { marginBottom: "" }
                        : { marginBottom: "-3rem" }
                    }
                  >
                    Upload Your CV
                  </h3>
                  {profileData && profileData.cvLink !== null ? (
                    <div className="mt-5  pt-5 pb-3 mb-5">
                      <Button
                        className="my-cust-button mr-3"
                        onClick={() => {
                          if (deviceType === "Mobile") {
                            setIsViewCvClick(true);
                          } else {
                            setIsViewCvClick(false);
                            console.log(API_SERVER_URL + viewCVLink);
                            window.open(API_SERVER_URL + viewCVLink);
                          }
                        }}
                      >
                        View Uploaded CV
                      </Button>
                      <Button
                        className="my-cust-button"
                        onClick={() => {
                          if (profileData && profileData.status === 1) {
                            setDialogStatus(true);
                          } else {
                            setPopUpStatus(true);
                          }
                        }}
                      >
                        Change CV
                      </Button>
                    </div>
                  ) : (
                    <Button
                      className="my-cust-button mt-5 mb-5"
                      onClick={() => {
                        if (profileData && profileData.status === 1) {
                          setDialogStatus(true);
                          setDialogMsg(
                            "You need to verify email first to upload CV"
                          );
                        } else {
                          setPopUpStatus(true);
                        }
                      }}
                    >
                      Upload CV
                    </Button>
                  )}
                  <h4 className="text-muted">Supported file formats: PDF</h4>
                  <h4 className="text-muted">Maximum file size: 2MB</h4>
                </div>
              </span>
              <hr className="cv_border w-75 mt-5" />
            </>
          )}
        </div>
      ) : (
        <EditProfile
          status={history.location.state && history.location.state.status}
          setMode={setMode}
        />
      )}
    </div>
  );
};

export default Profile;
