/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import phone from "../../assets/logo/call@1.5x.png";
import whatsUp from "../../assets/logo/whatsapp@1.5x.png";
import location from "../../assets/logo/location@1.5x.png";
import email from "../../assets/logo/email@1.5x.png";
import { Row, Col } from "react-bootstrap";
import "./contactUs.css";
import fbLog from "../../assets/logo/FB@3x.png";
import twitLogo from "../../assets/logo/TT@2x.png";
import instaLogo from "../../assets/logo/instagram-2@3x.png";
import linkdinLogo from "../../assets/logo/linkedin-2@3x.png";
import youtubeLogo from "../../assets/logo/youtube-3@3x.png";
import { Jumbotron } from "reactstrap";
import GoogleMap from "../../components/map/GoogleMap";
import { services } from "../../helpers/utilities";
import { ACTIVITY } from "../../helpers/ACTIVITY_CONSTANTS";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

export default function ContactUS() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({type:'setCurrentLink',currentLink:'/contactUs'})
    window.scrollTo(0, 0);
  }, []);
  const profile = useSelector((state) => state.profileData);
  return (
    <div>
      <div>
        <Jumbotron className="jumbotron pl-5">
          <h1>Connect with Us</h1>
        </Jumbotron>
        <div
          className=" pl-5 pr-5 pb-5 "
          style={{ background: "#ffffff", paddingTop: "5rem" }}
        >
          <Row>
            <Col>
              <div className="pl-5">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "-15px",
                  }}
                >
                  <img src={phone} style={{ width: "22px", height: "22px" }} />
                  <div
                    style={{ display: "flex", flex: "1", marginLeft: "25px" }}
                  >
                    <h5 className="highlighted-desc">+852 9133 4700</h5>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "12px",
                  }}
                >
                  <img
                    src={whatsUp}
                    style={{ width: "22px", height: "22px" }}
                  />
                  <div
                    style={{ display: "flex", flex: "1", marginLeft: "25px" }}
                  >
                    <h5 className="highlighted-desc">+852 9133 4700</h5>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "12px",
                  }}
                >
                  <img src={email} style={{ width: "22px", height: "22px" }} />
                  <div
                    style={{ display: "flex", flex: "1", marginLeft: "25px" }}
                  >
                    <h5 className="highlighted-desc">
                      oppbank@zubinfoundation.org{" "}
                    </h5>
                  </div>
                </div>

                <div
                  className="mt-5"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "12px",
                  }}
                >
                  <img
                    src={location}
                    style={{ width: "20px", height: "21px" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "22px",
                      paddingBottom: "20px",
                    }}
                  >
                    <div className="highlighted-desc">
                      <p> The Zubin Mahtani Gidumal Foundation Limited</p>
                      <p> Unit F, 5th Floor, High Fashion Center </p>
                      <p> No. 1 Kwai Hei Street</p>
                      <p> Kwai Fong, Hong Kong</p>
                    </div>

                    {/* 5/F, Unit F-J, Block 2 Kwai Tak Industrial Centre 15-33 Kwai Tak Street Kwai Chung Hong Kong */}
                  </div>
                </div>
              </div>
            </Col>
            <Col
              className="pr-5"
              style={{ marginRight: "5rem" }}
              xl="6"
              lg="7"
              md="12"
              sm="12"
              xs="12"
            >
              {/* <GoogleMap latitude={22.357593223613474} longitude={114.12403235845366} /> */}

              {/* <embed width="600" height="400"  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3689.9060378409554!2d114.12195096495572!3d22.357176485294058!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403f8beee863389%3A0xa3b1b769c9eb6007!2sHigh%20Fashion%20Centre!5e0!3m2!1sen!2sin!4v1632913366089!5m2!1sen!2sin"/> */}

              <iframe
                title="Map"
                style={{ border: "none", borderRadius: "1rem" }}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3689.9060378409554!2d114.12195096495572!3d22.357176485294058!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403f8beee863389%3A0xa3b1b769c9eb6007!2sHigh%20Fashion%20Centre!5e0!3m2!1sen!2sin!4v1632913366089!5m2!1sen!2sin"
                className="map-style"
                loading="lazy"
              />
            </Col>
          </Row>
          <hr
            className="mr-auto ml-auto mb-5"
            style={{
              width: "90%",
              border: "1px solid #979797",
              background: "#979797",
            }}
          />

          <div className="" style={{ marginLeft: "7rem" }}>
            <h4
              style={{
                display: "flex",
                justifyContent: "start",
                marginTop: "20px",
              }}
            >
              Follow Us
            </h4>
            <p className="description  mb-4">
              Connect with us to keep up-to-date on our latest resources,
              opportunities and events.
            </p>
          </div>
          <div className="" style={{ marginLeft: "7rem" }}>
            <img
              onClick={() => {
                services.statusCheck(profile.status) &&
                  services.activityLogService(
                    ACTIVITY.CLICK_MORE_FOLLOW_ON_FACEBOOK,
                    profile
                  );
                window.location.replace(
                  "https://m.facebook.com/OpportunityBank4EM/"
                );
              }}
              src={fbLog}
              style={{ width: "30px", height: "30px", marginRight: "1rem" }}
            />
            <img
              onClick={() => {
                services.statusCheck(profile.status) &&
                  services.activityLogService(
                    ACTIVITY.CLICK_MORE_FOLLOW_ON_INSTAGRAM,
                    profile
                  );
                window.location.replace(
                  "https://instagram.com/oppbank?utm_medium=copy_link"
                );
              }}
              src={instaLogo}
              style={{ width: "30px", height: "30px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
