import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import './createCv.css'
import api from '../../api';
import Logout from '../../logOut/Logout';
import { useDispatch, useSelector } from 'react-redux';

const CreateCVForm = ({ }) => {

  const [addressObj, setAddressObj] = useState({});
  const [careerObj, setCareerObj] = useState('');
  const [skillArr, setSkillArr] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();
  let authToken = useSelector(state=>state.auth_token)

  useEffect(() => {
    console.log(authToken)
    api.onlineCV.getAddress(authToken).
      then(result => {
        console.log("RESULT => get address", result);
        if (result.resultCode === 1) {
          setAddressObj(result.resultData.userAddress)
        } else {
          setAddressObj({})
        }
      }).catch(error => {
        console.log(error)
        if (!!error.response && !!error.response.status && error.response.status === 401) {
          Logout(dispatch, history,false);
        }
        // if (error.message === 'Network Error') {
        //   setMessage("Please check your network connection!");
        //   setAlertClr("danger");
        //   setShowAlert(true);
        // }
      })
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    console.log(history.location)
    if (history.location && history.location.state) {
      setCareerObj(history.location.state.careerObjective)
    }
  }, [])

  const editAddress = () => {
    history.push({
      pathname: '/contactInfo',
      state: addressObj
    })
  }

  const editCareerObj = () => {
    history.push({
      pathname: '/careerObjective',
      state: careerObj
    })
  }

  useEffect(() => {
    api.onlineCV.getSkills(authToken).
      then(result => {
        console.log("GET SKILL selected", result)
        if (result.resultCode === 1) {
          setSkillArr(result.resultData.userSkillList)
        } else {
          setSkillArr([])
        }
      }).catch(error => {
        console.log(error)
        if (!!error.response && !!error.response.status && error.response.status === 401) {
          Logout(dispatch, history,false);
        }
      })
  }, [])

  return (
    <div>

      <h3 classNmae="header-style">Contact Information
            <i
          onClick={editAddress}
          className="fas fa-pen fa-1x edit-style"
          style={{ color: 'blue' }}
        ></i>
      </h3>

      <h5 className="data-cv-style">
        {addressObj.flat && `${addressObj.flat}, `}
        {addressObj.floor && `${addressObj.floor}, `}
        {addressObj.buildingName && `${addressObj.buildingName}, `}
        {addressObj.streetName && `${addressObj.streetName}, `}
        {addressObj.district && `${addressObj.district}`}
      </h5>
      {Object.keys(addressObj).length === 0 &&
        <Button
          className="cv-btn"
          onClick={() => {
            console.log("CLICK")
            history.push('/contactInfo')
          }}>
          Add contact detail
        </Button>
      }

      <h3 classNmae="header-style">Career Objective
            <i
          onClick={editCareerObj}
          className="fas fa-pen fa-1x edit-style"
          style={{ color: 'blue' }}
        ></i>
      </h3>
      <h5 className="data-cv-style">
        {careerObj}
      </h5>
      {!careerObj &&
        <Button
          className="cv-btn"
          onClick={() => {
            history.push('/careerObjective')
          }}>
          Add career objective
        </Button>
      }

      <h3 classNmae="header-style">Skills
            <i
          onClick={() => {
            history.push('/skills')
          }}
          className="fas fa-pen fa-1x edit-style"
          style={{ color: 'blue' }}
        ></i>
      </h3>
      <div className="mb-5">
        {
          skillArr.length > 0 ?
            skillArr.map((ski, idx) => {
              return (
                <h5 className="data-cv-style" style={{ marginBottom: '-10px' }}>
                  {ski}
                </h5>
              )
            })
            :
            <h5 className="data-cv-style" style={{ marginBottom: '-10px' }}>
              No Skill Selcted
    </h5>
        }
      </div>


      {!skillArr.length > 0 &&
        <Button
          className="cv-btn mb-5"
          onClick={() => {
            history.push('/skills')
          }}>
          Add skills
        </Button>
      }
    </div >
  )
}

export default CreateCVForm;