// import React, { Component } from 'react';
// import GoogleMapReact from 'google-map-react';

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

// class Test extends Component {
// 	static defaultProps = {
// 		// center: {
// 		//     lat: 59.95,
// 		//     lng: 30.33
// 		// },
// 		zoom: 11
// 	};
// 	renderMarkers = (map, maps) => {
// 		let marker = new maps.Marker({
// 			position: { lat: 22.357593223613474, lng: 114.12403235845366 },
// 			map,
// 			title: 'Hello World!'
// 		});
// 		return marker;
// 	};

// 	render() {
// 		return (
// 			// Important! Always set the container height explicitly
// 			<div style={{ height: '100vh', width: '100%' }}>
// 				<GoogleMapReact
// 					// bootstrapURLKeys={'AIzaSyAWCm8fLrC0D4l4GBYggEzpFfEf7lDh1Is'}
// 					defaultCenter={this.props.center}
// 					defaultZoom={this.props.zoom}
// 					defaultCenter={{
// 						lat: 22.357355101367798,
// 						lng: 114.12404309027458
// 					}}
// 					// 	lat: 22.357444,
// 					// 	lng:  114.124097
// 					// }}

// 					// 22.357355101367798, 114.12404309027458
// 					// onGoogleApiLoaded={({ map, maps }) => {
// 					// 	let marker = new maps.Marker({
// 					// 		position: { lat: 22.357355101367798, lng: 114.12404309027458 },
// 					// 		map,
// 					// 		// navigator
// 					// 	});
// 					// 	return marker;
// 					// }}
// 					onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
// 				>

// 					{/* <AnyReactComponent
//                         lat={22.357593223613474}
//                         lng={114.12403235845366}
//                         text="My Marker"
//                     /> */}

// 					{/* <Marker lat={22.357593223613474} lng={114.12403235845366} /> */}
// 				</GoogleMapReact>
// 			</div >
// 		);
// 	}
// }


// // 22.357593223613474, 114.12403235845366

// export default Test;



// import React from 'react';
// import GoogleMapReact from 'google-map-react';

// const Test = ({ latitude, longitude }) => {
// 	const renderMarkers = (map, maps) => {
// 		let marker = new maps.Marker({
// 			position: { lat: 22.357593223613474, lng: 114.12403235845366 },
// 			map,
// 			title: 'Hello World!'
// 		});
// 		return marker;
// 	};

// 	return (
// 		<div style={{ height: '100vh', width: '100%' }}>
// 			<GoogleMapReact
// 				defaultCenter={{ lat: 22.357593223613474, lng: 114.12403235845366 }}
// 				defaultZoom={16}
// 				yesIWantToUseGoogleMapApiInternals
// 				//   onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
// 				onGoogleApiLoaded={({ map, maps }) => {
// 					let marker = new maps.Marker({
// 						position: { lat: 22.357355101367798, lng: 114.12404309027458 },
// 						map,
// 						// navigator
// 					});
// 					return marker;
// 				}}
// 			>
// 			</GoogleMapReact>
// 		</div >
// 	);
// };

// export default Test;