import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Alert } from "reactstrap";
import api from "../../../api";
import { API_SERVER_URL } from "../../../app_constants";
import CVDrawer from "../../../components/cv-drawer/CVDrawer";
import { useDeviceSize } from "../../../helpers/customHooks";
import Logout from "../../../logOut/Logout";
import ProfileView from "./ProfileView";
import Loader from "react-js-loader";
import CustDialog from "../../../components/cust-dialog/CustDialog";
import CvViewer from "../../../Component/forMobileCv/CvViewer";

const ProfileContainer = () => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [viewCvClick, setViewCvClick] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const deviceSize = useDeviceSize();
  console.log("DEVICE SIZE", deviceSize);
  const history = useHistory();
  const dispatch = useDispatch();
  const toggle = () => setPopoverOpen(!popoverOpen);

  const [mode, setMode] = useState(
    history.location.state && history.location.state.status === "EDITREWARD"
      ? "EDITREWARD"
      : "DISPLAY"
  );

  const profileData = useSelector((state) => state.profileData);

  const [alertClr, setAlertClr] = useState();
  const [deviceType, setDeviceType] = useState("");
  const [popUpStatus, setPopUpStatus] = useState(false);
  const [viewCVLink, setViewCVLink] = useState("");
  const acceptedFileType = "application/pdf";
  const imageMaxSize = 2097152; //bytes
  const auth = useSelector((state) => state.auth_token);
  const [isErr, setIsErr] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setOpen] = React.useState(false);
  const [dialogStatus, setDialogStatus] = useState(false);
  const [isViewCvClick, setIsViewCvClick] = useState(false);
  const [isCvComplete, setIsCvComplete] = useState(false);
  const [isBtnDisable, setIsBtnDisable] = useState(false);
  const [createdCvId, setCreatedCvId] = useState(null);
  const [dialogMsg, setDialogMsg] = useState("");

  const handleOpenDialog = () => {
    setDialogStatus(true);
  };
  const handleCloseDialog = () => {
    setDialogStatus(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const verifyNow = () => {
    
      history.push({
        pathname: '/OTP',
        state: {
          status: "fromJoblist",
        },
      }
    );
  };

  const onViewUploadedCv = () => {
    if (deviceType === "Mobile") {
      setIsViewCvClick(true);
    } else {
      setIsViewCvClick(false);
      console.log(API_SERVER_URL + viewCVLink);
      window.open(API_SERVER_URL + viewCVLink);
    }
  };

  const onUploadCv = () => {
    if (profileData && profileData.status === 1) {
      setDialogStatus(true);
      setDialogMsg("You need to verify email first to upload CV");
    } else {
      setPopUpStatus(true);
    }
  };
  const onClickCreateCv = () => {
    if (profileData && profileData.status === 1) {
      setDialogStatus(true);
      setDialogMsg("You need to verify email first to create online CV");
    } else {
      history.push("/createCV");
    }
  };
  const onChangCv = () => {
    console.log("CHANGE CV")
    if (profileData && profileData.status == 1) {
      setDialogStatus(true);
    } else {
      setPopUpStatus(true);
    }
  };

  const onClickEditCv = () => {
    history.push("/createCV");
  };

  useEffect(() => {
    checkDevice();
    isCvDone();
    console.log(
      "hiostory_Location",
      history.location.state && history.location.state.status
    );
  }, []);

  const checkDevice = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setDeviceType("Mobile");
    } else {
      setDeviceType("Desktop");
    }
  };

  const isCvDone = () => {
    // check is CV compleyte
    setIsLoading(true);
    api.onlineCV
      .isCvComplete(auth)
      .then((result) => {
        console.log("is CV comleted ", result);
        if (result.resultCode === 1) {
          setIsCvComplete(result.resultData.isComplete);
          setIsBtnDisable(result.resultData.enableGeneratePdfButton);
          setCreatedCvId(result.resultData.userCvDetails.profile.cvId);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setIsErr(true);
          setAlertClr("danger");
          window.scrollTo(0, 0);
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 3000);
        }
      });
    console.log("profile ", profileData);
    setViewCVLink(profileData && profileData.cvLink);
  };

  useEffect(() => {
    console.log("Profile DATA", profileData);
    setViewCVLink(profileData && profileData.cvLink);
  }, [profileData]);

  const onClose = React.useCallback(() => {
    setPopUpStatus(false);
  }, []);

  useEffect(() => {
    setViewCvClick(true);
  }, [viewCvClick]);

  const handleCVOnDrop = (files, rejectedFiles) => {
    console.log("rejectedFiles ....", rejectedFiles);
    if (rejectedFiles && rejectedFiles.length > 0) {
      switch (rejectedFiles[0].errors[0].code) {
        case "file-invalid-type":
          setMessage("File type must be pdf");
          setIsErr(true);
          window.scrollTo(0, 0);
          setAlertClr("danger");
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 3000);
          break;

        case "file-too-large":
          setMessage("CV file size should be less than 2 MB");
          setIsErr(true);
          setAlertClr("danger");
          window.scrollTo(0, 0);
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 3000);
          break;

        default:
          break;
      }
    }

    console.log("FILE ", files);

    const data = new FormData();
    data.append("cv", files[0]);
    console.log(data);
    console.log(files[0]);

    console.log(auth);
    setIsLoading(true);
    api.jobApplication
      .uploadCV(data, auth)
      .then((result) => {
        console.log("PATH CV PROFILE", result.resultData.profile);
        if (result.resultCode === 1) {
          setMessage("Your CV has been uploaded successfully");
          setIsErr(false);
          setAlertClr("success");
          window.scrollTo(0, 0);
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 3000);

          dispatch({
            type: "set_profile_data",
            profileData: result.resultData.profile,
          });
          dispatch({
            type: "shouldeReloadJobList/save",
            shouldeReloadJobList: true,
          });
          // setCVLink(result.resultData.profile.cvLink)
        } else {
          setMessage(result.resultMsg);
          setIsErr(true);
          setAlertClr("danger");
          window.scrollTo(0, 0);
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 3000);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setIsErr(true);
          setAlertClr("danger");
          window.scrollTo(0, 0);
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 3000);
        }
      });
    console.log("CV NEW CV ", profileData.cvLink);

    setPopUpStatus(false);
  };
  const uploadeCVWithUploaded = () => {
    console.log("CV NEW CV ", profileData.cvLink);
    setPopUpStatus(false);
    // setCVLink(profile.cvLink)
    // applyJob(profileData.cvLink);
  };

  const showCvPdf = () => {
    api.onlineCV
      .userCv(auth)
      .then((result) => {
        console.log("user CV ", result);
        setViewCVLink(result.resultData.userCvProfile.createdCvLink);
        if (deviceType === "Mobile") {
          setIsViewCvClick(true);
        } else {
          window.open(
            API_SERVER_URL + result.resultData.userCvProfile.createdCvLink
          );
        }
        setDialogStatus(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setIsErr(true);
          setAlertClr("danger");
          window.scrollTo(0, 0);
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 3000);
        }
      });
  };

  return (
    <>
      <Alert className="alert-position" isOpen={open} color={alertClr}>
        {message}{" "}
      </Alert>
      {isLoading && (
        <div
          style={{ zIndex: "1500" }}
          className="d-flex justify-content-center"
        >
          <Loader
            type="spinner-cub"
            bgColor={"rgb(53, 50, 50)"}
            title={"Loading..."}
            size={100}
          />
        </div>
      )}
      <CustDialog
        open={dialogStatus}
        handleClose={handleCloseDialog}
        message={
          profileData && profileData.status === 0
            ? "Kindly Login first to Edit your profile"
            : dialogMsg
        }
        agree={profileData && profileData.status === 0 ? "Login" : "Verify Now"}
        disagree={"Close"}
        routepath={profileData && profileData.status === 0 ? "/signIn" : "/OTP"}
        title={"Email verification needed"}
      />
      <CvViewer
        showCV={isViewCvClick}
        setShowCv={setIsViewCvClick}
        url={API_SERVER_URL + viewCVLink}
      />
      <CVDrawer
        profileData={profileData}
        cvLink={profileData && profileData.cvLink}
        API_SERVER_URL={API_SERVER_URL}
        setPopUpStatus={setPopUpStatus}
        popUpStatus={popUpStatus}
        handleCVOnDrop={handleCVOnDrop}
        imageMaxSize={imageMaxSize}
        acceptedFileType={acceptedFileType}
      />
      <ProfileView
        profileData={profileData}
        onChangeCv={onChangCv}
        onViewUploadedCv={onViewUploadedCv}
        onUploadCv={onUploadCv}
        onClickCreateCv={onClickCreateCv}
        onClickEditCv={onClickEditCv}
        onClickViewCv={showCvPdf}
        isCvComplete={isCvComplete}
        isBtnDisable={isBtnDisable}
        verifyNow={verifyNow}
        setPopoverOpen={setPopoverOpen}
        popoverOpen = {popoverOpen}
        setDialogStatus={setDialogStatus}
        setDialogMsg = {setDialogMsg}
        setMode={setMode}
        history={history}
        mode={mode}
      />
    </>
  );
};

export default ProfileContainer;
