import React from "react";
import "./circular-loader.scss";

const CircularLoader = () => {
  return (
    <div class="spinner-container">
      <div class="spinner spiral"></div>
    </div>
  );
};

export default CircularLoader;
