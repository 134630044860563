import React, { useState } from "react";
import { COLORS, typography } from "../../assets/design_rules";

const ThemeButton = ({
  style,
  onClick,
  children,
  className,
  fontFamily,
  fontSize = typography.primary,
  color = "#f2f8fe",
  backgroundColor = "#3c6ffe",
  inverse = false,
  textOnly = false,
  shadow = false,
  status
  
}) => {
  let [styleSheet, setStyleSheet] = useState({
    fontFamily: fontFamily,
    fontSize: fontSize,
    fontWeight: "600",
    backgroundColor: textOnly
      ? "transparent"
      : inverse
      ? color
      : backgroundColor,
    color: textOnly ? COLORS.primary : inverse ? backgroundColor : color,
    padding: "1rem 2rem",
    border: "",
    borderRadius: "3rem",
    // minWidth: '90px',
    borderColor: "transparent",
    outline: "none",
    ...style
  });
  const cbuttonHandler1 = () => {
    setStyleSheet({
      ...styleSheet,
      backgroundColor: textOnly?COLORS.secondary:inverse ? backgroundColor : color,
      color: textOnly?COLORS.primary:inverse ? color : backgroundColor,
      boxShadow: shadow?'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 8px 0 rgba(0, 0, 0, 0.19)':"none"
    });
  };
  const cbuttonHandler2 = () => {
    setStyleSheet({
      ...styleSheet,
      backgroundColor: textOnly?'transparent':inverse ? color : backgroundColor,
      color: textOnly?COLORS.primary:inverse ? backgroundColor : color,
      boxShadow:'none'
    });
  };
  return (
    <button
      onClick={onClick}
      style={styleSheet}
      className={className}
      onMouseEnter={cbuttonHandler1}
      onMouseLeave={cbuttonHandler2}
      disabled={status}
    >
      {children}
    </button>
  );
};
export default ThemeButton;
