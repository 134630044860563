import React, { useState, useEffect } from 'react'
import './AppliedJob.css'
import moment from 'moment'
import { Card, Button } from 'react-bootstrap'
import whiteImg from '../../../assets/logo/white.png'
import { useDispatch, useSelector } from "react-redux";
import { Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Alert, ModalHeader, ModalBody, Input, ModalFooter } from 'reactstrap';
import contract from '../../../assets/logo/job_type1.png';
import Loader from "react-js-loader";
import api from '../../../api'
import { API_IMAGE_URL } from '../../../app_constants'
import Logout from '../../../logOut/Logout'



const AppliedJobTrack = ({ data = [], endPoint = 20, uploade }) => {

  console.log("END POINT Appied", endPoint)
  const profile = useSelector(state=>state.profileData)

  const [favId, setFavId] = useState();
  const [buttonName, setButtonName] = useState('')
  const [message, setMessage] = useState('')
  const [successMsg, setSuccessMsg] = useState('');
  const [msgAlertClr, setMsgAlertClr] = useState('success');
  const [show, setShow] = useState(false)
  const [modalHeading, setModalHeading] = useState('');
  const [detailClick, setDetailClick] = useState(false);
  const [applyClick, setApplyClick] = useState(false);
  const [jobId, setJobId] = useState();
  const [alertClr, setAlertClr] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isLoading, setIsLoading] = useState('');
  const [singleJob, setSingleJob] = useState([]);
  const [isPopUpShow, setIsPopUpShow] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [deviceType, setDeviceType] = useState();
  const [btnBgClr, setBtnBgClr] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();
  // const scrollEnd = useSelector(state => state.scrollEnd)

  const auth = useSelector(state=>state.auth_token)


  // To check device(mobile or dekstop)
  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent)) {
      setDeviceType("Mobile");
    } else {
      setDeviceType("Desktop");
    }
  }, []);


  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setMessage("");
        setAlertClr("");
        setShowAlert(false);
      }, 2000);
    }
  }, [showAlert])

  const short = (str, allowedLength = 10) => {
    if (str && str.length > allowedLength) {
      return str.slice(0, allowedLength) + "..."
    }
    return str
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    // console.log("SCROLL ", scrollEnd)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setSuccessMsg('');
      setMsgAlertClr("success")
    }, 2000)
  }, [successMsg])





  const handleAddRemoveFav = (c, idx) => {

    setIsLoading(true);

    setFavId(idx)
    console.log("single job ", c)
    var status;
    var dataId = {
      jobId: c.id
    }

    if (profile) {
      status = profile.status;
    } else {
      status = 0
    }

    if (status === 1) {
      setShow(true);
      setMessage("Kindly verify your email first to add job in favorite list");
      setModalHeading("Email verification needed");
      setButtonName("Verify Email Now");
    } else if (status === 0) {
      setShow(true);
      setMessage("You need to sign up first to add the job to Favorites");
      setModalHeading("Sign up needed");
      setButtonName("Sign Up Now");
    }

    console.log(auth, " ", dataId)
    if (status === 2) {
      if (!c.isFavorite) {
        api.job.isFav(dataId, auth).then(result => {
          console.log("IS FAV ", result);
          if (result.resultCode === 1) {
            setSuccessMsg("This job has been added as a Favorite")
            setMsgAlertClr("success")
            dispatch({ type: "shouldeReloadJobList/save", shouldeReloadJobList: true });
          }
          setIsLoading(false);
        }).catch(error => {
          console.log(error)
          if (!!error.response && !!error.response.status && error.response.status === 401) {
            Logout(dispatch, history, false);
          }
          if (error.message === 'Network Error') {
            setMessage("Please check your network connection!");
            setAlertClr("danger");
            setShowAlert(true);
          }

        })
      } else {

        api.job.isFavRemove(dataId, auth).then(result => {
          console.log("IS FAV REMOVE", result);
          if (result.resultCode === 1) {
            setSuccessMsg("This job has been removed from Favorite")
            setMsgAlertClr("success")
            dispatch({ type: "shouldeReloadJobList/save", shouldeReloadJobList: true });
          }
          setIsLoading(false)
        }).catch(error => {
          console.log(error)
          if (!!error.response && !!error.response.status && error.response.status === 401) {
            Logout(dispatch, history, false);
          }
          if (error.message === 'Network Error') {
            setAlertMessage("Please check your network connection!");
            setAlertClr("danger");
            setShowAlert(true);
          }
        })
      }
    }
  }




  return (
    <React.Fragment>


      <Alert className='alert-position' isOpen={showAlert} color={alertClr}>{alertMessage} </Alert>
      {/* .slice(0, endPoint) */}
      {data.length > 0 ?
        data.slice(0, endPoint).map((c, idx) => {
          return (
            <div className="in-single-line">
              {/* <div className="box-cart" > */}
              <Card className='card-style p-1 mr-3' >
                <Card.Body className="card-box" >

                  <div
                    style={{ cursor: 'auto' }}
                    onClick={() => {
                      setDetailClick(true);
                      setJobId(c.id);
                      // dispatch({ type: 'scrollEnd/save', scrollEnd: (window.pageYOffset) })
                      // console.log("SCROLLLLw", window.pageYOffset)
                    }}>

                    <div className="image-card">
                      <img style={{ width: '5rem', height: '7rem' }} src={c.categoryIconPath ? (API_IMAGE_URL + c.categoryIconPath) : whiteImg} />
                      <div className="title-loc">
                        <h5>
                          {short(c.jobTitle, 21)}
                        </h5>
                        <p>
                          <i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp; {' '}
                          {c.locationNmList.length > 1 ? short(c.locationNmList[0], 12) + "..." : short(c.locationNmList[0])}
                        </p>
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {((idx === favId) && isLoading) &&
                        < Loader type="spinner-cub" bgColor={"rgb(53, 50, 50)"} title={"Loading..."} size={100} />
                      }
                    </div>


                    <p style={{ marginTop: '1.5%' }}>
                      {short(c.categoryName, 21)}
                    </p>
                    <p style={{ marginTop: '1%' }}>

                      {short(c.oneLinerDescription, 31)}
                    </p>

                    <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h6>
                        {'$ ' + short(c.compensation, 21)}
                      </h6>
                      <h6>
                        <img src={contract} style={{ width: '1.6rem' }} />{' '}
                        {c.contractTypeNmList.length > 1 ? short(c.contractTypeNmList[0], 11) + "..." : short(c.contractTypeNmList[0])}
                      </h6>
                    </div>

                  </div>
                  <div className="d-flex  mt-3 mb-3">
                    <Button
                      style=
                      {{
                        backgroundColor:
                          (c.jobApplicationStatus === 0 ? "rgb(83 90 220)" :
                            c.jobApplicationStatus === 1 ? "#rgb(234 234 87)" :
                              c.jobApplicationStatus === 2 ? "#a2d0e2" :
                                c.jobApplicationStatus === 3 ? "#bbda86" :
                                  c.jobApplicationStatus === 4 ? "rgb(19, 182, 19)" : '#ec9083'),

                        borderColor:
                          (c.jobApplicationStatus === 0 ? "rgb(83 90 220)" :
                            c.jobApplicationStatus === 1 ? "#rgb(234 234 87)" :
                              c.jobApplicationStatus === 2 ? "#a2d0e2" :
                                c.jobApplicationStatus === 3 ? "#bbda86" :
                                  c.jobApplicationStatus === 4 ? "rgb(19, 182, 19)" : '#ec9083'),

                        color: "white",
                        marginLeft: '5px',
                        borderRadius: "30px"
                      }}

                      className="apply-btn-style"
                      onClick={() => {
                        setFavId('')
                        setSingleJob(c)
                        console.log("iD ", idx)
                        setJobId(c.id)
                      }}>
                      {c.jobApplicationStatus === 0 && "Applied"}
                      {c.jobApplicationStatus === 1 && `Referred`}
                      {c.jobApplicationStatus === -1 && "Rejected"}
                      {c.jobApplicationStatus === 2 && "Shortlisted"}
                      {c.jobApplicationStatus === -2 && "Not Shortlisted"}
                      {c.jobApplicationStatus === 3 && "Interviewed"}
                      {c.jobApplicationStatus === 4 && "Selected"}
                      {c.jobApplicationStatus === -4 && "Rejected"}
                      {/* {c.jobApplicationStatus} */}
                    </Button>
                  </div>
                </Card.Body>
                {/* <hr style={{ width: '90%', display: 'flex', justifyContent: 'center' }} /> */}
                <Card.Footer>
                  <div className="row" style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <div style={{ marginRight: '40%' }}>
                      <p style={{ marginRight: '-12px' }}>
                        {/* <i class='far fa-calendar'></i>&nbsp;{' '} */}
                        {' '} <span style={{ fontStyle: 'italic' }}>Posted On</span>{' '}
                        {moment(c.publishOn).format('DD-MM-YYYY')}
                      </p>
                    </div>
                    <div >
                      <Alert className='alert-position' style={{ position: 'absolute', top: '-10rem',
                       left: '-22rem', width: '12pc' }}
                        isOpen={(successMsg && (idx === favId))}
                        // isOpen={true}
                        color={msgAlertClr}>{successMsg} </Alert>


                      <i class="fa fa-star" aria-hidden="true" value={c.id}
                        style={(c.isFavorite) ?
                          { color: "#499cff", fontSize: '2rem', cursor: 'pointer' }
                          :
                          { color: 'black', fontSize: '2rem', cursor: 'pointer' }}
                        onClick={() => handleAddRemoveFav(c, idx)}
                      // onClick={() => favIcon(c, idx)}
                      ></i>
                      {/* <i class="fa fa-ellipsis-h" aria-hidden="true" style={{ fontSize: '2rem' }}></i> */}
                    </div>
                  </div>
                </Card.Footer>
              </Card>

            </div>
          )
        })
        :
        <div className="noList">
          <h3 style={{ display: 'flex', justifyContent: 'center' }}>No, Applied Job Found </h3>
        </div>
      }

    </React.Fragment >
  )
}

export default AppliedJobTrack;