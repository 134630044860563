import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory } from "react-router-dom";
import "./custDialog.css";
import { useDispatch } from "react-redux";

const CustDialog = ({
  open,
  handleClose,
  message,
  title,
  agree = "Yes",
  disagree = "No",
  routepath,
  handleAction,
}) => {
  const dispatch = useDispatch()
  const history = useHistory();
  return (
    <Dialog
      disableBackdropClick={true}
      // style={{ padding: "80px",  }}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="dialog"
      // maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">
        <h4>{title}</h4>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{ fontSize: "1.5rem" }}
          id="alert-dialog-description"
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="dialog-size">
        <Button
          onClick={() => {
            if (routepath) {
              console.log("ROUTE PATH RISHABH ", routepath);
              dispatch({ type: "setOtp_timer_value", OTP_Timer_value: 300000 });

              history.push({
                pathname: routepath,
                state: {
                  status: "fromJoblist",
                },
              });
            } else {
              console.log("HANDLE ACTION");
              handleAction();
            }
            handleClose();
          }}
          style={{ fontSize: "1.4rem", textAlign: "start" }}
          color="primary"
          autoFocus
        >
          {agree}
        </Button>
        <Button
          onClick={handleClose}
          style={{ fontSize: "1.4rem", textAlign: "start" }}
          color="primary"
        >
          {disagree}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustDialog;
