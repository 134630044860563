import React, { useEffect, useState } from "react";
import aboutUsBanner from "../../assets/Banner/About-us/banner@3x.png";
import tabletSizeBanner from "../../assets/Banner/About_us@2x.png";
import mobileSizeBanner from "../../assets/Banner/Aboutus@1.5x.png";
import { Button, Col, Row } from "reactstrap";
import { useMediaQuery } from "react-responsive";
import { ResponsiveImage, ResponsiveImageSize } from "react-responsive-image";
import { Cnon, Impact, Purviz, Sie } from "../../assets";
import { AsyncImage } from "../../helpers/AsyncImage";
import { healthcare, jobs, path, scholarships, training, wellfare } from '../../assets';
import ServiceCardComponent from "../../components/card/ServiceCardComponent";
import JumbotronPlaceHolder from "../../components/JumbotronPlaceHolder";
import {useDispatch} from 'react-redux'


const serviceItems = [
  {
      title:"Jobs",
      iconSrc:jobs,
      details:"Full-time, part-time, internship – different openings available"
  },
  {
      title:"Training & Event",
      iconSrc:training,
      details:"Events, workshops and online videos on different topics from job hunting to work culture in Hong Kong to learning Cantonese"
  },
  {
      title:"Scholarships",
      iconSrc:scholarships,
      details:"Check out what scholarships are in the market and how you can apply"
  },
  {
      title:"Social & Healthcare Services",
      iconSrc:healthcare,
      details:"Customised referral for you "
  },
  {
      title:"Welfare Security Information",
      iconSrc:wellfare,
      details:"Any welfare and assistance you may be eligible"
  },
  // {
  //     title:"App",
  //     iconSrc:"",
  //     details:"We are building an App version of Opportunity Bank – Stay Tuned"
  // },
]


export default function AboutUS() {
  const dispatch = useDispatch()
    const isMobileDevice = useMediaQuery({
        query: "(min-device-width: 0px) and (max-device-width: 768px)",
      });
    
      const isTabletDevice = useMediaQuery({
        query: "(min-device-width: 481) and (max-device-width: 768px)",
      });
    
      const isLaptop = useMediaQuery({
        query: "(min-device-width: 769px) ",
      });
    
    //   const isDesktop = useMediaQuery({
    //     query: "(max-device-width: 1200px)",
    //   });
    
    //   const isBigScreen = useMediaQuery({
    //     query: "(min-device-width: 1201px )",
    //   });
    
  useEffect(() => {
    dispatch({type:'setCurrentLink',currentLink:'/aboutUs'});
    window.scrollTo(0, 0);
  }, []);

 
  return (
    <div>
       <JumbotronPlaceHolder>
        {
          mobileSizeBanner &&
              isMobileDevice &&
              <>
                <AsyncImage
                  loading
                  src={mobileSizeBanner}
                  alt="First slide"
                  style={{ width: "100%", height: "100%" }}
                />
                <div className="banner-details">
                  <h3
                    style={{ fontWeight: "400", color: "white", fontStyle: "italic" }}
                  >
                    About us
                  </h3>
                  <h1
                    className="banner-title"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      color: "white",
                      textAlign: "start",
                    }}
                  >
                    We connect ethnic minorities in <br /> Hong Kong with resources
                  </h1>
                </div>
              </>

            }

            {
              isLaptop &&
              <>
                <AsyncImage
                  loading
                  src={aboutUsBanner}
                  alt="second slide"
                  style={{ width: "100%", height: "100%" }}
                />
                <div className="banner-details">
                  <h3
                    style={{ fontWeight: "400", color: "white", fontStyle: "italic" }}
                  >
                    About us
                  </h3>
                  <h1
                    className="banner-title"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      color: "white",
                      textAlign: "start",
                    }}
                  >
                    We connect ethnic minorities in <br /> Hong Kong with resources
                  </h1>
                </div>
              </>
            }
      </JumbotronPlaceHolder>
      
      <div className="cust-padddings pt-5 pb-5">
        <p className="description ">
          Opportunity Bank, an initiative of The Zubin Foundation, is a hub
          which connects ethnic minorities with resources such as job & network,
          training, scholarships as well as social & healthcare services in a
          one-stop, easy to access platform. We collaborate with different
          service providers - corporates/SMEs, funders, schools, etc. to gather
          different resources for you.
        </p>

        <h3 className="large-header">Mission</h3>
        <hr className="cust-underline" />
        <p className="description mt-5">
          Opportunity Bank seeks to push for greater transparency and
          accessibility to information, better allocation of resources and will
          identify service gaps. We empower and support the ethnic minority
          community by enhancing employment and education, reducing poverty,
          bettering quality of life as a result as well as improving upward
          mobility.
        </p>

        <h3 className="large-header" style={{ textAlign: "start" }}>
          Services at a glance
        </h3>
        <hr className="cust-underline" />
        <p className="description mt-5">
          Opportunity Bank seeks to push for greater transparency & higher
          accessibility to information.
        </p>
        <Row  className=" d-flex justify-content-start  mb-0 p-0 mr-0 pt-5 " style={{width:"100%"}}>
            <Col xs="12 ml-0 pl-0" md="12"  lg="12 p-0 " xl="9 p-0" className="d-flex" >
              <Row className=" pl-0 " style={{paddingBottom:"95px", marginLeft:"auto", marginRight:"auto",width:"100%"}}>
                {serviceItems.map((item) => {
                  return (
                    <Col xs="12" sm="12" md="6" lg="4 p-0 m-0 d-flex justify-content-center">
                        <ServiceCardComponent title={item.title} icon={item.iconSrc} description={item.details} arrow={path}/>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        <h3 className="large-header" style={{ textAlign: "start" }}>
          {" "}
          Provide Opportunity and Get Involved
        </h3>
        <hr className="cust-underline" />
        <p className="description mt-5">
          There are a few ways to support ethnic minorities in Hong Kong
        </p>
        <ul className="description mt-4 ml-5">
          <li> <strong>Provide Opportunities</strong></li>
          <li> <strong>Provide Resources</strong></li>
          <li> <strong>Become a Volunteer</strong></li>
        </ul>

        <Button
          className="mt-3"
          style={{
            backgroundColor: "transparent",
            color: "#499CFF",
            borderColor: "#499CFF",
          }}
        >
          Learn More
        </Button>

     
      </div>
      <div className="cust-padddings pt-5 pb-5" style={{background:"white"}}>
       <h3 className="large-header"> Thank you</h3>
        <hr className="cust-underline" />
        <p className="description mt-5">
          Thank you the companies and organisations below making Opportunity
          Bank possible:
        </p>
        <h4 className="mt-5 pt-5 mb-5" style={{
          color: "#344256",
          fontWeight:"600"
        }}>Funded by</h4>
        <div>
          {/* <img src={Sie} style={{height:"100%"}}/> */}
          <img src={Cnon} style={{height:"100%"}}/>
          <img src={Purviz} style={{height:"100%"}}/>
        </div>
        <h4 className="mt-5 pt-5 mb-5" style={{
          color: "#344256",
          fontWeight:"600"
        }}>Incubated by</h4>
          <img src={Impact} style={{height:"100%"}}/>
       </div>
    </div>
  );
}
