/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Button } from "reactstrap";
import { verifiedBadge } from "../../../assets";
import EditProfile from "../EditProfile";
import "./profile.scss";

const ProfileView = ({
  onChangeCv,
  onViewUploadedCv,
  profileData,
  onUploadCv,
  isCvComplete,
  onClickCreateCv,
  onClickEditCv,
  onClickViewCv,
  isBtnDisable,
  verifyNow,
  setPopoverOpen,
  setDialogStatus,
  setDialogMsg,
  setMode,
  history,
  mode,
}) => {
  return (
    <>
      {mode === "DISPLAY" ? (
        <div className="profile-card">
          <div class="detail-section">
            <div className="cover">
            <div className="profile-pic">
              {profileData &&
                profileData?.firstName &&
                profileData?.firstName.split("")?.shift()?.toUpperCase()}
              {profileData &&
                profileData?.firstName &&
                profileData?.lastName?.split("")?.shift()?.toUpperCase()}
            </div>
            </div>
            
            <div className="details">
              <div className="profile-title">
                {profileData && profileData.status === 0 ? (
                  <h3>Guest User</h3>
                ) : (
                  <>
                    <h3>
                      {profileData && profileData.firstName}{" "}
                      {profileData && profileData.lastName}{" "}
                      <i
                        onMouseEnter={() => {
                          setPopoverOpen(true);
                        }}
                        onMouseLeave={() => {
                          setPopoverOpen(false);
                        }}
                        className="fas fa-pen  fa-1x text-primary pointer ml-1rem"
                        // style={{ marginBottom: '13px' }}
                        onClick={
                          (profileData && profileData.status === 1) ||
                          (profileData && profileData.status === 0)
                            ? () => {
                                setDialogStatus(true);
                                setDialogMsg(
                                  "You need to verify email first to update profile"
                                );
                              }
                            : () => {
                                setMode("EDIT");
                              }
                        }
                      ></i>
                    </h3>
                  </>
                )}

                {profileData && profileData.status === 2 ? (
                  <div className="verified-badge pointer">
                    Verified <img src={verifiedBadge} />
                  </div>
                ) : (
                  <div
                    className="verified-badge text-danger pointer"
                    onClick={verifyNow}
                  >
                    Verify <i class="fas fa-exclamation-circle text-danger"></i>
                  </div>
                )}
              </div>
              <hr />
              <div className="contact">
                <div className="email">
                  <i class="fas fa-envelope "></i>
                  {profileData && profileData.email}
                </div>
                <br />
                <div className="email">
                  <i class="fas fa-phone-alt"></i>
                  {profileData && profileData.mobile}
                </div>
              </div>
            </div>
          </div>
          <div className="cv-section">
            <h3>Curriculum Vitae</h3>
            <hr />
            <p className="cv-header">Create Your CV</p>
            <div className="cv-btn-grp">
              {isCvComplete ? (
                <React.Fragment>
                  <Button
                    className="cust-cv-btn mr-3"
                    disabled={isBtnDisable}
                    onClick={onClickViewCv}
                  >
                    <i class="fas fa-eye"></i> View Created CV
                  </Button>
                  <Button className="cust-cv-btn" onClick={onClickEditCv}>
                    <i class="fas fa-edit"></i> Edit CV
                  </Button>
                </React.Fragment>
              ) : (
                <Button className="cust-cv-btn" onClick={onClickCreateCv}>
                  <i class="fas fa-edit"></i> Create CV
                </Button>
              )}
            </div>
            <hr />
            <p className="cv-header">Upload Your CV</p>
            {profileData && profileData.cvLink !== null ? (
              <div className="cv-btn-grp">
                <Button className="cust-cv-btn mr-3" onClick={onViewUploadedCv}>
                  <i class="fas fa-eye"></i> View Uploaded CV
                </Button>

                <Button className="cust-cv-btn" onClick={onChangeCv}>
                  <i class="fas fa-upload"></i> Change CV
                </Button>
              </div>
            ) : (
              <div className="cv-btn-grp">
                <Button className="cust-cv-btn" onClick={onUploadCv}>
                  <i class="fas fa-upload"></i> Upload CV
                </Button>
              </div>
            )}

            <hr />
            <h4 className="text-muted">Supported file formats: PDF</h4>
            <h4 className="text-muted">Maximum file size: 2MB</h4>
          </div>
        </div>
      ) : (
        <>
          <div
            className=" p-5 card"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {mode === "EDIT" && (
              <i
                onClick={() => {
                  setMode("DISPLAY");
                }}
                className="fas fa-chevron-left pointer fa-2x mr-auto"
              ></i>
            )}
            {mode === "DISPLAY" ? (
              <h1 className="ml-auto mr-auto">Profile</h1>
            ) : (
              <h1 className="mr-auto">Personal Info</h1>
            )}
          </div>
          <EditProfile
            status={history.location.state && history.location.state.status}
            setMode={setMode}
          />
        </>
      )}
    </>
  );
};

export default ProfileView;
