import React from "react";

const JumbotronPlaceHolder = ({ children,className="image_replacement shine" }) => {
  return (
    <div className={className}>
      {children}
    </div>
  );
};

export default JumbotronPlaceHolder;
