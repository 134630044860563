export const ACTIVITY={
    CLICK_APPLY_JOB : "CLICK_APPLY_JOB",
    CLICK_CATEGORY_JOB_LIST : "CLICK_CATEGORY_JOB_LIST",
    CLICK_FAVORITE_JOB:"CLICK_FAVORITE_JOB",
    CLICK_JOB : "CLICK_JOB" ,
    CLICK_MORE_ABOUT_US :"CLICK_MORE_ABOUT_US",
    CLICK_MORE_CONTACT_US : "CLICK_MORE_CONTACT_US",
    CLICK_MORE_DONATE_NOW : "CLICK_MORE_DONATE_NOW",
    CLICK_MORE_FOLLOW_ON_FACEBOOK :"CLICK_MORE_FOLLOW_ON_FACEBOOK",
    CLICK_MORE_FOLLOW_ON_INSTAGRAM :"CLICK_MORE_FOLLOW_ON_INSTAGRAM",
    CLICK_MORE_INTERN_WITH_US : "CLICK_MORE_INTERN_WITH_US" ,
    CLICK_MORE_RATE_OUR_APP : "CLICK_MORE_RATE_OUR_APP",
    CLICK_MORE_REPORT_A_PROBLEM :"CLICK_MORE_REPORT_A_PROBLEM",
    CLICK_MORE_SETTINGS :"CLICK_MORE_SETTINGS",
    CLICK_MORE_SETTINGS_CHANGE_PASSWORD :"CLICK_MORE_SETTINGS_CHANGE_PASSWORD",
    CLICK_MORE_SETTINGS_PRIVACY_POLICY : "CLICK_MORE_SETTINGS_PRIVACY_POLICY",
    CLICK_MORE_SETTINGS_SIGNOUT: "CLICK_MORE_SETTINGS_SIGNOUT",
    CLICK_MORE_SETTINGS_TERMS_AND_CONDITIONS :"CLICK_MORE_SETTINGS_TERMS_AND_CONDITIONS",
    CLICK_MORE_SHARE_APP :"CLICK_MORE_SHARE_APP",
    CLICK_MORE_SUBSCRIBE_TO_OUR_NEWS_LETTER :"CLICK_MORE_SUBSCRIBE_TO_OUR_NEWS_LETTER",
    CLICK_MORE_TUTORIAL:"CLICK_MORE_TUTORIAL",
    CLICK_MORE_VOLUNTEER_WITH_US :"CLICK_MORE_VOLUNTEER_WITH_US",
    CLICK_SEE_ALL_JOB_LIST :"CLICK_SEE_ALL_JOB_LIST",
    CONTINUE_AS_GUEST :"CONTINUE_AS_GUEST",
    FIRST_APP_USE_OF_THE_DAY :"FIRST_APP_USE_OF_THE_DAY",
    OPEN_HOME_SCREEN :"OPEN_HOME_SCREEN",
    OPEN_MORE_OPTIONS :"OPEN_MORE_OPTIONS",
    OPEN_PROFILE_SCREEN :"OPEN_PROFILE_SCREEN",
    OPEN_TRACK_SCREEN :"OPEN_TRACK_SCREEN",
    RESEND_OTP_EMAIL_VERIFICATION :"RESEND_OTP_EMAIL_VERIFICATION",
    RESEND_OTP_FORGOT_PASSWORD :"RESEND_OTP_FORGOT_PASSWORD",
    SET_JOB_FILTER :"SET_JOB_FILTER",
    SET_JOB_FILTER_BY_CATEGORY :"SET_JOB_FILTER_BY_CATEGORY",
    SET_JOB_FILTER_BY_CONTRACT_TYPE :"SET_JOB_FILTER_BY_CONTRACT_TYPE",
    SET_JOB_FILTER_BY_LOCATION:"SET_JOB_FILTER_BY_LOCATION",
    SHARE_JOB :"SHARE_JOB",
    SKIP_EMAIL_VERFICATION :"SKIP_EMAIL_VERFICATION",
    USE_GLOBAL_SEARCH :"USE_GLOBAL_SEARCH",
    VIEW_JOB_DETAILS_SECOND_TAB :"VIEW_JOB_DETAILS_SECOND_TAB"
    
}