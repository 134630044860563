import React, { useState } from 'react'
import Dropzone from 'react-dropzone';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';



const imageMaxSize = 20000000; //bytes 
const acceptedFileType = 'application/pdf';
const acceptedFileTypeArray = acceptedFileType.split(',').map((item) => { return item.trim() });

// const CVDropzone = ({ handleCVOnDrop }) => {
export default function CVDropzone(handleCVOnDrop, props) {

    // var profile = (JSON.parse(localStorage.getItem("profile")));
    let profile = useSelector(state=>state.profileData) 
    const dispatch = useDispatch();
    console.log("FILE ", acceptedFileType)
    return (
        <>
            <Dropzone
                onDrop={handleCVOnDrop} className=""
                multiple={false} maxSize={imageMaxSize}
                accept={acceptedFileType}
            >
                {({ getRootProps, getInputProps }) => (

                    <div className='for_sm_text' {...getRootProps({ className: 'dropzone' })} style={{ padding: 20 }}>

                        <section style={{ display: 'flex', justifyContent: 'center' }}>
                            <input {...getInputProps()} />
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {(profile && profile.cvLink) &&
                                    <Button className="button-cv-style" >Upload New CV </Button>
                                }
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {(profile && !profile.cvLink) &&
                                    <Button className="button-cv-style" >Upload CV</Button>
                                }
                            </div>
                        </section>
                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '-7px', marginTop: '10px' }}>
                            <p>Supported file format: PDF</p>
                        </div>
                        <div style={(profile && profile.cvLink) ? { marginBttom: '7rem', display: 'flex', justifyContent: 'center' } : { display: 'flex', justifyContent: 'center' }}>
                            <p>Maximum file size: 2 MB</p>
                        </div>
                    </div>
                )}
            </Dropzone>


        </>
    )
}

// export default CVDropzone;