import React, { useState, useEffect } from 'react';
import '../createCv.css'
import CareerObjective from '../careerObj/CareerObjective'
import Education from '../education/Education';
import Skills from '../skills/Skills';
import WorkExperience from '../WorkExp/WorkExperience';


const StepThree = ({ passFromSkill, passFromSkillPrev, updateSkillStep, updateExperienceStep }) => {



  return (
    <div className="pb-5">

      <WorkExperience
        onWorkExpAction={(data) => {
          updateExperienceStep(data);
        }}
      />
      <div style={{ height: '40px', width: '100%', backgroundColor: 'white' }}></div>
      <h3 className="mt-3" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} >Skills</h3>

      <Skills
        nextStep={(data) => {
          console.log("Step data skill", data);
          passFromSkill(data)
        }}

        previousStep={(data) => {
          console.log("Step data skill", data);
          passFromSkillPrev(data)
        }}

        onSkillAction={(data) => {
          console.log("Step data skill", data);
          updateSkillStep(data)
        }}
      />
    </div>
  )
}

export default StepThree;