export const StaticData = {
    testimonials:[
        {
            remark:"My biggest change in mindset was definitely... Learning how to advertise and promote myself especially in the context of the job I am applying for can really set me apart from the crowd as I show the employer exactly what I can do for them and why they should hire me. Thank you so much to Barclays and The Zubin Foundation for making this experience possible and for giving me this opportunity.",
            name:"EM Youth",
            designation:"Online Training Workshop Series"
        },
        {
            remark:"We really value our partnership with The Zubin Foundation. In 2020, we welcomed two tertiary students into our office for work experience through the internship programme, and we were pleased to hire into our HR and marketing departments two students referred to us, enabling us to uncover talent that we might not have otherwise discovered.",
            name:"Penny Barsha",
            designation:"Corporate Responsibility Manager – Asia, Linklaters"
        }
    ]
}