import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import "./obStepper.css";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";
import StepOne from "../../Pages/CreateCVForm/steps/StepOne";
import StepTwo from "../../Pages/CreateCVForm/steps/StepTwo";
import StepThree from "../../Pages/CreateCVForm/steps/StepThree";
import StepFour from "../../Pages/CreateCVForm/steps/StepFour";
import api from "../../api";
import LastStep from "../../Pages/CreateCVForm/steps/LastStep";
import { useHistory } from "react-router";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 136deg, rgb(230 161 118) 0%, rgb(249 124 139) 50%, rgb(247 101 243) 100%)",
      // 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 136deg, rgb(230 161 118) 0%, rgb(249 124 139) 50%, rgb(247 101 243) 100%)",
      // 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(230 161 118) 0%, rgb(249 124 139) 50%, rgb(247 101 243) 100%)",
    // 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(230 161 118) 0%, rgb(249 124 139) 50%, rgb(247 101 243) 100%)",
    // 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    // color:'green',
  },
  // inCompleted: {
  // 	backgroundImage:
  // 		'linear-gradient (red rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  // },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "-32px",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Personal & Contact Information",
    "Career Objective & Education",
    "Work Experience and Skill",
    "Certificates, Award and Languages ",
    "Generate CV",
  ];
}

export default function CustomizedSteppers() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [updateStepSymbol, setUpdateStepSymbol] = React.useState("");
  const [isStepOneDone, setIsStepOneDone] = React.useState(false);
  const [isStepTwoDone, setIsStepTwoDone] = React.useState(false);
  const [isStepThreeDone, setIsStepThreeDone] = React.useState(false);
  const [isStepFourDone, setIsStepFourDone] = React.useState(false);
  const [isLastStepDone, setIsLastStepDone] = React.useState(false);
  const [isCareerObjDone, setIsCareerObjDone] = React.useState(false);
  const [isEducationDone, setIsEducationDone] = React.useState(false);
  const [isExperienceDone, setIsExperienceDone] = React.useState(false);
  const [isSkillDone, setIsSkillDone] = React.useState(false);

  const steps = getSteps();
  let authToken = useSelector((state) => state.auth_token);
  var history = useHistory();

  useEffect(() => {
    // if (history.location.state && history.location.state.status === "EDITREWARD") {
    //   console.log("HISATORY CONTACT if cond stepper ", window.pageYOffset)
    // }
    // window.scrollTo(0, 670)
    // console.log("HISATORY CONTACT stepper", window.pageYOffset)
    // ele.scrollIntoView()
  }, []);

  useEffect(() => {
    document.body.style.overflow = '';
    return () => {
      document.body.style.overflow = ''; 
    };
  }, []); 

  useEffect(() => {
    // api.onlineCV.isCvComplete(authToken)
    //   .then(result => {
    //     console.log("user CV STEPPER", result);

    //   })

    console.log("STEAP CALL ", activeStep);
    if (activeStep !== 4) {
      checkStepOneCompete();
      checkStepTwoCompete();
      checkStepThreeCompete();
      checkStepFourCompete();
    }
  }, [activeStep, updateStepSymbol]);

  useEffect(() => {
    console.log("USEEFFECT FROM STAP THREE", isExperienceDone, isSkillDone);
    if (isExperienceDone && isSkillDone) {
      setIsStepThreeDone(true);
    } else {
      setIsStepThreeDone(false);
    }
  }, [isSkillDone, isExperienceDone]);

  useEffect(() => {
    console.log("USEEFFECT FROM STAP TWO", isEducationDone, isCareerObjDone);
    if (isEducationDone && isCareerObjDone) {
      setIsStepTwoDone(true);
    } else {
      setIsStepTwoDone(false);
    }
  }, [isCareerObjDone, isEducationDone]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep, updateStepSymbol]);

  const checkStepOneCompete = () => {
    api.onlineCV.getAddress(authToken).then((result) => {
      console.log("RESULT => get address", result);
      if (result.resultCode === 1) {
        setIsStepOneDone(true);
      } else {
        setIsStepOneDone(false);
      }
    });
  };

  const checkStepTwoCompete = () => {
    api.onlineCV.cvProfile(authToken).then((result) => {
      console.log("RESULT => PROFILE CV", result);
      if (
        result.resultCode === 1 &&
        result.resultData.userCvProfile.careerObjective
      ) {
        console.log("FORM Two TRUE CAREER");
        // formOne = true
        setIsCareerObjDone(true);
      } else {
        console.log("FORM Two false CAREER");
        setIsCareerObjDone(false);
        // formOne = false
      }
    });

    api.onlineCV.getEducation(authToken).then((result) => {
      if (result.resultCode === 1) {
        if (result.resultData.educationList.length > 0) {
          console.log("FROM TWO TRUE EDUC");
          // formTwo = true;
          setIsEducationDone(true);
        } else {
          console.log("FROM TWO FALSE EDUC");
          setIsEducationDone(false);
          // formTwo = false;
        }
      }
    });
  };

  const checkStepThreeCompete = () => {
    api.onlineCV.cvProfile(authToken).then((result) => {
      console.log("tesult is fresher ", result);
      if (result.resultCode === 1) {
        if (result.resultData.userCvProfile.isFresher === 1) {
          setIsExperienceDone(true);
        } else {
          api.onlineCV.getWorkExperience(authToken).then((result) => {
            console.log("tesult get work exp ", result);
            if (result.resultCode === 1) {
              if (result.resultData.workExperienceList.length > 0) {
                console.log("WORK EXP FORM TRUE ");
                setIsExperienceDone(true);
              } else {
                console.log("WORK EXP FORm FALSE");
                setIsExperienceDone(false);
              }
            } else {
              setIsExperienceDone(false);
            }
          });
          // setIsExperienceDone(false);
        }
      }
    });

    api.onlineCV.getSkills(authToken).then((result) => {
      console.log("GET SKILL selected", result);
      if (result.resultCode === 1) {
        setIsSkillDone(true);
      } else {
        setIsSkillDone(false);
      }
    });
  };

  const checkStepFourCompete = () => {
    api.onlineCV.getLanguage(authToken).then((result) => {
      console.log("RESULT get lang checkStepFourCompete --->", result);
      if (result.resultCode === 1) {
        const isCantoneseAvailable =
          result?.resultData?.userLanguageList &&
          result?.resultData?.userLanguageList?.some(
            (item) => item.language === "CANTONESE"
          );
        if (isCantoneseAvailable) {
          setIsStepFourDone(true);
        } else {
          setIsStepFourDone(false);
        }
      } else {
        setIsStepFourDone(false);
      }
    });
  };

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed, root } = props;

    const icons = {
      1:
        (root || active || completed) && isStepOneDone === true ? (
          <Check className="checkColor" />
        ) : (root || active || completed) && !isStepOneDone ? (
          <Close className="crossColor" />
        ) : (
          <div className={classes.circle} />
        ),

      2:
        (root || active || completed) && isStepTwoDone === true ? (
          <Check className="checkColor" />
        ) : (root || active || completed) && isStepTwoDone === false ? (
          <Close className="crossColor" />
        ) : (
          <div className={classes.circle} />
        ),

      3:
        (root || active || completed) && isStepThreeDone === true ? (
          <Check className="checkColor" />
        ) : (root || active || completed) && isStepThreeDone === false ? (
          <Close className="crossColor" />
        ) : (
          <div className={classes.circle} />
        ),

      4:
        (root || active || completed) && isStepFourDone === true ? (
          <Check className="checkColor" />
        ) : (root || active || completed) && isStepFourDone === false ? (
          <Close className="crossColor" />
        ) : (
          <div className={classes.circle} />
        ),

      5:
        (root || active || completed) && isLastStepDone === true ? (
          <Check className="checkColor" />
        ) : (root || active || completed) && isLastStepDone === false ? (
          <Close className="crossColor" />
        ) : (
          <div className={classes.circle} />
        ),
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <StepOne
            passFromContactinfo={(data) => {
              console.log("step data", data);
              setActiveStep(data);
            }}
            updateStep={(data) => {
              console.log("step data isDone STEPPER", data);
              setUpdateStepSymbol(data);
            }}
            id="scroll"
          />
        );

      case 1:
        return (
          <StepTwo
            passFromEducation={(data) => {
              console.log("step data", data);
              setActiveStep(data);
            }}
            passFromEducationPrev={(data) => {
              console.log("step data", data);
              setActiveStep(data);
            }}
            updateEducationStep={(data) => {
              console.log("step data STEP NO edu", data);
              setUpdateStepSymbol(data);
            }}
            updateCareerObjStep={(data) => {
              console.log("step data STEP NO car", data);
              setUpdateStepSymbol(data);
            }}
          />
        );
      case 2:
        return (
          <StepThree
            passFromSkill={(data) => {
              console.log("step data", data);
              setActiveStep(data);
            }}
            passFromSkillPrev={(data) => {
              console.log("step data", data);
              setActiveStep(data);
            }}
            updateExperienceStep={(data) => {
              console.log("step data EXPERIENCE ", data);
              setUpdateStepSymbol(data);
            }}
            updateSkillStep={(data) => {
              console.log("step data", data);
              setUpdateStepSymbol(data);
            }}
          />
        );
      case 3:
        return (
          <StepFour
            passFromLanguage={(data) => {
              console.log("step data", data);
              setActiveStep(data);
            }}
            passFromLanguagePrev={(data) => {
              console.log("step data", data);
              setActiveStep(data);
            }}
            updateLanguageStep={(data) => {
              console.log("step data", data);
              setUpdateStepSymbol(data);
            }}
          />
        );
      case 4:
        return (
          <LastStep
            onGenerateCvAction={(data) => {
              console.log("GENERATE CV BUTOON ", data);
              setIsLastStepDone(!data);
            }}
          />
        );
      default:
        return "";
    }
  }

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>
      {/* <div style={{ zIndex: 1500, top: '7rem' }}> */}
      <i
        style={{
          fontSize: "3rem",
          zIndex: 1500,
          top: "7rem",
          color: "#6b6b6b",
        }}
        onClick={() => {
          console.log("icon click", history);
          history.goBack();
        }}
        className="fa fa-chevron-left ml-3"
        aria-hidden="true"
      ></i>
      {/* </div>
			<div> */}
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              // onClick={handleStep(index)}
              StepIconComponent={ColorlibStepIcon}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {/* </div> */}
      <div>
        <Typography className={classes.instructions}>
          {getStepContent(activeStep)}
        </Typography>
      </div>
      <div className="d-flex justify-content-center mb-5">
        {activeStep !== 0 && (
          <Button
            onClick={() => {
              setActiveStep(activeStep - 1);
            }}
            className="my-cust-button prev-btn mt-4  pt-1 pb-1  mb-5"
          >
            Previous
          </Button>
        )}
        {activeStep !== 4 && (
          <Button
            onClick={() => {
              setActiveStep(activeStep + 1);
            }}
            className="my-cust-button prev-btn mt-4  pt-1 pb-1  mb-5"
          >
            Next
          </Button>
        )}
      </div>
    </div>
  );
}
