import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form, InputGroup } from "react-bootstrap";
import api from "../../api";
import Slide from "@material-ui/core/Slide";
import ObModal from "../../components/Modal/ObModal";
import { useIsFocusVisible } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Cross from "../../svg_objects/cross";
import RightTick from "../../svg_objects/RightTick";
import CustModal from "../../components/cust-modal/CustModal";
import { Validations } from "../../Validations";
import { Alert, ModalHeader, ModalBody, Input, ModalFooter } from 'reactstrap';
import {useSelector,useDispatch} from 'react-redux'
import { useHistory } from "react-router";

const EditProfile = ({ setMode, comingFrom = null, status }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [openToast, setOpenToast] = React.useState(false);
  const [transition, setTransition] = React.useState(undefined);
  const [message, setMessage] = useState("");
  const [errMsgs, setErrMsgs] = useState(null);
  const [isErr, setIsErr] = useState(false);
  const [errCount, setErrCount] = useState(0);
  const [alertClr, setAlertClr] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [open, setOpen] = React.useState(false);
  const authToken = useSelector(state=>state.auth_token)
  const profileData = useSelector(state=>state.profileData)
  const dispatch= useDispatch()
  let history = useHistory() 
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleClose = () => {
  //   setOpenToast(false);
  // };
 

  useEffect(() => {
    console.log("PROFILE DATA", profileData);
  }, [profileData]);

  // const onChangeHandler = (e) => {
  //   if (e.target.name === 'firstName' || e.target.name === 'lastName') {
  //     let reg = new RegExp(/[^A-Za-z]/gi, '');
  //     if (!(reg).test(e.target.value)) {
  //       dispatch({type:"set_profile_data",profileData:{...profileData,[e.target.name]:e.target.value}})
  //     }
  //   } else if (e.target.name === "mobile") {
  //     var regE1 = new RegExp(/[^0-9]/gi, "");
  //     if (!regE1.test(e.target.value)) {
  //       dispatch({type:"set_profile_data",profileData:{...profileData,[e.target.name]:e.target.value}})
  //     }
  //   } else {
  //     dispatch({type:"set_profile_data",profileData:{...profileData,[e.target.name]:e.target.value}})
  //   }

  //   setErrMsgs({
  //     ...errMsgs,
  //     [e.target.name]: null,
  //   });
  // };

  useEffect(() => {
    if (profileData) {
      setFirstName(profileData.firstName || "");
      setLastName(profileData.lastName || "");
      setMobileNumber(profileData.mobile || "");
    }
  }, [profileData]);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "firstName") {
      setFirstName(value);
    } else if (name === "lastName") {
      setLastName(value);
    } else if (name === "mobile") {
      setMobileNumber(value);
    }
    setErrMsgs({
      ...errMsgs,
      [name]: null,
    });
  };

  useEffect(() => {
    console.log(errMsgs);
  }, [errMsgs]);

  const updateProfile = async (Transition) => {
    let err = {};
    let count = 0;
    if (firstName.length <= 0) {
      err.firstName = "Firstname can't be empty";
      ++count;
    }
    if (lastName.length <= 0) {
      err.lastName = "Lastname can't be empty";
      ++count;
    }
    if (mobileNumber.length === 0) {
      err.mobile = "Phone no can't be empty";
      ++count;
    }

    const t1 = Validations.hasWhiteSpace(firstName);
    if (t1) {
      err.firstName = "Please Enter Valid Firstname";
      ++count;
    }

    const t2 = Validations.hasWhiteSpace(lastName);
    if (t2) {
      err.LastName = "Please Enter Valid Lastname";
      ++count;
    }

    const t3 = Validations.hasWhiteSpace(mobileNumber);
    if (t3) {
      err.LastName = "Please Enter Valid Mobile no";
      ++count;
    }

    if (count > 0) {
      console.log(err);
      setErrMsgs({
        ...err,
      });
      return;
    } else {
      let Obj = {
        firstName: firstName,
        lastName: lastName,
        mobile: Number(mobileNumber),
      };
      try {
        let response = await api.user.updateProfile(Obj, authToken);
        console.log("API RESPONSE", response);
        if (response.resultCode === 1) {
          setMessage(response.resultMsg);
          setTransition(() => Transition);
          setIsErr(false);
          setAlertClr("success")
          // dispatch({type:"set_profile_data",profileData:profileData})
          dispatch({
            type: "set_profile_data",
            profileData: {
              ...profileData,
              firstName: Obj.firstName,
              lastName: Obj.lastName,
              mobile: Obj.mobile,
            },
          });
          setTimeout(() => {
            handleOpen();
          }, 500);
          setTimeout(() => {
            handleClose();
          }, 3000);
          if(status==="EDITREWARD"){
            history.push('/count')
          }
          if (comingFrom === null) {
            setTimeout(() => {
              setMode("DISPLAY");
            }, 2000);
          }
        } else {
          if (response.resultMsg === "Can not update. No profile details changed.") {
            setMessage("Can not update. No profile details changed");
            setIsErr(true);
            setAlertClr("danger");
          } else {
            setMessage(response.resultMsg);
            setIsErr(true);
            setAlertClr("danger");
          }
          setTimeout(() => {
            handleOpen();
          }, 500);
          setTimeout(() => {
            handleClose();
          }, 2000);
          setTransition(() => Transition);
          setIsErr(true);
        }
      } catch (err) {
        console.error(err);
        if (err.response.status === 501) {
          setMessage("Contact number already registered. Please enter a new contact number.");
          setIsErr(true);
          setAlertClr("danger");
          // setTimeout(() => {
            handleOpen();
          // }, 500);
          setTimeout(() => {
            handleClose();
          }, 2000);
        }
        if (err.response.status === 422) {
          setMessage("Please enter valid Contact Number");
          setIsErr(true);
          setAlertClr("danger");
          // setTimeout(() => {
            handleOpen();
          // }, 500);
          setTimeout(() => {
            handleClose();
          }, 2000);
        }
      }
    }
  };

  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

  return (
    <div className="card profile-edit-container  mt-5 mb-5">
      {/* <CustModal
        open={open}
        isErr={isErr}
        handleClose={handleClose}
        message={message}
      /> */}


      <Form className="form-style p-5" style={{ fontSize: "larger" }}>
        <Alert className="alert-position" isOpen={open} color={alertClr}>
          {message}{" "}
        </Alert>
        <Form.Group controlId="firstname">
          <Form.Label
            className="cust-label shiftToLeft"

          >
            First Name
          </Form.Label>
          <Form.Control
            className="cust-inputStyle"
            onChange={onChangeHandler}
            name="firstName"
            style={{ backgroundColor: "white", textAlign: "left" }}
            value={firstName}
          ></Form.Control>
          {errMsgs && errMsgs.firstName && (
            <p style={{ color: "red" }}>{errMsgs.firstName}</p>
          )}
        </Form.Group>
        <Form.Group controlId="lastname">
          <Form.Label
            className="cust-label shiftToLeft"

          >
            Last Name
          </Form.Label>
          <Form.Control
            className="cust-inputStyle"
            onChange={onChangeHandler}
            name="lastName"
            style={{ backgroundColor: "white", textAlign: "left" }}
            value={lastName}
          ></Form.Control>
          {errMsgs && errMsgs.lastName && (
            <p style={{ color: "red" }}>{errMsgs.lastName}</p>
          )}
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label
            className="cust-label shiftToLeft"

          >
            Email Id
          </Form.Label>
          <Form.Control
            className="cust-inputStyle"
            onChange={onChangeHandler}
            name="email"
            style={{ textAlign: "left" }}
            disabled="true"
            value={profileData && profileData.email}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="contact">
          <Form.Label
            className="cust-label shiftToLeft"

          >
            Contact Number
          </Form.Label>
          <Form.Control
            maxLength={8}
            type="text"
            className="cust-inputStyle"
            onChange={onChangeHandler}
            name="mobile"
            style={{ backgroundColor: "white", textAlign: "left" }}
            value={mobileNumber}
          ></Form.Control>
          {errMsgs && errMsgs.mobile && (
            <p style={{ color: "red" }}>{errMsgs.mobile}</p>
          )}
        </Form.Group>
        <div className="d-flex justify-content-center">
          <Button
            onClick={() => {
              updateProfile(TransitionUp);
            }}
            className="my-cust-button mt-5 pl-5 pt-1 pb-1 pr-5"
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditProfile;
