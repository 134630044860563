import React from 'react';
import { useHistory } from 'react-router-dom';

import './Auth_Layout.css'

const Auth_Layout = ({component}) => {
    const history = useHistory()
    console.log(component);
    return (
        <div className="auth-layout">
            <span className='home-btn' onClick={()=>history.push('/home')}>
            <i class="fas fa-home mr-2"></i>
            Home
            </span>
            {component}
        </div>
    );
};

export default Auth_Layout;