/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./card.css";
import moment from "moment";
import { Card, Button, Modal } from "react-bootstrap";
import {
  API_IMAGE_URL,
  API_SERVER_URL,
  DEFAULT_JWT_TOKEN,
} from "../../app_constants";
import api from "../../api";
import whiteImg from "../../assets/logo/white.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Alert, ModalHeader, ModalBody, Input, ModalFooter } from "reactstrap";
import contract from "../../assets/logo/job_type1.png";
import axios from "axios";
import Logout from "../../logOut/Logout";
import Loader from "react-js-loader";
import Dropzone from "react-dropzone";
import { useDropzone } from "react-dropzone";
import Drawer from "react-bottom-drawer";
// import { base64StringToBlob } from 'blob-util';
// import { Document, Page, pdfjs } from 'react-pdf';
import CvViewer from "../../Component/forMobileCv/CvViewer";
import { defaultJobIcon } from "../../assets";
import RewardPopup from "../RewardPopup";
import FeedbackPopup from "../feedback/FeedbackPopup";
import CustDialog from "../cust-dialog/CustDialog";
import { services } from "../../helpers/utilities";
import { ACTIVITY } from "../../helpers/ACTIVITY_CONSTANTS";
import RMTrainingCard from "./Card";
import zIndex from "@material-ui/core/styles/zIndex";

const imageMaxSize = 2097152; //bytes
const acceptedFileType = "application/pdf";
const acceptedFileTypeArray = acceptedFileType.split(",").map((item) => {
  return item.trim();
});

const TrainingEventCard = ({ data = [], endPoint, setEndPoint }) => {
  // var profile = JSON.parse(localStorage.getItem("profile"))
  let profile = useSelector((state) => state.profileData);
  const dispatch = useDispatch();

  const [favIdx, setFavIdx] = useState();
  const [jobData, setJobData] = useState();
  const [applyId, setApplyId] = useState();
  const [buttonName, setButtonName] = useState("");
  const [message, setMessage] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [msgAlertClr, setMsgAlertClr] = useState("success");
  const [show, setShow] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [detailClick, setDetailClick] = useState(false);
  const [applyClick, setApplyClick] = useState(false);
  const [trainingId, setTrainingId] = useState();
  const [alertClr, setAlertClr] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [singleJob, setSingleJob] = useState([]);
  const [isPopUpShow, setIsPopUpShow] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [deviceType, setDeviceType] = useState();
  const [selectedFile, setSelectedFile] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [isRewardAllocate, setIsRewardAllocate] = useState(false);
  const [isFeedbackPopupOpen, setIsFeedbackPopupOpen] = useState(false);
  const [earnReward, setEarnReward] = useState(0);
  const [dialogStatus, setDialogStatus] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  const history = useHistory();
  // const scrollEnd = useSelector(state => state.scrollEnd);
  const userCV = useSelector((state) => state.userCV);

  // var auth = JSON.parse(localStorage.getItem("userAuth"));
  let auth = useSelector((state) => state.auth_token);

  // To check device(mobile or dekstop)
  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setDeviceType("Mobile");
    } else {
      setDeviceType("Desktop");
    }
    console.log("uploade userCV", userCV);
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setMessage("");
        setAlertClr("");
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (isRewardAllocate) {
      setTimeout(() => {
        setIsRewardAllocate(false);
      }, 6000);
    }
    console.log("isALocated", isRewardAllocate);
  }, [isRewardAllocate]);

  const short = (str, allowedLength = 10) => {
    if (str && str.length > allowedLength) {
      return str.slice(0, allowedLength) + "...";
    }
    return str;
  };

  useEffect(() => {
    if (successMsg) {
      setTimeout(() => {
        setSuccessMsg("");
        setMsgAlertClr("success");
      }, 3000);
    }
  }, [successMsg]);

  const onClick = () => {
    if (profile.status === 1) {
      dispatch({ type: "setOtp_timer_value", OTP_Timer_value: 300000 });
      history.push({
        pathname: "/OTP",
        state: {
          status: "fromJoblist",
        },
      });
    } else if (profile.status === 0) {
      history.push("/signUp");
    }
  };

  useEffect(async () => {
    if (applyClick) {
      let activityLogDetails = {
        activityCode: "CLICK_APPLY_JOB",
        deviceFcmToken: "WEB_TKN_" + profile.email,
        userId: profile.id,
        entityType: "JOB",
        entityId: trainingId,
        activityData: null,
      };

      try {
        console.log(activityLogDetails);
        let res = await api.activity.activityLog(
          activityLogDetails,
          DEFAULT_JWT_TOKEN
        );
        console.log("Activity Log Response", res);
      } catch (err) {
        console.log("Activity log err", err.response);
      }
      console.log(profile);
      // 2- verified, 1- Non verified, 0- guest user
      if (profile.status === 1) {
        setShow(true);
        setMessage("You need to verify email first to apply to this training");
        setModalHeading("Email verification needed");
        setButtonName("Verify Email Now");
      } else if (profile.status === 0) {
        setShow(true);
        setMessage("You need to sign up first to apply to this training");
        setModalHeading("Sign up needed");
        setButtonName("Sign Up Now");
      } else {
        console.log("SINGLE JOB ", singleJob);
        if (!singleJob.isApplied) {
          setApplyClick(false);
          setIsPopUpShow(true);
          console.log("CLICK APLY");

          console.log("profile ", profile);
        } else {
          setSuccessMsg("Training is already applied");
          setMsgAlertClr("danger");
        }
      }
    }

    if (detailClick) {
      let activityLogDetails = {
        activityCode: "CLICK_JOB",
        deviceFcmToken: "WEB_TKN_" + profile.email,
        userId: profile.id,
        entityType: "JOB",
        entityId: trainingId,
        activityData: null,
      };

      try {
        console.log(activityLogDetails);
        let res = await api.activity.activityLog(
          activityLogDetails,
          DEFAULT_JWT_TOKEN
        );
        console.log("Activity Log Response", res);
      } catch (err) {
        console.log("Activity log err", err.response);
      }
      // console.log(status);
      // 2- verified, 1- Non verified, 0- guest user
      if (profile.status === 1) {
        setShow(true);
        setMessage("You need to verify email first to view the training details");
        setModalHeading("Email verification needed");
        setButtonName("Verify Email Now");
      } else if (profile.status === 0) {
        setShow(true);
        setMessage("You need to sign up first to view the training details ");
        setModalHeading("Sign up needed");
        setButtonName("Sign Up Now");
      } else {
        // history.push("/jobDetail");

        history.push({
          pathname: "/trainingDetail/" + trainingId,
        });
      }
    }
  }, [applyClick, detailClick]);

  const handleAddRemoveFav = (c, idx) => {
    console.log(c);
    setFavIdx(idx);
    setApplyId("");
    console.log("single training ", c);
    var status;
    var dataId = {
      onlineVideoTrainingId: c.onlineVideoTrainingId,
    };

    if (profile) {
      status = profile.status;
    } else {
      status = 0;
    }

    if (profile.status === 1) {
      setShow(true);
      setMessage("You need to sign up first to add the training to Favourites");
      setModalHeading("Email verification needed");
      setButtonName("Verify Email Now");
    } else if (status === 0) {
      setShow(true);
      setMessage("You need to sign up first to add the job to Favourites");
      setModalHeading("Sign up needed");
      setButtonName("Sign Up Now");
    }

    console.log(auth, " ", dataId);
    if (status === 2) {
      // add training as fav
      if (!c.isFavourite) {
        api.training
          .isFav(dataId, auth)
          .then((result) => {
            console.log("IS FAV ", result);
            if (result.resultCode === 1) {
              setIsLoading(true);
              setSuccessMsg("This training has been added as a Favourite");
              setMsgAlertClr("success");

              dispatch({
                type: "shouldeReloadTrainingList/save",
                shouldeReloadTrainingList: true,
              });
            }
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
            if (
              !!error.response &&
              !!error.response.status &&
              error.response.status === 401
            ) {
              Logout(dispatch, history, false);
            }
            if (error.message === "Network Error") {
              setMessage("Please check your network connection!");
              setAlertClr("danger");
              setShowAlert(true);
            }
          });
      } else {
        // fav job remove from fav list
        api.training
          .isFavRemove(dataId, auth)
          .then((result) => {
            console.log("IS FAV REMOVE", result);
            if (result.resultCode === 1) {
              setIsLoading(true);
              setSuccessMsg("This training has been removed from Favourite");
              setMsgAlertClr("success");
              dispatch({
                type: "shouldeReloadTrainingList/save",
                shouldeReloadTrainingList: true,
              });
            }
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
            if (
              !!error.response &&
              !!error.response.status &&
              error.response.status === 401
            ) {
              Logout(dispatch, history, false);
            }
            if (error.message === "Network Error") {
              setAlertMessage("Please check your network connection!");
              setAlertClr("danger");
              setShowAlert(true);
            }
          });
      }
    }
  };

  return (
    <React.Fragment>
      {isFeedbackPopupOpen && (
        <FeedbackPopup isFeedbackOpen={isFeedbackPopupOpen} />
      )}

      <div className="fix">
        <CustDialog
          open={dialogStatus}
          handleClose={() => {
            setDialogStatus(false);
            history.push("/createCV");
          }}
          message="To create your CV, we are navigating away from this screen. To easily find this training later, you can add it to your Favorite List"
          disagree="No thanks!"
          agree="Add to favourite  and continue"
          handleAction={() => {
            if (!isFavorite) {
              handleAddRemoveFav(jobData, favIdx);
              // setDialogStatus(false);
              // history.push('/createCV');
            }
          }}
        />
        <Alert className="alert-position" isOpen={showAlert} color={alertClr}>
          {alertMessage}{" "}
        </Alert>

        <Modal
          className="modal-style"
          onHide={() => {
            setShow(false);
            setDetailClick(false);
            setApplyClick(false);
          }}
          show={show}
          // size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <i
                class="fa fa-exclamation-triangle"
                aria-hidden="true"
                style={{ color: "#ffc107" }}
              ></i>{" "}
              {modalHeading}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{message}</Modal.Body>
          <Modal.Footer>
            <Button
              onClick={onClick}
              style={{
                cursor: "pointer",
                borderColor: "#5ba4fb",
                backgroundColor: "#5ba4fb",
              }}
            >
              {buttonName}
            </Button>
            <Button
              className="close-btn"
              // style={{backgroundColor:'#bbb6b6', border:'none', width:'500px'}}
              onClick={() => {
                setApplyClick(false);
                setDetailClick(false);
                setShow(false);
              }}
              style={{
                cursor: "pointer",
                backgroundColor: "#95989a",
                width: "100px",
                borderColor: "#95989a",
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* <div className="container"> */}
        {data.slice(0, endPoint).map((c, idx) => {
          return (
            <RMTrainingCard
              onClick={() => {
                setDetailClick(true);
                setTrainingId(c.onlineVideoTrainingId);
                dispatch({
                  type: "scrollEnd/save",
                  scrollEnd: window.pageYOffset,
                });
                console.log("SCROLLLLw", window.pageYOffset);
              }}
              style={{
                margin: "2rem",
              }}
              data={{
                categoryIconPath: c.categoryIconPath,
                locationNmList: c.locationNmList,
                oneLinerDescription: c.oneLinerDescription,
                title: c.title,
                compensation: c.compensation,
                contractTypeNmList: c.contractTypeNmList,
                publishOn: c.createdOn,
                description: c.description,
              }}
              onApply={() => {
                setApplyClick(true);
                setApplyId(idx);
                setFavIdx("");
                setSingleJob(c);
                console.log("iD ", idx);
                setTrainingId(c.onlineVideoTrainingId);
                setFavIdx(idx);
                setJobData(c);
                setIsFavorite(c.isFavorite);
              }}
              isApplied={c.isApplied}
              favComponent={
                <i
                  class="fa fa-star"
                  aria-hidden="true"
                  value={c.id}
                  style={
                    c.isFavourite
                      ? {
                          color: "#499cff",
                          fontSize: "2rem",
                          cursor: "pointer",
                        }
                      : { color: "black", fontSize: "2rem", cursor: "pointer" }
                  }
                  onClick={() => {
                    console.log(c);
                    services.statusCheck(profile.status) &&
                      services.activityLogService(
                        ACTIVITY.CLICK_FAVORITE_JOB,
                        profile,
                        c.id
                      );
                    handleAddRemoveFav(c, idx);
                  }}
                  // onClick={() => favIcon(c, idx)}
                ></i>
              }
            >
              {isRewardAllocate && earnReward > 0 && idx === applyId && (
                <div
                  className="d-flex justify-content-center"
                  style={{
                    position: "absolute",
                    zIndex: "1500",
                    height: "fit-content",
                    top: "0px",
                    left: "0px",
                    width: "100%",
                    display:"none",
                  }}
                >
                  <RewardPopup
                    rewardMsg="Congrats! You've just earned your daily reward  for appying for 5 training today.Best wishes in your search for a dream job"
                    isPopupOpen={isRewardAllocate}
                    earnReward={earnReward}
                    rewardStyle="reward-rm"
                    from="list-job"
                  />
                </div>
              )}

              <Alert
                className="alert-position"
                style={{
                  position: "absolute",
                  top: "2%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: "14",
                }}
                isOpen={
                  (successMsg && idx === favIdx) ||
                  (successMsg && idx === applyId)
                }
                color={msgAlertClr}
              >
                {successMsg}
              </Alert>
            </RMTrainingCard>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default TrainingEventCard;
