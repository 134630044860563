import React from 'react';
import JobCard from '../../Component/JobCard';
import Cust_Banner from '../../components/cust-banner/Cust_Banner';
import './Jobs.css'

const Jobs = () => {
    return (
        <div className="job-opportunity">
            <Cust_Banner/>
            <h1>Job Function Categories</h1>
            <hr className="cust-underline"/>
            <JobCard/>
        </div>
    );
};

export default Jobs;