import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";

const ClaimModal = ({ show, setShowModal }) => {

  const history = useHistory();

  const handleClose = () => {
    setShowModal(false);
    localStorage.setItem("popupShownDate", new Date().toLocaleDateString());
  };

  console.log("history ----> ",history);

  return (
    <>
      <Modal show={show} onHide={handleClose} className="contact-us-modal">
        <Modal.Header closeButton>
          <Modal.Title>Last chance to claim!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h4>
              Redeem all points by January 31st. Unclaimed rewards expires
              February 1st. ⏰
            </h4>
            {/* <h4>
              Email us on{" "}
              <a href="mailto:oppbank@zubinfoundation.org">
                <u>oppbank@zubinfoundation.org</u>
              </a>{" "}
              or
            </h4> */}
            <h4>
              <i
                className="fab fa-whatsapp-square"
                style={{ color: "#65D251", fontSize: "25px" }}
              ></i>
              <a
                href="https://wa.me/+85296823100"
                target="_blank"
                rel="noopener"
              >
                &nbsp;<u>+852 9682 3100</u>
              </a>
            </h4>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "space-between" }}>
          <Button
            className="close-btn-modal"
            variant="primary"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            className="close-btn-modal"
            variant="secondary"
            onClick={() => {
              handleClose()
              setTimeout(()=>{
              history.replace("/count");
              },2000)
            }}
          >
            Redeem Now
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClaimModal;
