import React from 'react'
import './glanceSection.css'
import job from '../../assets/logo/skills.png'
import TAE from '../../assets/logo/seminar.png'
import Scolor from '../../assets/logo/student.png'
import SAH from '../../assets/logo/drug.png'
// import WSI from '../../assets/logo/responsive-deign.png'
import App from '../../assets/logo/seminar.png'
const GlanceSection = ({ }) => {
  return (
    <React.Fragment >
      <h3 className="glance-align" style={{ marginTop: '3rem' }}>
        Services at a glance
      </h3>
      <div className="line"></div>
      <p className="glace-para" style={{ textAlign: 'start', marginLeft: '3%' }}>
        Opportunity Bank seeks to push for greater transparency & higher accessibility to information.
      </p>
      {/* --------------------------------- */}

      {/* <div className="top-container">
        {/* <div className="" > */}



      {/* </div> 
      </div> */}
    </React.Fragment>
  )
}

export default GlanceSection;













// <ul className="ul-item">
// <div class="card item-card card-block">
//   <li className="li-item-align" >
//     <img className="img-item"
//       src={job} alt="Photo of sunset" />
//     <h5 class="item-card-title mt-3 mb-3">Job</h5>
//   </li>
// </div>
// <div class="card item-card card-block">
//   <li className="li-item-align">
//     <img className="img-item"
//       src={TAE} alt="Photo of sunset" />
//     <h5 class="item-card-title mt-3 mb-3">Training &
// Events</h5>
//   </li>
// </div>

// <div class="card item-card card-block">
//   <li className="li-item-align">
//     <img className="img-item"
//       src={Scolor} alt="Photo of sunset" />
//     <h5 class="item-card-title mt-3 mb-3">Scholarship</h5>
//   </li>
// </div>


// {/* </div> */}
// </ul>

// <ul className="ul-item">
// <div class="card item-card card-block">
//   <li className="li-item-align">
//     <img className="img-item"
//       src={SAH} alt="Photo of sunset" />
//     <h5 class="item-card-title mt-3 mb-3">Social & Healthcare
// </h5>
//   </li>
// </div>
// <div class="card item-card card-block">
//   <li className="li-item-align">
//     <img className="img-item"
//       src={job} alt="Photo of sunset" />
//     <h5 class="item-card-title mt-3 mb-3">Welfare Security Info</h5>
//   </li>
// </div>
// {/* </div>
// <div className=""> */}
// <div class="card item-card card-block">
//   <li className="li-item-align">
//     <img className="img-item"
//       src={App} alt="Photo of sunset" />
//     <h5 class="item-card-title mt-3 mb-3">App
// (to come)</h5>
//   </li>
// </div>
// </ul>
