import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import './contactInfo.css'
import api from '../../../api';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import Logout from '../../../logOut/Logout';
import { useDispatch, useSelector } from 'react-redux';
import Loader from "react-js-loader";

const ContactInfo = ({ nextStep, onContactInfoSave }) => {

  const [flatNo, setFlatNo] = useState('');
  const [floor, setFloor] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [streetName, setStreetName] = useState('');
  const [streetNameErr, setStreetNameErr] = useState('');
  const [districtId, setDistrictId] = useState('');
  const [districtErr, setDistrictErr] = useState('');
  const [districtList, setDistrictList] = useState([]);
  const [message, setMessage] = useState('');
  const [alertClr, setAlertClr] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const authToken =useSelector(state => state.auth_token)
  
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    api.onlineCV.district(authToken).
      then(result => {
        console.log("DISTRIC ==> ", result)
        if (result.resultCode === 1) {
          setDistrictList(result.resultData.districtList)
        } else {
          setDistrictList([])
        }
      }).catch(error => {
        console.log(error)
        if (!!error.response && !!error.response.status && error.response.status === 401) {
          Logout(dispatch, history, false);
        }
        if (error.message === 'Network Error') {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      })




  }, [])

  // useEffect(() => {
  //   // console.log("HISTORY ", history.location.state.districtId)
  //   if (history.location && history.location.state) {
  // setFloor(history.location.state.floor)
  // setFlatNo(history.location.state.flat)
  // setBuildingName(history.location.state.buildingName)
  // setStreetName(history.location.state.streetName)
  // setDistrictId(history.location.state.districtId)
  //   }
  // }, [])

  useEffect(() => {
    console.log(authToken)
    setIsLoading(true)
    api.onlineCV.getAddress(authToken).
      then(result => {
        console.log("RESULT => get address", result);
        if (result.resultCode === 1) {
          setFloor(result.resultData.userAddress.floor)
          setFlatNo(result.resultData.userAddress.flat)
          setBuildingName(result.resultData.userAddress.buildingName)
          setStreetName(result.resultData.userAddress.streetName)
          setDistrictId(result.resultData.userAddress.districtId)
          // stepFromContactInfo(true)
        } else {
          // stepFromContactInfo(false)
        }
        setIsLoading(false)
      }).catch(error => {
        setIsLoading(false)
        console.log(error)
        if (!!error.response && !!error.response.status && error.response.status === 401) {
          Logout(dispatch, history, false);
        }
        if (error.message === 'Network Error') {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      })
    // window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setMessage('');
      setAlertClr("");
      setShowAlert(false);
    }, 3000)
  }, [showAlert])

  const saveAddress = () => {

    if (!streetName) {
      setStreetNameErr("Please fill up mandatory field")
    } else {
      setStreetNameErr('')
    }

    if (!districtId) {
      setDistrictErr("Please fill up mandatory field")
    } else {
      setDistrictErr("")
    }

    var data = {
      // flat: flatNo,
      // floor: floor,
      // buildingName: buildingName,
      streetName: streetName,
      districtId: Number(districtId)
    }

    if (flatNo) {
      data.flat = flatNo
    }

    if (floor) {
      data.floor = floor
    }

    if (buildingName) {
      data.buildingName = buildingName
    }
    setIsLoading(true)
    console.log("DATA ", data)
    api.onlineCV.address(data, authToken).
      then(result => {
        console.log("RESULT => address ", result)
        if (result.resultCode === 1) {
          setMessage("Address saved successfully");
          setAlertClr("success");
          setShowAlert(true);
          // stepFromContactInfo(true)
          if (history.location.state) {
            setMessage(result.resultMsg);
            setAlertClr("danger");
            setShowAlert(true);
          }
        } else {

          setTimeout(() => {
            onContactInfoSave("contactInfo");
          }, 3000)
          // stepFromContactInfo(false)
        }
        setIsLoading(false)
      }).catch(error => {
        // passToStepOne(false)
        setIsLoading(false)
        console.log(error)
        if (!!error.response && !!error.response.status && error.response.status === 401) {
          Logout(dispatch, history, false);
        }
        if (error.message === 'Network Error') {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      })
  }

  return (
    <React.Fragment>
      <h3 className="mt-3" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} >Contact Information</h3>
      <div id="" className="card profile-edit-container   mt-5" >

        {isLoading &&
          <div style={{ zIndex: '1500' }} className="d-flex justify-content-center">
            < Loader type="spinner-cub" bgColor={"rgb(53, 50, 50)"} title={"Loading..."} size={100} />
          </div>
        }

        <Form className="form-style p-5" style={{ fontSize: "larger" }}>
          <Alert className='alert-position' isOpen={showAlert} color={alertClr}>{message} </Alert>

          <Form.Group >
            <Form.Label className="cust-label shiftToLeft mt-3">
              Flat No
            </Form.Label>
            <Form.Control className="cv-inputStyle"
              style={{ backgrounfColor: 'white' }}
              value={flatNo}
              onChange={(e) => {
                setFlatNo(e.target.value);
              }}
              type="text" />
          </Form.Group>

          <Form.Group >
            <Form.Label className="cust-label shiftToLeft">
              Floor
            </Form.Label>
            <Form.Control className="cv-inputStyle"
              style={{ backgrounfColor: 'white' }}
              value={floor}
              onChange={(e) => {
                setFloor(e.target.value);
              }}
              type="text" />
          </Form.Group>

          <Form.Group >
            <Form.Label className="cust-label shiftToLeft">
              Building Name
            </Form.Label>
            <Form.Control className="cv-inputStyle"
              style={{ backgrounfColor: 'white' }}
              value={buildingName}
              onChange={(e) => {
                setBuildingName(e.target.value);
              }}
              type="text" />
          </Form.Group>

          <Form.Group >
            <Form.Label className="cust-label shiftToLeft">
              Street Name<span className="mandatory-star ml-2">*</span>
            </Form.Label>
            <Form.Control className="cv-inputStyle"
              style={{ backgrounfColor: 'white' }}
              value={streetName}
              onChange={(e) => {
                setStreetName(e.target.value);
                if (e.target.value) {
                  setStreetNameErr("");
                }
              }}
              type="text" />
            <Form.Text className="font-size-vali-msg" style={{ color: 'red', display: 'flex', justifyContent: 'start', fontFamily: 'emoji' }} >
              {streetNameErr}
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Label style={{ marginBottom: '-2px' }} className="cust-label shiftToLeft">
              District<span className="mandatory-star ml-2">*</span>
            </Form.Label>
            <select
              style={{ backgrounfColor: 'white' }}
              className="dropdown-style"
              value={districtId}
              onChange={(e) => {
                setDistrictId(e.target.value)
                if (e.target.value) {
                  setDistrictErr('')
                }
              }}>
              <option ></option>
              {
                districtList.map((Item, idx) => {
                  return <option key={idx} value={Item.locationId}>{Item.locationName}</option>
                })
              }
            </select>
            <Form.Text className="font-size-vali-msg" style={{ color: 'red', display: 'flex', justifyContent: 'start', fontFamily: 'emoji' }} >
              {districtErr}
            </Form.Text>
          </Form.Group>
          <div className="d-flex justify-content-center">
            {/* <div> */}
            <Button
              onClick={() => {
                saveAddress();
                if (history.location.state) {
                  window.history.back()
                }
              }}
              className="my-cust-button mt-2  pl-5 pt-1 pb-1 pr-5"
            >
              Save
            </Button>
            {/* </div>
          <div> */}
            {/* <Button
            onClick={() => {
              saveAddress();
              setTimeout(() => {
                nextStep(1);
              }, 4000);
            }}
            className="my-cust-button mt-2  pl-5 pt-1 pb-1 pr-5"
          >
            Save &  Next
          </Button> */}
            {/* </div> */}
          </div>
        </Form>


      </div>
    </React.Fragment>
  )
}

export default ContactInfo;