import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import JumbotronPlaceHolder from "../../components/JumbotronPlaceHolder";
import mobileSizeBanner from "../../assets/Banner/Dashboard@1.5x.png";
import banner from "../../assets/Banner/trainingpage.jpg";
import Loader from "react-js-loader";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import { API_IMAGE_URL, DEFAULT_JWT_TOKEN } from "../../app_constants";
import { defaultJobIcon } from "../../assets";
import api from "../../api";
import Logout from "../../logOut/Logout";
import { useHistory } from "react-router-dom";
import { Alert, Row, Col } from "reactstrap";
import Search from "../../components/search/Search";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import JobCard from "../../components/card/JobCard";
import TrainingEventCard from "../../components/training-event-card/TrainingEventCard";

const TrainingList = () => {
  const isMobileDevice = useMediaQuery({
    query: "(min-device-width: 0px) and (max-device-width: 768px)",
  });

  const isTabletDevice = useMediaQuery({
    query: "(min-device-width: 481) and (max-device-width: 768px)",
  });

  const isLaptop = useMediaQuery({
    query: "(min-device-width: 769px) ",
  });

  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profileData);
  const auth = useSelector((state) => state.auth_token);

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [alertClr, setAlertClr] = useState("");
  const [allCategory, setAllCategory] = useState([]);
  const [allTrainingList, setAllTrainingList] = useState([]);
  const [filterTrainingList, setFilterTrainingList] = useState([]);
  const [category, setCategory] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const [endPoint, setEndPoint] = useState(20);
  const [searchTrainingList, setSearchTrainingList] = useState([]);

  const shouldeReloadTrainingList = useSelector(
    (state) => state.shouldeReloadTrainingList
  );

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setMessage("");
        setAlertClr("");
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    getTrainingList();
    dispatch({
      type: "shouldeReloadTrainingList/save",
      shouldeReloadTrainingList: false,
    });
  }, [shouldeReloadTrainingList]);

  const allCategoryList = () => {
    setIsLoading(true);
    api.training
      .allCategory()
      .then((result) => {
        console.log("ALL Training Category Result => ", result);
        if (result.resultCode === 1) {
          setAllCategory(result.resultData.trainingEventCategoryList);
        } else {
          setAllCategory([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          console.log("logOutClick");
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch({ type: "setCurrentLink", currentLink: "/trainingList" });
    allCategoryList();
    // window.scrollTo(0, 0);
  }, []);

  //  Get Training List => API
  const getTrainingList = () => {
    // 2- verified, 1- Non verified, 0- guest user
    console.log("PROFILE ", profile);

    console.log("user type ", profile);
    if (profile) {
      if (profile.status === 0 || profile.status === 1) {
        path = "gu";
        authToken = DEFAULT_JWT_TOKEN;
      } else if (profile.status === 2) {
        path = "vu";
        authToken = auth;
      }
    } else {
      var path = "gu";
      var authToken = DEFAULT_JWT_TOKEN;
    }

    console.log("path info ", path, " ", authToken);

    setIsLoading(true);
    authToken &&
      api.training
        .fetchAllTraining(path, authToken)
        .then((result) => {
          console.log("ALL TrainingList Result => ", result);
          if (result.resultCode === 1) {
            setAllTrainingList(result.resultData.onlineVideoTrainingList);
          } else {
            setAllTrainingList([]);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          if (
            !!error.response &&
            !!error.response.status &&
            error.response.status === 401
          ) {
            console.log("logOutClick");
            Logout(dispatch, history, false);
          }
          if (error.message === "Network Error") {
            setMessage("Please check your network connection!");
            setAlertClr("danger");
            setShowAlert(true);
          }
        });
  };

  // useEffect(() => {
  //   getTrainingList();
  //   dispatch({
  //     type: "shouldeReloadJobList/save",
  //     shouldeReloadJobList: false,
  //   });
  // }, [shouldeReloadJobList]);

  useEffect(() => {
    console.log("Search Text ", searchText);
    console.log("filter List  ", filterTrainingList);
    console.log("List  ", allTrainingList);
    var searchArr = [];
    // Search Applied

    if (searchText) {
      searchArr = filterTrainingList.filter((item, idx) => {
        console.log("ITEM SEARCH ", item);
        return (
          item.title?.toLowerCase().includes(searchText.toLowerCase()) ||
          item.oneLinerDescription
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
          item.trainingCategoryName
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
          item.jobCategoryName
            ?.toString()
            .toLowerCase()
            .includes(searchText.toLowerCase())
        );
      });
    } else {
      searchArr = JSON.parse(JSON.stringify(filterTrainingList));
    }
    // setEndPoint(20);

    console.log("SEARCH LOc", searchArr);
    setSearchTrainingList(searchArr);
  }, [searchText, filterTrainingList]);

  //filter with location, contratct type and category
  useEffect(() => {
    let categoryName;
    var filterList = JSON.parse(JSON.stringify(allTrainingList));
    if (category) {
      filterList = filterList.filter((item) => {
        categoryName = auth ? item.category : item.trainingCategoryName;
        console.log("Category Selected", category, " - ", categoryName);
        return category === categoryName;
      });
    }

    // var locItem = [];
    // if (location) {
    //   filterList = filterList.filter((item) => {
    //     item.locationNmList.map((i) => {
    //       if (i === location) {
    //         locItem.push(item);
    //       }
    //     });
    //   });
    //   filterList = locItem;
    // }

    // var contractItem = [];
    // if (contractType) {
    //   filterList = filterList.filter((item) => {
    //     // return (item.contractTypeNmList.toString() === contractType)

    //     item.contractTypeNmList.map((i) => {
    //       if (i === contractType) {
    //         contractItem.push(item);
    //       }
    //     });
    //   });
    //   // console.log("SELECTED CATE loc", contractItem);
    //   filterList = contractItem;
    // }

    console.log("SELECTED CATE ", filterList);
    setFilterTrainingList(filterList);
    // setFilterTrainingList(allTrainingList);
    console.log("FilterTrainingList", filterTrainingList);
    console.log("allTrainingList", allTrainingList);
  }, [category, allTrainingList]);

  return (
    <React.Fragment>
      <Alert className="alert-position" isOpen={showAlert} color={alertClr}>
        {message}{" "}
      </Alert>
      <JumbotronPlaceHolder>
        {isMobileDevice && (
          <img
            loading
            src={mobileSizeBanner}
            alt="First slide"
            style={{ width: "100%", height: "100%" }}
          />
        )}

        {isLaptop && (
          <img
            loading
            src={banner}
            alt="second slide"
            style={{ width: "100%", height: "100%" }}
          />
        )}
        <div className="jobbanner"></div>
        <div className="banner-details">
          <h1
            className="banner-title"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              color: "white",
              textAlign: "start",
            }}
          >
            Join our Training program <br />{" "}
          </h1>
        </div>
      </JumbotronPlaceHolder>
      <p className="banner-overlay"></p>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          top: "200px",
          left: "50%",
        }}
      >
        {isLoading && (
          <Loader
            type="spinner-cub"
            bgColor={"black"}
            title={"Loading..."}
            size={100}
          />
        )}
      </div>
      <h3 className="large-header pl-5 pt-5 text-start">
        Training Program Categories
      </h3>
      <hr className="cust-underline ml-5 mb-5" />
      <div className="product-swiper-container mt-5">
        <Swiper
          className="book-swiper d-flex justify-content-space-around"
          slidesPerView={5}
          spaceBetween={1}
          loop={true}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 180,
            },
            360: {
              slidesPerView: 2,
              spaceBetween: 170,
            },
            400: {
              slidesPerView: 2,
              spaceBetween: 170,
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 3,
              spaceBetween: 190,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 3,
              width: 640,
              spaceBetween: 180,
            },
            // when window width is >= 768px
            768: {
              width: 768,
              slidesPerView: 5,
              spaceBetween: 190,
            },
          }}
          navigation={true}
        >
          {allCategory?.map((data, idx) => {
            if (data.trainingCount > 0) {
              // console.log(data);
              return (
                <SwiperSlide key={idx}>
                  <div
                    className="service-card"
                    style={
                      category === data.categoryName
                        ? {
                            border: "1px solid grey",
                            boxShadow: "inset 0 0 20px grey",
                          }
                        : { background: "white" }
                    }
                    onClick={() => {
                      // setCategory(data.categoryName)
                      // console.log("CATEGPRY", category)
                      var cat = data.categoryName;
                      if (category === "") {
                        setCategory(data.categoryName);
                      } else {
                        setCategory("");
                      }
                      dispatch({
                        type: "category/save",
                        categoryNm: data.categoryName,
                      });
                    }}
                  >
                    <img
                      src={
                        data.iconPath
                          ? API_IMAGE_URL + data.iconPath.toString()
                          : defaultJobIcon
                      }
                      alt="icon"
                    />
                    <h3 className="mt-5">
                      {data.categoryName}
                      {`(${data.trainingCount})`}
                    </h3>
                  </div>
                </SwiperSlide>
              );
            }
          })}
        </Swiper>
      </div>

      <Search
        searchText={searchText}
        setSearchText={setSearchText}
        placeholder="Search by all Training"
      />
      <h3 className=" text-center">Recommended Trainings</h3>
      <div>
        <div className="responsive-lists">
          {searchTrainingList?.length === 0 ? (
            <h3 style={{ marginTop: "4rem" }}>No, Result Found !!</h3>
          ) : (
            <TrainingEventCard
              data={searchTrainingList}
              endPoint={endPoint}
              setEndPoint={setEndPoint}
            />
          )}
        </div>
      </div>
      <div
        style={{ marginTop: "5rem", display: "flex", justifyContent: "center" }}
      >
        {!(endPoint > searchTrainingList.length) && (
          <Button
            className="view-btn"
            onClick={() => {
              setEndPoint(endPoint + 20);
              // dispatch({ type: 'endPoint/save', endPointMap: (endPoint + 20) })
            }}
          >
            Load More
          </Button>
        )}
      </div>
    </React.Fragment>
  );
};

export default TrainingList;
