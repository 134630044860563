import React from 'react';
import { Col, Row } from 'reactstrap';

const ServiceCardComponent = ({icon, title, description, arrow}) => {
    return (
        // <div className="service-card-2 pt-4 pb-4 mb-5">
            <Row className="service-card-2" style={{width:"100%"}}>
                <Col xs="3" md="3 p-3" lg="3 p-5">
                <img src={icon} />
                </Col>
                <Col xs="7" md="7 p-3" lg="7 p-2" className="text-left">
                    <h4>{title}</h4>
                    <p className="description" style={{lineHeight:"1.5"}}>{description}</p>
                </Col>
                <Col xs="2" md="2" lg="2 p-1" className="d-flex flex-column justify-content-end align-items-center " style={{height:"100%"}}>
                    <img
                    src={arrow}
                    />
                </Col>
            </Row>
        // </div>
    );
};

export default ServiceCardComponent;
