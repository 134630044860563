import React, { useEffect, useState } from "react";
import { Jumbotron } from "react-bootstrap";
import { useHistory } from "react-router";

const ComingSoon = () => {
  const history = useHistory();
  useEffect(() => {
    console.log(history);
    setTimeout(() => {
      history.goBack();
    }, 2000);
  }, []);
  return (
    <div style={{ display: "flex" }}>
      <Jumbotron
        className="jumbotron"
        style={{ backgroundColor: "#b9f6ca99", width: "100%" }}
      >
        <h1 className="ml-5 pl-5">Coming Soon...</h1>
      </Jumbotron>
    </div>
  );
};

export default ComingSoon;
