/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Button, Row, Input, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./otp.css";
import { Alert } from "reactstrap";
import api from "../../api";
import logo from "../../assets/logo/branding-2@2x.png";
import Logout from "../../logOut/Logout";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-js-loader";
import { useViewVisibility } from "../../cust-hooks/usePageVisibility";
import moment from "moment";

export default function OTP_Verification() {
  const [otp, setOtp] = useState(new Array(4).fill(""));
  let [intervalId, setIntervalId] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const [seconds, setSeconds] = useState(null);
  const [alertClr, setAlertClr] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const [message, setMessage] = useState("");
  const [isReloaded, setIsReloaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isResendOtpClick, setIsResendOtpClick] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [origin, setOrigin] = useState("");
  const lastTimerValue = useSelector((state) => state.OTP_Timer_value);
  const [milisec, setMilisec] = useState(lastTimerValue);
  const [lastTimerValueOnPause, setLastTimerValueOnPause] = useState(null);
  const viewVisibility = useViewVisibility();
  const timeStamp = useSelector((state) => state.timeStampOnPause);
  const mailSent = useSelector((state) => state.mailSent);
  const history = useHistory();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.auth_token);

  const profileData = useSelector((state) => state.profileData);
  // console.log("PR ", profileData)

  useEffect(() => {
    console.log("STATE", history.location);
    console.log("Came From", history.location.status);
    console.log(
      "NEW STATE",
      history.location.state && history.location.state.email
    );

    if (
      history.location.state &&
      history.location.state.status === "fromSignup"
    ) {
      setOrigin("fromSignup");
      setNewEmail(
        (email) =>
          (email = history.location.state && history.location.state.email)
      );
    } else if (
      (history.location.state &&
        history.location.state.status === "fromHome") ||
      (history.location.state &&
        history.location.state.status === "fromJoblist")
    ) {
      console.log("handle navigation");
      setOrigin("Local");
      setNewEmail((email) => (email = profileData && profileData.email));
    } else if (
      history.location.state &&
      history.location.state.status === "facebookbtn"
    ) {
      // do nothing
      setOrigin("facebookbtn");
      setNewEmail(
        (email) =>
          (email = history.location.state && history.location.state.email)
      );
    } else {
      history.push("/signIn");
    }
  }, []);

  useEffect(() => {
    console.log("New Email", newEmail);
    console.log("orign ", origin);
    if (
      history.location.state &&
      history.location.state.status !== "fromSignup" &&
      minutes === 5
    ) {
      handleResendOtp();
    }
  }, [newEmail]);

  const counter = () => {
    // if (minutes <= 0 && seconds <= 0) {
    //   console.log("CLear interval called");
    //   console.log("interval Id", intervalId);
    //   clearInterval(intervalId);
    // }
    // localStorage.setItem('milisecond', JSON.stringify(milisec));
    dispatch({ type: "setOtp_timer_value", OTP_Timer_value: milisec });
    if (minutes >= 0 && seconds >= 0 && milisec >= 0) {
      let newMin = Math.floor(milisec / 60000);
      let newSec = ((milisec % 60000) / 1000).toFixed(0);
      setMinutes(newMin);
      setSeconds(newSec);
    }
  };

  useEffect(() => {
    console.log("VISIBILITY", viewVisibility);
    if (viewVisibility === "hidden") {
      dispatch({ type: "set_timestamp_onpause", timeStampOnPause: moment() });
      setLastTimerValueOnPause(lastTimerValue);
    }
    if (viewVisibility === "visible" && timeStamp) {
      console.log("VISIBILITY RAN", timeStamp);
      // dispatch({ type: "setOtp_timer_value", OTP_Timer_value: (moment(timeStamp).diff(moment(), 'seconds')) * 1000 + lastTimerValue + 2})
      setMilisec(
        moment(timeStamp).diff(moment(), "seconds") * 1000 +
          lastTimerValueOnPause
      );
      dispatch({ type: "set_timestamp_onpause", timeStampOnPause: null });
    }
  }, [viewVisibility]);

  const otpTimer = () => {
    let id = null;
    if (milisec > 0) {
      id = setInterval(() => {
        setMilisec((milisec) => milisec - 1000);
      }, 1000);
      setIntervalId(id);
    }
  };

  useEffect(() => {
    let id = null;
    if (milisec > 0) {
      id = setInterval(() => {
        setMilisec((milisec) => milisec - 1000);
      }, 1000);
      setIntervalId(id);
    }
    return () => {
      clearInterval(id);
      dispatch({ type: "set_timestamp_onpause", timeStampOnPause: null });
    };
  }, []);

  useEffect(() => {
    counter();
  }, [milisec]);

  const verifyOTP = () => {
    // setIsResendOtpClick(false);latest
    // console.log("OP ", !otp.join(""))
    if (!otp.join("")) {
      // setMessage("Please enter OTP");
      // setAlertClr("danger");
      // setShowAlert(true);
      // setIsLoading(false);
    }

    var data = {
      email: newEmail,
      otp: otp.join(""),
      deviceFcmToken: "WEB_TKN_" + newEmail,
    };
    console.log(data);
    setIsLoading(true);

    if (minutes !== 0 && seconds !== 0) {
      if (!!otp.join("")) {
        if (authToken !== null) {
          api.verification
            .verifyOtp(data, authToken)
            .then((result) => {
              console.log("Result OTP verify ", result);
              if (result.resultCode === 1) {
                // dispatch({ type: "set_profile_data", profileData: {...profileData,status:2} })
                setMessage(
                  "Congratulations! Your registration is complete. Start exploring your opportunities"
                );
                setAlertClr("success");
                setShowAlert(true);
                // setTimeout(() => {
                //   Logout(dispatch,history,false)
                // }, 2000);
                // --------------
                window.scrollTo(0, 0);
                setTimeout(() => {
                  history.push({
                    pathname: "/home",
                    state: {
                      status: "fromOTPVerify",
                    },
                  });
                }, 3000);

                dispatch({
                  type: "set_auth_token",
                  auth_token: result?.resultData?.authToken,
                });

                dispatch({
                  type: "set_profile_data",
                  profileData: result?.resultData?.profile,
                });
                // --------------
              } else {
                if (result.resultMsg === "The OTP is invalid or expired") {
                  setMessage("Oops! The OTP is invalid or has expired");
                  setAlertClr("danger");
                  setShowAlert(true);
                }
                setOtp([...otp.map((v) => "")]);
              }
              setIsLoading(false);
            })
            .catch((error) => {
              console.log(error);
              if (
                !!error.response &&
                !!error.response.status &&
                error.response.status === 401
              ) {
                Logout(dispatch, history, false);
              }
              if (error.message === "Network Error") {
                setMessage("Please check your network connection!");
                setAlertClr("danger");
                setShowAlert(true);
              }
            });
        }
        if (authToken === null) {
          api.verification
            .verifyOtpSignUp(data)
            .then((result) => {
              console.log("Result OTP verify ", result);
              if (result.resultCode === 1) {
                // dispatch({ type: "set_profile_data", profileData: {...profileData,status:2} })
                setMessage(
                  "Congratulations! Email Verified Successfully"
                );
                setAlertClr("success");
                setShowAlert(true);
                setTimeout(() => {
                  history.push("/signin");
                  // Logout(dispatch, history, false);
                }, 2000);
              } else {
                if (result.resultMsg === "The OTP is invalid or expired") {
                  setMessage("Oops! The OTP is invalid or has expired");
                  setAlertClr("danger");
                  setShowAlert(true);
                }
                setOtp([...otp.map((v) => "")]);
              }
              setIsLoading(false);
            })
            .catch((error) => {
              console.log(error);
              if (
                !!error.response &&
                !!error.response.status &&
                error.response.status === 401
              ) {
                Logout(dispatch, history, false);
              }
              if (error.message === "Network Error") {
                setMessage("Please check your network connection!");
                setAlertClr("danger");
                setShowAlert(true);
              }
            });
        }
      } else {
        setMessage("Please enter OTP");
        setAlertClr("danger");
        setShowAlert(true);
        setIsLoading(false);
      }
    } else {
      setMessage(
        "Oops! OTP has expired. To set a new one, please click on Reset OTP"
      );
      setAlertClr("danger");
      setShowAlert(true);
      setOtp([...otp.map((v) => "")]);
      setIsLoading(false);
    }

    window.scrollTo(0, 0);
  };

  const handleResendOtp = () => {
    console.log("HandleResend OTP Called");
    // dispatch({ type: "setOtp_timer_value", OTP_Timer_value: 300000 })
    setMilisec(300000);
    setOtp([...otp.map((v) => "")]);
    // console.log("Email ", userD[1]);
    var email = {
      email: newEmail,
    };
    console.log("newEmail ", newEmail);
    setIsLoading(true);
    api.verification
      .resendOTP(email)
      .then((result) => {
        console.log("RESULT => RESEND OTP ", result);
        if (result.resultCode === 1) {
          setMessage(
            "OTP resent. Please check your email's Inbox or Spam folder"
          );
          setAlertClr("success");
          setShowAlert(true);
        } else {
          setMessage(result.resultMsg);
          setAlertClr("danger");
          setShowAlert(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      });
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setMessage("");
        setAlertClr("");
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const handleChange = (ele, idx) => {
    if (isNaN(ele.value)) return false;

    setOtp([...otp.map((d, i) => (i === idx ? ele.value : d))]);

    //focus on next field
    if (ele.nextSibling) {
      ele.nextSibling.focus();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      {/* <Alert className='alert-note' isOpen={true} color="warning">
        <span style={{ fontWeight: 'bold' }}>Note :</span>  Please do not refresh the page. </Alert> */}

      <Alert className="alert-position" isOpen={showAlert} color={alertClr}>
        {message}{" "}
      </Alert>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {isLoading && (
          <Loader
            type="spinner-cub"
            bgColor={"rgb(53, 50, 50)"}
            title={"Loading..."}
            size={100}
          />
        )}
      </div>
      <div>
        <img src={logo} className="logo_style" />
      </div>
      <h5 className="otp-page mb-2 mt-5">Enter verification code</h5>
      <div className="otp-page-style">We have sent you 4 digit</div>
      <div className="otp-page-style">verification code on your</div>
      <div className="otp-page-style">registered email</div>
      <h6 className="mb-5 mt-5" style={{ fontSize: "1.4rem" }}>
        {newEmail}
      </h6>
      <div className="d-flex justify-content-center mt-4 mb-3">
        {otp.map((data, idx) => {
          return (
            <input
              inputmode="numeric"
              type="text"
              id="focus"
              className="opt-field"
              name="otp"
              maxLength="1"
              key={idx}
              value={data}
              onChange={(e) => handleChange(e.target, idx)}
              onFocus={(e) => e.target.select()}
              autoComplete="off"
            />
          );
        })}
      </div>
      <h6 style={minutes < 1 ? { color: "red" } : { color: "black" }}>
        {minutes}:{seconds < 10 ? "0" : ""}
        {seconds}{" "}
      </h6>
      {/* } */}

      <div className="d-flex justify-content-center mt-4">
        <Button className="verify-button" onClick={verifyOTP}>
          Verify
        </Button>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <a
          onClick={handleResendOtp}
          style={{ color: "blue", cursor: "pointer" }}
        >
          Resend OTP
        </a>
      </div>
      <div className="d-flex justify-content-center mt-4 ">
        <a
          onClick={() => {
            dispatch({ type: "setOtp_timer_value", OTP_Timer_value: 300000 });
            if (
              history.location.state &&
              history.location.state.status === "fromHome"
            ) {
              return history.push("/signIn");
            } else if (
              history.location.state &&
              history.location.state.status === "fromJoblist"
            ) {
              return history.push("/signIn");
            } else if (
              history.location.state &&
              history.location.state.status === "fromJoblist"
            ) {
              history.push("/signIn");
            } else if (
              history.location.state &&
              history.location.state.status === "facebookbtn"
            ) {
              history.push({
                pathname: "/signIn",
                state: {
                  action: "FACEBOOKCLICK",
                },
              });
            } else {
              history.push("/signIn");
            }
            // setIsResendOtpClick(false)  latest
          }}
          style={{ color: "gray", cursor: "pointer" }}
          className="mb-5"
        >
          Skip for now
        </a>
      </div>
    </div>
  );
}
