import React, { useEffect, useState } from "react";
import { InputGroup, InputGroupAddon, Input } from "reactstrap";
import api from "../../api";
import OBlogo from "../../assets/logo/branding-2@2x.png";
import sha256 from "sha256";
import { useHistory } from "react-router-dom";
import { Alert } from "reactstrap";
import { Form, Button } from "react-bootstrap";
import Logout from "../../logOut/Logout";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-js-loader";
import { useViewVisibility } from "../../cust-hooks/usePageVisibility";
import moment from "moment";


const PwdResetOtpScreen = () => {
  const [email, setEmail] = useState()
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [password, setPassword] = useState()
  const [passErr, setPassErr] = useState()
  const [rePassErr, setRePassErr] = useState()
  const [re_password, setRe_Password] = useState()
  const [alertClr, setAlertClr] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  let [intervalId, setIntervalId] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const [seconds, setSeconds] = useState(null);
  const lastTimerValue = useSelector(state => state.OTP_Timer_value)
  const [milisec, setMilisec] = useState(lastTimerValue);
  const [lastTimerValueOnPause,setLastTimerValueOnPause] = useState(null)
  const viewVisibility = useViewVisibility() 
  const timeStamp = useSelector(state => state.timeStampOnPause)
  const [showPass, setShowPass] = useState(false);
  const [showNewPass, setNewShowPass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setMessage("");
        setAlertClr("");
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert])

 

  useEffect(() => {
    let id = null;
    if (milisec > 0) {
      id = setInterval(() => {
        setMilisec(milisec => milisec - 1000);
      }, 1000)
      setIntervalId(id);
    }
    return () => {
      clearInterval(id)
      dispatch({type:'set_timestamp_onpause',timeStampOnPause:null})

    }
  }, []);

  useEffect(() => {
    counter();
  }, [milisec])


  const counter = () => {
    if ((milisec <= 0) && intervalId) {

    }
    if (minutes <= 0 && seconds <= 0) {
      console.log("CLear interval called");
      console.log("interval Id", intervalId);
      clearInterval(intervalId);
    }
    
    if (minutes >= 0 && seconds >= 0 && milisec >= 0) {
      dispatch({ type: "setOtp_timer_value", OTP_Timer_value: milisec })
      let newMin = Math.floor(milisec / 60000)
      let newSec = ((milisec % 60000) / 1000).toFixed(0)
      setMinutes(newMin)
      setSeconds(newSec)
    }

  }

  useEffect(() => {
    console.log("VISIBILITY",viewVisibility)
    if (viewVisibility === 'hidden') {
      dispatch({ type: "set_timestamp_onpause", timeStampOnPause: moment() })
      setLastTimerValueOnPause(lastTimerValue)
    }
    if (viewVisibility === 'visible'&& timeStamp) {
      console.log("VISIBILITY RAN",timeStamp)
      // dispatch({ type: "setOtp_timer_value", OTP_Timer_value: (moment(timeStamp).diff(moment(), 'seconds')) * 1000 + lastTimerValue + 2})
      setMilisec((moment(timeStamp).diff(moment(), 'seconds')) * 1000 + lastTimerValueOnPause)
      dispatch({type:'set_timestamp_onpause',timeStampOnPause:null})
    }
  }, [viewVisibility])

  const handleChange = (ele, idx) => {
    if (isNaN(ele.value)) return false;
    setOtp([...otp.map((d, i) => (i === idx ? ele.value : d))]);
    //focus on next field
    if (ele.nextSibling) {
      ele.nextSibling.focus();
    }
  };

  useEffect(() => {
    console.log("OPT ", otp)
  }, [otp])

  const cancel = () => {
    history.push({
      pathname: "/signin",
      state: {
        email: email,
      },
    });
  }

  const handleResendOtp = () => {

    setMilisec(300000);
    // window.location.reload();

    setOtp([...otp.map(v => "")]);
    setPassword('')
    setRe_Password('')

    let body = {
      email: email
    }
    console.log("Email ", body);
    setIsLoading(true)
    api.verification.resendOTP(body).
      then(result => {
        console.log("RESULT => RESEND OTP ", result);
        if (result.resultCode === 1) {
          setMessage(result.resultMsg);
          setAlertClr("success");
          setShowAlert(true);
        } else {
          setMessage(result.resultMsg);
          setAlertClr("danger");
          setShowAlert(true);
        }
        setIsLoading(false)
      }).catch(error => {
        console.log(error);
        if (!!error.response && !!error.response.status && error.response.status === 401) {
          Logout(dispatch, history, false);
        }
        if (error.message === 'Network Error') {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }

      })
    window.scrollTo(0, 0);
  }


  const validatePassword = () => {
    var isValidPass = true;

    if (!password) {
      isValidPass = false;
    } else if ((password && password.trim().length < 6) || (password && password.trim().length > 20)) {
      setPassErr('Password should be minimum 6 and maximum 20 characters');
      isValidPass = false;
    } else {
      setPassErr('');
      isValidPass = true;
    }
    return isValidPass;
  };

  const validateNewPassword = () => {
    var isValidPass = true;

    if (!re_password) {
      isValidPass = false;
    } else if ((re_password && re_password.trim().length < 6) || (re_password && re_password.trim().length > 20)) {
      setRePassErr('Password should be minimum 6 and maximum 20 characters');
      isValidPass = false;
    } else {
      setRePassErr('');
      isValidPass = true;
    }
    return isValidPass;
  };

  const handleSubmit = () => {      
    // validatePassword();
    // validateNewPassword();

    if (!password) {
      setPassErr("Please enter your password");
    } else if ((password && password.trim().length < 6) || (password && password.trim().length > 20)) {
      setPassErr('Password should be minimum 6 and maximum 20 characters');
    } else {
      setPassErr('');
    }

    if (!re_password) {
      setRePassErr("Please enter your password");
    } else if ((re_password && re_password.trim().length < 6) || (re_password && re_password.trim().length > 20)) {
      setRePassErr('Password should be minimum 6 and maximum 20 characters');
    } else {
      setRePassErr('');
    }

    if (!otp.join("")) {
      setMessage("Please enter OTP");
      setAlertClr("danger");
      setShowAlert(true);
      window.scrollTo(0, 0)
      console.log("NO OTP")
    }

    if (minutes !== 0 && seconds !== 0) {
      if (validatePassword() && validateNewPassword()) {
        if (password === re_password) {

          let body = {
            email: email,
            otp: otp.join("").toString(),
            passwordHash: sha256(password),
            deviceFcmToken: `WEB_TKN_${email}`,
          }

          console.log(body)
          setIsLoading(true)
          api.password.passwordReset(body).then(res => {
            console.log("RESULT pasword resrt ", res)
            if (res.resultCode === 1) {
              setAlertClr("success")
              setShowAlert(true)
              setMessage(res.resultMsg)
              window.scrollTo(0, 0)
              setTimeout(() => {
                history.push({
                  pathname: "/signin",
                  state: {
                    email: email,
                  },
                });
              }, 2000)
            } else {
              setMessage(res.resultMsg);
              setAlertClr("danger");
              setShowAlert(true);
              window.scrollTo(0, 0)
            }
            setIsLoading(false)
          }).catch(error => {
            setIsLoading(false)
            console.log(error)
            if (!!error.response && !!error.response.status && error.response.status === 401) {
              Logout(dispatch, history, false);
            }
            if (error.message === 'Network Error') {
              setMessage("Please check your network connection!");
              setAlertClr("danger");
              setShowAlert(true);
            }
          })
        } else {
          setRePassErr("Password and confirm password should be same")
        }
      }

    } else {
      setMessage("OTP Expired, You can get now OTP by clicking on reset OTP");
      setAlertClr("danger");
      setShowAlert(true);
      setOtp([...otp.map(v => "")]);
      setPassword('')
      setRe_Password('')
      window.scrollTo(0, 0)
    }
  }


  useEffect(() => {
    setEmail(history.location.state.email)
  }, [])

  return (
    <>

      <div className="align-page" style={{ width: "350px" }}>
        <Alert className="alert-position" isOpen={showAlert} color={alertClr}>
          {message}{" "}
        </Alert>
        <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', left: '50%', top: '25%' }}>
          {isLoading &&
            <Loader type="spinner-cub" bgColor={"rgb(53, 50, 50)"} title={"Loading..."} size={100} />
          }
        </div>
        <div>
          <img src={OBlogo} className="loginLogo mb-3 mt-2" />
        </div>
        <h3 style={{ color: "#5B5A5A" }}>Reset Password</h3>
        <p
          className="ml-auto mr-auto text-muted"
          style={{ width: "200px", textAlign: "center", fontSize: "1.6rem" }}
        >
          We have sent you 4 digits verification code on your registered email
        </p>
        <h3 className="mt-5" style={{ fontSize: "1.7rem" }}>"{email}"</h3>
        <label className="cust-label">
          VERIFICATION CODE
        </label>
        <div className="d-flex justify-content-center mt-4 mb-3" style={{ flexDirection: "column" }}>
          <div>
            {otp.map((data, idx) => {
              return (
                <input
                  inputMode="numeric"
                  type="text"
                  id="focus"
                  className="opt-field mb-5"
                  name="otp"
                  maxLength="1"
                  key={idx}
                  value={data}
                  onChange={(e) => handleChange(e.target, idx)}
                  onFocus={(e) => e.target.select()}
                  // disabled={(minutes === 0) && (seconds === 0)}
                  autoComplete="off"
                />
              );
            })}
          </div>
          <h6 style={minutes < 1 ? { color: 'red' } : { color: 'black' }}>{minutes}:{seconds < 10 ? "0" : ""}{seconds} </h6>

          <Form.Group controlId="password">
            <Form.Label className="cust-label shiftToLeft mt-5 mb-3">NEW PASSWORD</Form.Label>
            <div className="password-position">
              <Form.Control className="pass-cust-inputStyle"
                type={showPass ? "text" : "password"}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (e.target.value) {
                    setPassErr('')
                  }
                }}
                value={password}
              />
              {showPass ?
                <span className="material-icons" onClick={() => {
                  setShowPass(false)
                }}>
                  visibility
                </span>
                :
                <span className="material-icons" onClick={() => {
                  setShowPass(true)
                }}>
                  visibility_off
                </span>
              }
            </div>
            <Form.Text style={{ color: 'red', display: 'flex', justifyContent: 'start', fontFamily: 'emoji' }} >
              {passErr}
            </Form.Text>

          </Form.Group>

          <Form.Group controlId="password">
            <Form.Label className="cust-label shiftToLeft mt-5 mb-3">CONFIRM NEW PASSWORD</Form.Label>
            <div className="password-position">
              <Form.Control className="pass-cust-inputStyle"
                type={showNewPass ? "text" : "password"}
                onChange={(e) => {
                  setRe_Password(e.target.value);
                  if (e.target.value) {
                    setRePassErr('')
                  }
                }}
                value={re_password}
              />
              {showNewPass ?
                <span className="material-icons" onClick={() => {
                  setNewShowPass(false)
                }}>
                  visibility
                </span>
                :
                <span className="material-icons" onClick={() => {
                  setNewShowPass(true)
                }}>
                  visibility_off
                </span>
              }
            </div>
            <Form.Text style={{ color: 'red', display: 'flex', justifyContent: 'start', fontFamily: 'emoji' }} >
              {rePassErr}
            </Form.Text>
          </Form.Group>

          <InputGroup className="mt-5"></InputGroup>
          <Button className="auth-btn"
            onClick={handleSubmit}
            style={{ borderRadius: "50px" }}>
            Submit
          </Button>
          <li className="resendlink text-primary mt-5" onClick={handleResendOtp}>Resend OTP</li>
          <li className="resendlink text-muted mt-4 mb-3" onClick={() => { cancel() }}>Cancel</li>
        </div>
      </div>
    </>
  );
};

export default PwdResetOtpScreen;
