export default function Logout(dispatch, history, isManualLogout = '',from='') {
  console.log("logoutFunction ===>>> logout()");
  dispatch({ type: "token/remove" });
  dispatch({ type: "isSessionExpired/set", isSessionExpired: !isManualLogout });
  dispatch({ type: "set_auth_token", auth_token: null })
  dispatch({
    type: "set_profile_data", profileData: {
      status: 0
    }
  })
  history.push("/signin");
}
















