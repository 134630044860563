import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap';
import api from '../../../api';
import Logout from '../../../logOut/Logout';
import { Alert } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from "react-js-loader";

const CareerObjective = ({ onCareerObjSave }) => {

  const [careerObj, setCareerObj] = useState('');
  const [careerObjErr, setCareerObjErr] = useState('');
  const [message, setMessage] = useState('');
  const [alertClr, setAlertClr] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let authToken = useSelector(state=>state.auth_token)
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true)
    api.onlineCV.cvProfile(authToken)
      .then(result => {
        console.log("RESULT => PROFILE CV", result);
        if (result.resultCode === 1) {
          setCareerObj(result.resultData.userCvProfile.careerObjective)
          console.log("CARRER OBJ TRUE")
          // isCareerObjDone(true);
        } else {
          console.log("CARRER OBJ FALSE")
          // isCareerObjDone(true);
        }
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setMessage('');
      setAlertClr("");
      setShowAlert(false);
    }, 3000)
  }, [showAlert])

  const saveCareerObj = () => {
    if (!careerObj) {
      setCareerObjErr("Text limit is 10-80 words")
    } else {
      setCareerObjErr("")
    }
    if ((careerObj && (careerObj.trim().split(" ").length) >= 10)) {
      setIsLoading(true)
      var data = {
        careerObjective: careerObj
      }
      api.onlineCV.addCareerObj(data, authToken).
        then(result => {
          console.log("RESULT ==> carrer obj", result);
          if (result.resultCode === 1) {
            setMessage("Career Objectives saved successfully");
            setAlertClr("success");
            setShowAlert(true);
            setTimeout(() => {
              onCareerObjSave("careerObj")
            }, 2000)
           
            // passToStepTwo(true)
          } else {
            setMessage(result.resultMsg);
            setAlertClr("danger");
            setShowAlert(true);
          }
          setIsLoading(false)
        }).catch(error => {
          setIsLoading(false)
          // passToStepTwo(false)
          console.log(error)
          if (!!error.response && !!error.response.status && error.response.status === 401) {
            Logout(dispatch, history, false);
          }
          if (error.message === 'Network Error') {
            setMessage("Please check your network connection!");
            setAlertClr("danger");
            setShowAlert(true);
          }
        })
    } else {
      setCareerObjErr("Text limit is 10-80 words")
    }
  }

  return (
    <div className="card profile-edit-container mt-5 mb-5">

      {isLoading &&
        <div style={{ zIndex: '1500', }} className="d-flex justify-content-center">
          < Loader type="spinner-cub" bgColor={"rgb(53, 50, 50)"} title={"Loading..."} size={100} />
        </div>
      }
      <Form className="form-style p-5" style={{ fontSize: "larger" }}>
        <Alert className='alert-position' isOpen={showAlert} color={alertClr}>{message} </Alert>
        <Form.Group >
          <Form.Label className="cust-label shiftToLeft mt-3 mb-3">
            Career Objective
          </Form.Label>
          <Form.Control
            as="textarea" rows={2}
            className="cv-inputStyle"

            // maxLength={((careerObj && careerObj.trim().split(" ").length === 80)) && "80"}
            style={{ backgrounfColor: 'white', minHeight: '60px' }}
            value={careerObj}
            onChange={(e) => {
              if (((e.target.value).trim().split(" ").length) <= 80) {
                setCareerObj(e.target.value);
              }
              if (((e.target.value).trim().split(" ").length) >= 10) {
                setCareerObjErr("")
              }
            }}
            type="text" />
        </Form.Group>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '-10px' }}>
          <Form.Text className="font-size-vali-msg" style={{ color: 'red', fontFamily: 'emoji' }} >
            {careerObjErr}
          </Form.Text>
          <Form.Text className="font-size-vali-msg" style={{ fontFamily: 'emoji' }} >
            {(careerObj === "") ? 0 : (careerObj && careerObj.trim().split(" ").length)}/ 80
          </Form.Text>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            onClick={saveCareerObj}
            className="my-cust-button mt-2 mb-5 pl-5 pt-1 pb-1 pr-5"
          >
            Save
          </Button>
        </div>
      </Form>


    </div>
  )
}

export default CareerObjective;