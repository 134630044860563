import React, { useEffect } from "react";
import moment from "moment";
import { Button } from "react-bootstrap";
import { API_IMAGE_URL } from "../../app_constants";
import { defaultJobIcon, defaulttraining } from "../../assets";
import ReactReadMoreReadLess from "react-read-more-read-less";
import "./event-card.css";
const SingleEventCard = ({ data, onClick, children, style, favComponent }) => {
  // console.log(data);
  const short = (str, allowedLength = 10) => {
    if (str && str.length > allowedLength) {
      return str.slice(0, allowedLength) + "...";
    }
    return str;
  };
  return (
    <React.Fragment>
      <div className="rm-jobCard" style={style}>
        {children}
        <div onClick={onClick} style={{ cursor: "pointer" }}>
          <div className="header">
            <img
              src={
                data.categoryIconPath
                  ? API_IMAGE_URL + data.categoryIconPath
                  : defaulttraining
              }
            />
            <div>
              <h4 className="title">{short(data.title, 15)}</h4>
              {/* <h4>
              <i class="fas fa-map-marker-alt mr-2"></i>
              {data.locationNmList.length > 1
                ? short(data.locationNmList[0], 12) + "..."
                : short(data.locationNmList[0])}
            </h4> */}
            </div>
          </div>
          <div className="body">
            {/* <h4>
          <ReactReadMoreReadLess
                charLimit={50}
                readMoreText={"Read more ▼"}
                readLessText={"Read less ▲"}
            >
              {data.oneLinerDescription}
            </ReactReadMoreReadLess>
           
          </h4> */}
            <h4 className="descrip">
              {data.oneLinerDescription}
              {/* <ReactReadMoreReadLess
                charLimit={30}
                readMoreText={"Read more ▼"}
                readLessText={"Read less ▲"}
              >
                {data.oneLinerDescription}
              </ReactReadMoreReadLess> */}
            </h4>
          </div>
        </div>
        
        <div className="footer">
          <hr />
          <div className="footer-last">
            <div className="date">
              <p>{moment(data.startDate).format("MMM Do YYYY")}</p>
              <p>{moment(data.endDate).format("MMM Do YYYY")}</p>
            </div>

            <div>
              <Button
                className={"apply-job"}
                onClick={onClick}
                style={{ padding: "0.6rem 1rem" }}
                fontSize="1.5rem"
              >
                View
              </Button>

              {favComponent}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SingleEventCard;
