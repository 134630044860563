import React from "react";
import { Col, Row } from "reactstrap";
import CustomButton from "../CustButton/CustomButton";
import './OBCarousel.css'

const CarouselOverlay = ({title}) => {
  return (
    <div className="carousel-overlay">
      <h1>{title}</h1>
      <h5>date</h5>
    </div>
  );
};

export default CarouselOverlay;
