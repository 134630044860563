import React, { useState, useEffect } from 'react';
import '../createCv.css';
import DisplayAwardNCertificate from '../AwardNCertificate/DisplayAwardNCertif';
import LanguageForm from '../language/LanguageForm';


const StepFour = ({ passFromLanguage, passFromLanguagePrev, updateLanguageStep }) => {



  return (
    <div className="pb-5">
      <h3 classNmae="header-style" style={{ display: 'flex', justifyContent: 'center' }}>Certificates And Awards</h3>
      <DisplayAwardNCertificate />
      <div style={{ height: '40px', width: '100%', backgroundColor: 'white' }}></div>

      <h3 className="mt-3" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} >Language</h3>
      <LanguageForm
        nextStep={(data) => {
          passFromLanguage(data)
        }}

        previousStep={(data) => {
          passFromLanguagePrev(data)
        }}

        onLanguageAction={(data) => {
          updateLanguageStep(data)
        }}
      />
    </div>
  )
}

export default StepFour;