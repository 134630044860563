// import React from "react";
// import Carousel from "react-bootstrap/Carousel";
// import Testimonials from "../Testimonial/Testimonials";
// import './testimonial-slider.css'

// const TestimonialSlider = ({ data }) => {
//   return (
//     <div className="testimonial-slider" style={{
//         // background:"yellow",
//         // width:"500px",
//         width:"450px",
//         marginLeft:"auto",
//         marginRight:"auto",
//     }}>
//         <Carousel controls={true}>
//       {data.map((i) => (
//           <Carousel.Item style={{
//             width:"fit-content",
//           }}>
//             <Testimonials  remark={i.remark} designation={i.designation} name={i.name}/>
//           </Carousel.Item>
//       ))}
//       </Carousel>
//     </div>
//   );
// };

// export default TestimonialSlider;

import React from "react";
import SwiperCore, {
  EffectCoverflow,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper";
import "./testimonial-slider.css";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import "swiper/components/effect-coverflow/effect-coverflow.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import Testimonials from "../Testimonial/Testimonials";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectCoverflow]);

const TestimonialSlider = ({data}) => {
  return (
    
      <div className="testimonial-container">
        <Swiper
          
          slidesPerView={1}
       spaceBetween={40}
          navigation
          // pagination={{ clickable: true }}
          // scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
          onReachEnd={() => {
            console.log("reached end");
          }}
        >
          {data.map((i) => {
            return (
              <SwiperSlide key={i.id}>
                <Testimonials
                  remark={i.remark}
                  designation={i.designation}
                  name={i.name}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
   
  );
};

export default TestimonialSlider;
