import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Cross from "../../svg_objects/cross";
import RightTick from "../../svg_objects/RightTick";

const CustModal = ({open,isErr,message,handleClose}) => {
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div
          className={classes.paper}
          style={{
              width:"350px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {isErr ? <Cross /> : <RightTick />}
          <h2>{isErr ? "Error!" : "Success"}</h2>
          <h4 id="transition-modal-description">{message}</h4>
        </div>
      </Fade>
    </Modal>
  );
};

export default CustModal;
