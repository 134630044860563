import Reat, { useState } from 'react';

 const HeaderName = ({ style, className, color = "#344256", fontFamily = "Poppins-SemiBold", fontSize = "24px", children }) => {

    const [headerNameStyle, setHeaderNameStyle] = useState({
        color: color,
        fontFamily: fontFamily,
        fontSize: fontSize,
        ...style
    })
    return (
        <>
            <span
                className={className}
                style={headerNameStyle}>
                {children}
            </span>
        </>
    )
}

export default HeaderName;