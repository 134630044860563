import React from 'react';
import './arrow-expand.css';

const ArrowExpand = ({ children }) => {
    return (

        <div id="arrow-expand-container">
            <button className="learn-more">
                <div className="arrow-container" >
                <i class="fas fa-chevron-right arrow1"></i>
                <i
                  class="fa fa-arrow-right arrow2"
                ></i>
                </div>
                <div className="button-text">{children}</div>
            </button>
        </div>

    );
};

export default ArrowExpand;