import React, { useState, useEffect } from 'react';
import '../contactInfoCv/contactInfo.css'
import ContactInfo from '../contactInfoCv/ContactInfo';
import EditProfile from '../../profile/EditProfile';

const StepOne = ({ passFromContactinfo, updateStep }) => {
useEffect(()=>{
  window.scrollTo(1000, 1000)
},[])

  return (
    <div className="pb-5">
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <h3 className="header-style">Personal Information</h3>
      </div>
      <EditProfile comingFrom="CV" />
      <div style={{ height: '30px', width: '100%', backgroundColor: 'white' }}></div>

      {/* <h3 className="mt-3" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} >Contact Information</h3> */}
      <ContactInfo
        nextStep={(data) => {
          passFromContactinfo(data)
        }}

        onContactInfoSave={(data) => {
          console.log("DATA IN STEP ONE ", data)
          updateStep(data);
        }}
      />
    </div>
  )
}

export default StepOne;