import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import api from '../../../api';
import AwardNCertif from './AwardNCertif';
import { Alert } from 'reactstrap'
import Loader from "react-js-loader";
import Logout from "../../../logOut/Logout";

const DisplayAwardNCertificate = ({ }) => {

  const [awardOrCertifList, setAwardOrCertifList] = useState([])
  const [toBeEdit, setToBeEdit] = useState('')
  const [alertClr, setAlertClr] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [workFlow, setWorkFlow] = useState("DISPLAY");
  const [isAddClick, setIsAddClick] = useState(false);

  const authToken = useSelector(state=>state.auth_token)
  const history = useHistory();
  const dispatch = useDispatch();
  const isListReload = useSelector(state => state.isListReload)

  useEffect(() => {
    getListOfCertiNAward()
  }, [])

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
        setMessage("")
        setAlertClr("")
      }, 3000);
    }
  }, [showAlert])

  useEffect(() => {
    console.log("DISPALY WOTK FLOE ", workFlow)
    if (isListReload) {
      getListOfCertiNAward()
      dispatch({ type: 'isListReload/save', isListReload: false })
    }
  }, [isListReload])

  const getListOfCertiNAward = () => {
    api.onlineCV.getAwardNCertificate(authToken)
      .then(result => {
        console.log("RESULT ", result);
        if (result.resultCode === 1) {
          setAwardOrCertifList(result.resultData.certificateList)
        }
      }).catch(error => {
        setIsLoading(false)
        console.log(error)
        if (!!error.response && !!error.response.status && error.response.status === 401) {
         Logout(dispatch, history,false);
        }
        if (error.message === 'Network Error') {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      })
  }

  const deleteCertifNAward = (id) => {
    var data = {
      certficateId: id
    }
    api.onlineCV.delAwardNCertificate(data, authToken)
      .then(result => {
        console.log("RESULT DELETE CERT AWARD ", result)
        if (result.resultCode === 1) {
          setMessage(result.resultMsg);
          setAlertClr("success");
          setShowAlert(true);
          getListOfCertiNAward()
        } else {
          setMessage(result.resultMsg);
          setAlertClr("danger");
          setShowAlert(true);
        }
      }).catch(error => {
        setIsLoading(false)
        console.log(error)
        if (!!error.response && !!error.response.status && error.response.status === 401) {
         Logout(dispatch, history,false);
        }
        if (error.message === 'Network Error') {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      })
    window.scrollTo(0, 0)
  }

  const editCertifNAward = (e) => {
    console.log("e", e)
    setToBeEdit(e)
    setWorkFlow("FORM")
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Alert className='alert-position' isOpen={showAlert} color={alertClr}>{message} </Alert>

      {isLoading &&
        <div style={{ zIndex: '1500' }} className="d-flex justify-content-center">
          < Loader type="spinner-cub" bgColor={"rgb(53, 50, 50)"} title={"Loading..."} size={100} />
        </div>
      }
      {(awardOrCertifList.length !== 0 && workFlow === "DISPLAY" && !isAddClick) ?

        <>
          <div className="add-icon d-flex justify-content-end">
            <i class="fa fa-plus icon-style plus-icon mr-3 mb-1" aria-hidden="true"
            // style={{marginBottom:'-20px', marginTop:'10px'}}
              onClick={() => {
                setIsAddClick(true);
              }}>{' '}Add </i>
          </div>
          {awardOrCertifList.map((e, idx) => {
            return (
              <>
                <div key={idx} className="in-single-line mb-5">
                  <div className="box-row-size">
                    <Card className='card-style-workEd' >
                      <Card.Body className="card-box">
                        <div className="d-flex justify-content-end">

                          <i
                            onClick={() => editCertifNAward(e)}
                            className="fas fa-pen fa-1x icon-style"
                          ></i>
                          <i class="fa fa-trash icon-style ml-3" aria-hidden="true"
                            onClick={() => deleteCertifNAward(e.id)}
                          ></i>
                        </div >

                        <h6>{e.certificationName} </h6>
                        <h6>{e.year} </h6>

                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </>
            )
          })}
        </>
        :
        <AwardNCertif setWorkFlow={setWorkFlow} workFlow={workFlow} toBeEdit={toBeEdit} setIsAddClick={setIsAddClick} />
      }
    </div>
  )
}

export default DisplayAwardNCertificate;