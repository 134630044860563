import React, { useState } from "react";
import OBlogo from "../../assets/logo/branding-2@2x.png";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./account-deletion.css";
import { API_SERVER_URL } from "../../app_constants";
import axios from "axios";
import { DEFAULT_JWT_TOKEN } from "../../app_constants";
const AccountDelete = () => {
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if email is empty
    if (!email.trim()) {
      setEmailErr("Email is required");
      return;
    }

    // Perform further actions for form submission
    console.log("Form submitted with email:", email);
    let res = await axios.get(
      API_SERVER_URL + `/api/gu/account_deletion/${email}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${DEFAULT_JWT_TOKEN}`,
        },
      }
    );

    console.log("res", res);
    console.log("res", res.status);
    if (res.status === 201) {
      setSuccess(true);
    } else {
      setSuccess(false);
    }
    setSuccessMessage(res?.data?.resultMsg);
    setEmail("");
  };

  return (
    <>
      <div>
        <span
          className="deletepage-home-btn"
          onClick={() => {
            history.push("/home");
            window.location.reload();
          }}
        >
          <i class="fas fa-home mr-2"></i>
          Home
        </span>
      </div>
      <div className="container-account-deletion">
        <div className="delete-container form-container">
          <img src={OBlogo} className="loginLogo mt-2" alt="Opportunity-bank" />

          <Form className="login-form" onSubmit={handleSubmit}>
            <Form.Group
              className="d-flex justify-content-center mt-3"
              style={{ color: "#5B5A5A" }}
            >
              <h4 className="deletion-title">Account Deletion Request</h4>
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label className="cust-label shiftToLeft">
                Email Address
              </Form.Label>
              <Form.Control
                className="cust-inputStyle"
                value={email}
                placeholder="Enter your email address"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailErr(""); // Clear error when user types
                }}
                type="email"
                required
              />
              <Form.Text
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "start",
                  fontFamily: "emoji",
                }}
              >
                {emailErr}
              </Form.Text>
            </Form.Group>
            <Button type="submit">Submit</Button>
            {success ? (
              <div className="success-delete-msg">{successMessage}</div>
            ) : (
              <div className="error-delete-msg">{successMessage}</div>
            )}
          </Form>
        </div>
      </div>
    </>
  );
};

export default AccountDelete;
