
import React, { useState, useEffect } from 'react'
import { Document, Page, pdfjs } from 'react-pdf';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import './CvViewer.css'
const CvViewer = ({ showCV, setShowCv, url }) => {

    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scaleValue, setScaleValue] = useState(1)
    const [cvSize, setcvSize] = useState('lg')
    const [errorMessage, setErrorMessage] = useState('')
    const [backgroundColor, setBackgroundColor] = useState('#ffffff')
    const [hiddenStatus, setHiddenStatus] = useState(false)

    function onDocumentLoadSuccess({ numPages }) {
        setBackgroundColor("#ffffff")
        setNumPages(numPages);
        setPageNumber(1);
        setHiddenStatus(false)
    }


    function onDocumentLoadError() {
        setBackgroundColor("pink")
        setErrorMessage("Resume Not Found")
        setHiddenStatus(true)
    }


    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    useEffect(() => {
        console.log(scaleValue)
    }, [scaleValue])


    return (
        // <Row className="modal-container" style={{width:"100vw"}}>
        <Modal
            id="CVViewer"
            style={{ marginTop: '20%' }}
            show={showCV}
            size='lg'
            closeOnBackdrop={false}
        >
            <Modal.Header className="d-flex justify-content-end" style={{ backgroundColor: backgroundColor }}>


                <i class="fa fa-times fa-2x p-2 pointerCursor"
                    onClick={() => { setShowCv(false) }}
                    style={{ marginLeft: "5px" }}
                    aria-hidden="true" ></i>

            </Modal.Header>
            <Modal.Body className="p-0" style={{
                height: "fit-content",
                backgroundColor: backgroundColor,
                marginTop: '10px'
            }}>
                <div
                    className="cv-body"
                    style={{
                        display: 'flex', flexDirection: "column", paddingLeft: '50%',
                        alignItems: "center", justifyContent: 'center'
                    }}>
                    <Document

                        file={url}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={onDocumentLoadError}
                    >
                        <Page pageNumber={pageNumber}
                            scale={scaleValue}
                        />
                    </Document>
                    <Row>
                        <h3 className="pdf-error-style" hidden={!hiddenStatus}>{errorMessage}</h3>
                    </Row>
                    <div >
                        <Row className="d-flex justify-content-center" >
                            <Col className="d-flex justify-content-center" >
                                <font hidden={hiddenStatus}>Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}</font>
                            </Col>
                        </Row>
                        <Modal.Footer>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }} >
                                <Button
                                    // type="button"
                                    disabled={pageNumber <= 1}
                                    onClick={previousPage}
                                    className="Pre"

                                >
                                    Previous
	                        </Button>
                                <Button
                                    // type="button"
                                    disabled={pageNumber >= numPages}
                                    onClick={nextPage}

                                >
                                    Next
	                        </Button>

                            </div>
                        </Modal.Footer>
                        {/* } */}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        // </Row>
    )

}

export default CvViewer;




