/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import api from "../../../api";
import CvViewer from "../../../Component/forMobileCv/CvViewer";
import CustDialog from "../../../components/cust-dialog/CustDialog";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button } from "react-bootstrap";
import "./step.css";
import Logout from "../../../logOut/Logout";
import { Alert } from "reactstrap";
import Loader from "react-js-loader";
import { API_SERVER_URL } from "../../../app_constants";
import RewardPopup from "../../../components/RewardPopup";
import ThemePopup from "../../../components/theme-popup/ThemePopup";
import { useDeviceSize } from "../../../helpers/customHooks";

const LastStep = ({ onGenerateCvAction }) => {
  const [enableGenCvButton, setEnableGenCvButton] = useState(false);
  const [createdCvLink, setCreatedCvLink] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [isViewCvClick, setIsViewCvClick] = useState(false);
  const [dialogStatus, setDialogStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [alertClr, setAlertClr] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRewardAllocate, setIsRewardAllocate] = useState(false);
  const [earnReward, setEarnReward] = useState(0);
  const deviceSize = useDeviceSize();
  
  const authToken = useSelector((state) => state.auth_token);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    generateCV();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setMessage("");
      setAlertClr("");
      setShowAlert(false);
    }
  }, [showAlert]);

  // To check device(mobile or dekstop)
  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setDeviceType("Mobile");
    } else {
      setDeviceType("Desktop");
    }
  }, []);

  const generateCV = () => {
    api.onlineCV
      .isCvComplete(authToken)
      .then((result) => {
        console.log("is CV comleted ", result);
        if (result.resultCode === 1) {
          setEnableGenCvButton(result.resultData.enableGeneratePdfButton);
          if (result.resultData.isComplete) {
            onGenerateCvAction(result.resultData.enableGeneratePdfButton);
          } else {
            onGenerateCvAction(true);
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      });
  };

  const handleCloseDialog = () => {
    setDialogStatus(false);
    setIsViewCvClick(false);
  };

  useEffect(() => {
    console.log("CV LINK ", createdCvLink);
  }, [createdCvLink]);

  // const createCv = () => {
  //   api.onlineCV.userCv(authToken)
  //     .then(result => {
  //       console.log("user CV ", result);
  //       setCreatedCvLink((API_SERVER_URL + result.resultData.userCvProfile.createdCvLink))
  //     }).catch(error => {
  //       console.log(error)
  //       if (!!error.response && !!error.response.status && error.response.status === 401) {
  //         Logout(dispatch, history, false);
  //       }
  //       if (error.message === 'Network Error') {
  //         setMessage("Please check your network connection!");
  //         setAlertClr("danger");
  //         setShowAlert(true);
  //       }
  //     })
  // }

  const generateCvPdf = () => {
    api.onlineCV
      .userCv(authToken)
      .then((result) => {
        console.log("user CV ", result);
        if (result.resultCode === 1) {
          setDialogStatus(true);
          // console.log(
          //   "REWARD EARN",
          //   result.resultData.rewardDetails.newEarnedRewards
          // );
          setCreatedCvLink(
            API_SERVER_URL + result.resultData.userCvProfile.createdCvLink
          );
          if (
            result.resultData.rewardDetails &&
            result.resultData.rewardDetails.newEarnedRewards > 0
          ) {
            // setIsRewardAllocate(true);
            // setEarnReward(result.resultData.rewardDetails.newEarnedRewards);
          } else {
            console.log("REWARD NOT EARN");
            setDialogStatus(true);
          }
          generateCV();
        }
      })
      .catch((error) => {
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      });
  };

  useEffect(() => {
    if (isRewardAllocate) {
      setTimeout(() => {
        setDialogStatus(true);
        setIsRewardAllocate(false);
      }, 4000);
    }
    console.log("isRewardAllocate", isRewardAllocate);
  }, [isRewardAllocate]);

  return (
    <div className="mt-5">
      {deviceType === "Mobile" && isViewCvClick && (
        <CvViewer
          showCV={isViewCvClick}
          setShowCv={setIsViewCvClick}
          url={createdCvLink}
        />
      )}
      <Alert className="alert-position" isOpen={showAlert} color={alertClr}>
        {message}{" "}
      </Alert>
      {isLoading && (
        <div
          style={{ zIndex: "1500" }}
          className="d-flex justify-content-center"
        >
          <Loader
            type="spinner-cub"
            bgColor={"rgb(53, 50, 50)"}
            title={"Loading..."}
            size={100}
          />
        </div>
      )}
      {/* {isRewardAllocate && earnReward > 0 && (
        <RewardPopup
          rewardMsg="Congrats! You've just earned your daily reward for creating your CV online"
          isPopupOpen={isRewardAllocate}
          earnReward={earnReward}
          from="cv-create-reward"
        />
      )} */}

      <ThemePopup
        deviceSize={deviceSize}
        open={dialogStatus}
        positive="View My CV"
        negative="Okay"
        title="Congrats!"
        subtitle="Online CV generated successfully"
        onClickPositive={() => {
          // setIsViewCvClick(true)
          if (deviceType === "Mobile") {
            setIsViewCvClick(true);
          } else {
            window.open(createdCvLink);
          }
          setDialogStatus(false);
        }}
        onClickNegative={handleCloseDialog}
      />

      {/* <Dialog
        style={{ padding: "80px" }}
        open={true}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h4>Online CV generated</h4>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ fontSize: "1.5rem" }}
            id="alert-dialog-description"
          >
            Online CV generated successfully
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="dialog-btn"
            onClick={() => {
              // setIsViewCvClick(true)
              if (deviceType === "Mobile") {
                setIsViewCvClick(true);
              } else {
                window.open(createdCvLink);
              }
              setDialogStatus(false);
            }}
            // href={deviceType === "Desktop" && (createdCvLink)} target='_blank'
            style={{ fontSize: "1.4rem" }}
            color="primary"
            autoFocus
          >
            View My CV
          </Button>
          <Button
            className="dialog-btn"
            onClick={handleCloseDialog}
            style={{ fontSize: "1.4rem" }}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog> */}

      <div className="d-flex justify-content-center">
        <Button
          className="cv-generate-btn mt-5"
          style={
            enableGenCvButton
              ? {
                  backgroundColor: "#007BFF",
                  color: "white",
                  borderColor: "#007BFF",
                }
              : {
                  backgroundColor: "#6F8092",
                  color: "white",
                  borderColor: "#6F8092",
                }
          }
          onClick={() => {
            // setDialogStatus(true)
            generateCvPdf();
          }}
          disabled={!enableGenCvButton}
        >
          Generate CV
        </Button>
      </div>
    </div>
  );
};

export default LastStep;
