/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { MenuItems } from "./MenuItems";
import "./Navbar.css";
import logo from "../../assets/logo/navBar2.png";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import api from "../../api";
import { Alert, Button, Col, Row } from "reactstrap";
import Logout from "../../logOut/Logout";
import Loader from "react-js-loader";
import ProfileIcon from "../profileIcon/ProfileIcon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import FindInPageOutlinedIcon from "@material-ui/icons/FindInPageOutlined";
import RedeemIcon from "@material-ui/icons/Redeem";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import smile from "../../assets/images/smile.png";
import { useMediaQuery } from "react-responsive";
import { services } from "../../helpers/utilities";
import { ACTIVITY } from "../../helpers/ACTIVITY_CONSTANTS";
import { BrandLogo, BrandLogo2, BrandLogo3 } from "../../assets/svg";
import { brandLogo } from "../../assets";


const Navbar = () => {
  const history = useHistory()
  const dispatch = useDispatch()
const currentlink = useSelector(state=>state.currentLink)
console.log("CURRENT LINK",currentlink)
  let status;

  const isXl = useMediaQuery({
    query: "(min-device-width: 1200px)",
  });
  const isL = useMediaQuery({ query: "(min-device-width: 1025px)" });
  const isMd = useMediaQuery({ query: "(min-width: 769px)" });
  const isSm = useMediaQuery({ query: "(min-device-width: 481px)" });
  const isXs = useMediaQuery({ query: "(min-device-width: 320px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  let auth = useSelector((state) => state.auth_token);
  // const [profile, setProfile] = useState({
  //   status: 0,
  // });
  const [deviceType, setDeviceType] = useState();

  const [anchorEl, setAnchorEl] = React.useState(null);
  let profile = useSelector((state) => state.profileData);
  // let [profile,setProfile] = useState({
  //   status:0
  // })

  const   loadFbLoginApi=()=>{

    window.fbAsyncInit = function() {
        window.FB.init({
            appId      : 466367218319436, //1563255950526879
            cookie     : true,  // enable cookies to allow the server to access
            // the session
            xfbml      : true,  // parse social plugins on this page
            version    : 'v2.5' // use version 2.1
        });
    };

    console.log("Loading fb api");
      // Load the SDK asynchronously
    (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
}

useEffect(()=>{
 let B= loadFbLoginApi()
 console.log("BBBBBB",B)
 console.log("FB Obj",window.FB)
},[])

  const handleDropClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setDeviceType("Mobile");
    } else {
      setDeviceType("Desktop");
    }
  }, []);

  useEffect(() => {
    console.log(deviceType);
  }, [deviceType]);
  const [clicked, setClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alertClr, setAlertClr] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");

  

  const logOutManually = async () => {
    console.log("FB OBJECT IN logout fun",window.FB)
    console.log("method of logout", profile);
    if (profile) {
      var data = {
        deviceFcmToken: `WEB_TKN_${profile.email}`,
      };
    }
    console.log("auth logout ", auth);
    setIsLoading(true);
    api.auth
      .logOut(data, auth)
      .then((result) => {
        console.log("RESULT ==> LOG out ", result);
        if (result.resultCode === 1) {
          console.log("IN Logout");
          Logout(dispatch, history, true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          // Logout( false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      });
 
  };

  const moreOption = () => {
    // alert("MORE")
    return (
      <ul>
        <li>PRIVACY POLICY</li>
      </ul>
    );
  };

  const handleClick = () => {
    setClicked(!clicked);
  };

  return (
    <>
      <Alert className="alert-position" isOpen={showAlert} color={alertClr}>
        {message}{" "}
      </Alert>

      {/* {profile && (
        <p
          style={{
            fontSize: "15px",
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "5rem",
            marginBottom: "-3px",
          }}
        >
          Welcome {profile.firstName}
        </p>
      )} */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          top: "120px",
          left: "50%",
        }}
      >
        {isLoading && (
          <Loader
            type="spinner-cub"
            bgColor={"rgb(53, 50, 50)"}
            title={"Loading..."}
            size={100}
          />
        )}
      </div>
      <div style={{ position: "sticky", top: 0, zIndex: 25 }}>
        <nav className="cNavbarItems">
          {/* <img
            className="ob-nav-logo"
            src={brandLogo}
            style={{ marginRight: "auto", marginLeft: "13px",width:"40rem",imageRendering: "-webkit-optimize-contrast"  }}
          /> */}
          <BrandLogo3 onClick={() => {
              history.push("/home");
            }} className="ob-nav-logo" style={{height:"100%", marginRight: "auto", marginLeft: "13px",width:"40rem",imageRendering: "-webkit-optimize-contrast", cursor:"pointer" }}/>

          <div className="nav-btn-group">
            {profile && profile.status === 0 ? (
              <Button
                className="flat-rect-btn navlogin-btn navlogin-btn-hover"
                onClick={() => {
                  history.push("/signUp");
                }}
              >
                Sign up
              </Button>
            ) : (
              <p
                className="d-flex welcome-msg-desktop align-items-center mr-5"
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "500",
                  fontStyle: "italic",
                }}
              >
                Welcome {profile && profile.firstName}{" "}
              </p>
            )}
            {profile && profile.status === 0 ? (
              <Button
                className="rect-btn navlogin-btn mr-4 navlogin-btn-hover"
                onClick={() => {
                  history.push("/signIn");
                }}
              >
                Sign In
              </Button>
            ) : (
              <>
                <ProfileIcon
                  onClick={handleDropClick}
                  firstName={profile && profile.firstName}
                  lastName={profile && profile.lastName}
                />
                <div className="cust-dropdown">
                  <Menu
                    id="simple-menu"
                    className="profile-menu "
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem className="welcome-msg-mobile">
                      <p
                        className="d-flex  align-items-center "
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "500",
                          fontStyle: "italic",
                        }}
                      >
                        Welcome {profile && profile.firstName}{" "}
                      </p>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        services.statusCheck(profile.status) &&
                          services.activityLogService(
                            ACTIVITY.OPEN_PROFILE_SCREEN,
                            profile
                          );
                        handleClose();
                        history.push("/profile");
                      }}
                    >
                      <AccountCircleIcon
                        className=" mr-4"
                        style={{ transform: "scale(1.5)" }}
                      />{" "}
                      Profile
                    </MenuItem>
                    <MenuItem
                      disabled={profile && profile.status !== 2}
                      onClick={() => {
                        services.statusCheck(profile.status) &&
                          services.activityLogService(
                            ACTIVITY.OPEN_TRACK_SCREEN,
                            profile
                          );
                        handleClose();
                        history.push("/trackJob");
                      }}
                    >
                      <FindInPageOutlinedIcon
                        className=" mr-4 "
                        style={{ transform: "scale(1.5)" }}
                      />{" "}
                      Track Job
                    </MenuItem>

                    {/* <MenuItem
                      disabled={profile && profile.status !== 2}
                      onClick={() => {
                        handleClose();
                        history.push("/count");
                      }}
                    >
                      <RedeemIcon
                        className=" mr-4 "
                        style={{ transform: "scale(1.5)" }}
                      />{" "}
                      My Rewards
                    </MenuItem> */}

                    <hr />
                    <MenuItem
                      className="mb-3"
                      onClick={() => {
                        services.statusCheck(profile.status) &&
                          services.activityLogService(
                            ACTIVITY.CLICK_MORE_SETTINGS_SIGNOUT,
                            profile
                          );
                        handleClose();
                        console.log()
                           window.FB.api('/me/permissions', 'delete', null, () => window.FB.logout());
                        logOutManually();
                        localStorage.removeItem("popupShownDate")
                      }}
                    >
                      <DirectionsRunIcon
                        className=" mr-4 "
                        style={{ transform: "scale(1.5)" }}
                      />{" "}
                      Sign out
                    </MenuItem>
                  </Menu>
                </div>
              </>
            )}
            <div className="cmenu-icon" onClick={handleClick}>
              <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
            </div>
          </div>
        </nav>

        <Row className="m-0 menu-bar">
          <Col lg="8 m-0 p-0 " sm="12">
            <ul className={clicked ? "cnav-menu active" : "cnav-menu"}>
              {MenuItems.map((item, index) => {
                return (
                  <li
                    onClick={()=>{dispatch({type:'setCurrentLink',currentLink:item.url})}}
                    className={
                      item.url === currentlink
                        ? "list-item selected"
                        : "list-item"
                    }
                    key={index}
                  >
                    {/* <a className={item.cName} href={item.url}> */}
                    <a
                      className={item.cName}
                      style={
                        item.title === "Sign In" &&
                        profile &&
                        services.statusCheck(profile.status)
                          ? {
                              display: "none",
                              color: " #FFFFFF",
                              textAlign: "center",
                            }
                          : {
                              display: "block",
                              color: " #FFFFFF",
                              textAlign: "center",
                            }
                      }
                      onClick={() => {
                        if (item.title === "About Us") {
                          services.statusCheck(profile.status) &&
                            services.activityLogService(
                              ACTIVITY.CLICK_MORE_ABOUT_US,
                              profile
                            );
                        }
                        if (item.title === "Contact Us") {
                          services.statusCheck(profile.status) &&
                            services.activityLogService(
                              ACTIVITY.CLICK_MORE_CONTACT_US,
                              profile
                            );
                        }
                        if (item.title === "Home") {
                          services.statusCheck(profile.status) &&
                            services.activityLogService(
                              ACTIVITY.OPEN_HOME_SCREEN,
                              profile
                            );
                        }
                        if (
                          item.title === "Sign In" &&
                          profile &&
                          services.statusCheck(profile.status)
                        ) {
                          console.log(window.FB,"MANUAL LOG OUT ");
                          
                          logOutManually();
                        } else if (item.title === "More") {
                          // moreOption()
                        } else {
                          history.push(item.url);
                        }
                      }}
                    >
                      {item.title}
                    </a>
                  </li>
                );
              })}
              <li>
                <a className={MenuItems.cName}></a>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Navbar;
