import React from 'react';
import DisplayPopup from '../components/award-popup/DisplayPopup';
import TestimonialSlider from '../components/TestimonialSliders/TestimonialSlider';
import { StaticData } from '../helpers/staticData';

const TestPage = () => {
    return (
        <div>
            <DisplayPopup/>
        </div>
    );
};

export default TestPage;