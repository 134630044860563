import React from 'react';
import './profileIcon.css';

const ProfileIcon = ({firstName=null, lastName=null,style, onClick}) => {
    let str1 = firstName&&firstName[0].toUpperCase()
    let str2 = lastName&&lastName[0].toUpperCase()
    return (
        str1&&<div onClick={onClick} className={`profile-icon`} style={style}>
        {
            str1+str2
        }
    </div>
    );
};

export default ProfileIcon;