import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import { Button, Form } from 'react-bootstrap';
import api from "../../api";
import OBlogo from "../../assets/logo/branding-2@2x.png";
import "./Password-Reset-Page.css";
import { Alert } from "reactstrap";
import { useDispatch } from "react-redux";
import Logout from "../../logOut/Logout";
import Loader from "react-js-loader";


const PasswordResetPage = () => {
  const [email, setEmail] = useState();
  const [emailErr, setEmailErr] = useState();
  const [alertClr, setAlertClr] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setMessage("");
        setAlertClr("");
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert])

  const handleSubmit = async () => {
    dispatch({ type: "setOtp_timer_value", OTP_Timer_value: 300000 })

    var regEx = new RegExp(/^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i);

    if (!email) {
      setEmailErr('Please enter your email');
    } else if (!regEx.test(email)) {
      setEmailErr('Please enter valid email');
    } else {
      setEmailErr('');
    }

    if(history.location.state&&history.location.state.from === 'facebookbtn'){
      const fbData = {
        ...history.location.state.fbData,
        email:email
      }
      try{
        let res = await api.auth.socialMedia(fbData)
        console.log(res)
      }catch(err){
        console.error(err)
      }
    }

    let body = {
      email: email,
    };
    setIsLoading(true);

    api.verification
      .sendOtp(body)
      .then((res) => {
        if (res.resultCode === 1) {
          setShowAlert(true)
          setMessage(res.resultMsg)
          setAlertClr("success");
          setTimeout(() => {
            if(history.location.state&&history.location.state.from==='facebookbtn'){
              history.push({
                pathname: "/OTP",
                state: {
                  status:'facebookbtn',
                  email: email,
                },
              });
            }
            else{
              history.push({
                pathname: "/passwordResetOtp",
                state: {
                  email: email,
                },
              });
            }
          }, 2000)
        } else {
          setShowAlert(true)
          setMessage(res.resultMsg)
          setAlertClr("danger");
        }
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error);
        if (!!error.response && !!error.response.status && error.response.status === 401) {
         Logout(dispatch, history,false);
        }
        if (error.message === 'Network Error') {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }

      });
  };
  useEffect(() => {
    console.log("email", email);
  }, [email]);

  return (
    <div className="align-page">
      {/* <div style={{ display: 'flex', justifyContent: 'center' }}> */}
      {isLoading &&
        <Loader type="spinner-cub" bgColor={"rgb(53, 50, 50)"} title={"Loading..."} size={100} />
      }
      {/* </div> */}
      <div className="ml-auto mr-auto pwdreset-container">
        <Alert className="alert-position" isOpen={showAlert} color={alertClr}>
          {message}{" "}
        </Alert>

        <img src={OBlogo} className="loginLogo mb-3" />
        <h3 style={{ color: "#5B5A5A" }}>Hello,</h3>
        <h3 style={{ color: "#5B5A5A" }} className="mb-4">
          We will need Email to proceed
      </h3>

        <div className="pwdreset">
          <Form.Group controlId="email" className="mt-5 mb-3">
            <Form.Label className="cust-label shiftToLeft">EMAIL ADDRESS</Form.Label>
            <Form.Control className="cust-inputStyle"
              value={email}
              onChange={(e) => {
                if (!(e.target.value).includes(" ")) {
                  console.log("SPACE not ALLOW")
                  setEmail(e.target.value);
                }
                if (e.target.value) {
                  setEmailErr("");
                }
              }}
              type="email" />
            <Form.Text style={{ color: 'red', display: 'flex', justifyContent: 'start', fontFamily: 'emoji' }} >
              {emailErr}
            </Form.Text>
          </Form.Group>

          {/* <InputGroup>
          <InputGroupAddon addonType="prepend">
            <label className="shiftToLeft cust-label mt-5 mb-3">
              EMAIL ADDRESS
            </label>
          </InputGroupAddon>
          <Input
            onChange={(e) => {
              setEmail(e.target.value);
              if (e.target.value) {
                setEmailErr('');
              }
            }}
            className="cust-inputStyle"
          />
        </InputGroup> */}
          {/* <span style={{ color: 'red', display: 'flex', justifyContent: 'start', fontFamily: 'emoji' }} >
          {emailErr}
        </span> */}
          <InputGroup style={{ marginTop: '5rem' }}>
            <Button
              className="auth-btn"
              onClick={handleSubmit}
              style={{ borderRadius: "50px" }}
            >
              Submit
          </Button>
          </InputGroup>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetPage;
