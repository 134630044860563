
export const MenuItems = [
    {
        title: 'Home',
        url: '/home',
        cName: 'cnav-links',
    },
    
    {
        title: 'Services',
        url: '/service',
        cName: 'cnav-links'
    },
    {
        title: 'Jobs',
        url: '/jobList',
        cName: 'cnav-links'
    },
    {
        title: 'Trainings',
        url: '/trainingList',
        cName: 'cnav-links'
    },
    {
        title: 'Events',
        url: '/eventList',
        cName: 'cnav-links'
    }
    ,
    {
        title: 'Contact Us',
        url: '/contactUs',
        cName: 'cnav-links'
    },
    {
        title: 'About Us',
        url: '/aboutUs',
        cName: 'cnav-links'
    },
    {
        title: "Sign In",
        url: "/signIn",
        cName: 'cnav-links'
    },
]