import { createStore, applyMiddleware } from "redux";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import logger from "redux-logger";

const initialState = {
  authToken: null,
  // scrollEnd: 0,
  isLoggedIn: false,
  endPointMap: 20,
  OTP_Timer_value: 300000,
  mailSent: true,
  timeStampOnPause: null,
  profileData: {
    status: 0,
  },
  auth_token: null,
  currentLink: "/home",
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set_timestamp_onpause":
      return { ...state, ...rest };
    case "set_auth_token":
      return { ...state, ...rest };
    case "set_profile_data":
      return { ...state, ...rest };
    case "mail_sent":
      return { ...state, ...rest };
    case "setOtp_timer_value":
      return { ...state, ...rest };
    case "isLoggedIn":
      return { ...state, ...rest };
    case "minute/save":
      return { ...state, ...rest };
    case "second/save":
      return { ...state, ...rest };
    case "token/save":
      return { ...state, ...rest };
    case "token/remove":
      return { ...state, ...rest, authToken: null };
    case "isSessionExpired/set":
      return { ...state, ...rest };
    case "profile/save":
      return { ...state, ...rest };
    case "shouldeReloadJobList/save":
      return { ...state, ...rest };
    case "shouldeReloadTrainingList/save":
      return { ...state, ...rest };
    case "shouldeReloadEventList/save":
      return { ...state, ...rest };
    case "isListReload/save":
      return { ...state, ...rest };
    // case 'scrollEnd/save':
    //     return { ...state, ...rest }
    case "endPoint/save":
      return { ...state, ...rest, endPointMap: 20 };
    case "category/save":
      return { ...state, ...rest };
    case "isLogout/set":
      return { ...state, ...rest };
    case "setCurrentLink":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: [
    "isLoggedIn",
    "authToken",
    "OTP_Timer_value",
    "timeStampOnPause",
    "auth_token",
    "profileData",
    "currentLink",
  ],
};

const pReducer = persistReducer(persistConfig, changeState);
const middleware = applyMiddleware(logger);

const store = createStore(pReducer, middleware);

const persistor = persistStore(store);

export { persistor, store };
