import React, { useEffect } from 'react';
import { Jumbotron } from "reactstrap";
import Testimonials from '../components/Testimonial/Testimonials';
import { StaticData } from '../helpers/staticData';

const OBImpact = () => {
    useEffect(()=>{
    window.scrollTo(0,0)
      
    },[])
    return (
        <div style={{ background: "white" }}>
        <Jumbotron className="jumbotron" style={{backgroundColor: "#FEF9F5"}}>
          <h1 className="ml-5 pl-5" >
            Impact Of <br/>
            Opportunity Bank
          </h1>
        </Jumbotron>
        <div>
            <div>
                <h1 className="large-header m-0 mt-5 ml-5 ">
                Words from EM about Opportunity Bank
                </h1>
                <hr className="cust-underline mb-5 ml-5 "/>
                <p className="description ml-5 ">
                Opportunity Bank is to provide ethnic minorities with resources and opportunities to better their quality of life. Here are a few examples of how Opportunity Bank made a difference in someone’s life, from actual service users, in their own words
                </p>
            </div>
            <div>
                <h1 className="large-header m-0 ml-5 ">
                    You are in Good Hands
                </h1>
                <hr className="cust-underline mb-5 ml-5 "/>
                <div className="impact-testimonial-grp pb-5">
                {
                    StaticData.testimonials.map(i=>(
                        <Testimonials remark={i.remark} name={i.name} designation={i.designation} />
                    ))
                }
               
                </div>
            </div>
        </div>
        </div>
    );
};

export default OBImpact;