import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


const ObModal = ({ message, show = true, modalHeading, buttonName, onClick }) => {

  const [close, setClose] = useState(false);

  console.log("OB modal ")

  return (
    <React.Fragment>
      <Modal
        show={true}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {modalHeading}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={onClick}>
            {buttonName}
          </Button> */}
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default ObModal;