import React from "react";
import { Jumbotron } from "reactstrap";

const TermsConditions = () => {
  return (
    <div style={{ background: "white" }}>
      <Jumbotron className="jumbotron" style={{ background: "#FEF9F5" }}>
        <h1 className="ml-5 pl-5">
          Term &<br /> Conditions
        </h1>
      </Jumbotron>
      <div className="ml-5 pl-5 mr-5 pr-5 mt-5 pt-5">
        <h1 className="large-header">Disclaimer</h1>
        <hr className="cust-underline" />
        <p className="description mb-5">
          All information in this document is provided for general information
          only and is not in the nature of advice. It should not be relied upon
          for any purpose and The Zubin Mahtani Gidumal Foundation Limited (TZF)
          makes no warranty or representation and gives no assurance as to its
          accuracy, completeness or suitability for any purpose. Inclusion of
          information about a company, programme or individual in this
          publication does not indicate TZF’s endorsement. Where cited, you
          should refer to the primary sources for more information. This report
          does not necessarily reflect the views of TZF member companies or the
          sponsors of this report. TZF reserves the right to make alterations to
          any of its documents without notice.
        </p>
        <h1 className="large-header pt-5 mt-5">Intellectual Property Rights</h1>
        <hr className="cust-underline" />
        <p className="description mb-5">
          ©2020 The Zubin Mahtani Gidumal Foundation Limited. All rights
          reserved. Reproduction and dissemination of this document (in whole or
          in part) is not allowed without express prior written permission of
          The Zubin Mahtani Gidumal Foundation Limited (TZF) and due
          acknowledgement of authorship. If use of this document (in whole or in
          part) will generate income for the licensee, express prior written
          permission to that effect must be obtained from TZF. To obtain
          permission, write to <a href="oppbank@zubinfoundation.org">oppbank@zubinfoundation.org</a>
        </p>
      </div>
    </div>
  );
};

export default TermsConditions;
