import React, { useEffect, useState } from "react";
import SwiperCore, {
  EffectCoverflow,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper";
import "./swiper.css";
import { Modal, Button } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import "swiper/components/effect-coverflow/effect-coverflow.scss";
import DirectoryCard from "../directory-card/DirectoryCard";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_JWT_TOKEN } from "../../app_constants";

import api from "../../api";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectCoverflow]);

const ProductSwiper = ({ dataList }) => {
  
  const dispatch = useDispatch();
  const [modalHeading, setModalHeading] = useState('')
  const [buttonName, setButtonName] = useState('')
  const [message, setMessage] = useState('')
  const [show, setShow] = useState(false)

  let profile = useSelector(state=>state.profileData) 
  const history = useHistory();


  useEffect(() => {
    console.log(dataList, "DATA LIST");
  }, []);

  const onClick = () => {
    if (profile.status === 1) {
      dispatch({ type: "setOtp_timer_value", OTP_Timer_value: 300000 })
      history.push({
        pathname: '/OTP',
        state: {
          status: 'fromHome'
        }
      })
    } else if (profile.status === 0) {
      history.push('/signUp')
    }
  }


  return (
    <div className="swiper1">
      <Modal
        className="modal-style"
        onHide={() => {
          setShow(false);
        }}
        show={show}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <i
              class="fa fa-exclamation-triangle"
              aria-hidden="true"
              style={{ color: "#ffc107" }}
            ></i>{" "}
            {modalHeading}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={onClick}
            style={{
              cursor: "pointer",
              borderColor: "#5ba4fb",
              backgroundColor: "#5ba4fb",
            }}
          >
            {buttonName}
          </Button>
          <Button
            className="close-btn"
            // style={{backgroundColor:'#bbb6b6', border:'none', width:'500px'}}
            onClick={() => {
              setShow(false);
            }}
            style={{
              cursor: "pointer",
              backgroundColor: "#95989a",
              width: "100px",
              borderColor: "#95989a",

            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="product-swiper-container">
        <Swiper
          className="book-swiper d-flex justify-content-space-around"
          slidesPerView={5}
          spaceBetween={1}
          loop={true}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 180,
            },
            360: {
              slidesPerView: 2,
              spaceBetween: 170,
            },
            400: {
              slidesPerView: 2,
              spaceBetween: 170,
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 3,
              spaceBetween: 190,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 3,
              width: 640,
              spaceBetween: 180,
            },
            // when window width is >= 768px
            768: {
              width: 768,
              slidesPerView: 5,
              spaceBetween: 190,
            },
          }}
          navigation
          // pagination={{ clickable: true }}
          // scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
          onReachEnd={() => {
            console.log("reached end");
          }}
        >
          {/* {
                    dataList.map((item)=>{
                        return <SwiperSlide  key={item.id}></SwiperSlide>
                    })
                } */}
          {dataList && dataList.filter((data, idx) => idx < 5).map(data => {
            return (
              <SwiperSlide >
                <div onClick={async () => {
                  let activityLogDetails = {
                    activityCode: "CLICK_JOB",
                    deviceFcmToken: "WEB_TKN_"+profile.email,
                    userId: profile.id,
                    entityType: "JOB",
                    entityId: data.id,
                    activityData:null
                }
                try {
                  console.log(activityLogDetails)
                  let res = await api.activity.activityLog(activityLogDetails,DEFAULT_JWT_TOKEN)
                  console.log("Activity Log Response", res)
                  
              } catch (err) {
                  console.log("Activity log err", err.response)
              }
                console.log(data)
                // console.log(status);
                // 2- verified, 1- Non verified, 0- guest user
                if (profile.status === 1) {
                  setShow(true);
                  setMessage("Kindly verfiy your email first to view the job details");
                  setModalHeading("Email verification needed");
                  setButtonName("Verify Email Now");
                } else if (profile.status === 0) {
                  setShow(true);
                  setMessage("You need to sign up first to view the job details ");
                  setModalHeading("Sign up needed");
                  setButtonName("Sign Up Now");
                } else {
                  history.push({
                    pathname: '/jobDetail/' + data.id
                  })
                  console.log("Job id", data)
                }
              }}>
                <DirectoryCard data={data} />
                </div>
              </SwiperSlide>
            )
          })}
          {/* <SwiperSlide>
          <DirectoryCard />
        </SwiperSlide> */}
          {/* <SwiperSlide>
                  <DirectoryCard/>
                </SwiperSlide>
                <SwiperSlide>
                  <DirectoryCard/>
                </SwiperSlide>
                <SwiperSlide>
                  <DirectoryCard/>
                </SwiperSlide>
                <SwiperSlide>
                  <DirectoryCard/>
                </SwiperSlide> */}
        </Swiper>
      </div>
    </div>
  );
};

export default ProductSwiper;