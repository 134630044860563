/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Button } from "react-bootstrap";
import { API_IMAGE_URL } from "../../app_constants";
import { defaultJobIcon, defaulttraining } from "../../assets";
import ReactReadMoreReadLess from "react-read-more-read-less";
import ThemeButton from "../theme-button/ThemeButton";
import "./card.scss";
import contract from "../../assets/logo/job_type1.png";

import moment from "moment";

const RMTrainingCard = ({
  onClick,
  data,
  onApply,
  isApplied,
  favComponent,
  children,
  style,
}) => {
  const short = (str, allowedLength = 10) => {
    if (str && str.length > allowedLength) {
      return str.slice(0, allowedLength) + "...";
    }
    return str;
  };

  return (
    <>
      <div className="rm-jobCard"  style={style}>
        {children}
        <div onClick={onClick} style={{"cursor":"pointer"}}>
        <div className="header">
          <img
            src={
              data.categoryIconPath
                ? API_IMAGE_URL + data.categoryIconPath
                : defaulttraining
            }
          />
          <div>
            <h4>{short(data.title, 35)}</h4>
            {/* <h4>
              <i class="fas fa-map-marker-alt mr-2"></i>
              {data.locationNmList.length > 1
                ? short(data.locationNmList[0], 12) + "..."
                : short(data.locationNmList[0])}
            </h4> */}
          </div>
        </div>
        <div className="body">
          {/* <h4>
          <ReactReadMoreReadLess
                charLimit={50}
                readMoreText={"Read more ▼"}
                readLessText={"Read less ▲"}
            >
              {data.oneLinerDescription}
            </ReactReadMoreReadLess>
           
          </h4> */}
          <h4>
          <ReactReadMoreReadLess
                charLimit={50}
                readMoreText={"Read more ▼"}
                readLessText={"Read less ▲"}
            >
              {data.description}
            </ReactReadMoreReadLess>
           
          </h4>
        </div>
        </div>
        <div className="footer">
          <div>
            {/* <div>
              <h3>$ </h3>
              <h4>{short(data.compensation, 11)}</h4>
            </div> */}
            <h4>
              {/* <img
                src={contract}
                className="mr-2"
                style={{ width: "1.6rem" }}
              /> */}
              {/* {data.contractTypeNmList.length > 1
                ? short(data.contractTypeNmList[0], 11) + "..."
                : short(data.contractTypeNmList[0])} */}
            </h4>
          </div>
          <hr />
          <div>
            <p>{moment(data.publishOn).fromNow()}</p>
            <Button
              className={"apply-job"}
              onClick={onClick}
              style={{ padding: "0.6rem 1rem" }}
              fontSize="1.5rem"
            >
              View
            </Button>
            {favComponent}
          </div>
        </div>
      </div>
    </>
  );
};

export default RMTrainingCard;
