import api from "../api"
import { DEFAULT_JWT_TOKEN } from "../app_constants"


export const services = {
  activityLogService: async (activityCode=null,profile,job_id=null,activityData=null)=>{
    console.log("PROFILE DATA",profile)
        let activityLogDetails = {
            activityCode: activityCode,
            deviceFcmToken: "WEB_TKN_" + profile.email,
            userId: profile.id,
            entityType: "JOB",
            entityId: job_id,
            activityData: activityData
          } 
          try{
            let res = await api.activity.activityLog(activityLogDetails,DEFAULT_JWT_TOKEN)
            console.log("ActivityLog Response",res.data)
          }
          catch(err){
            console.log("Activity log err", err.response)
          }
    },

    statusCheck: (status) => /^([1-2])$/.test(status),

    statusCheckForGuORLu: (status) => /^([0-1])$/.test(status),

}



