import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const UsefulModal = ({ show, setShowModal }) => {
  const handleClose = () => setShowModal(false);
  return (
    <>
      <Modal show={show} onHide={handleClose} className="contact-us-modal">
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h4>To apply / To learn more,</h4>
            {/* <h4>
              Email us on{" "}
              <a href="mailto:oppbank@zubinfoundation.org">
                <u>oppbank@zubinfoundation.org</u>
              </a>{" "}
              or
            </h4> */}
            <h4>
              <i
                className="fab fa-whatsapp-square"
                style={{ color: "#65D251", fontSize: "25px" }}
              ></i>
              <a
                href="https://wa.me/+85296823100"
                target="_blank"
                rel="noopener"
              >
                &nbsp;<u>+852 9682 3100</u>
              </a>
            </h4>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button className="close-btn-modal" variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UsefulModal;
