import React, { useState, useEffect, useRef } from 'react';

export const useViewVisibility = () => {
   let getVisibilityState= () =>{
        if(document.visibilityState){
          return document.visibilityState;
        }
      }
    const [viewVisibility, setViewVisibility] = useState(getVisibilityState())
    useEffect(() => {
        document.addEventListener("visibilitychange", () => {
            setViewVisibility(getVisibilityState())
        })
    })


    return viewVisibility;

}