import React, { useEffect, useState } from 'react';
import './CustomButton.css'

const CustomButton = ({ children, style, className, color = "#499cff", textColor = "#ffffff", onClick, rounded=false,shadow=false,disabled }) => {
    const [stylesheet,setStylesheet] = useState({
        backgroundColor: color,
        color: textColor,
        fontSize: "1.5rem",
        padding: "1rem 3rem 1rem 3rem",
        minWidth: "12rem",
        border: "none",
        borderRadius: rounded?"15rem":"4px",
        boxShadow:shadow? "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19)":"",
        ...style
    })

    useEffect(() => {
        if(disabled){
            setStylesheet({
                ...stylesheet,
                color: textColor + "B3",
                backgroundColor: color + "B3"
            })
        }else{
            setStylesheet({
                ...stylesheet,
                color: textColor,
                backgroundColor: color
            })
        }
    }, [disabled])
 
    return (
        
            <button className={className} style={stylesheet} onClick={onClick} disabled={disabled}>{children}</button>
        
    );
};

export default CustomButton;