/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { DEFAULT_JWT_TOKEN } from "../app_constants";
import { useMediaQuery } from "react-responsive";

export const useAuthLevel = (profileData) => {
    let auth_token = useSelector(state => state.auth_token)
    const authLevel = {
        path: "gu",
        auth_token: DEFAULT_JWT_TOKEN
    }

    console.log(profileData)


    if (profileData) {
        if (profileData.status === 1 || profileData.status === 0) {
            return authLevel
        } else if (profileData.status === 2 && auth_token) {
            authLevel.path = "lu"
            authLevel.auth_token = auth_token
        }
    }


    return authLevel;

}


export const useDeviceSize = () => {
    const isXl = useMediaQuery({
        query: "(min-device-width: 1201px)",
    });
    const isL = useMediaQuery({ query: "(min-device-width: 1025px) and (max-device-width: 1200px)" });
    const isMd = useMediaQuery({ query: "(min-device-width: 769px) and (max-device-width: 1024px)" });
    const isSm = useMediaQuery({ query: "(min-device-width: 481px) and (max-device-width: 768px)" });
    const isXs = useMediaQuery({ query: "(min-device-width: 320px) and (max-device-width: 480px)" });
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
    const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
    

    const getScreenSize =() =>{
        switch (true) {
            case isXl: return "isXl" 
            case isL: return "isL"
            case isMd: return "isMd"
            case isSm: return "isSm"
            case isXs: return "isXs"
            default:return "none"
        }
    }
    let [deviceType, setDeviceType] = useState(getScreenSize())

    useEffect(() => {
        window.addEventListener("resize", () => {
            switch (true) {
                case isXl: deviceType = null; setDeviceType("isXl"); break;
                case isL: deviceType = null; setDeviceType("isL"); break;
                case isMd: deviceType = null; setDeviceType("isMd"); break;
                case isSm: deviceType = null; setDeviceType("isSm"); break;
                case isXs: deviceType = null; setDeviceType("isXs"); break;
                // case isPortrait: setDeviceType("isPortrait"); break; 
                // case isRetina: setDeviceType("isRetina"); break; 
                default: setDeviceType("none"); break;
            }
        })
    })
    return deviceType;
}