import React from "react";
import { Jumbotron } from "reactstrap";

const PrivacyPolicy = () => {
  return (
    <div style={{ background: "white" }}>
      <Jumbotron className="jumbotron">
        <h1 className="ml-5 pl-5">
          Privacy &<br /> Policy
        </h1>
      </Jumbotron>
      <div className="ml-5 pl-5 mr-5 pr-5 mt-5 pt-5">
        <p className="description mb-5">
          The Zubin Foundation is committed to protecting the privacy of your
          personal data and undertakes to comply with the requirements of the
          Personal Data Ordinance in Hong Kong and all applicable personal data
          privacy laws and regulations such as the European Union’s General Data
          Protection Regulation. To ensure you are well informed of the personal
          data as collected, please read through our Privacy Policy.n
        </p>
        <p className="description mt-5 mb-5 pb-5">
          Here at The Zubin Foundation, we want everyone who comes to us for
          support or assistance, or otherwise uses our services or supports our
          work, including via our website, to understand how we use your
          personal data. The following privacy notice explains this.
        </p>
        <h5 className="description-header mt-5 mb-5">
          WHO WE ARE AND HOW DO WE COLLECT YOUR INFORMATION
        </h5>
        <p className="description mt-5">
          The Zubin Mahtani Gidumal Foundation Limited (together,
          &quot;Zubin&quot;, &quot;we&quot;, &quot;us&quot;, &quot;our&quot;)
          may collect, use, keep or share personal data about you.
        </p>
        <p className="description mt-5">
          This information may be collected from you:
        </p>
        <ul className="description mt-5 ml-5">
          <li>
            directly, for example, when you contact us or use one of our
            services such as Call Mira, when you complete any client intake form
            and during meetings or calls with you, if you make a donation,
            subscribe to our newsletter, participate in a survey or if you
            volunteer or intern with us;
          </li>
          <li>
            when you interact with us through third parties, for example, when
            you make a donation via PayPal or other third party providers;
          </li>
          <li>
            when you use or access services operated by our trusted partners;
            and
          </li>
          <li>automatically from your computer or mobile</li>
        </ul>
        <p className="description mt-5">
          You do not have to provide us with this data, but we may not be able
          to provide our services to you, or otherwise consider or respond to
          your enquiries or requests, if you do not provide certain information
          (that we will tell you we need you to provide).
        </p>
        <p className="description mt-5 mb-5 pb-5">
          We may also use cookies and other online identifiers (such as IP
          address, login information, browser type, location information) to
          collect information about your computer or mobile device when you
          access our website or other services online. These identifiers help us
          to ensure our website runs effectively and helps us to improve our
          services. You can opt-out or delete cookies by changing your browser
          settings at any time.
        </p>
        <h5 className="description-header mt-5 pt-5 mb-5">
          WHAT DO WE USE YOUR PERSONAL DATA FOR
        </h5>
        <p className="description mt-5">
          We collect and use your personal data to:
        </p>
        <ul className="description mb-5 pb-5 mt-5 ml-5">
          <li>
            provide our services to you, including to provide you with access to
            our professional support advisors, translators and other support and
            legal professionals, and to keep a record of the services we provide
            to you;
          </li>
          <li>
            communicate with you and respond to your enquiries, requests or
            feedback;
          </li>
          <li>
            (with your consent) provide you with our newsletter and information
            related to our campaigns, initiatives, research, opinions and news,
            seminars, fundraisers, events and opportunities or ways you can
            support our work;
          </li>
          <li>
            if you apply to volunteer or intern with us, to process your
            application, assess you suitability for the role and, if successful,
            to manage our relationship with you;
          </li>
          <li>process any donation or other payments;</li>
          <li>
            help us monitor and improve our services (including for research and
            training purposes);
          </li>
          <li>administer our website and ensure it runs effectively;</li>
          <li>
            fulfil our legal and regulatory obligations and comply with requests
            from the courts, government and regulatory authorities;
          </li>
          <li>for our internal auditing purposes; and</li>
          <li>for any other purpose to which you consent.</li>
        </ul>
        <h5 className="description-header  pt-5 mt-5 mb-5">
          WHO DO WE SHARE YOUR INFORMATION WITH
        </h5>
        <p className="description mt-5">
          We may share your personal data for the same purposes with:
        </p>
        <ul className="description mt-5 ml-5">
          <li>
            counsellors, therapists, translators or other support professionals,
            so they can support the services we provide to you;
          </li>
          <li>
            other third party service providers who provide services to, or on
            behalf of, The Zubin Foundation;
          </li>
          <li>
            legal advisors and law enforcement agencies where necessary (for
            example, where there is an imminent danger your safety, or where we
            are required to disclose information by law); and
          </li>
          <li>other third parties at your express direction.</li>
        </ul>
        <p className="description mt-5 mb-5 pb-5">
          Sometimes our website contains links to third party websites. These
          websites are subject to separate privacy policies and terms and
          conditions. Please ensure that you have checked the terms which apply
          before you provide your personal data to such third parties.
        </p>
        <h5 className="description-header pt-5 mt-5 mb-5">
          HOW LONG DO WE KEEP YOUR INFORMATION FOR
        </h5>
        <p className="description mt-5 mb-5 pb-5">
          We take reasonable measures to ensure your personal data is accurate
          and secure. We will keep your data for as long as required for the
          purposes outlined above, unless we are required to keep it for longer
          to comply with laws and regulations which apply to Zubin.
        </p>
        <h5 className="description-header pt-5 mt-5 mb-5">
          CHANGES TO THIS PRIVACY NOTICE
        </h5>
        <p className="description mb-5 pb-5 mt-5">
          We may make changes to this privacy notice from time to time. Where we
          make significant changes we will notify you of these changes in
          advance and, where required, we will ask for your consent to these
          changes.
        </p>
        <h5 className="description-header pt-5 mt-5 mb-5">
          CHANGES TO THIS PRIVACY NOTICE
        </h5>
        <p className="description mt-5">
          We may make changes to this privacy notice from time to time. Where we
          make significant changes we will notify you of these changes in
          advance and, where required, we will ask for your consent to these
          changes.
        </p>
        <h5 className="description-header mt-5 mb-5">
          HOW DO YOU FIND OUT MORE INFORMATION
        </h5>
        <p className="description mt-5">
          For further information about how we process personal data, to access
          or correct your personal data, or to unsubscribe from direct marketing
          messages, please contact by emailing info@zubinfoundation.org.
        </p>

        <p className="description mt-5" style={{marginBottom:"100px"}}>(Last update on 5 November 2019)</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
