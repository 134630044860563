

// import React from 'react';
// import GoogleMapReact from 'google-map-react';

// const GoogleMap = ({ latitude, longitude }) => {

//     // const renderMarkers = (map, maps) => {
//     // 	let marker = new maps.Marker({
//     // 		position: { lat: 22.357593223613474, lng: 114.12403235845366 },
//     // 		map,
//     // 		
//     // 	});
//     // 	return marker;
//     // };

//     return (
//         <div style={{ height: '50vh', width: '100%' }}>
//             <GoogleMapReact
//                 defaultCenter={{ lat:latitude, lng: longitude }}
//                 defaultZoom={16}
//                 yesIWantToUseGoogleMapApiInternals
//                 //   onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
//                 onGoogleApiLoaded={({ map, maps }) => {
//                     let marker = new maps.Marker({
//                         position: { lat: latitude, lng: longitude },
//                         // position: { lat: 22.357355101367798, lng: 114.12404309027458 },
//                         map,
//                         // navigator
//                     });
//                     return marker;
//                 }}
//             >
//             </GoogleMapReact>
//         </div >
//     );
// };

// export default GoogleMap;