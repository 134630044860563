import Sie from './images/sie.png'
import Cnon from './images/cnon.png'
import Purviz from './images/purviz.png'
import Impact from './images/impact.png'
import jobs from './images/jobs.png'
import training from './images/training.png'
import scholarships from './images/scholarships.png'
import healthcare from './images/healthcare.png'
import wellfare from './images/welfare.png'
import path from './images/ion-arrow-right-c - Ionicons.png'
import defaultJobIcon from './images/defaultjobs@1.5x.png'
import defaulttraining from './images/defaulttraining@1.5x.png'
import defaultProfileIcon from './images/user@1.5x.png'
import verifiedBadge from './images/verified-badge.png'
import brandLogo from './logo/branding-logo-crop.png'
import appleDownload from './brands/appledownload.png'
import googlePlayDownload from './brands/googleplaydownload.png'
import abs1 from './images/abs1.png'
import abs2 from './images/abs2.png'
import ictLogo from './logo/ict-award-logo.jpg'

export {
    Sie,
    Cnon,
    Purviz,
    Impact,
    jobs,
    training,
    scholarships,
    healthcare,
    wellfare,
    path,
    defaultJobIcon,
    defaultProfileIcon,
    verifiedBadge,
    brandLogo,
    appleDownload,
    googlePlayDownload,
    abs1,
    abs2,
    ictLogo,
    defaulttraining
}