/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Button, Alert } from 'reactstrap'

// import { styled } from '@mui/material/styles';
import './RedeemRewards.css'
import CountUp from 'react-countup';
import { useSelector } from 'react-redux';
import NoReq from '../../assets/images/no_applied_jobs@3x.png'
import { makeStyles, styled } from '@material-ui/core';
import axios from 'axios';
import Drawer from '@material-ui/core/Drawer';
import CustomButton from '../../components/CustButton/CustomButton';
import RangeInput from '../../components/range_input/RangeInput';
import api from '../../api';
import { useHistory } from 'react-router';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import moment from 'moment';
// import {  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const useStyles = makeStyles({
    root: {
        //   background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        //   border: 0,
        borderRadius: 5,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        //   color: 'white',
        //   height: 48,
        //   padding: '0 30px',
    },
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const RedeemRewards = () => {
    const [alertClr, setAlertClr] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [deviceType, setDeviceType] = useState("")
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const history = useHistory()
    const classes = useStyles();
    const [agree, setAgree] = useState(false)
    const [drawerStatus, setDrawerStatus] = useState(false)
    const [selectedNav, setSelectedNav] = useState(1)
    let [rewardBalance, setRewardBalance] = useState(0)
    let [CouponCount, setCouponCount] = useState(0)
    const [redeemRequestList, setRedeemRequestList] = useState([])
    const [rewardRuleList, setRewardRuleList] = useState([])
    const [details, setDetails] = useState({
        couponCount: null,
        firstName: null,
        lastName: null,
        mobile: null,
        address: null
    })
    const [currentTimeStamp, setCurrentTimeStamp] = useState();
    let [max, setMax] = useState(0)
    const [val, setVal] = useState(0);
    // let authToken = useSelector(state => state.authToken)
    // console.log("AUTH", authToken)
    const authToken = useSelector(state=>state.auth_token)
    const profile = useSelector(state=>state.profileData)
    // console.log("PROFILE ", profile)

    const setRedeemReqBoardData = async () => {
        try {
            let reward_rule_res = await api.crud.getAllRecords("/api/lu/reward_points/rules", authToken)
            if (reward_rule_res.resultCode === 1) {
                setRewardRuleList(reward_rule_res.resultData.rewardRulesList)
            }
            let redeemListRes = await api.crud.getAllRecords("/api/vu/reward_points/redeem_request", authToken)
            console.log("RESPONSE", redeemListRes)
            if (redeemListRes.resultCode === 1) {
                setRedeemRequestList(redeemListRes.resultData.redeemRequestList)
            }
            let rewardBalanceRes = await api.crud.getAllRecords("/api/vu/reward_points/balance", authToken)
            console.log("RESPONSE", rewardBalanceRes)
            if (rewardBalanceRes.resultCode === 1) {
                setCouponCount(parseInt(rewardBalanceRes.resultData.rewardBalance / 100))
                setRewardBalance(rewardBalanceRes.resultData.rewardBalance)
                setVal(rewardBalanceRes.resultData.rewardBalance)
                let number = rewardBalanceRes.resultData.rewardBalance
                let lMax = parseInt(number / 100)
                let cc = 0;
                if (lMax < 1) {
                    lMax = 1
                } else {
                    cc = lMax
                }
                setCouponCount(cc)
                let remainder = number % 100
                if (remainder % 100 === 0) {
                    setMax(lMax * 100)
                } else {
                    setMax((lMax) * 100)
                }

            }
            let addressRes = await api.crud.getAllRecords('/api/lu/user/address', authToken)
            let address = "";
            if (addressRes.resultCode === 1) {
                console.log("Address", addressRes.resultData.userAddress)
                address = getAddressStringFromContactInfo(addressRes.resultData.userAddress)
                console.log("ADDRESS WAS CALLED", address)
            }

            setDetails({
                address: address,
                couponCount: parseInt(rewardBalanceRes.resultData.rewardBalance / 100),
                firstName: profile.firstName,
                lastName: profile.lastName,
                mobile: profile.mobile ? parseInt(profile.mobile) : null
            })

        } catch (err) {
            console.log(err)
        }
    }

    const toggleChange = () => {
        setAgree(!agree)
    }


    useEffect(() => {
        console.log(deviceType)
    }, [deviceType])

    useEffect(() => {
        console.log(agree)
    }, [agree])

    useEffect(() => {
        window.scrollTo(0,0)
        setRedeemReqBoardData()
    }, [])

    useEffect(() => {
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
                navigator.userAgent
            )
        ) {
            setDeviceType("Mobile");
        } else {
            setDeviceType("Desktop");
        }

    })


    const getAddressStringFromContactInfo = (userContactInfo) => {
        console.log(userContactInfo)
        try {
            let addressString = ''
            if(!!userContactInfo && !!userContactInfo.id){
            let userContactInfoObj = {
                flat:userContactInfo.flat,
                floor: userContactInfo.floor,
                buidlingName: userContactInfo.buidlingName,
                streetName: userContactInfo.streetName,
                district : userContactInfo.district
            }
            let userContactInfoValues = Object.values(userContactInfoObj)
            // if (!!userContactInfo && !!userContactInfo.id) {

            let userContactInfoNotNullValuesArray = userContactInfoValues.filter(item => item != null)
            console.log("User Info Array",userContactInfoNotNullValuesArray)
             addressString = userContactInfoNotNullValuesArray.join()
        }
            return addressString;
        } catch (error) {
            console.log("error: ", error);
            return '';
        }
    }

    // useEffect(() => {
    //     setDetails({
    //         ...details,

    //     })

    // }, [drawerStatus])

    useEffect(() => {
        console.log("Detail....", details)
    }, [details])

    useEffect(() => {
        setDetails(
            {
                ...details,
                couponCount: parseInt(val / 100)
            }
        )

    }, [val])

    const convertDate = (date) => {
        let dispatchedOn = "-"
        dispatchedOn = moment(date).format('YYYY-MM-DD');
        let dspDateDiff = moment().diff(dispatchedOn, 'days');
        if (dspDateDiff === 0) {
            dispatchedOn = 'Today';
        } else if (dspDateDiff === 1) {
            dispatchedOn = 'Yesterday';
        }
        return dispatchedOn;
    }



    useEffect(() => {
        console.log("REDEEM REQUEST", redeemRequestList)
    }, [redeemRequestList])

    const onClaimCoupon = async () => {
        let body = {
            fullName: details.firstName + " " + details.lastName,
            couponCount: details.couponCount,
            mobile: parseInt(details.mobile),
            address: details.address
        }

        try {
            let res = await api.crud.protectedPostRequest('/api/vu/reward_points/redeem_request', body, authToken)
            console.log(res)
            if (res.resultCode === 1) {
                setRedeemReqBoardData()
                setMessage("Your Redeem Request Added Successfully")
                setAlertClr("success")
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false)
                }, 3000)
                setAgree(false)
                setDrawerStatus(false)
                setOpen(false)
                window.scrollTo(0, 0)
            }
        } catch (err) {
            setMessage("Redeem Request Failed !")
            setAlertClr("danger")
            setShowAlert(true)
            setTimeout(() => {
                setShowAlert(false)
            }, 3000)
            setAgree(false)
            setDrawerStatus(false)
            setOpen(false)
            setRedeemReqBoardData()
            console.error(err.response)
        }
    }


    const CustRow = ({ claimedOn, couponClaimed, status, dispatchedOn }) => {

        return (
            <Row className="redeem-req-row">
                <Col xs="8" lg="8">
                    <Row >
                        <Col ><p>Coupons Claimed:</p></Col>
                        <Col ><p>{couponClaimed}</p></Col>
                    </Row>
                    <Row>
                        <Col><p>Claimed On:</p></Col>
                        <Col ><p>{convertDate(claimedOn)}</p></Col>
                    </Row>
                    <Row>
                        <Col >
                             <p>Dispatched On:</p> 
                        </Col>
                        <Col  >


                            {
                                dispatchedOn ? <p>{convertDate(dispatchedOn)}</p> : "Pending"
                            }
                        </Col>
                    </Row>
                </Col>
                <Col xs="4" lg="4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Tag1 type={status} />
                </Col>
            </Row>
        )
    }

    const Tag1 = ({ type }) => {
        return (
            <div className={type === 2 ? 'dispatched' : 'pending'}>
                <div className="layer1"></div>
                <div className={type === 2 ? "dispatched-layer2" : "pending-layer2"}>
                    {type === 2 ? 'Dispatched' : 'Pending'}
                </div>
            </div>
        )
    }


    const Tag2 = ({ title, rules, ruleNo }) => {
        return (

            <div className="reward-guide-card">
                <Row>
                    <Col xs="7" sm="8" md="8" lg="8">
                        <p >{title}</p>
                    </Col>
                    <Col xs="5" className="d-flex" sm="4" md="4" lg="4">
                        <div className="tag2 ml-auto">
                            <div className="l1"></div>
                            <p>{ruleNo}</p>
                        </div>
                    </Col>
                </Row>
                <ul className="closely-coupled-description m-3 p-3">
                    {
                        rules.map(i => {
                            return <li>{i}</li>
                        })
                    }
                </ul>
            </div>

        )
    }


    return (
        <div className="redeem-reward-view">
            <Alert className="alert-position" style={{ marginBottom: '-1px' }} isOpen={showAlert} color={alertClr}>
                {message}{" "}
            </Alert>

            <div className="reward-board">
                <Row className="w-100 reward-left m-0 p-0">
                    <Col className="m-0 p-0 mt-5 " xs="12" sm="12" md="12" lg="6">
                        <div className="reward-count">
                            <h3>
                                <CountUp
                                    start={0}
                                    end={rewardBalance}
                                    duration={2.5}
                                    seperator=","
                                />
                            </h3>
                            <h6>Current Reward Balance</h6>
                        </div>
                        <div className="reward-claim">
                            <h5>Redeem Rewards</h5>
                            <p>100 Reward Points = 1 Supermarket Coupon</p>

                            <Modal
                                className="reward-conf-modal"
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <div className="card reward-confirmation-popup">
                                    <div className="drawer-content">
                                        <h2 className="mb-5">Confirm Your Request</h2>
                                        <div className="details mb-5">

                                            <p className=" d-flex justify-content-space-between"><strong className="mr-3">Number Of Coupons : </strong> <span>{details.couponCount}</span> </p>
                                            <p className="d-flex justify-content-space-between"><strong className="mr-3">Name : </strong><span> {details.firstName + " " + details.lastName}</span></p>
                                            <p className="d-flex justify-content-space-between"><span><strong className="mr-3">Mobile : </strong> {details.mobile}</span>
                                                {
                                                    !details.mobile && <i className="fas fa-pen ml-auto text-primary" onClick={() => {
                                                        history.push({
                                                            pathname: '/profile',
                                                            state: {
                                                                status: 'EDITREWARD'
                                                            }
                                                        })
                                                    }}></i>
                                                }
                                            </p>
                                            <p className="d-flex justify-content-space-between"><span><strong className="mr-3">Address : </strong> {details.address}</span>
                                                {
                                                    !details.address && <i className="fas fa-pen ml-auto text-primary" onClick={() => {
                                                        history.push({
                                                            pathname: '/address',
                                                            state: {
                                                                status: 'EDITREWARD'
                                                            }
                                                        })
                                                    }}></i>
                                                }
                                            </p>
                                        </div>
                                        <p className="noteInfo mb-5"><strong>Note:</strong> The coupons will be sent by post to you at the address given above</p>
                                        <Row className="mb-3 mt-3">
                                            <Col xs="2" sm="2" lg="2">
                                                <input
                                                    className="check"
                                                    type="checkbox"
                                                    name="confirm"
                                                    onClick={toggleChange}
                                                    disabled={(details.firstName && details.lastName && details.mobile && details.address) ? false : true}
                                                // disabled={true}
                                                />
                                            </Col>
                                            <Col className="m-0 p-0" xs="10" sm="10" lg="10">
                                                <label className="confirm_label mb-5" for="confirm">I confirm, that the information I provided above is correct</label>
                                            </Col>
                                        </Row>
                                        <div className="d-flex flex-column align-items-center">
                                            <CustomButton disabled={!agree} rounded={true} style={{ padding: "1rem 2rem" }}
                                                onClick={onClaimCoupon}
                                                className="mb-4" shadow={true}>
                                                Submit My Redemption Request
                                            </CustomButton>
                                            <CustomButton onClick={() => { setOpen(false); setAgree(false) }} rounded={true} style={{ padding: "1rem 2rem" }} shadow={true} color="#E0E0E0" textColor="#000" className="b" >
                                                Cancel
                                            </CustomButton>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                            <Drawer
                                className={classes.root}
                                anchor={"bottom"}
                                open={drawerStatus}
                            // onClose={toggleDrawer(anchor, false)}
                            >
                                <div className="drawer-content">
                                    <h2 className="mb-5">Confirm Your Request</h2>
                                    <div className="details mb-5">
                                        <p className=" d-flex justify-content-space-between"><strong className="mr-3">Number Of Coupons : </strong> <span>{details.couponCount}</span> </p>
                                        <p className="d-flex justify-content-space-between"><strong className="mr-3">Name : </strong><span> {details.firstName + " " + details.lastName}</span></p>
                                        <p className="d-flex justify-content-space-between"><strong className="mr-3">Mobile : </strong> <span>{details.mobile}</span></p>
                                        <p className="d-flex justify-content-space-between"><span><strong className="mr-3">Address : </strong> {details.address}</span> <i className="fas fa-pen ml-auto text-primary" onClick={() => {
                                            history.push({
                                                pathname: '/address',
                                                state: {
                                                    status: 'EDITREWARD'
                                                }
                                            })
                                        }}></i></p>
                                    </div>
                                    <p className="noteInfo mb-5"><strong>Note:</strong> The coupons will be sent by post to you at the address given above</p>
                                    <Row className="mb-3 mt-3">
                                        <Col xs="2" sm="2" lg="2">
                                            <input
                                                className="check"
                                                type="checkbox"
                                                name="confirm"
                                                onClick={toggleChange}
                                                disabled={(details.firstName || details.lastName || details.mobile || details.address) ? false : true}
                                            />
                                        </Col>
                                        <Col className="m-0 p-0" xs="10" sm="10" lg="10">
                                            <label className="confirm_label mb-5" for="confirm">I confirm, that the information I provided above is correct</label>
                                        </Col>
                                    </Row>
                                    <div className="d-flex flex-column align-items-center">
                                        <CustomButton
                                            disabled={!agree}
                                            rounded={true} style={{ padding: "1rem 2rem" }} onClick={onClaimCoupon} className="mb-4" shadow={true}>
                                            Submit My Redemption Request
                                        </CustomButton>
                                        <CustomButton onClick={() => { setDrawerStatus(false) }} rounded={true} style={{ padding: "1rem 2rem" }} shadow={true} color="#E0E0E0" textColor="#000" className="b" >
                                            Cancel
                                        </CustomButton>
                                    </div>
                                </div>
                            </Drawer>



                            <div className="slider-container">
                                <RangeInput onChange={setVal} rewardBalance={rewardBalance} defaultValue={val} max={max} step={(val >= 100) ? 100 : 1} />

                            </div>
                            <button className={(val >= 100) ? "button_active" : "button_disabled"} onClick={(val >= 100) ? () => { setOpen(true) } : () => null}>
                                {/* <button className={(val >= 100) ? "button_active" : "button_disabled"} onClick={(val >= 100) ? () => { deviceType === "Desktop" ? setOpen(true) : setDrawerStatus(true) } : () => null}> */}
                                Claim {details.couponCount >= 1 && parseInt(details.couponCount)} Coupon
                            </button>
                        </div>
                    </Col>
                    <Col className="m-0 p-0" xs="12" sm="12" md="12" lg="6">
                        <Row className="reward-nav">
                            <Col className="p-0 m-0">
                                <div className={(selectedNav === 1) ? "active-reward-link reward-nav-link pointer" : "reward-nav-link pointer"} onClick={() => { setSelectedNav(1) }}>
                                    My Rewards
                                </div>
                            </Col>
                            <Col className="p-0 m-0">
                                <div className={(selectedNav !== 1) ? "active-reward-link reward-nav-link pointer" : "reward-nav-link pointer"} onClick={() => { setSelectedNav(2) }}>
                                    Reward Guide
                                </div>
                            </Col>
                        </Row>
                        {
                            selectedNav === 1 ?
                                <>
                                    <h5 className="redeem-req-row-header mt-5">
                                        Past Redeem Requests
                                    </h5>
                                    <div className="past-redeem-req">
                                        {
                                            (redeemRequestList.length > 0) ? redeemRequestList.map(data => {
                                                return <CustRow status={data.status} couponClaimed={data.couponCount} claimedOn={data.requestedOn} dispatchedOn={data.dispatchedOn} />
                                            }) :
                                                <div className="no-redeem-img">
                                                    <img alt="Banner" src={NoReq} />
                                                    <h3>No redeem requests found</h3>
                                                    <p>You can't see any history here<br />
                                                        because you have not redeemed <br />points yet.
                                                    </p>
                                                </div>
                                        }
                                    </div>
                                </>
                                :
                                <div className="reward-guide">
                                    {
                                        rewardRuleList.map((i, idx) => {
                                            return (
                                                <Tag2 title={i.action} ruleNo={i.rewardPoints} rules={i.ruleList} />
                                            )
                                        })
                                    }
                                </div>
                        }


                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default RedeemRewards;