/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  API_IMAGE_URL,
  API_SERVER_URL,
  DEFAULT_JWT_TOKEN,
} from "../../app_constants";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { Alert } from "reactstrap";
import api from "../../api";
import {
  defaultJobIcon,
  defaultProfileIcon,
  defaulttraining,
} from "../../assets";
import "./eventList.css";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Logout from "../../logOut/Logout";
import { services } from "../../helpers/utilities";
import { RWebShare } from "react-web-share";
import RewardPopup from "../../components/RewardPopup";

const EventDetails = () => {
  const params = useParams();
  const [deviceType, setDeviceType] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [eventDetail, setEventDetail] = useState([]);
  const [message, setMessage] = useState("");
  const [alertClr, setAlertClr] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isRewardAllocate, setIsRewardAllocate] = useState(false);
  const [earnReward, setEarnReward] = useState(0);
  const [rewardMsg, setRewardMsg] = useState("");

  console.log("Params : ", params);

  const dispatch = useDispatch();
  const history = useHistory();
  const authToken = useSelector((state) => state.auth_token);
  const profile = useSelector((state) => state.profileData);

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setDeviceType("Mobile");
    } else {
      setDeviceType("Desktop");
    }

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchEventDetails();
  }, [params]);

  const short = (str, allowedLength = 10) => {
    if (str && str.length > allowedLength) {
      return str.slice(0, allowedLength) + "...";
    }
    return str;
  };

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setMessage("");
        setAlertClr("");
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (isRewardAllocate) {
      setTimeout(() => {
        setIsRewardAllocate(false);
      }, 6000);
    }
  }, [isRewardAllocate]);

  const fetchEventDetails = () => {
    var eventId = Number(params.trainingEventId);
    // console.log(eventId);

    console.log("USEEFFECT CALL");
    setIsLoading(true);

    api.event
      .getEventById(eventId, authToken)
      .then((result) => {
        console.log("RESULT - Event Detail", result);
        if (result.resultCode === 1) {
          setEventDetail(result?.resultData?.trainingEvent);
          console.log("Reward ======> ", result.resultData.activityResult);
          if (
            result.resultData.activityResult.rewardDetails &&
            result.resultData.activityResult.rewardDetails.newEarnedRewards > 0
          ) {
            // setIsRewardAllocate(true);
            // setRewardMsg(
            //   "Congrats! You've just earned your daily reward for viewing five different events today"
            // );
            // setEarnReward(
            //   result.resultData.activityResult.rewardDetails.newEarnedRewards
            // );
          }
        } else {
          setEventDetail([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      });
  };

  const handleAddRemoveFav = () => {
    var dataId = {
      TrainingEventId: eventDetail?.trainingEventId,
    };
    console.log(dataId);
    setIsLoading(true);
    if (!eventDetail.isFavorite) {
      api.event
        .isFav(dataId, authToken)
        .then((result) => {
          console.log("IS FAV ", result);
          if (result.resultCode === 1) {
            setMessage("This event has been added as a Favourite");
            setShowAlert(true);
            setAlertClr("success");
            fetchEventDetails();
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          if (
            !!error.response &&
            !!error.response.status &&
            error.response.status === 401
          ) {
            Logout(dispatch, history, false);
          }
          if (error.message === "Network Error") {
            setMessage("Please check your network connection!");
            setAlertClr("danger");
            setShowAlert(true);
          }
        });
    } else {
      api?.event
        ?.isFavRemove(dataId, authToken)
        .then((result) => {
          console.log("IS FAV REMOVE", result);
          if (result.resultCode === 1) {
            setShowAlert(true);
            setAlertClr("success");
            setMessage("This event has been removed from Favourite");
            fetchEventDetails();
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          if (
            !!error.response &&
            !!error.response.status &&
            error.response.status === 401
          ) {
            Logout(dispatch, history, false);
          }
          if (error.message === "Network Error") {
            setMessage("Please check your network connection!");
            setAlertClr("danger");
            setShowAlert(true);
          }
        });
    }
  };
  return (
    <>
      <Alert
        className="alert-position"
        //  isOpen={showAlert}
        color={alertClr}
      >
        {message}{" "}
      </Alert>

      {isRewardAllocate && earnReward > 0 && (
        <div className="reward-style-home">
          <div className="d-flex justify-content-center">
            <RewardPopup
              rewardMsg={rewardMsg}
              isPopupOpen={isRewardAllocate}
              earnReward={earnReward}
              rewardStyle="reward-position"
            />
          </div>
        </div>
      )}

      <div className="job-details-main">
        <Row className="jobdetails-header" style={{ marginBottom: "5rem" }}>
          <Col lg="6" xs="12 mb-5" className="d-flex align-items-center ">
            <div className="jobdetails-icon mr-5 ">
              <img
                src={
                  eventDetail.categoryIconPath
                    ? API_IMAGE_URL + eventDetail.categoryIconPath
                    : defaulttraining
                }
              />
            </div>
            <h2 className="Event-Title">{eventDetail?.title} </h2>
          </Col>
          <Col lg="6" className="d-flex justify-content-end align-items-center">
            {/* <Button className="flat-rect-btn mr-4">Save</Button> */}
            <div
              className="d-flex mr-5"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={async () => {
                services.statusCheck(profile.status) &&
                  services.activityLogService(
                    "CLICK_FAVORITE_JOB",
                    profile,
                    eventDetail?.trainingEventId
                  );
                handleAddRemoveFav();
              }}
            >
              <i
                className="fa fa-star mb-2"
                aria-hidden="true"
                value={eventDetail && eventDetail?.trainingEventId}
                style={
                  eventDetail?.isFavorite
                    ? {
                        color: "#499cff",
                        fontSize: "2rem",
                        cursor: "pointer",
                        fontSize: "30px",
                      }
                    : {
                        color: "black",
                        fontSize: "2rem",
                        cursor: "pointer",
                        fontSize: "30px",
                      }
                }
              ></i>
              <p style={{ fontSize: "15px", color: "gray" }}>FAVOURITE</p>
            </div>
          </Col>
        </Row>
        <div>
          <Row className="d-flex justify-content-center">
            <Col lg="5" xl="4" className="mb-5">
              <h4 className="jobdetails-label mb-5">EVENT DETAILS</h4>
              <div className="jobdetails-container">
                <Row>
                  <Col
                    style={{ wordWrap: "nowrap" }}
                    className="jobdetails-title"
                  >
                    Title
                  </Col>
                  <Col
                    className="jobdetails-value"
                    style={{ wordWrap: "break-word", margin: "1px" }}
                  >
                    {" "}
                    {eventDetail.title}
                    {/* {jobDetail &&
                      jobDetail.locationNmList &&
                      jobDetail.locationNmList.map((loc, idx) => {
                        console.log(jobDetail.locationNmList.length, " ", idx);
                        return (
                          <>
                            {loc}
                            {jobDetail.locationNmList.length !== idx + 1 &&
                              ", "}
                          </>
                        );
                      })} */}
                  </Col>
                </Row>
                <Row>
                  <Col className="jobdetails-title"> Description</Col>
                  <Col className="jobdetails-value">
                    {eventDetail.description}
                    {/* {jobDetail &&
                      jobDetail.contractTypeNmList &&
                      jobDetail.contractTypeNmList.map((con, idx) => {
                        return (
                          <>
                            {con}
                            {jobDetail.contractTypeNmList.length !== idx + 1 &&
                              ","}
                          </>
                        );
                      })} */}
                  </Col>
                </Row>

                <Row>
                  <Col className="jobdetails-title">Category</Col>
                  <Col className="jobdetails-value">
                    {" "}
                    {eventDetail.category}
                  </Col>
                </Row>
                <Row>
                  <Col className="jobdetails-title">Event Language</Col>
                  <Col className="jobdetails-value">
                    {" "}
                    {eventDetail.eventLanguage}
                  </Col>
                </Row>
                <Row>
                  <Col className="jobdetails-title">Event Organiser</Col>
                  <Col className="jobdetails-value">
                    {eventDetail.eventOrganiser}
                  </Col>
                </Row>
                <Row>
                  <Col className="jobdetails-title">Event Status</Col>
                  <Col className="jobdetails-value">
                    {eventDetail.eventStatus}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg="7" xl="8">
              <h4 className="jobdetails-label mb-5">ABOUT EVENTS</h4>
              <div className="jobdetail-content">
                <h5 className="jobdetails-title mr-5 mb-4">
                  One Liner Decription
                </h5>
                <ul className="jobdetails-value">
                  <li>{eventDetail && eventDetail.oneLinerDescription}</li>
                </ul>
                <Row>
                  <Col className="jobdetails-title"> Start Date</Col>
                  <Col className="jobdetails-value">
                    {moment(eventDetail?.startDate).utc().format("MMM Do YYYY")}
                  </Col>
                </Row>
                <Row>
                  <Col className="jobdetails-title"> End Date</Col>
                  <Col className="jobdetails-value">
                    {moment(eventDetail?.endDate).utc().format("MMM Do YYYY")}
                  </Col>
                </Row>
                <Row>
                  <Col className="jobdetails-title"> Start Time</Col>
                  <Col className="jobdetails-value">
                    {moment(eventDetail?.startTime).utc().format("LT")}
                  </Col>
                </Row>
                <Row>
                  <Col className="jobdetails-title"> End Time</Col>
                  <Col className="jobdetails-value">
                    {moment(eventDetail?.endTime).utc().format("LT")}
                  </Col>
                </Row>
                <Row>
                  <Col className="jobdetails-title"> Telephone</Col>
                  <Col className="jobdetails-value">
                    {eventDetail?.telephone}
                  </Col>
                </Row>
                {eventDetail.isOnline ? (
                  <Row>
                    <Col className="jobdetails-title"> Online Platform</Col>
                    <Col className="jobdetails-value">
                      {eventDetail?.onlinePlatform}
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                <Row>
                  <Col className="jobdetails-title"> Created On</Col>
                  <Col className="jobdetails-value">
                    {moment(eventDetail?.createdOn).format("MMM Do YYYY")}
                  </Col>
                </Row>
                {eventDetail.isOnline===0?<Row>
                  <Col className="jobdetails-title"> Event Address</Col>
                  <Col className="jobdetails-value">
                    {eventDetail?.eventAddress}
                  </Col>
                </Row>:""}
                {/* <h5 className="jobdetails-title mt-5 pt-5 mr-5 mb-4">
                  Job Requirements
                </h5> */}
                {/* {jobDetail &&
                  jobDetail.requirementList &&
                  jobDetail.requirementList.map((resp, idx) => {
                    return (
                      <ul className="jobdetails-value">
                        <li>{resp}</li>
                      </ul>
                    );
                  })} */}
                <Row>
                  <Col className="jobdetails-title"> Email</Col>
                  <Col className="jobdetails-value">{eventDetail.email}</Col>
                </Row>

                {eventDetail.isOnline === 1 ? (
                  <Row>
                    <Col className="jobdetails-title">Link</Col>
                    <Col className="jobdetails-value">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          eventDetail.eventRegistrationUrl?.startsWith("http")
                            ? eventDetail.eventRegistrationUrl
                            : `https://${eventDetail.eventRegistrationUrl}`
                        }
                      >
                        {eventDetail.eventRegistrationUrl}
                      </a>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default EventDetails;
