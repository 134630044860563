import React from "react";
import "./index.css";
import { ictLogo } from "../../assets";

const Popup = (props) => {
  return (
    <div className="popup-box">
      <div className="box">
      <i class="fas fa-times awclose" onClick={()=>{props.setIsOpen(!props.isOpen)}}></i>
        <h1> ICT Awards 2021</h1>
        <img alt="ict award logo" src={ictLogo} />
        <h3 className="mt-5">
          <span className="mr-2">Opportunity Bank</span>
           is awarded the Certificate of Merit in the Hong Kong <span>ICT Awards 2021</span>:
          Smart People (Smart Inclusion) Category.
        </h3>
      </div>
    </div>
  );
};

export default Popup;
