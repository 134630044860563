import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import moment from 'moment'
import DatePicker from "react-datepicker";
import api from '../../../api';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Logout from '../../../logOut/Logout';
import { Alert } from 'reactstrap'
import Loader from "react-js-loader";
import './awardCerti.css'

const AwardNCertif = ({ workFlow, setWorkFlow, toBeEdit, setIsAddClick }) => {

  const [certifOrAwardName, setCertifOrAwardName] = useState('');
  const [certifNAwardErr, setCertifNAwardErr] = useState('');
  const [orgniser, setOrgniser] = useState('');
  const [startYear, setStartYear] = useState('');
  const [startYearErr, setStartYearErr] = useState('');
  const [result, setResult] = useState('');
  const [isAward, setIsAward] = useState(1);
  const [message, setMessage] = useState('');
  const [alertClr, setAlertClr] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let authToken = useSelector(state=>state.auth_token)
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("Edata ", toBeEdit, "PATH ", workFlow)
    if (workFlow === "FORM" && toBeEdit) {
      setCertifOrAwardName(toBeEdit.certificationName)
      setOrgniser(toBeEdit.organiserName && toBeEdit.organiserName)
      var sy = new moment((toBeEdit.year), "yyyy").utc();
      console.log(sy._d)
      setStartYear(sy._d)
      setResult(toBeEdit.result && toBeEdit.result)
      setIsAward(toBeEdit.isAward)
    } else {
      setCertifOrAwardName("")
      setOrgniser("")
      setStartYear("")
      setResult("")
      setIsAward(1)
    }
  }, [])

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
        setMessage("")
        setAlertClr("")
      }, 3000);
    }
  }, [showAlert])

  const saveCertifNAward = () => {
    if (!certifOrAwardName) {
      setCertifNAwardErr(`Please enter your ${isAward === 0 ? "award " : "certificate "}name`)
    }
    if (!startYear) {
      setStartYearErr(`Please select year`)
    }
    if (workFlow === "FORM" && toBeEdit.id) {
      var data = {
        certficateId: toBeEdit.id,
        certificationName: certifOrAwardName,
        year: Number(moment(startYear).format("yyyy")),
        isAward: isAward
      }
      console.log("DATA ", data)
      if (orgniser) {
        data.organiserName = orgniser
      }

      if (result) {
        data.result = result
      }
      api.onlineCV.editAwardNCertificate(data, authToken)
        .then(result => {
          console.log("RESULT ", result);
          if (result.resultCode === 1) {
            setMessage("Certification updated successfully");
            setAlertClr("success");
            setShowAlert(true);
            setTimeout(() => {
              setWorkFlow("DISPLAY")
              setIsAddClick(false)
              dispatch({ type: 'isListReload/save', isListReload: true })
            }, 2000);

          } else {
            setMessage(result.resultMsg);
            setAlertClr("danger");
            setShowAlert(true);
          }
        }).catch(error => {
          console.log(error)
          if (!!error.response && !!error.response.status && error.response.status === 401) {
            Logout(dispatch, history, false);
          }
          if (error.message === 'Network Error') {
            setMessage("Please check your network connection!");
            setAlertClr("danger");
            setShowAlert(true);
          }
        })

    } else {
      var data = {
        certificationName: certifOrAwardName,
        year: Number(moment(startYear).format('yyyy')),
        isAward: isAward
      }
      if (orgniser) {
        data.organiserName = orgniser
      }
      if (result) {
        data.result = result
      }
      console.log("DSATA ", data)
      console.log("auth ", authToken)
      api.onlineCV.saveAwardNCertificate(data, authToken)
        .then(result => {
          console.log("RESULT SAVE Certi and award", result);
          if (result.resultCode === 1) {
            setMessage("Certification saved successfully");
            setAlertClr("success");
            setShowAlert(true);
            setTimeout(() => {
              setWorkFlow("DISPLAY")
              setIsAddClick(false)
              dispatch({ type: 'isListReload/save', isListReload: true })
            }, 2000);

          } else {
            setMessage(result.resultMsg);
            setAlertClr("danger");
            setShowAlert(true);
          }

        }).catch(error => {
          console.log(error)
          if (!!error.response && !!error.response.status && error.response.status === 401) {
            Logout(dispatch, history, false);
          }
          if (error.message === 'Network Error') {
            setMessage("Please check your network connection!");
            setAlertClr("danger");
            setShowAlert(true);
          }
        })
    }
    setCertifOrAwardName("")
      setOrgniser("")
      setStartYear("")
      setResult("")
      setIsAward(1)
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    console.log("IS WARD ", isAward)
  }, [isAward])

  return (
    <div className="card profile-edit-container mt-5 mb-5">
      {isLoading &&
        <div style={{ zIndex: '1500' }} className="d-flex justify-content-center">
          < Loader type="spinner-cub" bgColor={"rgb(53, 50, 50)"} title={"Loading..."} size={100} />
        </div>
      }
      <Form className="form-style p-5" style={{ fontSize: "larger" }}>
      <Alert className='alert-position' isOpen={showAlert} color={alertClr}>{message} </Alert>

        <Form.Group className="d-flex justify-content-center">
          <Form.Check
            onChange={(e) => {
              setIsAward(1)
            }}
            value={isAward}

            style={{ marginRight: '5rem' }}
            inline
            checked={isAward === 1}
            label="Certificate"
            name="group1"
            type="radio"
          />

          {/* </Form.Group>
                <Form.Group> */}
          <Form.Check
            onChange={(e) => {
              setIsAward(0)
            }}
            value={isAward}
            style={{ marginLeft: '5rem', color:'gary' }}
            inline
            checked={isAward === 0}
            label="Award"
            name="group1"
            type="radio"
          />
        </Form.Group>
        <Form.Group >
          <Form.Label   className="cust-label shiftToLeft mt-3">
            Certification/Award<span className="mandatory-star ml-2">*</span>
          </Form.Label>
          <Form.Control className="cv-inputStyle"
            style={{ backgrounfColor: 'white' }}
            value={certifOrAwardName}
            onChange={(e) => {
              setCertifOrAwardName(e.target.value);
              if (e.target.value) {
                setCertifNAwardErr('')
              }
            }}
            type="text" />
          <Form.Text className="font-size-vali-msg" style={{ color: 'red', display: 'flex', justifyContent: 'start', fontFamily: 'emoji' }} >
            {certifNAwardErr}
          </Form.Text>
        </Form.Group>

        <Form.Group >
          <Form.Label   className="cust-label shiftToLeft">
            Organiser
          </Form.Label>
          <Form.Control className="cv-inputStyle"
            style={{ backgrounfColor: 'white' }}
            value={orgniser}
            onChange={(e) => {
              setOrgniser(e.target.value);
            }}
            type="text" />
        </Form.Group>

        <Form.Group >
          <Form.Label   className="cust-label shiftToLeft">
            Year<span className="mandatory-star ml-2">*</span>
          </Form.Label>
          <DatePicker
            className="date-input"
            selected={startYear}
            onChange={(date) => {
              console.log("DATE ", date)
              setStartYear(date)
              if (date) {
                setStartYearErr('')
              }
            }}
            showYearPicker
            dateFormat="yyyy"
            maxDate={moment().toDate()}
          />

          <Form.Text className="font-size-vali-msg" style={{ color: 'red', display: 'flex', justifyContent: 'start', fontFamily: 'emoji' }} >
            {startYearErr}
          </Form.Text>
        </Form.Group>

        <Form.Group >
          <Form.Label   className="cust-label shiftToLeft">
            Result
          </Form.Label>
          <Form.Control className="cv-inputStyle"
            style={{ backgrounfColor: 'white' }}
            value={result}
            onChange={(e) => {
              setResult(e.target.value);
            }}
            type="text" />

        </Form.Group>
        <div className="d-flex justify-content-center">
          <Button
            onClick={saveCertifNAward}
            className="my-cust-button mt-5 pl-5 pt-1 pb-1 pr-5"
          >
            Save
          </Button>
        </div>
      </Form>

    </div>
  )
}

export default AwardNCertif;