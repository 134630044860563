import React, { useEffect, useState } from 'react'
import './workExp.css'
import { Button, Card } from 'react-bootstrap'
import api from '../../../api'
import { Alert, Collapse } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Logout from "../../../logOut/Logout";
import Loader from "react-js-loader";
import WorkExpForm from './WorkExpForm'
import moment from 'moment'
import { Component } from 'react'

const WorkExperience = ({ onWorkExpAction }) => {

  const [alertClr, setAlertClr] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isFresher, setIsFresher] = useState('');
  const [workExpList, setWorkExpList] = useState([]);
  const [workExpId, setWorkExpId] = useState('');
  const [workFlow, setWorkFlow] = useState('DISPLAY');


  const authToken = useSelector(state=>state.auth_token)

  const history = useHistory();
  const dispatch = useDispatch();
  const isListReload = useSelector(state => state.isListReload)

  useEffect(() => {
    getCvProfile();
    getWorkExp();
  }, [])

  useEffect(() => {
    console.log("isListReload", isListReload)
    if (isListReload) {
      onWorkExpAction("workExp")
      getWorkExp();
      dispatch({ type: 'isListReload/save', isListReload: false })
    }
  }, [isListReload])

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setMessage("");
        setAlertClr("");
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert])

  const getWorkExp = () => {
    api.onlineCV.getWorkExperience(authToken)
      .then(result => {
        console.log("tesult get work exp ", result)
        if (result.resultCode === 1) {
          setWorkExpList(result.resultData.workExperienceList)
        } else {
          setWorkExpList([])
        }
      }).catch(error => {
        setIsLoading(false)
        console.log(error)
        if (!!error.response && !!error.response.status && error.response.status === 401) {
          Logout(dispatch, history, false);
        }
        if (error.message === 'Network Error') {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      })
  }

  useEffect(() => {
    console.log("CHECK", workFlow, " ", isFormOpen)
  }, [workFlow, isFormOpen])

  const getCvProfile = () => {
    setIsLoading(true)
    api.onlineCV.cvProfile(authToken)
      .then(result => {
        console.log("RESULT => PROFILE CV", result);
        if (result.resultCode === 1) {
          if (result.resultData.userCvProfile.isFresher === null) {
            setIsFresher('')
          } else if (result.resultData.userCvProfile.isFresher === 0) {
            setIsFresher(false)
          } else {
            setIsFresher(true)
          }
        } else {
        }
        setIsLoading(false)
      }).catch(error => {
        setIsLoading(false)
        console.log(error)
        if (!!error.response && !!error.response.status && error.response.status === 401) {
          Logout(dispatch, history, false);
        }
        if (error.message === 'Network Error') {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      })
  }

  const handleIsUserFresher = (status) => {
    var data = {
      isFresher: status
    }
    console.log("DATA Freser ", data)
    api.onlineCV.isUserFresher(data, authToken)
      .then(result => {
        console.log("Result => fresher chck", result)
        if (result.resultCode === 1) {
          if (status === false) {
            // onWorkExpAction("notFresher")
            setIsFormOpen(true)
            setIsFresher(false)
          } else {
            onWorkExpAction("fresher")
            setTimeout(() => {
              setIsFresher(true);
            }, 2000);
          }
          getCvProfile()
        } else {

        }
      }).catch(error => {
        setIsLoading(false)
        console.log(error)
        if (!!error.response && !!error.response.status && error.response.status === 401) {
          Logout(dispatch, history, false);
        }
        if (error.message === 'Network Error') {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      })
  }

  const deleteWorkExp = (id) => {
    setIsLoading(true)
    var data = {
      workExperienceId: id
    }
    console.log("DATA ", data)
    api.onlineCV.delWorkExperience(data, authToken)
      .then(result => {
        console.log("DELETE WORK EXP ", result);
        if (result.resultCode === 1) {
          setMessage(result.resultMsg);
          setAlertClr("success");
          setShowAlert(true);
          onWorkExpAction("deleWorkExp")
          getWorkExp();
        } else {
          setMessage(result.resultMsg);
          setAlertClr("danger");
          setShowAlert(true);
        }
        window.scrollTo(0, 0)
        setIsLoading(false)
      }).catch(error => {
        setIsLoading(false)
        console.log(error)
        if (!!error.response && !!error.response.status && error.response.status === 401) {
          Logout(dispatch, history, false);
        }
        if (error.message === 'Network Error') {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      })
  }

  const editWorkExp = (w) => {
    console.log(w)
    setWorkExpId(w.id)
    setWorkFlow("EDIT")
  }

  useEffect(() => {
    console.log("IS FRESHWR ", isFresher)
  }, [isFresher])

  return (
    <div className="mb-5" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Alert className='alert-position' isOpen={showAlert} color={alertClr}>{message} </Alert>

      {isLoading &&
        <div style={{ zIndex: '1500' }}>
          < Loader type="spinner-cub" bgColor={"rgb(53, 50, 50)"} title={"Loading..."} size={100} />
        </div>
      }

      <h3 classNmae="header-style">Work Experience</h3>
      {(isFresher === '' && !isFormOpen && workExpList.length === 0) ?
        <>
          <h5>Are you a fresher ?</h5>
          <Button className='mb-3 mt-3 btn-work-exp-fre'
            style={{ width: 'auto' }}
            onClick={() => handleIsUserFresher(true)}>Yes, I am a fresher
          </Button>
          <Button className='mb-5 btn-work-exp-wor'
            style={{ width: 'auto' }}
            onClick={() => {
              handleIsUserFresher(false)
            }}>No, I have Work experience
          </Button>
        </>
        :
        (isFresher === true && !isFormOpen) ?
          <div className="d-flex justify-content-space-around">
            <h5 style={{ marginRight: '70px' }}>I am a Fresher </h5>
            <h5 style={{ color: '#499cff', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                setIsFresher('')
              }}>Change</h5>
          </div >
          :
          (isFresher === false && workExpList.length === 0 && !isFormOpen) &&
          <>
            <div className="d-flex justify-content-space-around">
              <h5 style={{ marginRight: '70px' }}>I am not a Fresher </h5>
              <h5 style={{ color: '#499cff', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => {
                  setIsFresher('')
                }}>Change</h5>
            </div >
            <Button className="mt-3 btn-work-exp-wor" style={{ width: "auto" }} onClick={() => {
              handleIsUserFresher(false)
            }}>Add Work Experiences</Button>
          </>

      }



      {(workFlow === "EDIT") || (isFormOpen) ?
        <WorkExpForm workExpId={workExpId} setIsFormOpen={setIsFormOpen}
          setWorkFlow={setWorkFlow} workFlow={workFlow} />
        :
        <>
          {(!isFormOpen && workExpList.length > 0) &&
            <span className="plus-icon-align" style={{ marginBottom: '2px' }}>
              <i class="fa fa-plus icon-style" aria-hidden="true"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setIsFormOpen(true);
                  // setWorkFlow("EDIT")
                }}>{' '} Add </i>
            </span>
          }
          {workExpList.map((w, idx) => {
            return (
              <>
                <div key={idx} className="in-single-line">
                  <div className="box-row-size">
                    <Card className='card-style-workEd' >
                      <Card.Body className="card-box">
                        <div className="d-flex justify-content-end">
                          <div className="add-icon d-flex justify-content-end">

                          </div>
                          <i
                            onClick={() => editWorkExp(w)}
                            className="fas fa-pen fa-1x icon-style"
                          ></i>
                          <i class="fa fa-trash icon-style ml-3" aria-hidden="true"

                            onClick={() =>
                              deleteWorkExp(w.id)
                            }
                          ></i>
                        </div >

                        <h6>{w.jobTitle} </h6>
                        <h6>{w.employerName} </h6>
                        <h6>{w.jobLocation} </h6>
                        <h6>{moment(w.startMonthYear).format('MMM yyyy')} - {(w.endMonthYear) ? `${moment(w.endMonthYear).format('MMM yyyy')}` : 'Present '}</h6>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </>
            )
          })}
        </>
      }
    </div>
  )
}

export default WorkExperience;