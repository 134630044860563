import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import { Container } from "reactstrap";
import './cust-banner.css'

const Cust_Banner = () => {
  return (
    <div className="banner ">
        <img src="https://www.inspirit.co.in/wp-content/uploads/2020/03/01-1-1.png"/>
      <h3 >Jobs</h3>
      <h1 >Get the right job in seconds with us!</h1>
    </div>
  );
};

export default Cust_Banner;
