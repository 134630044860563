import React, { useEffect, useState } from "react";
import AppliedJobTrack from "./AppliedJob/AppliedJobTrack";
import api from "../../api";
import FavJobTrack from "./favJob/FavJobTrack";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Logout from "../../logOut/Logout";
import { useHistory } from "react-router-dom";
import { Alert } from "reactstrap";
import Loader from "react-js-loader";

export default function JobTrack() {
  const [appiedJob, setAppliedJob] = useState([]);
  const [favJobList, setFavJobList] = useState([]);
  const [appliedJobClick, setAppliedJobClick] = useState(true);
  const [favJobClick, setFavJobClick] = useState(false);
  const [endPoint, setEndPoint] = useState(20);
  const [endPointFav, setEndPointFav] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [alertClr, setAlertClr] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const authToken = useSelector(state=>state.auth_token)
  const shouldeReloadJobList = useSelector(
    (state) => state.shouldeReloadJobList
  );
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    fetchAppliedJobList();
    fetchFavJobList();

    dispatch({
      type: "shouldeReloadJobList/save",
      shouldeReloadJobList: false,
    });
  }, [shouldeReloadJobList]);

  useEffect(() => {
    fetchAppliedJobList();
    fetchFavJobList();
    window.scrollTo(0, 0);
  }, []);

  const fetchFavJobList = () => {
    setIsLoading(true);
    api.job
      .favJobList(authToken)
      .then((result) => {
        console.log("Fav JOB LIST ", result);
        if (result.resultCode === 1) {
          setFavJobList(result.resultData.favoriteJobList);
        } else {
          setFavJobList([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      });
  };

  const fetchAppliedJobList = () => {
    setIsLoading(true);
    api.job
      .appliedJobList(authToken)
      .then((result) => {
        console.log("APPIED JOB LIST ", result);
        if (result.resultCode === 1) {
          setAppliedJob(result.resultData.favoriteJobList);
        } else {
          setAppliedJob([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      });
  };

  return (
    <React.Fragment>
      <Alert className="alert-position" isOpen={showAlert} color={alertClr}>
        {message}{" "}
      </Alert>

      <div style={{ display: "flex", justifyContent: "center", zIndex: '1500', top: '4rem' }}>
        {isLoading && (
          <Loader
            type="spinner-cub"
            bgColor={"rgb(53, 50, 50)"}
            title={"Loading..."}
            size={100}
          />
        )}
      </div>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "15px",
          marginTop: "30px",
        }}
      >
        <h4
          className={appliedJobClick && "bottom-line"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setAppliedJobClick(true);
            setFavJobClick(false);
          }}
        >
          APPLIED LIST
        </h4>
        <h4
          className={favJobClick && "bottom-line"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setFavJobClick(true);
            setAppliedJobClick(false);
          }}
        >
          FAVORITE LIST
        </h4>
      </Row>
      {appliedJobClick && (
        <>
          <div className="fix">
            <AppliedJobTrack data={appiedJob} endPoint={endPoint} />
          </div>
          <Row className="mt-5 pt-5">
            <Col lg="12" className="d-flex justify-content-center align-items-center">
              <div >
                {!(endPoint > appiedJob.length) && (
                  <Button
                    style={{
                      backgroundColor: "transparent",
                      color: "#499CFF",
                      border: "1px solid #DBDBDB",
                    }}
                    onClick={() => {
                      setEndPoint(endPoint + 20);
                    }}
                  >
                    Load More
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </>
      )}
      {favJobClick && (
        <div className="fix">
          <FavJobTrack favData={favJobList} endPoint={endPointFav} />
          <div style={{ marginTop: "5rem" }}>
            {!(endPoint > favJobList.length) && (
              <Button
                style={{
                  backgroundColor: "transparent",
                  color: "#499CFF",
                  border: "1px solid #DBDBDB",
                }}
                onClick={() => {
                  setEndPointFav(endPointFav + 20);
                }}
              >
                Load More
              </Button>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
