export const COLORS = {
    primary : "#3c6ffe",
    secondary: "#f2f8fe",
    positive:"#5ABF81",
    negative:"red",
    darkBlue:"#243460",
    grey: "#2C303C",
    light: "",
    minor:"#8790A2",
    major:"#E8EAEE"
}