import React from 'react';
import './cv-drawer.scss';
import {  OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import { Button,Modal, ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import Dropzone from "react-dropzone";

const CVDrawer = ({cvLink,profileData,API_SERVER_URL,setPopUpStatus,popUpStatus,handleCVOnDrop,imageMaxSize,acceptedFileType}) => {
   console.log("RMCV",profileData)
    return (
             <Modal style={{ marginTop: "110px" }} isOpen={popUpStatus}>
          <ModalHeader className="CV-header">
            <h5>Submit CV</h5>
          </ModalHeader>
          <ModalBody>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {
              cvLink &&
               (
                <Button
                  className="button-cv-style border-cv"
                  href={profileData && API_SERVER_URL + profileData.cvLink}
                  target="_blank"
                  onClick={() => {
                    setPopUpStatus(false);
                  }}
                >
                  View CV
                </Button>
              )}
            </div>

            <div className="dropzone-div">
              <Dropzone
                onDrop={handleCVOnDrop}
                className="dropzone-size"
                multiple={false}
                maxSize={imageMaxSize}
                accept={acceptedFileType}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    className="for_sm_text"
                    {...getRootProps({ className: "dropzone" })}
                    style={{ marginTop: '1rem' }}
                  >
                    <section
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <input {...getInputProps()} />
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {profileData && profileData.cvLink && (
                          <Button className="button-cv-style border-cv">
                            Upload New CV{" "}
                          </Button>
                        )}
                      </div>

                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {profileData && !profileData.cvLink && (
                          <Button className="button-cv-style border-cv">Upload CV</Button>
                        )}
                      </div>
                    </section>
                  </div>
                )}
              </Dropzone>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "-7px",
                  marginTop: "10px",
                }}
              >
                <p>Supported file format: PDF</p>
              </div>
              <div
                style={
                  profileData && !profileData.cvLink
                    ? {
                      marginBttom: "9rem",
                      display: "flex",
                      justifyContent: "center",
                    }
                    : { display: "flex", justifyContent: "center" }
                }
              >
                <p>Maximum file size: 2 MB</p>
              </div>
            </div>
          </ModalBody>
          
          <ModalFooter>
            <Button
              style={{ backgroundColor: "#c5c4c4", color: "black" }}
              onClick={() => {
                setPopUpStatus(false);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
    );
};

export default CVDrawer;