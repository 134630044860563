import React, { useState } from 'react';

const Paragraph = ({ onClick, children, style, className, color = "#83868B", fontFamily = "Poppins-Regular",
    fontSize = "16px", lineHeight = "30px", letterSpacing = '0', cursor, textAlign }) => {

    const [paraStyle, setParastyle] = useState({
        color: color,
        fontFamily: fontFamily,
        fontSize: fontSize,
        lineHeight: lineHeight,
        letterSpacing: letterSpacing,
        cursor: cursor,
        textAlign: textAlign,
        ...style
    });
    console.log("PARAGRAPH ")
    return (
        <React.Fragment>
            <p
                onClick={onClick}
                className={className}
                style={paraStyle}
            >
                {children}
            </p>
        </React.Fragment>
    )
}

export default Paragraph;