import React, { useState } from 'react'
import StepOne from '../../Pages/CreateCVForm/steps/StepOne';
import './testStepper.css'


export default function TestStepper({ }) {

    const [activeStep, setActiveStep] = useState(0);
    const [isStepOneDone, setIsStepOneDone] = useState('');
    const [isStepTwoDone, setIsStepTwoDone] = useState('');
    const [isStepThreeDone, setIsStepThreeDone] = useState('');
    const [isStepFourDone, setIsStepFourDone] = useState('');


    return (
        <div className="stepProgressbar mt-3 mb-3">
            <div className="step" onClick={() => {
                return (
                    <StepOne dataFromContaInfo={(data) => {
                        console.log("step data", data)
                        setIsStepOneDone(data)
                    }
                    } />
                )
            }}>
                {isStepOneDone === '' ?
                    <>
                        <span className="circle" style={{ backgroundColor: 'skyblue', height: '18px', width: '18px' }}></span>
                        <span className="line mt-2"></span>
                    </>
                    :
                    isStepOneDone === false ?
                        <>
                            <i class="fa fa-times-circle" style={{ fontSize: "22px", color: "red" }}></i>
                            <span className="line mt-2"></span>
                        </>
                        :
                        <>
                            <i class="fa fa-check-circle" style={{ fontSize: "22px", color: "green" }}></i>
                            <span className="line mt-2"></span>
                        </>
                }
                <p className="step-text">Step 1</p>
            </div>

            <div className="step">
                {isStepTwoDone === '' ?
                    <>
                        <span className="circle" style={{ backgroundColor: 'skyblue', height: '18px', width: '18px' }}></span>
                        <span className="line mt-2"></span>
                    </>
                    :
                    isStepTwoDone === false ?
                        <>
                            <i class="fa fa-times-circle" style={{ fontSize: "22px", color: "red" }}></i>
                            <span className="line mt-2"></span>
                        </>
                        :
                        <>
                            <i class="fa fa-check-circle" style={{ fontSize: "22px", color: "green" }}></i>
                            <span className="line mt-2"></span>
                        </>

                }
                <p className="step-text">Step 2</p>
            </div>

            <div className="step">
                {/* <CCollapse show={stepStatus.step3 === 0}> */}
                {isStepThreeDone === '' ?
                    <>
                        <span className="circle" style={{ backgroundColor: 'skyblue', height: '18px', width: '18px' }}></span>
                        <span className="line mt-2"></span>
                    </>
                    :
                    isStepThreeDone === false ?
                        <>
                            <i class="fa fa-times-circle" style={{ fontSize: "22px", color: "red" }}></i>
                            <span className="line mt-2"></span>
                        </>
                        :
                        <>
                            <i class="fa fa-check-circle" style={{ fontSize: "22px", color: "green" }}></i>
                            <span className="line mt-2"></span>
                        </>
                }
                <p className="step-text">Step 3</p>
            </div>

            <div className="step">
                {/* <CCollapse show={stepStatus.step4 === 0}> */}
                {isStepFourDone === '' ?
                    <>
                        <span className="circle" style={{ backgroundColor: 'skyblue', height: '18px', width: '18px' }}></span>
                        <span className="line mt-2"></span>
                    </>
                    :
                    isStepFourDone === false ?
                        <>
                            <i class="fa fa-times-circle" style={{ fontSize: "22px", color: "red" }}></i>
                            <span className="line mt-2"></span>
                        </>
                        :
                        <>
                            <i class="fa fa-check-circle" style={{ fontSize: "22px", color: "green" }}></i>
                            <span className="line mt-2"></span>
                        </>
                }
                <p className="step-text">Step 4</p>
            </div>
        </div >
    )
}