import React, { useEffect, useState } from 'react'
import { Form, Button, Card, Row, Col, Collapse } from 'react-bootstrap';
import { Alert } from 'reactstrap';
import api from '../../../api';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import "./education.css";
import moment from 'moment'
import Loader from "react-js-loader";
import Logout from "../../../logOut/Logout";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const Education = ({ nextStep, previousStep, onEducationAction }) => {

  const [institudeName, setInstitudeName] = useState('');
  const [institudeNameErr, setInstitudeNameErr] = useState('');
  const [location, setLocation] = useState('');
  const [locationErr, setLocationErr] = useState('');
  const [course, setCourse] = useState('');
  const [courseErr, setCourseErr] = useState('');
  const [startYear, setStartYear] = useState('');
  const [startYearErr, setStartYearErr] = useState('');
  const [endYear, setEndYear] = useState('');
  const [result, setResult] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [iseducationEdit, setIseducationEdit] = useState(false);
  const [isAddClick, setIsAddClick] = useState(false);
  const [singleEducation, setSingleEducation] = useState([]);
  const [message, setMessage] = useState('');
  const [alertClr, setAlertClr] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [educationList, setEducationList] = useState([]);
  const authToken = useSelector(state=>state.auth_token)
  const history = useHistory();
  const dispatch = useDispatch();


  useEffect(() => {
    setTimeout(() => {
      setMessage('');
      setAlertClr("");
      setShowAlert(false);
    }, 3000)
  }, [showAlert])

  useEffect(() => {
    getEducationList()
  }, [])

  const getEducationList = () => {
    setIsLoading(true)
    api.onlineCV.getEducation(authToken).then(result => {
      console.log("EDUCATION GET ", result.resultData.educationList);
      if (result.resultCode === 1) {
        setEducationList(result.resultData.educationList)
        // console.log("EDUCATION COMLETE?..", !result.resultData.educationList)
        if (result.resultData.educationList) {
          console.log("get education true")
          // isEducationDone(true)
        } else {
          console.log("get education false")
          // isEducationDone(false)
        }
      } else {
        setEducationList([])
        console.log("get education false")
        // isEducationDone(false)
      }
      setIsLoading(false)
    }).catch(error => {
      setIsLoading(false)
      console.log(error)
      if (!!error.response && !!error.response.status && error.response.status === 401) {
        Logout(dispatch, history, false);
      }
      if (error.message === 'Network Error') {
        setMessage("Please check your network connection!");
        setAlertClr("danger");
        setShowAlert(true);
      }
    })
    window.scrollTo(0, 300)
  }

  useEffect(() => {
    console.log("pageYOffset", window.pageYOffset)
  }, [window.pageYOffset])

  const editEducation = (e) => {
    setIseducationEdit(true);
    setSingleEducation(e)
    console.log("Edit e ", e)
    setInstitudeName(e.instituteName)
    setLocation(e.instituteLocation)
    setCourse(e.fieldOfStudy)
    setResult(e.result)
    // var sy = (new Date(`Fri mar 01 ${e.startYear} 00:00:00 GMT+0530`));
    var sy = new moment((e.startYear), "yyyy").utc();
    if (e.endYear) {
      // var ey = e.endYear && (new Date(`Fri mar 01 ${e.endYear} 00:00:00 GMT+0530`));
      var ey = new moment((e.endYear), "yyyy").utc();
      setEndYear(ey._d)
    }
    console.log(sy._d)
    setStartYear(sy._d)
  }

  const deleteEducation = (id) => {
    setIsLoading(true)
    var data = {
      educationId: id
    }
    console.log("EDUCATION ID ", data)
    api.onlineCV.delEducation(data, authToken)
      .then(result => {
        console.log("DELETE EDUCATION ", result);
        if (result.resultCode === 1) {
          setMessage(result.resultMsg);
          setAlertClr("success");
          setShowAlert(true);
          setTimeout(() => {
            onEducationAction("deleEducation")
          }, 2000);
          getEducationList();
        } else {
          setMessage(result.resultMsg);
          setAlertClr("danger");
          setShowAlert(true);
        }
        setIsLoading(false)
      }).catch(error => {
        setIsLoading(false)
        console.log(error)
        if (!!error.response && !!error.response.status && error.response.status === 401) {
          Logout(dispatch, history, false);
        }
        if (error.message === 'Network Error') {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      })
    window.scrollTo(0, 300)
  }

 

  const saveEducation = () => {
    setIsLoading(true)
    console.log("SINLE EDUC ", singleEducation)
    if (iseducationEdit && !isAddClick) {
      if (!institudeName) {
        setInstitudeNameErr("Please enter your institude name")
      }
      if (!location) {
        setLocationErr("Please enter your location")
      }
      if (!course) {
        setCourseErr("Please enter your course")
      }
      if (!startYear) {
        setStartYearErr("Please enter start year")
      }
      // setIseducationEdit(false)
      var data = {
        educationId: singleEducation.id,
        instituteName: institudeName,
        instituteLocation: location,
        fieldOfStudy: course,
      }
      if(startYear){
       data.startYear = (singleEducation?.startYear === startYear?.getFullYear()) ? Number(singleEducation?.startYear) : Number(moment(startYear).format('yyyy'))
      }
      if (endYear) {
        data.endYear = (singleEducation?.endYear === endYear?.getFullYear()) ? Number(singleEducation?.endYear) : Number(endYear?.getFullYear())
      }
      console.log("Education result ---> ", result);
      if (result === "" || result === null ) {
        data.result = null;
      } 
      else{
        data.result = result;
      }
      console.log("DATA edit ", data)
      api.onlineCV.editEducation(data, authToken)
        .then(result => {
          console.log("EDIT EDUCATION ", result)
          if (result.resultCode === 1) {
            setMessage("Educational qualifications updated successfully");
            // console.log("edit education true")
            // isEducationDone(true)
            setAlertClr("success");
            setShowAlert(true);

            setTimeout(() => {
              // console.log("edit education false")
              // isEducationDone(false);
              setIseducationEdit(false)
              setInstitudeName('');
              setLocation('');
              setCourse('');
              setStartYear('');
              setEndYear('');
              setResult('')
            }, 2000);
            getEducationList();
          } else {
            setMessage(result.resultMsg);
            setAlertClr("danger");
            setShowAlert(true);
            // console.log("edit education true")
            // isEducationDone(false)
          }
          setIsLoading(false)
        }).catch(error => {
          setIsLoading(false)
          console.log(error)
          if (!!error.response && !!error.response.status && error.response.status === 401) {
            Logout(dispatch, history, false);
          }
          if (error.message === 'Network Error') {
            setMessage("Please check your network connection!");
            setAlertClr("danger");
            setShowAlert(true);
          }
        })

    } else {

      if (!institudeName) {
        setInstitudeNameErr("Please enter your institude name")
      }
      if (!location) {
        setLocationErr("Please enter your location")
      }
      if (!course) {
        setCourseErr("Please enter your course")
      }
      if (!startYear) {
        setStartYearErr("Please enter start year")
      }
     
      var data = {
        instituteName: institudeName,
        instituteLocation: location,
        fieldOfStudy: course,
        // startYear: startYear,
        startYear: startYear && (startYear)?.getFullYear(),
      }
      if (endYear) {
        // data.endYear = endYear
        data.endYear = (endYear)?.getFullYear()
      }
      if (result) {
        data.result = result
      }
      console.log("DATA", data)
      api.onlineCV.saveEducation(data, authToken).
        then(result => {
          console.log("RESULT SAVE EDUCATION", result)
          if (result.resultCode === 1) {
            setMessage("Educational qualifications saved successfully");
            setAlertClr("success");
            setShowAlert(true);
            // console.log("save education true")
            // isEducationDone(true)
            setTimeout(() => {
              setIsAddClick(false)
              setInstitudeName('');
              setLocation('');
              setCourse('');
              setStartYear('');
              setEndYear('');
              setResult('')
              onEducationAction("education");
            }, 2000);


            getEducationList();
          } else {
            setMessage(result.resultMsg);
            setAlertClr("danger");
            setShowAlert(true);
            // console.log("save education false")
            // isEducationDone(false)
          }
          setIsLoading(false)
        }).catch(error => {
          // console.log("save education true")
          // isEducationDone(false)
          setIsLoading(false)
          console.log(error)
          if (!!error.response && !!error.response.status && error.response.status === 401) {
            Logout(dispatch, history, false);
          }
          if (error.message === 'Network Error') {
            setMessage("Please check your network connection!");
            setAlertClr("danger");
            setShowAlert(true);
          }
        })
    }
    window.scrollTo(0, 300)
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>


      {isLoading &&
        <div style={{ zIndex: '1500' }} className="d-flex justify-content-center">
          < Loader type="spinner-cub" bgColor={"rgb(53, 50, 50)"} title={"Loading..."} size={100} />
        </div>
      }

      <h3 classNmae="header-style" style={{ marginTop: '10px' }}>Education</h3>
      {(educationList.length === 0 || iseducationEdit || isAddClick) ?
        <div className="card profile-edit-container mt-5">
          <Form className="form-style p-5" style={{ fontSize: "larger" }}>
            <Alert className='alert-position' isOpen={showAlert} color={alertClr}>{message} </Alert>
            <Form.Group >
              <Form.Label className="cust-label shiftToLeft mt-3">
                School Name / College Name<span className="mandatory-star ml-2">*</span>
              </Form.Label>
              <Form.Control className="cv-inputStyle"
                style={{ backgrounfColor: 'white' }}
                value={institudeName}
                onChange={(e) => {
                  setInstitudeName(e.target.value);
                  if (e.target.value) {
                    setInstitudeNameErr('')
                  }
                }}
                type="text" />
              <Form.Text className="font-size-vali-msg" style={{ color: 'red', display: 'flex', justifyContent: 'start', fontFamily: 'emoji' }} >
                {institudeNameErr}
              </Form.Text>
            </Form.Group>

            <Form.Group >
              <Form.Label className="cust-label shiftToLeft">
                Location<span className="mandatory-star ml-2">*</span>
              </Form.Label>
              <Form.Control className="cv-inputStyle"
                style={{ backgrounfColor: 'white' }}
                value={location}
                onChange={(e) => {
                  setLocation(e.target.value);
                  if (e.target.value) {
                    setLocationErr('')
                  }
                }}
                type="text" />
              <Form.Text className="font-size-vali-msg" style={{ color: 'red', display: 'flex', justifyContent: 'start', fontFamily: 'emoji' }} >
                {locationErr}
              </Form.Text>
            </Form.Group>

            <Form.Group >
              <Form.Label className="cust-label shiftToLeft">
                Course<span className="mandatory-star ml-2">*</span>
              </Form.Label>
              <Form.Control className="cv-inputStyle"
                style={{ backgrounfColor: 'white' }}
                value={course}
                onChange={(e) => {
                  setCourse(e.target.value);
                  if (e.target.value) {
                    setCourseErr('')
                  }
                }}
                type="text" />
              <Form.Text className="font-size-vali-msg" style={{ color: 'red', display: 'flex', justifyContent: 'start', fontFamily: 'emoji' }} >
                {courseErr}
              </Form.Text>
            </Form.Group>

            <Form.Group >
              <Form.Label className="cust-label shiftToLeft">
                Start Year<span className="mandatory-star ml-2">*</span>
              </Form.Label>
              <DatePicker
                className="date-input"
                selected={startYear}
                onChange={(date) => {
                  console.log("DATE ", date)
                  setStartYear(date)
                  if (date) {
                    setStartYearErr('')
                  }
                }}
                showYearPicker
                dateFormat="yyyy"
                maxDate={moment().toDate()}
              />

              <Form.Text className="font-size-vali-msg" style={{ color: 'red', display: 'flex', justifyContent: 'start', fontFamily: 'emoji' }} >
                {startYearErr}
              </Form.Text>
            </Form.Group>

            <Form.Group >
              <Form.Label className="cust-label shiftToLeft">
                End Year
              </Form.Label>
              <DatePicker
                className="date-input"
                selected={endYear}
                onChange={(date) => setEndYear(date)}
                showYearPicker
                dateFormat="yyyy"
                maxDate={moment().toDate()}
                startDate={startYear}
                endDate={startYear}
                minDate={startYear}
              />

            </Form.Group>

            <Form.Group >
              <Form.Label className="cust-label shiftToLeft">
                Result
              </Form.Label>
              <Form.Control className="cv-inputStyle"
                style={{ backgrounfColor: 'white' }}
                value={result}
                onChange={(e) => {
                  setResult(e.target.value);
                }}
                type="text" />

            </Form.Group>
            <div className="d-flex justify-content-center">
              <Button
                onClick={saveEducation}
                className="my-cust-button mt-2 mb-5 pl-5 pt-1 pb-1 pr-5"
              >
                Save
              </Button>
              {/* <Button
                onClick={() => {
                  saveEducation()
                  nextStep(2)
                }}
                className="my-cust-button mt-2 mb-5 pl-5 pt-1 pb-1 pr-5"
              >
                Save & Next
              </Button> */}
            </div>

          </Form>

        </div>
        :
        <>
          <div className="add-icon d-flex justify-content-end">
            <i class="fa fa-plus icon-style plus-icon" aria-hidden="true"
              style={{ marginBottom: '2px' }}
              onClick={() => {
                setIsAddClick(true);
              }}>
              {' '} Add</i>
          </div>
          {educationList.map((e, idx) => {
            return (
              <>
                <div key={idx} className="in-single-line">
                  <div className="box-row-size">
                    <Card className='card-style-workEd' >
                      <Card.Body className="card-box">
                        <div className="d-flex justify-content-end">

                          <i
                            onClick={() => editEducation(e)}
                            className="fas fa-pen fa-1x icon-style"
                          ></i>
                          <i class="fa fa-trash icon-style ml-3" aria-hidden="true"
                            onClick={() => deleteEducation(e.id)}></i>
                        </div >

                        <h6>{e.instituteName} </h6>
                        <h6>{e.instituteLocation} </h6>
                        <h6>{e.fieldOfStudy} </h6>
                        <h6>{e.startYear} - {e.endYear ? `${e.endYear}` : 'Present '}</h6>
                        <h6>{e.result} </h6>

                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </>
            )
          })
          }
          <div className="mt-5">
            {/* <Button
              onClick={() => {
                previousStep(0)
              }}
              className="my-cust-button mr-4 pl-5 pt-1 pb-1 pr-5"
            >
              Previous
            </Button> */}
            {/* <Button
              onClick={() => {
                nextStep(2)
              }}
              className="my-cust-button  pl-5 pt-1 pb-1 pr-5 "
            >
              Next
            </Button> */}

          </div>
        </>
      }


    </div>
  )
}

export default Education;