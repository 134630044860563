/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { result } from "lodash";
import { API_SERVER_URL, DEFAULT_JWT_TOKEN } from "./app_constants";

export default {
  auth: {
    signUp: (data) =>
      axios
        .post(API_SERVER_URL + "/api/gu/auth/signup", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${DEFAULT_JWT_TOKEN}`,
          },
        })
        .then((result) => {
          console.log("API --- SignUp ", result);
          return result.data;
        }),

    signIn: (data) =>
      axios
        .post(API_SERVER_URL + "/api/gu/auth/login", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${DEFAULT_JWT_TOKEN}`,
          },
        })
        .then((result) => {
          console.log("API --- SIGN IN ", result);
          return result.data;
        }),

    logOut: (data, authToken) =>
      axios
        .post(API_SERVER_URL + "/api/lu/auth/logout", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API --- sign out ", result);
          return result.data;
        }),

    socialMedia: (data) =>
      axios
        .post(API_SERVER_URL + "/api/gu/auth/social_login", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${DEFAULT_JWT_TOKEN}`,
          },
        })
        .then((result) => {
          console.log("API --- SOCIAL IN ", result);
          return result.data;
        }),
  },

  userProfile: {
    editProfile: (data, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/lu/user/profile", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==> Edit Profile ==> ", result);
          return result.data;
        }),
  },

  onlineCV: {
    cvProfile: (authToken) =>
      axios
        .get(API_SERVER_URL + "/api/vu/user_cv/profile", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>  get profile cv  ==> ", result);
          return result.data;
        }),

    district: (authToken) =>
      axios
        .get(API_SERVER_URL + "/api/vu/user_cv/district", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>  get district ==> ", result);
          return result.data;
        }),

    address: (data, authToken) =>
      axios
        .post(API_SERVER_URL + "/api/lu/user/address", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==> Adress ==> ", result);
          return result.data;
        }),

    getAddress: (authToken) =>
      axios
        .get(API_SERVER_URL + "/api/lu/user/address", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>get Adress ==> ", result);
          return result.data;
        }),

    addCareerObj: (data, authToken) =>
      axios
        .post(API_SERVER_URL + "/api/vu/user_cv/career_objective", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>add career obj ==> ", result);
          return result.data;
        }),

    getMasterSkills: (authToken) =>
      axios
        .get(API_SERVER_URL + "/api/vu/user_cv/skill/master", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>get master skills ==> ", result);
          return result.data;
        }),

    getSkills: (authToken) =>
      axios
        .get(API_SERVER_URL + "/api/vu/user_cv/skill", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>get selected skills ==> ", result);
          return result.data;
        }),

    saveSkill: (data, authToken) =>
      axios
        .post(API_SERVER_URL + "/api/vu/user_cv/skill", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>save skills ==> ", result);
          return result.data;
        }),

    saveEducation: (data, authToken) =>
      axios
        .post(API_SERVER_URL + "/api/vu/user_cv/education", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>save education ==> ", result);
          return result.data;
        }),

    getEducation: (authToken) =>
      axios
        .get(API_SERVER_URL + "/api/vu/user_cv/education", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>get education ==> ", result);
          return result.data;
        }),

    editEducation: (data, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/vu/user_cv/education", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>edit education ==> ", result);
          return result.data;
        }),

    delEducation: (data, authToken) =>
      axios
        .delete(API_SERVER_URL + "/api/vu/user_cv/education", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          data: {
            educationId: data.educationId,
          },
        })
        .then((result) => {
          console.log("API ==> eduaction delete ==> ", result);
          return result.data;
        }),

    isUserFresher: (data, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/vu/user_cv/is_fresher", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==> is user fresher ==> ", result);
          return result.data;
        }),

    saveWorkExperience: (data, authToken) =>
      axios
        .post(API_SERVER_URL + "/api/vu/user_cv/work_experience", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>save work exp ==> ", result);
          return result.data;
        }),

    getWorkExperience: (authToken) =>
      axios
        .get(API_SERVER_URL + "/api/vu/user_cv/work_experience", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>get work exp ==> ", result);
          return result.data;
        }),

    editWorkExperience: (data, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/vu/user_cv/work_experience", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>edit work exp ==> ", result);
          return result.data;
        }),

    delWorkExperience: (data, authToken) =>
      axios
        .delete(API_SERVER_URL + "/api/vu/user_cv/work_experience", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          data: {
            workExperienceId: data.workExperienceId,
          },
        })
        .then((result) => {
          console.log("API ==>get by id work exp ==> ", result);
          return result.data;
        }),

    getWorkExperienceById: (dataId, authToken) =>
      axios
        .get(API_SERVER_URL + "/api/vu/user_cv/work_experience/" + dataId, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>edit work exp ==> ", result);
          return result.data;
        }),

    saveAwardNCertificate: (data, authToken) =>
      axios
        .post(API_SERVER_URL + "/api/vu/user_cv/certificate", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>save  awrad and certificate ==> ", result);
          return result.data;
        }),

    getAwardNCertificate: (authToken) =>
      axios
        .get(API_SERVER_URL + "/api/vu/user_cv/certificate", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>get awrad and certificate ==> ", result);
          return result.data;
        }),

    delAwardNCertificate: (data, authToken) =>
      axios
        .delete(API_SERVER_URL + "/api/vu/user_cv/certificate", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          data: {
            certficateId: data.certficateId,
          },
        })
        .then((result) => {
          console.log("API ==>delete awrad and certificate ==> ", result);
          return result.data;
        }),

    editAwardNCertificate: (data, authToken) =>
      axios
        .put(API_SERVER_URL + "/api/vu/user_cv/certificate", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>edit -save awrad and certificate ==> ", result);
          return result.data;
        }),

    getLanguageByLangName: (lang, authToken) =>
      axios
        .get(API_SERVER_URL + "/api/vu/user_cv/language/" + lang, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>eget language By language name  ==> ", result);
          return result.data;
        }),

    getLanguage: (authToken) =>
      axios
        .get(API_SERVER_URL + "/api/vu/user_cv/language", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>get language ==> ", result);
          return result.data;
        }),

    getLanguageByNameOption: (lang, authToken) =>
      axios
        .get(API_SERVER_URL + `/api/vu/user_cv/language/${lang}/options`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>get language name - option ==> ", result);
          return result.data;
        }),

    saveLanguageByNameOption: (data, authToken) =>
      axios
        .post(API_SERVER_URL + "/api/vu/user_cv/language", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>save language name - option ==> ", result);
          return result.data;
        }),

    deleLanguage: (data, authToken) =>
      axios
        .delete(API_SERVER_URL + "/api/vu/user_cv/language", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          data: {
            language: data.language,
          },
        })
        .then((result) => {
          console.log("API ==>selete language  ==> ", result);
          return result.data;
        }),

    isCvComplete: (authToken) =>
      axios
        .get(API_SERVER_URL + "/api/vu/user_cv/is_complete", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>get is CV complte  ==> ", result);
          return result.data;
        }),

    userCv: (authToken) =>
      axios
        .get(API_SERVER_URL + "/api/vu/user_cv/pdf", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>get generated cv  ==> ", result);
          return result.data;
        }),
  },

  activity: {
    activityLog: (data, authtoken) =>
      axios
        .post(API_SERVER_URL + "/api/gu/user_activity", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authtoken}`,
          },
        })
        .then((result) => {
          console.log("API => ACTIVITY LOGGED", result);
          return result;
        }),
  },

  feedback: {
    savefeedback: (data, authToken) =>
      axios
        .post(API_SERVER_URL + "/api/lu/activity_feedback", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API => RESULTT FEEDBACK SAVE", result);
          return result.data;
        }),
  },

  verification: {
    resendOTP: (email) =>
      axios
        .post(API_SERVER_URL + "/api/gu/auth/email/resend_otp", email, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${DEFAULT_JWT_TOKEN}`,
          },
        })
        .then((result) => {
          console.log("API ==> RESend OTP ==> ", result);
          return result.data;
        }),

    verifyOtp: (data, authtoken) =>
      axios
        .post(API_SERVER_URL + "/api/lu/auth/email/verify_otp", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authtoken}`,
          },
        })
        .then((result) => {
          console.log("API ==> Verify OTP ==> ", result);
          return result.data;
        }),
    verifyOtpSignUp: (data) =>
      axios
        .post(API_SERVER_URL + "/api/gu/auth/email/verify_otp", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${DEFAULT_JWT_TOKEN}`,
          },
        })
        .then((result) => {
          console.log("API ==> Verify OTP ==> ", result);
          return result.data;
        }),
    sendOtp: (data) =>
      axios
        .post(API_SERVER_URL + "/api/gu/auth/password/send_otp", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${DEFAULT_JWT_TOKEN}`,
          },
        })
        .then((result) => {
          console.log("API ==> Verify OTP ==> ", result);
          return result.data;
        }),
  },

  job: {
    allJob: () =>
      axios
        .get(API_SERVER_URL + "/api/gu/job/latest", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${DEFAULT_JWT_TOKEN}`,
          },
        })
        .then((result) => {
          console.log("API ==>Latest All Job ==> ", result);
          return result.data;
        }),

    fetchAllJob: (path, authToken) =>
      axios
        .get(API_SERVER_URL + `/api/${path}/job`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==> All Job ==> ", result);
          return result.data;
        }),

    isFav: (dataId, authToken) =>
      axios
        .post(API_SERVER_URL + "/api/vu/job/favorite/add", dataId, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==> fav job ==> ", result);
          return result.data;
        }),

    isFavRemove: (data, authToken) =>
      axios
        .delete(API_SERVER_URL + "/api/vu/job/favorite/remove", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          data: {
            jobId: data.jobId,
          },
        })
        .then((result) => {
          console.log("API ==> fav job remove ==> ", result);
          console.log("API ==> fav job remove DATa ==> ", result.data);
          return result.data;
        }),

    jobDetail: (jobId, authToken) =>
      axios
        .get(API_SERVER_URL + "/api/vu/job/" + jobId, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("Result = API => Job detail  ", result);
          return result.data;
        }),

    appliedJobList: (authToken) =>
      axios
        .get(API_SERVER_URL + "/api/lu/job/applied/list", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("Result = API =>Applied Job   ", result);
          return result.data;
        }),

    favJobList: (authToken) =>
      axios
        .get(API_SERVER_URL + "/api/lu/job/favorite/list", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("Result = API =>Applied Job   ", result);
          return result.data;
        }),

    allMaster: () =>
      axios
        .get(API_SERVER_URL + "/api/gu/get_all_masters_for_job_list_filter", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${DEFAULT_JWT_TOKEN}`,
          },
        })
        .then((result) => {
          console.log("Result = API =>mater api job list filter  ", result);
          return result.data;
        }),
  },

  // Event
  event: {
    allEventList: (path, authToken) =>
      axios
        .get(API_SERVER_URL + `/api/${path}/training/event`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==>All Event List ==> ", result);
          return result.data;
        }),

    getEventById: (id, authToken) =>
      axios
        .get(API_SERVER_URL + "/api/vu/training/event/" + id, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==> Event Details By Id ==> ", result);
          return result.data;
        }),

    allCategory: () =>
      axios
        .get(API_SERVER_URL + `/api/gu/event/category_with_count`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${DEFAULT_JWT_TOKEN}`,
          },
        })
        .then((result) => {
          console.log("Result = API => Event Category : ", result);
          return result.data;
        }),

    isFav: (dataId, authToken) =>
      axios
        .post(API_SERVER_URL + "/api/vu/training/event/favorite/add", dataId, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==> Fav Event  ==> ", result);
          return result.data;
        }),
    isFavRemove: (data, authToken) =>
      axios
        .delete(API_SERVER_URL + "/api/vu/training/event/favorite/remove", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          data: {
            TrainingEventId: data.TrainingEventId,
          },
        })
        .then((result) => {
          console.log("API ==> fav event remove ==> ", result);
          console.log("API ==> fav event remove DATA ==> ", result.data);
          return result.data;
        }),
  },

  training: {
    fetchAllTraining: (path, authToken) =>
      axios
        .get(API_SERVER_URL + `/api/${path}/training/online_video`, {
          // .get(API_SERVER_URL + `/api/vu/training/online_video`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==> All Training List ==> ", result);
          return result.data;
        }),
    // ID => getTrainingById
    getTrainingById: (id, authToken) =>
      axios
        .get(API_SERVER_URL + `/api/vu/training/online_video/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("API ==> All Training List ==> ", result);
          return result.data;
        }),

    allCategory: () =>
      axios
        .get(API_SERVER_URL + `/api/gu/training/category_with_count`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${DEFAULT_JWT_TOKEN}`,
          },
        })
        .then((result) => {
          console.log("Result = API => Training Category : ", result);
          return result.data;
        }),
    isFav: (dataId, authToken) =>
      axios
        .post(
          API_SERVER_URL + "/api/vu/training/online_video/favorite/add",
          dataId,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((result) => {
          console.log("API ==> Fav Training  ==> ", result);
          return result.data;
        }),
    isFavRemove: (data, authToken) =>
      axios
        .delete(
          API_SERVER_URL + "/api/vu/training/online_video/favorite/remove",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            data: {
              onlineVideoTrainingId: data.onlineVideoTrainingId,
            },
          }
        )
        .then((result) => {
          console.log("API ==> fav training remove ==> ", result);
          console.log("API ==> fav training remove DATA ==> ", result.data);
          return result.data;
        })
        .catch((error) => {
          console.log(data);
        }),
  },

  category: {
    allCategory: () =>
      axios
        .get(API_SERVER_URL + "/api/gu/job/category_with_count", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${DEFAULT_JWT_TOKEN}`,
          },
        })
        .then((result) => {
          console.log("Result = API => All category ", result);
          return result.data;
        }),
  },

  password: {
    passwordReset: (data) =>
      axios
        .post(API_SERVER_URL + "/api/gu/auth/password/reset", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${DEFAULT_JWT_TOKEN}`,
          },
        })
        .then((result) => {
          console.log("Result = API => All category ", result);
          return result.data;
        }),
  },

  jobApplication: {
    uploadCV: (data, authToken) =>
      axios
        .post(API_SERVER_URL + "/api/vu/upload/user/cv", data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("Result = API => CV  Upload ", result);
          return result.data;
        }),

    applyForJob: (data, authToken) =>
      axios
        .post(API_SERVER_URL + "/api/vu/job_application", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("Result = API => JOb APply ", result);
          return result.data;
        }),
  },

  rewardPoint: {
    dailyRewardPoint: (data, authToken) =>
      axios
        .post(API_SERVER_URL + "/api/lu/user_activity/rewardable", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((result) => {
          console.log("Result = API => DAily Reward ", result);
          return result.data;
        }),

    firstJobReward: (authToken) =>
      axios
        .get(
          API_SERVER_URL +
            "/api/vu/job_application/first_job_reward_request/status",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((result) => {
          console.log("Result = API => fisrt job Reward ", result);
          return result.data;
        }),
  },

  user: {
    updateProfile: async (data, authToken) => {
      let result = await axios.put(
        API_SERVER_URL + "/api/lu/user/profile",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      return result.data;
    },
  },
  crud: {
    getAllRecords: async (path, authToken) => {
      let result = await axios.get(API_SERVER_URL + path, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("Get Response received", result);
      return result.data;
    },

    protectedPostRequest: async (path, data, authToken) => {
      let result = await axios.post(API_SERVER_URL + path, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("post response recevied", result);
      return result.data;
    },
  },
};
