/* eslint-disable react-hooks/exhaustive-deps */
import Carousel from "react-bootstrap/Carousel";
import React, { useState, useEffect } from "react";
import "./OBCarousel.css";
import CarouselOverlay from "./CarouselOverlay";
import CustomButton from "../CustButton/CustomButton";
import { Row, Alert } from "reactstrap";
import { API_IMAGE_URL, API_SERVER_URL } from "../../app_constants";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Dropzone from "react-dropzone";
import Drawer from "react-bottom-drawer";
import Logout from "../../logOut/Logout";
import { useDispatch, useSelector } from "react-redux";
import api from "../../api";
import CVDropzone from "../../Component/dropZone/CVDropzone";
import CvViewer from "../../Component/forMobileCv/CvViewer";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import DoneIcon from "@material-ui/icons/Done";
import RewardPopup from "../RewardPopup";
import FeedbackPopup from "../feedback/FeedbackPopup";
import { DEFAULT_JWT_TOKEN } from "../../app_constants";
import { services } from "../../helpers/utilities";
import { ACTIVITY } from "../../helpers/ACTIVITY_CONSTANTS";

const imageMaxSize = 2097152; //bytes
const acceptedFileType = "application/pdf";
const acceptedFileTypeArray = acceptedFileType.split(",").map((item) => {
  return item.trim();
});

const OBCarousel = ({
  style,
  background,
  data = [],
  color = "#499cff",
  textColor = "#ffff",
}) => {
  const stylesheetOne = {
    backgroundColor: "#499cff",
    color: textColor,
    fontSize: "1.5rem",
    padding: "1rem 3rem 1rem 3rem",
    minWidth: "12rem",
    border: "none",
    borderRadius: "4px",
    ...style,
  };
  const stylesheetTwo = {
    backgroundColor: color,
    color: textColor,
    fontSize: "1.5rem",
    padding: "1rem 3rem 1rem 3rem",
    minWidth: "12rem",
    border: "none",
    borderRadius: "4px",
    background: background,
    ...style,
  };

  const [buttonName, setButtonName] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [detailClick, setDetailClick] = useState(false);
  const [applyClick, setApplyClick] = useState(false);
  const [jobId, setJobId] = useState();
  const [isPopUpShow, setIsPopUpShow] = useState(false);
  const [alertClr, setAlertClr] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [deviceType, setDeviceType] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [msgAlertClr, setMsgAlertClr] = useState("success");
  const [singleJob, setSingleJob] = useState([]);
  const [isViewCvClick, setIsViewCvClick] = useState(false);
  const [viewCVLink, setViewCVLink] = useState("");
  const [isCvComplete, setIsCvComplete] = useState(false);
  const [createdCvLink, setCreatedCvLink] = useState(null);
  const [createdCvId, setCreatedCvId] = useState(null);
  const [isBtnDisable, setIsBtnDisable] = useState(false);
  const [isRewardAllocate, setIsRewardAllocate] = useState(false);
  const [earnReward, setEarnReward] = useState(0);
  const [isFeedbackPopupOpen, setIsFeedbackPopupOpen] = useState(false);

  let profile = useSelector((state) => state.profileData);
  let auth = useSelector((state) => state.auth_token);

  const history = useHistory();
  const dispatch = useDispatch();

  // To check device(mobile or dekstop)
  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setDeviceType("Mobile");
    } else {
      setDeviceType("Desktop");
    }

    // check is CV compleyte
    auth &&
      api.onlineCV
        .isCvComplete(auth)
        .then((result) => {
          console.log("is CV comleted ", result);
          if (result.resultCode === 1) {
            setIsCvComplete(result.resultData.isComplete);
            setIsBtnDisable(result.resultData.enableGeneratePdfButton);
            setCreatedCvId(result.resultData.userCvDetails.profile.cvId);
            setCreatedCvLink(
              result.resultData.userCvDetails.profile.createdCvLink
            );
          }
        })
        .catch((error) => {
          console.log("EROROROROOROROROROR", error);
          if (
            !!error.response &&
            !!error.response.status &&
            error.response.status === 401
          ) {
            Logout(dispatch, history, false);
          }
          if (error.message === "Network Error") {
            setMessage("Please check your network connection!");
            setAlertClr("danger");
            setShowAlert(true);
          }
        });
  }, []);

  useEffect(() => {
    if (isRewardAllocate) {
      setTimeout(() => {
        setIsRewardAllocate(false);
      }, 6000);
    }
  }, [isRewardAllocate]);

  const showCvPdf = () => {
    api.onlineCV.userCv(auth).then((result) => {
      console.log("user CV ", result);
      if (result.resultCode === 1) {
        if (deviceType === "Mobile") {
          setViewCVLink(result.resultData.userCvProfile.createdCvLink);
          setIsViewCvClick(true);
        } else {
          window.open(
            API_SERVER_URL + result.resultData.userCvProfile.createdCvLink
          );
        }
      }
      setIsPopUpShow(false);
    });
  };

  const onClick = () => {
    if (profile.status === 1) {
      dispatch({ type: "setOtp_timer_value", OTP_Timer_value: 300000 });
      history.push({
        pathname: "/OTP",
        state: {
          status: "fromHome",
        },
      });
    } else if (profile.status === 0) {
      history.push("/signUp");
    }
  };

  useEffect(() => {
    if (successMsg) {
      setTimeout(() => {
        setSuccessMsg("");
      }, 3000);
    }
  }, [successMsg]);

  useEffect(async () => {
    if (applyClick) {
      services.statusCheck(profile.status) &&
        services.activityLogService(ACTIVITY.CLICK_APPLY_JOB, profile, jobId);

      console.log(profile);

      // 2- verified, 1- Non verified, 0- guest user
      if (profile.status === 1) {
        setShow(true);
        setMessage("You need to verify email first to apply to this job");
        setModalHeading("Email verification needed");
        setButtonName("Verify Email Now");
      } else if (profile.status === 0) {
        setShow(true);
        setMessage("You need to sign up first to apply to this job");
        setModalHeading("Sign up needed");
        setButtonName("Sign Up Now");
      } else {
        console.log("SINGLE JOB ", singleJob);
        if (!singleJob.isApplied) {
          setApplyClick(false);
          setIsPopUpShow(true);
          console.log("CLICK APPLY");

          console.log("profile ", profile);
        } else {
          console.log("ALREADY APPLIED");
          setSuccessMsg("Job is already applied");
          setMsgAlertClr("danger");
        }
      }
    }
  }, [applyClick]);

  const applyJob = async (CVLink) => {
    console.log("PROFILE DATA", profile);
    console.log("CV NEW CV ", CVLink);

    var data = {
      jobId: singleJob.id,
      cvLink: CVLink,
    };

    console.log("DTA ", data);
    setIsLoading(true);
    api.jobApplication
      .applyForJob(data, auth)
      .then((result) => {
        if (result.resultCode === 1) {
          if (
            result.resultData.rewardDetails &&
            result.resultData.rewardDetails.newEarnedRewards > 0
          ) {
            // setTimeout(() => {
            // setIsRewardAllocate(true);
            // setEarnReward(result.resultData.rewardDetails.newEarnedRewards);
            // }, 3000);
            setTimeout(() => {
              setSuccessMsg(
                "Your job application has been sent. Now you are one step closer to your dream job!"
              );
              setMsgAlertClr("success");
            }, 6000);

            if (
              result.resultData.feedbackDetails &&
              result.resultData.feedbackDetails.takeFeedback
            ) {
              setTimeout(() => {
                setIsFeedbackPopupOpen(true);
              }, 12000);
            }
          } else {
            setSuccessMsg(
              "Your job application has been sent. Now you are one step closer to your dream job!"
            );
            setMsgAlertClr("success");
            if (
              result.resultData.feedbackDetails &&
              result.resultData.feedbackDetails.takeFeedback
            ) {
              setTimeout(() => {
                setIsFeedbackPopupOpen(true);
              }, 4000);
            }
          }

          dispatch({
            type: "shouldeReloadJobList/save",
            shouldeReloadJobList: true,
          });
        } else {
          setMsgAlertClr("danger");
          setSuccessMsg(result.resultMsg);
        }
        setApplyClick(false);
        console.log("RESULT ", result);
      })
      .catch((error) => {
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      });
    window.scrollTo(0, 0);
  };

  const uploadeCVWithUploaded = (cv) => {
    console.log("CV NEW CV ", cv);
    setIsPopUpShow(false);
    // setCVLink(profile.cvLink)
    applyJob(cv);
  };

  const handleCVOnDrop = (files, rejectedFiles) => {
    console.log("rejectedFiles ....", rejectedFiles);
    if (rejectedFiles && rejectedFiles.length > 0) {
      switch (rejectedFiles[0].errors[0].code) {
        case "file-invalid-type":
          setMsgAlertClr("danger");
          setSuccessMsg("File type must be pdf");

          break;

        case "file-too-large":
          setMsgAlertClr("danger");
          setSuccessMsg("CV file size should be less than 2 MB");
          break;

        default:
          break;
      }
    }

    console.log("FILE ", files);
    if (rejectedFiles.length === 0) {
      const data = new FormData();
      data.append("cv", files[0]);
      console.log(data);
      console.log(files[0]);

      console.log(auth);
      setIsLoading(true);
      api.jobApplication
        .uploadCV(data, auth)
        .then((result) => {
          console.log("PATH CV PROFILE", result.resultData.profile);
          if (result.resultCode === 1) {
            setSuccessMsg("Your CV has been uploaded successfully");
            setMsgAlertClr("success");
            dispatch({
              type: "set_profile_data",
              profileData: result.resultData.profile,
            });
            dispatch({
              type: "shouldeReloadJobList/save",
              shouldeReloadJobList: true,
            });
            // setCVLink(result.resultData.profile.cvLink)
            setTimeout(() => {
              console.log(result.resultData.profile.cvLink);
              applyJob(result.resultData.profile.cvLink);
              // setCreatedCvLink(result.resultData.profile.cvLink)
            }, 3000);
          } else {
            setMsgAlertClr("danger");
            setSuccessMsg(result.resultMsg);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          if (
            !!error.response &&
            !!error.response.status &&
            error.response.status === 401
          ) {
            // Logout(dispatch, history, false);
          }
          if (error.message === "Network Error") {
            setMessage("Please check your network connection!");
            setAlertClr("danger");
            setShowAlert(true);
          }
        });
    }
    window.scrollTo(0, 0);
    setIsPopUpShow(false);
  };

  const onClose = React.useCallback(() => {
    setIsPopUpShow(false);
  }, []);

  return (
    <div>
      <CvViewer
        showCV={isViewCvClick}
        setShowCv={setIsViewCvClick}
        url={API_SERVER_URL + viewCVLink}
      />
      {isFeedbackPopupOpen && (
        <FeedbackPopup isFeedbackOpen={isFeedbackPopupOpen} />
      )}

      {deviceType === "Desktop" && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {deviceType === "Desktop" && (
              <Modal style={{ marginTop: "110px" }} show={isPopUpShow}>
                <Modal.Header className="CV-header">
                  <h5>Submit CV</h5>
                </Modal.Header>
                <Modal.Body>
                  <h5
                    className="mt-3"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    Create Your CV
                  </h5>
                  {isCvComplete ? (
                    <>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          id={isBtnDisable && "isDisable"}
                          disabled={isBtnDisable}
                          onClick={showCvPdf}
                          className="button-cv-style mt-3"
                        >
                          {" "}
                          View Created CV
                        </Button>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          className="button-cv-style mt-3"
                          onClick={() => {
                            history.push("/createCV");
                          }}
                        >
                          {" "}
                          Edit CV{" "}
                        </Button>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          className="button-cv-style-upload mt-3"
                          disabled={isBtnDisable}
                          id={isBtnDisable && "isDisable"}
                          onClick={() => uploadeCVWithUploaded(createdCvLink)}
                        >
                          {" "}
                          Apply With Created CV
                        </Button>
                      </div>
                    </>
                  ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        className="button-cv-style mt-3"
                        onClick={() => {
                          history.push("/createCV");
                        }}
                      >
                        {" "}
                        Create CV{" "}
                      </Button>
                    </div>
                  )}
                  {!createdCvId && (
                    <>
                      {/* <h6
                        className="mt-3"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        Earn 30 Reward Points
                      </h6>
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "-5px",
                        }}
                      >
                        by creating your CV with Opportunity Bank
                      </p> */}
                    </>
                  )}
                  <hr className="mb-1 hr-cv-style" />
                  <h5
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "-5px",
                    }}
                  >
                    Upload Your CV
                  </h5>
                  <div className="dropzone-div">
                    <Dropzone
                      onDrop={handleCVOnDrop}
                      className="dropzone-size"
                      multiple={false}
                      maxSize={imageMaxSize}
                      accept={acceptedFileType}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          className="for_sm_text"
                          {...getRootProps({ className: "dropzone" })}
                          style={{ marginTop: "2rem" }}
                        >
                          <section
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <input {...getInputProps()} />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {profile && profile.cvLink && (
                                <Button className="button-cv-style">
                                  Upload New CV{" "}
                                </Button>
                              )}
                            </div>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {profile && !profile.cvLink && (
                                <Button className="button-cv-style">
                                  Upload CV
                                </Button>
                              )}
                            </div>
                          </section>
                        </div>
                      )}
                    </Dropzone>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "-9px",
                        marginTop: "7px",
                      }}
                    >
                      <p>Supported file format: PDF</p>
                    </div>
                    <div
                      style={
                        profile && !profile.cvLink
                          ? {
                              marginBttom: "9rem",
                              display: "flex",
                              justifyContent: "center",
                            }
                          : { display: "flex", justifyContent: "center" }
                      }
                    >
                      <p>Maximum file size: 2 MB</p>
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {profile && profile.cvLink && (
                        <Button
                          className="button-cv-style mb-3"
                          href={profile && API_SERVER_URL + profile.cvLink}
                          target="_blank"
                          onClick={() => {
                            setIsPopUpShow(false);
                            // setIsViewCvClick(true)
                            // setViewCVLink(profile.cvLink)
                          }}
                        >
                          View CV
                        </Button>
                      )}
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {profile && profile.cvLink && (
                        <Button
                          className="button-cv-style-upload"
                          style={{ width: "auto" }}
                          onClick={() =>
                            uploadeCVWithUploaded(profile && profile.cvLink)
                          }
                        >
                          {" "}
                          Apply With Uploaded CV
                        </Button>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    style={{ backgroundColor: "#c5c4c4", color: "black" }}
                    onClick={() => {
                      setIsPopUpShow(false);
                      setApplyClick(false);
                    }}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </div>
        </>
      )}
      <Modal
        className="modal-style"
        onHide={() => {
          setShow(false);
          setDetailClick(false);
          setApplyClick(false);
        }}
        show={show}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <i
              class="fa fa-exclamation-triangle"
              aria-hidden="true"
              style={{ color: "#ffc107" }}
            ></i>{" "}
            {modalHeading}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={onClick}
            style={{
              cursor: "pointer",
              borderColor: "#5ba4fb",
              backgroundColor: "#5ba4fb",
            }}
          >
            {buttonName}
          </Button>
          <Button
            className="close-btn"
            // style={{backgroundColor:'#bbb6b6', border:'none', width:'500px'}}
            onClick={() => {
              setApplyClick(false);
              setDetailClick(false);
              setShow(false);
            }}
            style={{
              cursor: "pointer",
              backgroundColor: "#95989a",
              width: "100px",
              borderColor: "#95989a",
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {isRewardAllocate && earnReward > 0 && (
        <div className="reward-style-home">
          <div className="d-flex justify-content-center">
            <RewardPopup
              rewardMsg="Congrats! You've just earned your daily reward  for appying for 5 jobs today.Best wishes in your search for a dream job"
              isPopupOpen={isRewardAllocate}
              earnReward={earnReward}
              rewardStyle="reward-position"
            />
          </div>
        </div>
      )}

      <Carousel className="book-carousel">
        {data.slice(0, 5).map((item, idx) => {
          return (
            <Carousel.Item
              className="car-item"
              key={item.id}
              // style={{backgroundImage:`url(${item.imgsrc})`,height:"80vh",  backgroundRepeat:"no-repeat", backgroundAttachment:"contain",backgroundSize:"cover"}}
              interval={4000}
            >
              <Alert
                id="alert-position-carousel"
                isOpen={successMsg}
                color={msgAlertClr}
              >
                {successMsg}{" "}
              </Alert>

              <img
                loading
                className="d-block-desk"
                src={API_IMAGE_URL + item.imagePathBanner}
                alt="First slide"
              />
              <img
                loading
                className="d-block-mobile"
                src={API_IMAGE_URL + item.imagePathCard}
                alt="First slide"
              />
              {/* <CarouselOverlay title={item.title} /> */}
              <Carousel.Caption className="car-caption">
                <div className="ca-overlay">
                  <span
                  className="majorTitle-car mb-2"
                    style={{ color: "white", textAlign: "left" }}
                  >
                    {item.jobTitle}
                  </span>
                  <span className="minorTitle-car mb-2"  style={{ color: "white" }}>
                    <i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp;{" "}
                    {item.locationNmList.length === 1
                      ? item.locationNmList
                      : `${item.locationNmList[0]}.....`}
                  </span>
                  {/* <div style={{display:'inline-flex', justifyContent:'space-between', width:'20%'}} > */}
                  <div
                  // className="caption-date-row"
                  >
                    <span className="minorTitle-car mr-5 mb-2" style={{ color: "white" }}>
                      <i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;{" "}
                      {item.contractTypeNmList.length === 1
                        ? item.contractTypeNmList
                        : `${item.contractTypeNmList[0]}.....`}
                    </span>
                    <span className="minorTitle-car mb-2" style={{ color: "white" }}>
                      {/* <i class='far fa-calendar-alt'></i>{' '} */}
                      <i class="far fa-calendar"></i>&nbsp;{" "}
                      {moment(item.publishOn).format("MMMM DD, YYYY")}
                    </span>
                  </div>
                  <div
                    className=" p-0 mt-2"
                  >
                    {item.isApplied ? (
                      <Chip
                        avatar={
                          <Avatar>
                            <DoneIcon />
                          </Avatar>
                        }
                        label="Applied"
                        style={{
                          background: "transparent",
                          border: "1px solid white",
                          color: "white",
                          fontSize: "1.8rem",
                          padding: "2rem 1rem",
                        }}
                      />
                    ) : (
                      <Button
                        className="rect-btn"
                        onClick={() => {
                          setApplyClick(true);
                          setSingleJob(item);
                          console.log("iD ", item);
                          setJobId(item.id);
                        }}
                      >
                        Apply Now
                      </Button>
                    )}

                    <button
                      className="mybtn"
                      style={stylesheetTwo}
                      onClick={async () => {
                        let activityLogDetails = {
                          activityCode: "CLICK_JOB",
                          deviceFcmToken: "WEB_TKN_" + profile.email,
                          userId: profile.id,
                          entityType: "JOB",
                          entityId: item.id,
                          activityData: null,
                        };
                        try {
                          console.log(activityLogDetails);
                          let res = await api.activity.activityLog(
                            activityLogDetails,
                            DEFAULT_JWT_TOKEN
                          );
                          console.log("Activity Log Response", res);
                        } catch (err) {
                          console.log("Activity log err", err.response);
                        }

                        // console.log(status);
                        // 2- verified, 1- Non verified, 0- guest user
                        if (profile.status === 1) {
                          setShow(true);
                          setMessage(
                            "You need to verify email first to view the job details"
                          );
                          setModalHeading("Email verification needed");
                          setButtonName("Verify Email Now");
                        } else if (profile.status === 0) {
                          setShow(true);
                          setMessage(
                            "You need to sign up first to view the job details"
                          );
                          setModalHeading("Sign up needed");
                          setButtonName("Sign Up Now");
                        } else {
                          history.push({
                            pathname: "/jobDetail/" + item.id,
                          });
                          console.log("Job id", item);
                        }
                      }}
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </Carousel.Caption>
              {/* <div className="carousel-overlay"></div> */}
            </Carousel.Item>
          );
        })}
      </Carousel>

      {deviceType === "Mobile" && (
        <Drawer
          style={{ height: "70%", zIndex: "15" }}
          isVisible={isPopUpShow}
          onClose={onClose}
        >
          <h5 style={{ display: "flex", justifyContent: "center" }}>
            Create Your CV
          </h5>

          {isCvComplete ? (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  id={isBtnDisable && "isDisable"}
                  disabled={isBtnDisable}
                  onClick={() => {
                    showCvPdf();
                  }}
                  className="button-cv-style mt-3"
                >
                  {" "}
                  View Created CV
                </Button>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  className="button-cv-style mt-3"
                  onClick={() => {
                    history.push("/createCV");
                  }}
                >
                  {" "}
                  Edit CV{" "}
                </Button>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  className="button-cv-style-upload mt-3"
                  disabled={isBtnDisable}
                  id={isBtnDisable && "isDisable"}
                  onClick={() => uploadeCVWithUploaded(createdCvLink)}
                >
                  {" "}
                  Apply With Created CV
                </Button>
              </div>
            </>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className="button-cv-style mt-3"
                onClick={() => {
                  history.push("/createCV");
                }}
              >
                {" "}
                Create CV{" "}
              </Button>
            </div>
          )}
          {/* {!createdCvId && (
            <>
              <h6
                className="mt-3"
                style={{ display: "flex", justifyContent: "center" }}
              >
                Earn 30 Reward Points
              </h6>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "-1px",
                }}
              >
                by creating your CV with Opportunity Bank
              </p>
            </>
          )} */}
          <hr className="mb-1 hr-cv-style" />

          <h5 style={{ display: "flex", justifyContent: "center" }}>
            Upload Your CV
          </h5>
          <div className="dropzone-div">
            <Dropzone
              onDrop={handleCVOnDrop}
              className="dropzone-size"
              multiple={false}
              maxSize={imageMaxSize}
              accept={acceptedFileType}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  className="for_sm_text"
                  {...getRootProps({ className: "dropzone" })}
                >
                  <section
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <input {...getInputProps()} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {profile && profile.cvLink && (
                        <Button className="button-cv-style">
                          Upload New CV{" "}
                        </Button>
                      )}
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {profile && !profile.cvLink && (
                        <Button className="button-cv-style">Upload CV</Button>
                      )}
                    </div>
                  </section>
                </div>
              )}
            </Dropzone>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "-7px",
                marginTop: "10px",
              }}
            >
              <p>Supported file format: PDF</p>
            </div>
            <div
              style={
                profile && !profile.cvLink
                  ? {
                      marginBottom: "10rem",
                      display: "flex",
                      justifyContent: "center",
                    }
                  : { display: "flex", justifyContent: "center" }
              }
            >
              <p>Maximum file size: 2 MB</p>
            </div>
          </div>

          {/* <CVDropzone
           
            //  acceptedFileType={acceptedFileType} 
            handleCVOnDrop={handleCVOnDrop} /> */}

          {profile && profile.cvLink && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className="button-cv-style mb-3"
                // href={(profile && API_SERVER_URL + profile.cvLink)}
                onClick={() => {
                  setIsPopUpShow(false);
                  setIsViewCvClick(true);
                  setViewCVLink(profile.cvLink);
                }}
              >
                View CV
              </Button>
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "center" }}>
            {profile && profile.cvLink && (
              <Button
                className="button-cv-style-upload"
                style={{ marginBottom: "7rem", width: "auto" }}
                onClick={() => uploadeCVWithUploaded(profile && profile.cvLink)}
              >
                {" "}
                Apply With Uploaded CV
              </Button>
            )}
          </div>
        </Drawer>
      )}
    </div>
  );
};
export default OBCarousel;
