import React, { useEffect } from "react";
import { ObMobFrame } from "../../assets/svg";
import "./web-mob.css";
import { googlePlayDownload,appleDownload } from "../../assets";

const WebMobView = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div className="obwebmob">
      <div className="frame-img">
        <ObMobFrame className="frameimage"  />
      </div>
      <div className="about-app-link">
          <span className="appabout">
        Opportunity Bank is a HUB which connects ethnic minorities with
        resources such as job & network, upskilling training courses,
        scholarships as well as social & healthcare services in a one-stop, easy
        to access platform. Opportunity Bank includes a web portal (the one you
        are browsing) and an app.
        <br/>
        <br/>
         You can download the app here: 
          </span>
       <span>
           <img className="google-store-icon" src={googlePlayDownload}/>
           <img className="apple-store-icon" src={appleDownload}/>
       </span>
        
      </div>
    </div>
  );
};

export default WebMobView;
