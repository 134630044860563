import React, { useEffect, useState } from "react";
// import Navbar from '../../components/Navbar/Navbar'
import { Swiper, SwiperSlide } from "swiper/react";
import Cust_Swiper from "../../components/cust-swiper/Cust_Swiper";
import axios from "axios";
import api from "../../api";
import { useDispatch, useSelector } from "react-redux";
import OBCarousel from "../../components/OBCarousel/OBCarousel";
import Paragraph from "../../Component/Paragraph";
import HeaderName from "../../Component/HeaderName";
import GlanceSection from "./GlanceSection";
import { useHistory } from "react-router-dom";
import ObModal from "../../components/Modal/ObModal";
import { Modal, Button, Row } from "react-bootstrap";
import "./home.css";
import { DEFAULT_JWT_TOKEN } from "../../app_constants";
import Loader from "react-js-loader";
import { Alert, Col } from "reactstrap";
import Logout from "../../logOut/Logout";
import { useMediaQuery } from "react-responsive";
import trending from "../../assets/images/trending2.jpg";
import icon from "../../assets/images/responsive-design.png";
import jobnetworkicon from "../../assets/images/skills@1.5x.png";
import training from "../../assets/images/seminar.png";
import education from "../../assets/images/student.png";
import socialhealthcare from "../../assets/images/drug.png";
import usefulinfo from "../../assets/images/information-3.png";
import Testimonials from "../../components/Testimonial/Testimonials";
import google from "../../assets/brands/google-logo.png";
import airnb from "../../assets/brands/airnb.png";
import amazon from "../../assets/brands/amazon-logo.png";
import lenovo from "../../assets/brands/lenovo.png";
import paypal from "../../assets/brands/paypal.png";
import Fade from "react-reveal/Fade";
import AOS from "aos";
import "aos/dist/aos.css";
import moment from "moment";
import RewardPopup from "../../components/RewardPopup";
import { useViewVisibility } from "../../cust-hooks/usePageVisibility";
import { useAuthLevel } from "../../helpers/customHooks";
import { services as SERVICES } from "../../helpers/utilities";
import ArrowExpand from "../../components/arrow-expand/ArrowExpand";
import JumbotronPlaceHolder from "../../components/JumbotronPlaceHolder";
import TestimonialSlider from "../../components/TestimonialSliders/TestimonialSlider";
import { StaticData } from "../../helpers/staticData";
import { Cnon, Impact, Purviz, Sie } from "../../assets";
AOS.init();

const services = [
  {
    title: "Website & App",
    src: icon,
    url: "/webandmob",
  },
  {
    title: "Job",
    src: jobnetworkicon,
    url: "/joblist",
  },
  {
    title: "Training and Events",
    src: training,
    url: "/traininglist",
  },
  {
    title: "Scholarship",
    src: education,
    text: "educationModal",
  },
  {
    title: "Social & Healthcare",
    src: socialhealthcare,
    text: "socialModal",
  },
  {
    title: "Welfare Security Info",
    src: usefulinfo,
    text: "usefulModal",
  },
];

const brands = [lenovo, paypal, airnb, google, amazon];

export default function Home({
  setShowModal,
  setShowSocialModal,
  setShowUsefulModal,
  setClaimModalOpen,
}) {
  const [sData, setSData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alertClr, setAlertClr] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [deviceType, setDeviceType] = useState();
  const [isRewardAllocate, setIsRewardAllocate] = useState(false);
  const [rewardMsg, setRewardMsg] = useState("");
  const [earnReward, setEarnReward] = useState(0);

  const isXl = useMediaQuery({
    query: "(min-device-width: 1200px)",
  });
  const isL = useMediaQuery({ query: "(min-device-width: 1025px)" });
  const isMd = useMediaQuery({ query: "(min-width: 769px)" });
  const isSm = useMediaQuery({ query: "(min-device-width: 481px)" });
  const isXs = useMediaQuery({ query: "(min-device-width: 320px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  const viewVisibility = useViewVisibility();
  const history = useHistory();
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profileData);
  const auth_token = useSelector((state) => state.auth_token); // not needed

  // useEffect(() => {
  //   // Check if the pop-up has been shown today
  //   const lastPopupDate = localStorage.getItem("popupShownDate");

  //   if (
  //     (!lastPopupDate || lastPopupDate !== new Date().toLocaleDateString()) &&
  //     profileData.status === 2
  //   ) {
  //     // If the pop-up hasn't been shown today and status is 2, display it
  //     setClaimModalOpen(true);
  //     // Update localStorage to mark that the pop-up has been shown today
  //     localStorage.setItem("popupShownDate", new Date().toLocaleDateString());
  //   }
  // }, [profileData.status]);

  const authLevel = useAuthLevel(profileData);
  console.log("AUTH_LEVEL", authLevel);
  const shouldeReloadJobList = useSelector(
    (state) => state.shouldeReloadJobList
  );
  const isSessionExpired = useSelector((state) => state.isSessionExpired);
  const logOutClick = useSelector((state) => state.logOutClick);

  console.log("logout ", logOutClick);
  console.log("session ", isSessionExpired);
  if (
    !!history.location.state &&
    history.location.state.status === "fromSignIn"
  ) {
  }

  useEffect(() => {
    dispatch({ type: "setCurrentLink", currentLink: "/home" });

    window.scrollTo(0, 0);
    console.log("PROFILE STATUS 0?", SERVICES.statusCheck(profileData.status));
    latestJobList();
    getDailyReward();
    setTimeout(() => {
      getFirstJobReward();
    }, 4000);
  }, []);

  const getDailyReward = () => {
    console.log("PROFILE HOME ", profileData);
    // console.log("MOMENT DATE HOME", (moment(profile.lastLoginOn), 'day'))
    // console.log("MOMENT DATE HOME", (moment(), 'day'))
    console.log(
      "PROLE HOME ",
      profileData && !moment(profileData.lastLoginOn).isSame(moment(), "day")
    );

    if (
      !moment(profileData && profileData.lastLoginOn).isSame(moment(), "day") &&
      profileData &&
      profileData.status == 2
    ) {
      var data = {
        activityCode: "FIRST_APP_USE_OF_THE_DAY",
        entityType: null,
        entityId: null,
        activityData: null,
      };
      console.log("DATA REAWARD DAILY ", data);
      setIsLoading(true);
      api.rewardPoint
        .dailyRewardPoint(data, authLevel.auth_token)
        .then((result) => {
          console.log(" RESULT REAWARD DAILY ", result);
          if (result.resultCode === 1) {
            // setIsRewardAllocate(true);
            // setRewardMsg(
            //   "Congrats! You've just earned your daily reward for using the website today."
            // );
            // setEarnReward(result.resultData.rewardDetails.newEarnedRewards);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("error REAWARD DAILY", error);
          if (
            !!error.response &&
            !!error.response.status &&
            error.response.status === 401 &&
            SERVICES.statusCheck(profileData.status)
          ) {
            Logout(dispatch, history, false);
          }
          if (error.message === "Network Error") {
            setMessage("Please check your network connection!");
            setAlertClr("danger");
            setShowAlert(true);
          }
        });
    }
  };

  const getFirstJobReward = () => {
    setIsLoading(true);
    api.rewardPoint
      .firstJobReward(authLevel.auth_token)
      .then((result) => {
        console.log("RESULT REWARD Fisrt JOb ", result);
        if (result.resultCode === 1) {
          console.log("ALOCATRED IS ", isRewardAllocate);
          // setIsRewardAllocate(true);
          // setRewardMsg(
          //   "Congrats! You've just earned your reward for selection."
          // );
          // setEarnReward(
          //   result.resultData.firstJobRewardDetails.rewardDetails
          //     .newEarnedRewards
          // );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error REAWARD first job", error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401 &&
          SERVICES.statusCheck(profileData.status)
        ) {
          // Logout(dispatch, history, false);
          console.log("ERROR REASON", error.response, authLevel.auth_token);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      });
  };

  useEffect(() => {
    if (isRewardAllocate) {
      setTimeout(() => {
        setIsRewardAllocate(false);
      }, 4000);
    }
  }, [isRewardAllocate]);

  // useEffect(() => {
  //   console.log("Seession ", isSessionExpired);

  //   if (isSessionExpired === true) {
  //     setAlertClr("danger");
  //     setShowAlert(true);
  //     setMessage("Oops! Looks like your session has expired. Please sign in again");

  //   } else
  //     if (isSessionExpired === false) {
  //       setAlertClr("success");
  //       setShowAlert(true);
  //       setMessage("Logged out Successfully");
  //       // dispatch({ type: "isLogout/set", logOutClick: false });
  //     }
  //   setTimeout(() => {
  //     dispatch({ type: "isSessionExpired/set", isSessionExpired: '' });
  //   }, 3000);
  //   window.scrollTo(0, 0);
  //   console.log("Seession  ", isSessionExpired);
  // }, [isSessionExpired]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
        setMessage("");
        setAlertClr("");
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    latestJobList();
    dispatch({
      type: "shouldeReloadJobList/save",
      shouldeReloadJobList: false,
    });
  }, [shouldeReloadJobList]);

  const latestJobList = () => {
    console.log("profile auth ", profileData);
    console.log("path auth ", authLevel.path, " ", authLevel.auth_token);
    setIsLoading(true);
    api.job
      .fetchAllJob(authLevel.path, authLevel.auth_token)
      .then((result) => {
        console.log("JOB ", result);
        if (result.resultCode === 1) {
          setSData(result.resultData.jobList);
        } else {
          setSData([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("home error", error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401 &&
          SERVICES.statusCheck(profileData.status)
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      });
    // window.scrollTo(0, 0);
  };

  // useEffect(() => {
  //     setTimeout(() => {
  //         setApplyClick(false)
  //     }, 1000);
  // }, [applyClick, detailClick]);

  return (
    <React.Fragment>
      <Alert
        className="alert-position"
        style={{ marginBottom: "-1px" }}
        isOpen={showAlert}
        color={alertClr}
      >
        {message}{" "}
      </Alert>
      {isRewardAllocate && earnReward > 0 && (
        <div className="reward-style-home-daily">
          <div className="d-flex justify-content-center">
            <RewardPopup
              rewardMsg={rewardMsg}
              isPopupOpen={isRewardAllocate}
              earnReward={earnReward}
              rewardStyle="reward-position"
            />
          </div>
        </div>
      )}

      <OBCarousel
        data={sData}
        // setDetailClick={setDetailClick}
        // setApplyClick={setApplyClick}
        background="transparent"
        // jobId = {jobId}
      />
      {/* <JumbotronPlaceHolder>
      </JumbotronPlaceHolder> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          top: "200px",
          left: "50%",
        }}
      >
        {isLoading && (
          <Loader
            type="spinner-cub"
            bgColor={"rgb(53, 50, 50)"}
            title={"Loading..."}
            size={100}
          />
        )}
      </div>

      <ObModal message="message" show={true} />
      <div className="home-major">
        <div style={{ position: "relative" }} className="sections">
          <div style={{ paddingTop: "70px" }}></div>
          <Row className="d-flex justify-content-center p-0 m-0">
            <Col
              data-aos="fade-right"
              lg="5 p-5"
              className="block-left"
              style={{ alignItems: "start", justifyContent: "start" }}
            >
              {/* <Fade left> */}
              <h1 className="large-header m-0">
                Improving the lives of {isXl || isL || isMd ? <br /> : ""}
                Hong Kong ethnic minorities{isXl || isL || isMd ? (
                  <br />
                ) : (
                  ""
                )}{" "}
                by alleviating poverty {isXl || isL || isMd ? <br /> : ""}and
                enhancing social inclusion{isXl || isL || isMd ? <br /> : ""}
              </h1>
              {/* </Fade> */}
            </Col>
            <Col lg="5 p-5" className="block-right">
              <p data-aos="fade-left" className="description">
                Opportunity Bank is a HUB which connects ethnic minorities with
                resources such as job & network, upskilling training courses,
                scholarships as well as social & healthcare services in a
                one-stop, easy to access platform….
                <br />
                <br />
                <Row className="m-0 p-0">
                  <h4 style={{ fontWeight: 700, cursor: "pointer" }}>
                    Learn More about Opportunity Bank{" "}
                    <i class="fas fa-arrow-right"></i>
                  </h4>
                </Row>
              </p>
            </Col>
          </Row>

          <Row className="d-flex justify-content-center sections  p-0 m-0">
            <Col lg="10 p-5 m-0" className="block-left-sglance ">
              <h3
                data-aos="fade-up"
                className="large-header satsec"
                style={{ margin: "0" }}
              >
                Services at a glance
              </h3>

              <hr data-aos="fade-up" className="cust-underline" />

              <p
                data-aos="fade-up"
                className="description"
                style={{ margin: "0", marginTop: "20px" }}
              >
                {" "}
                Opportunity Bank seeks to push for greater transparency & higher
                accessibility to information.
              </p>
            </Col>
          </Row>
          <Row className=" d-flex justify-content-center mb-0 p-0 mr-0 ml-0 ">
            <Col xs="12" lg="8 p-0" className="d-flex">
              <Row
                className="m-0"
                style={{
                  padding: "0",
                  paddingBottom: "95px",
                  paddingTop: "40px",
                }}
              >
                {services.map((item) => {
                  return (
                    <Col
                      sm="6"
                      md="3"
                      lg="4 p-0 m-0 d-flex justify-content-center"
                    >
                      <div
                        data-aos="fade-up"
                        className="service-card"
                        onClick={() => {
                          if (item.text === "educationModal") {
                            setShowModal(true);
                          }
                          if (item.text === "socialModal") {
                            setShowSocialModal(true);
                          }
                          if (item.text === "usefulModal") {
                            setShowUsefulModal(true);
                          }
                          history.push(item.url);
                        }}
                      >
                        <img src={item.src} />
                        <h3 className="mt-5">{item.title}</h3>
                        {item.sub && (
                          <h3 style={{ fontSize: "small", color: "#78909c" }}>
                            {item.sub + ".."}
                          </h3>
                        )}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </div>
        <div
          className=" c2 sections"
          style={{
            padding: "0",
            paddingBottom: "90px",
            backgroundColor: "#F4F5F7",
          }}
        >
          {/* <div className="d-flex responsive-flex"> */}
          <div data-aos="fade-up">
            <Row className="d-flex justify-content-center  p-0 m-0">
              <Col sm="6" md="6" lg="5 p-5" className="block-left">
                <span>
                  <h3 className="large-header" data-aos="fade-up">
                    Recent Job Opportunities
                  </h3>

                  <hr className="cust-underline mb-5" />
                  <p data-aos="fade-up" className="description ">
                    Here are the new opportunities that we have discovered. Have
                    a look and see if you like them:
                  </p>
                </span>
              </Col>
              <Col
                sm="6"
                md="6"
                lg="5 p-5"
                className="block-right"
                style={{ alignItems: "flex-start", justifyContent: "flex-end" }}
              >
                <Button
                  className="mt-5 view-btn"
                  onClick={() => {
                    history.push({
                      pathname: "/jobList",
                    });
                    window.scrollTo(0, 0);
                  }}
                >
                  View All Jobs
                </Button>
              </Col>
            </Row>
          </div>

          <Cust_Swiper data-aos="fade-up" dataList={sData && sData} />
        </div>
        <div
          className="sections"
          style={{
            background: "white",
            paddingTop: "82px",
            paddingBottom: "82px",
          }}
        >
          <Row className="d-flex justify-content-center p-0 m-0">
            <Col
              className=" block-left"
              style={{ alignItems: "flex-start" }}
              lg="5 p-5"
              md="7"
            >
              <h3 className="large-header" data-aos="fade-up">
                Trending Resources and <br />
                Opportunities
              </h3>

              <hr className="cust-underline" />
              <h3
                data-aos="fade-up"
                className="description"
                style={{
                  color: "black",
                  fontWeight: "600",
                  marginTop: "30px",
                  fontSize: "large",
                }}
              >
                Better Education is the key to better quality of life and upward
                mobility for both the children and their families.
              </h3>

              <p data-aos="fade-up" className="description mt-1">
                To get your dream job or a better job, you may need to equip
                yourself with some more skills. We can offer career exploration,
                CV clinic, interview skills and other training activities.
                Schedule a call with us and let's explore together.
              </p>

              <Button
                className="mt-5 view-btn"
                onClick={() => {
                  history.push({
                    pathname: "/comingsoon",
                  });
                }}
              >
                View All Programs
              </Button>
            </Col>
            <Col
              className="block-right mt-5"
              md="5 p-5"
              lg="5 p-5 m-0"
              // style={{ overflowX: "hidden"}}
            >
              <img
                data-aos="fade-up"
                style={{ borderRadius: "2rem" }}
                src={trending}
              />

              {/* <img className="trending-img ml-5"src={trending}/> */}
            </Col>
          </Row>
        </div>
        <div
          className="d-flex flex-column align-items-center justify-content-center  "
          style={{
            background: "#FEF9F5",
            // paddingTop:"50px",
            // paddingBottom:"50px"
          }}
        >
          <h2
            data-aos="fade-up"
            className="large-header mt-5"
            style={{ lineHeight: "1" }}
          >
            You are in Good Hands
          </h2>

          <hr className="cust-underline mb-5" />
          <div data-aos="fade-up">
            <div className="home-testimonial-grp">
              <TestimonialSlider data={StaticData.testimonials} />
            </div>
          </div>

          <Button
            onClick={() => {
              history.push("/ob_impact");
              window.scrollTo(0, 0);
            }}
            className="view-btn mb-5 mt-5"
          >
            View All Testimonials
          </Button>
        </div>
        <Row className="branding m-0 p-3">
          <img src={Sie} alt="brand" className="brand" />
          <img src={Cnon} alt="brand" className="brand" />
          <img src={Purviz} alt="brand" className="brand" />
          <img src={Impact} alt="brand" className="brand" />
        </Row>
      </div>
      {/* <ObModal message="msg" show={true} modalHeading={modalHeading} /> */}
    </React.Fragment>
  );
}
