import React, { useState, useEffect } from 'react'
import './../AppliedJob/AppliedJob.css'
import moment from 'moment'
import { Card, Button } from 'react-bootstrap'
import whiteImg from '../../../assets/logo/white.png'
import { useDispatch, useSelector } from "react-redux";
import { Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Alert, ModalHeader, ModalBody, Input, ModalFooter } from 'reactstrap';
import contract from '../../../assets/logo/job_type1.png';
import axios from 'axios'
import Logout from '../../../logOut/Logout'
import Loader from "react-js-loader";
import Dropzone from 'react-dropzone'
import Drawer from "react-bottom-drawer";
// import { base64StringToBlob } from 'blob-util';
import { Document, Page, pdfjs } from 'react-pdf';
import api from '../../../api'
import { API_IMAGE_URL, API_SERVER_URL } from '../../../app_constants'
import CvViewer from '../../../Component/forMobileCv/CvViewer'
import RewardPopup from '../../../components/RewardPopup';
import FeedbackPopup from '../../../components/feedback/FeedbackPopup'

const imageMaxSize = 2097152; //bytes 
const acceptedFileType = 'application/pdf';
const acceptedFileTypeArray = acceptedFileType.split(',').map((item) => { return item.trim() });

const FavJobTrack = ({ favData = [], endPoint = 20, uploade }) => {

  console.log("END POINT ", endPoint)
  const profile = useSelector(state=>state.profileData)

  pdfjs.GlobalWorkerOptions.workerSrc =
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [favId, setFavId] = useState();
  const [applyId, setApplyId] = useState();
  const [buttonName, setButtonName] = useState('')
  const [message, setMessage] = useState('')
  const [successMsg, setSuccessMsg] = useState('');
  const [msgAlertClr, setMsgAlertClr] = useState('success');
  const [show, setShow] = useState(false)
  const [modalHeading, setModalHeading] = useState('');
  const [detailClick, setDetailClick] = useState(false);
  const [applyClick, setApplyClick] = useState(false);
  const [jobId, setJobId] = useState();
  const [alertClr, setAlertClr] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isLoading, setIsLoading] = useState('');
  const [singleJob, setSingleJob] = useState([]);
  const [isPopUpShow, setIsPopUpShow] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [deviceType, setDeviceType] = useState();
  const [selectedFile, setSelectedFile] = useState([]);
  const [isViewCvClick, setIsViewCvClick] = useState(false);
  const [viewCVLink, setViewCVLink] = useState('')
  const [numPages, setNumPages] = useState(null);
  const [isCvComplete, setIsCvComplete] = useState(false);
  const [createdCvLink, setCreatedCvLink] = useState(null);
  const [createdCvId, setCreatedCvId] = useState(null);
  const [isBtnDisable, setIsBtnDisable] = useState(false);
  const [isRewardAllocate, setIsRewardAllocate] = useState(false);
  const [earnReward, setEarnReward] = useState(0);
  const [isFeedbackPopupOpen, setIsFeedbackPopupOpen] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  // const scrollEnd = useSelector(state => state.scrollEnd);
  const userCV = useSelector(state => state.userCV);

  const auth = useSelector(state=>state.auth_token)


  // To check device(mobile or dekstop)
  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent)) {
      setDeviceType("Mobile");
    } else {
      setDeviceType("Desktop");
    }
    console.log("uploade userCV", userCV)

    // check is CV compleyte
    setIsLoading(true)
    api.onlineCV.isCvComplete(auth)
      .then(result => {
        console.log("is CV comleted ", result);
        if (result.resultCode === 1) {
          setIsCvComplete(result.resultData.isComplete)
          setIsBtnDisable(result.resultData.enableGeneratePdfButton)
          setCreatedCvId(result.resultData.userCvDetails.profile.cvId);
          setCreatedCvLink(result.resultData.userCvDetails.profile.createdCvLink)
        }
        setIsLoading(false)
      }).catch(error => {
        setIsLoading(false)
        console.log(error)
        if (!!error.response && !!error.response.status && error.response.status === 401) {
          Logout(dispatch, history, false);
        }
        if (error.message === 'Network Error') {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }

      })



  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setMessage("");
        setAlertClr("");
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert])


  useEffect(() => {
    if (isRewardAllocate) {
      setTimeout(() => {
        setIsRewardAllocate(false)
      }, 6000);
    }
  }, [isRewardAllocate])

  const short = (str, allowedLength = 10) => {
    if (str && str.length > allowedLength) {
      return str.slice(0, allowedLength) + "..."
    }
    return str
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    // console.log("SCROLL ", scrollEnd)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setSuccessMsg('');
      setMsgAlertClr("success")
    }, 3000)
  }, [successMsg])


  useEffect(() => {
    var status;
    if (profile) {
      status = profile.status;
    } else {
      status = 0;
    }

    if (applyClick) {
      console.log(profile);
      // 2- verified, 1- Non verified, 0- guest user
      console.log("SINGLE JOB ", singleJob)
      if (!singleJob.isApplied) {
        setApplyClick(false);
        setIsPopUpShow(true);
        console.log("CLICK APLY")

        console.log("profile ", profile)

      } else {
        setSuccessMsg("Job is already applied")
        setMsgAlertClr("danger")
      }
    }

  }, [applyClick, detailClick]);


  const handleAddRemoveFav = (c, idx) => {

    setIsLoading(true);

    setFavId(idx)
    setApplyId('')
    console.log("single job ", c)
    var status;
    var dataId = {
      jobId: c.id
    }

    if (profile) {
      status = profile.status;
    } else {
      status = 0
    }

    if (status === 1) {
      setShow(true);
      setMessage("Kindly verify your email first to add job in favorite list");
      setModalHeading("Email verification needed");
      setButtonName("Verify Email Now");
    } else if (status === 0) {
      setShow(true);
      setMessage("You need to sign up first to add the job to Favorites");
      setModalHeading("Sign up needed");
      setButtonName("Sign Up Now");
    }

    console.log(auth, " ", dataId)
    if (status === 2) {
      // if (!c.isFavorite) {
      //   api.job.isFav(dataId, auth).then(result => {
      //     console.log("IS FAV ", result);
      //     if (result.resultCode === 1) {
      //       setSuccessMsg("This job has been added as a Favorite")
      //       setMsgAlertClr("success")
      //       dispatch({ type: "shouldeReloadJobList/save", shouldeReloadJobList: true });
      //     }
      //     setIsLoading(false);
      //   }).catch(error => {
      //     setIsLoading(false);
      //     console.log(error)
      //     if (!!error.response && !!error.response.status && error.response.status === 401) {
      //       Logout(dispatch, history, false);
      //     }
      //     if (error.message === 'Network Error') {
      //       setMessage("Please check your network connection!");
      //       setAlertClr("danger");
      //       setShowAlert(true);
      //     }

      //   })
      // } else {


      // the above commented code is not neccessory because in fav list we have only fav job so we can not add unfav job from fav list we can remove only so belove code is for remove fav job from lsit
      api.job.isFavRemove(dataId, auth).then(result => {
        console.log("IS FAV REMOVE", result);
        if (result.resultCode === 1) {
          setSuccessMsg("This job has been removed from Favorite")
          setMsgAlertClr("success")
          setTimeout(() => {
            dispatch({ type: "shouldeReloadJobList/save", shouldeReloadJobList: true });
          }, 3000);
        }
        setIsLoading(false)
      }).catch(error => {
        setIsLoading(false)
        console.log(error)
        if (!!error.response && !!error.response.status && error.response.status === 401) {
          Logout(dispatch, history, false);
        }
        if (error.message === 'Network Error') {
          setAlertMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      })
      // }
    }
  }

  const applyJob = (CVLink) => {

    console.log("CV NEW CV ", CVLink);

    var data = {
      jobId: singleJob.id,
      cvLink: CVLink
    }
    console.log("DTA ", data);
    setIsLoading(true)
    api.jobApplication.applyForJob(data, auth)
      .then(result => {
        if (result.resultCode === 1) {
          if (result.resultData.rewardDetails && result.resultData.rewardDetails.newEarnedRewards > 0) {
            // setTimeout(() => {
            // setIsRewardAllocate(true);
            // setEarnReward(result.resultData.rewardDetails.newEarnedRewards)
            // }, 3000);
            setTimeout(() => {
              setSuccessMsg("Your job application has been sent. Now you are one step closer to your dream job!")
              setMsgAlertClr("success")
            }, 6000);
            if (result.resultData.feedbackDetails && result.resultData.feedbackDetails.takeFeedback) {
              setTimeout(() => {
                setIsFeedbackPopupOpen(true);
              }, 12000);
            }
          } else {
            setSuccessMsg("Your job application has been sent. Now you are one step closer to your dream job!")
            setMsgAlertClr("success");
            if (result.resultData.feedbackDetails && result.resultData.feedbackDetails.takeFeedback) {
              setTimeout(() => {
                setIsFeedbackPopupOpen(true);
              }, 4000);
            }
          }

          dispatch({ type: "shouldeReloadJobList/save", shouldeReloadJobList: true });
        } else {
          setMsgAlertClr("danger")
          setSuccessMsg(result.resultMsg)
        }
        setApplyClick(false);
        setIsLoading(false)
        console.log("RESULT ", result)
      }).catch(error => {
        setIsLoading(false)
        console.log(error)
        if (!!error.response && !!error.response.status && error.response.status === 401) {
          Logout(dispatch, history, false);

        }
        if (error.message === 'Network Error') {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      })
  }

  const uploadeCVWithUploaded = (cv) => {
    console.log("CV NEW CV ", cv)
    setIsPopUpShow(false);
    // setCVLink(cv)
    applyJob(cv);
  }

  const handleCVOnDrop = (files, rejectedFiles) => {
    console.log("rejectedFiles ....", rejectedFiles);
    if (rejectedFiles && rejectedFiles.length > 0) {
      switch (rejectedFiles[0].errors[0].code) {
        case "file-invalid-type":
          setMsgAlertClr("danger");
          setSuccessMsg("File type must be pdf");

          break;

        case "file-too-large":
          setMsgAlertClr("danger");
          setSuccessMsg("File too large, maximum size of file must be 2 MB");
          break;

        default:
          break;
      }
    }

    console.log("FILE ", files)
    setSelectedFile(files[0])

    if (!rejectedFiles.length > 0) {
      const data = new FormData()
      data.append('cv', files[0])
      console.log("REJECT FILE _  LENGTH")
      console.log(files[0])

      console.log(auth);
      setIsLoading(true)
      api.jobApplication.uploadCV(data, auth).then(result => {
        console.log("PATH CV PROFILE", result.resultData.profile);
        if (result.resultCode === 1) {
          setSuccessMsg("Your CV has been uploaded successfully")
          setMsgAlertClr("success")
          dispatch({type:"set_profile_data",profileData:result.resultData.profile})
          dispatch({ type: "shouldeReloadJobList/save", shouldeReloadJobList: true });
          // setCVLink(result.resultData.profile.cvLink)
          setTimeout(() => {
            applyJob(result.resultData.profile.cvLink);
          }, 3000);

        } else {
          setMsgAlertClr("danger")
          setSuccessMsg(result.resultMsg)
        }
        setIsLoading(false)
      }).catch(error => {
        setIsLoading(false)
        console.log(error)
        if (!!error.response && !!error.response.status && error.response.status === 401) {
          Logout(dispatch, history, false);

        }
        if (error.message === 'Network Error') {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      })
      console.log("CV NEW CV ", profile.cvLink);

    }

    setIsPopUpShow(false)
  }

  const onClose = React.useCallback(() => {
    setIsPopUpShow(false);
  }, []);

  const newCvUpload = (data) => {
    console.log("NEW CV UPL ", data)
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setIsViewCvClick(false)
    console.log("PDF ", viewCVLink)
  }

  const showCvPdf = () => {

    api.onlineCV.userCv(auth)
      .then(result => {
        console.log("user CV ", result);
        if (result.resultCode === 1) {
          if (deviceType === "Mobile") {
            setViewCVLink((result.resultData.userCvProfile.createdCvLink))
            setIsViewCvClick(true)
          } else {
            window.open(API_SERVER_URL + result.resultData.userCvProfile.createdCvLink)
          }
        }
        setIsPopUpShow(false);
      })

  }

  return (
    <React.Fragment>

      <Alert className='alert-position' isOpen={showAlert} color={alertClr}>{alertMessage} </Alert>
      <CvViewer showCV={isViewCvClick} setShowCv={setIsViewCvClick} url={API_SERVER_URL + viewCVLink} />
      {isFeedbackPopupOpen &&
        <FeedbackPopup isFeedbackOpen={isFeedbackPopupOpen} />
      }
      {deviceType === "Mobile" &&
        <Drawer style={{ height: '70%' }}
          isVisible={isPopUpShow}
          onClose={onClose}
        >
          <h5 style={{ display: 'flex', justifyContent: 'center' }}>Create Your CV</h5>

          {isCvComplete ?
            <>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  id={isBtnDisable && "isDisable"}
                  disabled={isBtnDisable}
                  onClick={() => {
                    showCvPdf()
                  }}
                  className="button-cv-style mt-3" > View Created CV</Button>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button className="button-cv-style mt-3" onClick={() => {
                  history.push("/createCV")
                }}> Edit CV </Button>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', }}>
                <Button className="button-cv-style-upload mt-3"
                  disabled={isBtnDisable}
                  id={isBtnDisable && "isDisable"}
                  onClick={() => uploadeCVWithUploaded(createdCvLink)}> Apply With Created CV
                </Button>
              </div>
            </>

            :
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button className="button-cv-style mt-3" onClick={() => {
                history.push("/createCV")
              }}> Create CV </Button>
            </div>
          }
          {/* {(!createdCvId) &&
            <>
              <h6 className="mt-3" style={{ display: 'flex', justifyContent: 'center' }}>
                Earn 30 Reward Points
              </h6>
              <p style={{ display: 'flex', justifyContent: 'center', marginBottom: '-1px' }}>by creating your CV with Opportunity Bank</p>
            </>
          } */}
          <hr className="mb-1 hr-cv-style" />

          <h5 style={{ display: 'flex', justifyContent: 'center' }}>Upload Your CV</h5>
          <div className="dropzone-div">
            <Dropzone
              onDrop={handleCVOnDrop} className="dropzone-size"
              multiple={false} maxSize={imageMaxSize}
              accept={acceptedFileType}
            >
              {({ getRootProps, getInputProps }) => (

                <div className='for_sm_text' {...getRootProps({ className: 'dropzone' })} >

                  <section style={{ display: 'flex', justifyContent: 'center' }}>
                    <input {...getInputProps()} />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {(profile && profile.cvLink) &&
                        <Button className="button-cv-style" >Upload New CV </Button>
                      }
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {(profile && !profile.cvLink) &&
                        <Button className="button-cv-style" onClick={() => {
                          // setUploadForFirstTime(true)
                        }}>Upload CV</Button>
                      }
                    </div>
                  </section>
                </div>
              )}
            </Dropzone>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '-7px', marginTop: '10px' }}>
              <p>Supported file format: PDF</p>
            </div>
            <div style={(profile && !profile.cvLink) ?
              { marginBttom: '10rem', display: 'flex', justifyContent: 'center' }
              : { display: 'flex', justifyContent: 'center' }}>
              <p>Maximum file size: 2 MB</p>
            </div>

            {(profile && profile.cvLink) &&
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button className="button-cv-style mb-3"
                  // href={(profile && API_SERVER_URL + profile.cvLink)}
                  onClick={() => {
                    console.log("VIEW CV CLICK")
                    setIsPopUpShow(false);
                    setViewCVLink(profile.cvLink)
                    setIsViewCvClick(true)
                  }}>
                  View CV
                </Button>
              </div>
            }

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {(profile && profile.cvLink) &&
                <Button className="button-cv-style-upload" style={{ marginBottom: '7rem', width: 'auto' }}
                  onClick={() => uploadeCVWithUploaded(profile && profile.cvLink)}> Apply With Uploaded CV</Button>
              }
            </div>

          </div>

        </Drawer>
      }
      {favData.length > 0 ?
        favData.slice(0, endPoint).map((c, idx) => {
          return (

            <div className="in-single-line">

              {/* <div className="box-cart" > */}
              <Card className='card-style p-1 mr-3'>
                <Card.Body className="card-box" >

                  <div
                    style={{ cursor: 'auto' }}
                    onClick={() => {
                      setDetailClick(true);
                      setJobId(c.id);
                      // dispatch({ type: 'scrollEnd/save', scrollEnd: (window.pageYOffset) })
                      // console.log("SCROLLLLw", window.pageYOffset)
                    }}>

                    <div className="image-card">
                      <img style={{ width: '5rem', height: '7rem' }} src={c.categoryIconPath ? (API_IMAGE_URL + c.categoryIconPath) : whiteImg} />
                      <div className="title-loc">
                        <h5>
                          {short(c.jobTitle, 21)}
                        </h5>
                        <p>
                          <i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp; {' '}
                          {c.locationNmList.length > 1 ? short(c.locationNmList[0], 12) + "..." : short(c.locationNmList[0])}
                        </p>
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {((idx === favId) && isLoading) || ((idx === applyId) && isLoading) &&
                        < Loader type="spinner-cub" bgColor={"rgb(53, 50, 50)"} title={"Loading..."} size={100} />
                      }
                    </div>


                    <p style={{ marginTop: '1.5%' }}>
                      {short(c.categoryName, 21)}
                    </p>
                    <p style={{ marginTop: '1%' }}>

                      {short(c.oneLinerDescription, 31)}
                    </p>

                    <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h6>
                        {'$ ' + short(c.compensation, 21)}
                      </h6>
                      <h6>
                        <img src={contract} style={{ width: '1.6rem' }} />{' '}
                        {c.contractTypeNmList.length > 1 ? short(c.contractTypeNmList[0], 11) + "..." : short(c.contractTypeNmList[0])}
                      </h6>
                    </div>

                  </div>
                  <div className="d-flex mt-3 mb-3">
                    <Button
                      style={c.isApplied ? { backgroundColor: 'transparent', borderColor: '#499cff', color: '#499cff', marginLeft: '5px' }
                        : { backgroundColor: '', borderColor: '', color: '' }}
                      className="apply-btn-style"
                      onClick={() => {
                        setApplyClick(true);
                        setApplyId(idx);
                        setFavId('')
                        setSingleJob(c)
                        console.log("iD ", idx)
                        setJobId(c.id)
                      }}>{c.isApplied ? "Applied" : "Apply Now"}</Button>
                  </div>


                  {(idx === applyId) &&
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {deviceType === "Desktop" &&
                        <Modal style={{ marginTop: '110px' }} show={isPopUpShow} >
                          <Modal.Header className="CV-header" >
                            <h5>
                              Submit CV
                            </h5>
                          </Modal.Header>
                          <Modal.Body>
                            <h5 style={{ display: 'flex', justifyContent: 'center' }}>Create Your CV</h5>

                            {isCvComplete ?
                              <>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <Button
                                    id={isBtnDisable && "isDisable"}
                                    disabled={isBtnDisable}
                                    onClick={showCvPdf}
                                    className="button-cv-style mt-3" > View Created CV</Button>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <Button className="button-cv-style mt-3" onClick={() => {
                                    history.push("/createCV")
                                  }}> Edit CV </Button>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', }}>
                                  <Button className="button-cv-style-upload mt-3"
                                    disabled={isBtnDisable}
                                    id={isBtnDisable && "isDisable"}
                                    onClick={() => uploadeCVWithUploaded(createdCvLink)}> Apply With Created CV
                                  </Button>
                                </div>
                              </>

                              :
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button className="button-cv-style mt-3" onClick={() => {
                                  history.push("/createCV")
                                }}> Create CV </Button>
                              </div>
                            }
                            {/* {(!createdCvId) &&
                              <>
                                <h6 className="mt-3" style={{ display: 'flex', justifyContent: 'center' }}>
                                  Earn 30 Reward Points
                                </h6>
                                <p style={{ display: 'flex', justifyContent: 'center', marginTop: '-5px' }}>by creating your CV with Opportunity Bank</p>
                              </>
                            } */}
                            <hr className="mb-1 hr-cv-style" />
                            <h5 style={{ display: 'flex', justifyContent: 'center' }}>Upload Your CV</h5>
                            <div className="dropzone-div">
                              <Dropzone
                                onDrop={handleCVOnDrop} className="dropzone-size"
                                multiple={false} maxSize={imageMaxSize}
                                accept={acceptedFileType}
                              >
                                {({ getRootProps, getInputProps }) => (

                                  <div className='for_sm_text' {...getRootProps({ className: 'dropzone' })}>

                                    <section style={{ display: 'flex', justifyContent: 'center' }}>
                                      <input {...getInputProps()} />
                                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        {(profile && profile.cvLink) &&
                                          <Button className="button-cv-style" >Upload New CV </Button>
                                        }
                                      </div>

                                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        {(profile && !profile.cvLink) &&
                                          <Button className="button-cv-style" >Upload CV</Button>
                                        }
                                      </div>
                                    </section>
                                  </div>
                                )}
                              </Dropzone>
                              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '-10px', marginTop: '10px' }}>
                                <p>Supported file format: PDF</p>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <p>Maximum file size: 2 MB</p>
                              </div>

                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {(profile && profile.cvLink) &&
                                  <Button className="button-cv-style mb-3"
                                    href={(profile && API_SERVER_URL + profile.cvLink)} target='_blank'
                                    onClick={() => {
                                      console.log("VIEW CV CLICK")
                                      setIsPopUpShow(false);
                                      // setViewCVLink(profile.cvLink)
                                      // setIsViewCvClick(true)
                                    }}
                                  >
                                    View CV
                                  </Button>
                                }
                              </div>

                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {(profile && profile.cvLink) &&
                                  <Button className="button-cv-style-upload"
                                    style={{ width: 'auto' }}
                                    onClick={() => uploadeCVWithUploaded(profile && profile.cvLink)}> Apply With Uploaded CV</Button>
                                }
                              </div>

                            </div>

                          </Modal.Body>
                          <Modal.Footer >

                            <Button
                              style={{ backgroundColor: '#c5c4c4', color: 'black' }} onClick={() => {
                                setIsPopUpShow(false);
                                setApplyClick(false)
                              }}>Close
                            </Button>

                          </Modal.Footer>
                        </Modal>
                      }
                    </div>
                  }
                </Card.Body>
                {/* <hr style={{ width: '90%', display: 'flex', justifyContent: 'center' }} /> */}
                <Card.Footer>
                  <div className="row" style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <div style={{ marginRight: '40%' }}>
                      <p style={{ marginRight: '-12px' }}>
                        {/* <i class='far fa-calendar'></i>&nbsp;{' '} */}
                        {' '}<span style={{ fontStyle: 'italic' }}>Posted On</span>{' '}
                        {moment(c.publishOn).format('DD-MM-YYYY')}
                      </p>
                    </div>
                    <div >
                      <Alert className='alert-position'
                        // style={{ position: 'absolute', bottom: '0.3rem', right: '5rem', width: '12pc' }}
                        style={{ position: 'absolute', top: '-13rem', left: '-21rem', width: '12pc' }}
                        isOpen={(successMsg && idx === favId) || (successMsg && idx === applyId)}
                        color={msgAlertClr}
                      >{successMsg} </Alert>
                      {((isRewardAllocate && earnReward > 0) && (idx === applyId)) &&
                        < div className="reward-style">
                          <div className="d-flex justify-content-center">
                            <RewardPopup
                              rewardMsg="Congrats! You've just earned your daily reward for appying for 5 jobs today. Best wishes in your search for a dream job"
                              isPopupOpen={isRewardAllocate} earnReward={earnReward} rewardStyle="reward-position" from="list-job" />
                          </div>
                        </div>
                      }

                      <i class="fa fa-star" aria-hidden="true" value={c.id}
                        style={(c.isFavorite) ?
                          { color: "#499cff", fontSize: '2rem', cursor: 'pointer' }
                          :
                          { color: 'black', fontSize: '2rem', cursor: 'pointer' }}
                        onClick={() => handleAddRemoveFav(c, idx)}
                      // onClick={() => favIcon(c, idx)}
                      ></i>
                      {/* <i class="fa fa-ellipsis-h" aria-hidden="true" style={{ fontSize: '2rem' }}></i> */}
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </div>
          )
        })

        :
        <div className="noList">
          <h3 >No, Favorite Job Found </h3>
        </div>
      }
    </React.Fragment >
  )
}

export default FavJobTrack;