/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./JobCard.css";
import moment from "moment";
import { Card, Button, Modal } from "react-bootstrap";
import {
  API_IMAGE_URL,
  API_SERVER_URL,
  DEFAULT_JWT_TOKEN,
} from "../../app_constants";
import api from "../../api";
import whiteImg from "../../assets/logo/white.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Alert, ModalHeader, ModalBody, Input, ModalFooter } from "reactstrap";
import contract from "../../assets/logo/job_type1.png";
import axios from "axios";
import Logout from "../../logOut/Logout";
import Loader from "react-js-loader";
import Dropzone from "react-dropzone";
import { useDropzone } from "react-dropzone";
import Drawer from "react-bottom-drawer";
// import { base64StringToBlob } from 'blob-util';
// import { Document, Page, pdfjs } from 'react-pdf';
import CvViewer from "../../Component/forMobileCv/CvViewer";
import { defaultJobIcon } from "../../assets";
import RewardPopup from "../RewardPopup";
import FeedbackPopup from "../feedback/FeedbackPopup";
import CustDialog from "../cust-dialog/CustDialog";
import { services } from "../../helpers/utilities";
import { ACTIVITY } from "../../helpers/ACTIVITY_CONSTANTS";
import RMJobCard from "../job-card/JobCard";
import zIndex from "@material-ui/core/styles/zIndex";

const imageMaxSize = 2097152; //bytes
const acceptedFileType = "application/pdf";
const acceptedFileTypeArray = acceptedFileType.split(",").map((item) => {
  return item.trim();
});

const JobCard = ({ data = [], endPoint, setEndPoint }) => {
  // var profile = JSON.parse(localStorage.getItem("profile"))
  let profile = useSelector((state) => state.profileData);
  const dispatch = useDispatch();

  const [favIdx, setFavIdx] = useState();
  const [jobData, setJobData] = useState();
  const [applyId, setApplyId] = useState();
  const [buttonName, setButtonName] = useState("");
  const [message, setMessage] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [msgAlertClr, setMsgAlertClr] = useState("success");
  const [show, setShow] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [detailClick, setDetailClick] = useState(false);
  const [applyClick, setApplyClick] = useState(false);
  const [jobId, setJobId] = useState();
  const [alertClr, setAlertClr] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [singleJob, setSingleJob] = useState([]);
  const [isPopUpShow, setIsPopUpShow] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [deviceType, setDeviceType] = useState();
  const [selectedFile, setSelectedFile] = useState([]);
  const [isViewCvClick, setIsViewCvClick] = useState(false);
  const [viewCVLink, setViewCVLink] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [isCvComplete, setIsCvComplete] = useState(false);
  const [isBtnDisable, setIsBtnDisable] = useState(false);
  const [createdCvLink, setCreatedCvLink] = useState(null);
  const [createdCvId, setCreatedCvId] = useState(null);
  const [isRewardAllocate, setIsRewardAllocate] = useState(false);
  const [isFeedbackPopupOpen, setIsFeedbackPopupOpen] = useState(false);
  const [earnReward, setEarnReward] = useState(0);
  const [dialogStatus, setDialogStatus] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  const history = useHistory();
  // const scrollEnd = useSelector(state => state.scrollEnd);
  const userCV = useSelector((state) => state.userCV);

  // var auth = JSON.parse(localStorage.getItem("userAuth"));
  let auth = useSelector((state) => state.auth_token);

  // To check device(mobile or dekstop)
  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setDeviceType("Mobile");
    } else {
      setDeviceType("Desktop");
    }
    console.log("uploade userCV", userCV);
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setMessage("");
        setAlertClr("");
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (isRewardAllocate) {
      setTimeout(() => {
        setIsRewardAllocate(false);
      }, 6000);
    }
    console.log("isALocated", isRewardAllocate);
  }, [isRewardAllocate]);

  const short = (str, allowedLength = 10) => {
    if (str && str.length > allowedLength) {
      return str.slice(0, allowedLength) + "...";
    }
    return str;
  };

  useEffect(() => {
    // window.scrollTo(0, 0)

    auth &&
      api.onlineCV
        .isCvComplete(auth)
        .then((result) => {
          console.log(
            "is CV comleted ",
            result.resultData.userCvDetails.profile.createdCvLink
          );
          if (result.resultCode === 1) {
            setIsCvComplete(result.resultData.isComplete);
            setIsBtnDisable(result.resultData.enableGeneratePdfButton);
            setCreatedCvId(result.resultData.userCvDetails.profile.cvId);
            setCreatedCvLink(
              result.resultData.userCvDetails.profile.createdCvLink
            );
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          if (
            !!error.response &&
            !!error.response.status &&
            error.response.status === 401
          ) {
            Logout(dispatch, history, false);
          }
          if (error.message === "Network Error") {
            setMessage("Please check your network connection!");
            setAlertClr("danger");
            setShowAlert(true);
          }
        });
  }, []);

  useEffect(() => {
    if (successMsg) {
      setTimeout(() => {
        setSuccessMsg("");
        setMsgAlertClr("success");
      }, 3000);
    }
  }, [successMsg]);

  const onClick = () => {
    if (profile.status === 1) {
      dispatch({ type: "setOtp_timer_value", OTP_Timer_value: 300000 });
      history.push({
        pathname: "/OTP",
        state: {
          status: "fromJoblist",
        },
      });
    } else if (profile.status === 0) {
      history.push("/signUp");
    }
  };

  useEffect(async () => {
    if (applyClick) {
      let activityLogDetails = {
        activityCode: "CLICK_APPLY_JOB",
        deviceFcmToken: "WEB_TKN_" + profile.email,
        userId: profile.id,
        entityType: "JOB",
        entityId: jobId,
        activityData: null,
      };

      try {
        console.log(activityLogDetails);
        let res = await api.activity.activityLog(
          activityLogDetails,
          DEFAULT_JWT_TOKEN
        );
        console.log("Activity Log Response", res);
      } catch (err) {
        console.log("Activity log err", err.response);
      }
      console.log(profile);
      // 2- verified, 1- Non verified, 0- guest user
      if (profile.status === 1) {
        setShow(true);
        setMessage("You need to verify email first to apply to this job");
        setModalHeading("Email verification needed");
        setButtonName("Verify Email Now");
      } else if (profile.status === 0) {
        setShow(true);
        setMessage("You need to sign up first to apply to this job");
        setModalHeading("Sign up needed");
        setButtonName("Sign Up Now");
      } else {
        console.log("SINGLE JOB ", singleJob);
        if (!singleJob.isApplied) {
          setApplyClick(false);
          setIsPopUpShow(true);
          console.log("CLICK APLY");

          console.log("profile ", profile);
        } else {
          setSuccessMsg("Job is already applied");
          setMsgAlertClr("danger");
        }
      }
    }

    if (detailClick) {
      let activityLogDetails = {
        activityCode: "CLICK_JOB",
        deviceFcmToken: "WEB_TKN_" + profile.email,
        userId: profile.id,
        entityType: "JOB",
        entityId: jobId,
        activityData: null,
      };

      try {
        console.log(activityLogDetails);
        let res = await api.activity.activityLog(
          activityLogDetails,
          DEFAULT_JWT_TOKEN
        );
        console.log("Activity Log Response", res);
      } catch (err) {
        console.log("Activity log err", err.response);
      }
      // console.log(status);
      // 2- verified, 1- Non verified, 0- guest user
      if (profile.status === 1) {
        setShow(true);
        setMessage("You need to verify email first to view the job details");
        setModalHeading("Email verification needed");
        setButtonName("Verify Email Now");
      } else if (profile.status === 0) {
        setShow(true);
        setMessage("You need to sign up first to view the job details ");
        setModalHeading("Sign up needed");
        setButtonName("Sign Up Now");
      } else {
        // history.push("/jobDetail");

        history.push({
          pathname: "/jobDetail/" + jobId,
        });
      }
    }
  }, [applyClick, detailClick]);

  const showCvPdf = () => {
    api.onlineCV.userCv(auth).then((result) => {
      console.log("user CV ", result);
      if (result.resultCode === 1) {
        if (deviceType === "Mobile") {
          setViewCVLink(result.resultData.userCvProfile.createdCvLink);
          setIsViewCvClick(true);
        } else {
          window.open(
            API_SERVER_URL + result.resultData.userCvProfile.createdCvLink
          );
        }
      }
      setIsPopUpShow(false);
    });
  };

  const handleAddRemoveFav = (c, idx) => {
    setFavIdx(idx);
    setApplyId("");
    console.log("single job ", c);
    var status;
    var dataId = {
      jobId: c.id      ,
    };

    if (profile) {
      status = profile.status;
    } else {
      status = 0;
    }

    if (profile.status === 1) {
      setShow(true);
      setMessage("You need to sign up first to add the job to Favourites");
      setModalHeading("Email verification needed");
      setButtonName("Verify Email Now");
    } else if (status === 0) {
      setShow(true);
      setMessage("You need to sign up first to add the job to Favourites");
      setModalHeading("Sign up needed");
      setButtonName("Sign Up Now");
    }

    console.log(auth, " ", dataId);
    if (status === 2) {
      // add job as fav
      if (!c.isFavorite) {
        api.job
          .isFav(dataId, auth)
          .then((result) => {
            console.log("IS FAV ", result);
            if (result.resultCode === 1) {
              setIsLoading(true);
              setSuccessMsg("This job has been added as a Favourite");
              setMsgAlertClr("success");

              dispatch({
                type: "shouldeReloadJobList/save",
                shouldeReloadJobList: true,
              });
            }
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
            if (
              !!error.response &&
              !!error.response.status &&
              error.response.status === 401
            ) {
              Logout(dispatch, history, false);
            }
            if (error.message === "Network Error") {
              setMessage("Please check your network connection!");
              setAlertClr("danger");
              setShowAlert(true);
            }
          });
      } else {
        // fav job remove from fav list
        api.job
          .isFavRemove(dataId, auth)
          .then((result) => {
            console.log("IS FAV REMOVE", result);
            if (result.resultCode === 1) {
              setIsLoading(true);
              setSuccessMsg("This job has been removed from Favourite");
              setMsgAlertClr("success");
              dispatch({
                type: "shouldeReloadJobList/save",
                shouldeReloadJobList: true,
              });
            }
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
            if (
              !!error.response &&
              !!error.response.status &&
              error.response.status === 401
            ) {
              Logout(dispatch, history, false);
            }
            if (error.message === "Network Error") {
              setAlertMessage("Please check your network connection!");
              setAlertClr("danger");
              setShowAlert(true);
            }
          });
      }
    }
  };

  const applyJob = (CVLink) => {
    console.log("CV NEW CV ", CVLink);

    var data = {
      jobId: singleJob.id,
      cvLink: CVLink,
    };
    console.log("DTA ", data);
    api.jobApplication
      .applyForJob(data, auth)
      .then((result) => {
        console.log("APLLY JOB ", result);
        if (result.resultCode === 1) {
          setIsLoading(true);
          console.log(
            "result.resultData.feedbackDetails.takeFeedback ",
            result.resultData.feedbackDetails.takeFeedback
          );

          if (
            result.resultData.rewardDetails &&
            result.resultData.rewardDetails.newEarnedRewards > 0
          ) {
            console.log(
              "IN EARCN REWARD ",
              result.resultData.rewardDetails.newEarnedRewards
            );
            // setTimeout(() => {
            setIsRewardAllocate(true);
            setEarnReward(result.resultData.rewardDetails.newEarnedRewards);
            // }, 3000);
            setTimeout(() => {
              setSuccessMsg(
                "Your job application has been sent. Now you are one step closer to your dream job!"
              );
              setMsgAlertClr("success");
            }, 6000);
            if (
              result.resultData.feedbackDetails &&
              result.resultData.feedbackDetails.takeFeedback
            ) {
              setTimeout(() => {
                setIsFeedbackPopupOpen(true);
              }, 12000);
            }
          } else {
            setSuccessMsg(
              "Your job application has been sent. Now you are one step closer to your dream job!"
            );
            setMsgAlertClr("success");
            if (
              result.resultData.feedbackDetails &&
              result.resultData.feedbackDetails.takeFeedback
            ) {
              setTimeout(() => {
                setIsFeedbackPopupOpen(true);
              }, 4000);
            }
          }
          dispatch({
            type: "shouldeReloadJobList/save",
            shouldeReloadJobList: true,
          });
        } else {
          setMsgAlertClr("danger");
          setSuccessMsg(result.resultMsg);
        }
        setApplyClick(false);
        setIsLoading(false);
        console.log("RESULT ", result);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      });
  };

  const uploadeCVWithUploaded = (cv) => {
    console.log("CV NEW CV ", cv);
    setIsPopUpShow(false);
    // setCVLink(cv)
    applyJob(cv);
  };

  const handleCVOnDrop = (files, rejectedFiles) => {
    console.log("rejectedFiles ....", rejectedFiles);
    if (rejectedFiles && rejectedFiles.length > 0) {
      switch (rejectedFiles[0].errors[0].code) {
        case "file-invalid-type":
          setMsgAlertClr("danger");
          setSuccessMsg("File type must be pdf");

          break;

        case "file-too-large":
          setMsgAlertClr("danger");
          setSuccessMsg("CV file size should be less than 2 MB");
          break;

        default:
          break;
      }
    }

    console.log("FILE ", rejectedFiles.length);
    setSelectedFile(files[0]);

    if (rejectedFiles.length === 0) {
      const data = new FormData();
      data.append("cv", files[0]);
      console.log("REJECT FILE _  LENGTH");
      console.log(files[0]);

      console.log(auth);
      api.jobApplication
        .uploadCV(data, auth)
        .then((result) => {
          console.log("PATH CV PROFILE", result);
          if (result.resultCode === 1) {
            setIsLoading(true);
            setSuccessMsg("Your CV has been uploaded successfully");
            setMsgAlertClr("success");
            // localStorage.setItem("profile", JSON.stringify(result.resultData.profile));
            dispatch({
              type: "set_profile_data",
              data: result.resultData.profile,
            });
            // setCVLink(result.resultData.profile.cvLink)
            setTimeout(() => {
              applyJob(result.resultData.profile.cvLink);
              // dispatch({ type: "shouldeReloadJobList/save", shouldeReloadJobList: true });
            }, 3000);
          } else {
            setMsgAlertClr("danger");
            setSuccessMsg(result.resultMsg);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          if (
            !!error.response &&
            !!error.response.status &&
            error.response.status === 401
          ) {
            Logout(dispatch, history, false);
          }
          if (error.message === "Network Error") {
            setMessage("Please check your network connection!");
            setAlertClr("danger");
            setShowAlert(true);
          }
        });
      console.log("CV NEW CV ", profile.cvLink);
    }

    setIsPopUpShow(false);
  };

  const onClose = React.useCallback(() => {
    setIsPopUpShow(false);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    handleCVOnDrop,
  });

  return (
    <React.Fragment>
      {isFeedbackPopupOpen && (
        <FeedbackPopup isFeedbackOpen={isFeedbackPopupOpen} />
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        {deviceType === "Desktop" && (
          <Modal style={{ marginTop: "110px" }} show={isPopUpShow}>
            <Modal.Header className="CV-header">
              <h5>Submit CV</h5>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{ display: "flex", justifyContent: "center" }}>
                Create Your CV
              </h5>
              {/* <div style={{ display: 'flex', justifyContent: 'center' }}> */}
              {isCvComplete ? (
                <>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      id={isBtnDisable && "isDisable"}
                      disabled={isBtnDisable}
                      onClick={showCvPdf}
                      className="button-cv-style mt-3"
                    >
                      {" "}
                      View Created CV
                    </Button>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      className="button-cv-style mt-3"
                      onClick={() => {
                        if (isFavorite) {
                          history.push("/createCV");
                        } else {
                          setDialogStatus(true);
                        }
                      }}
                    >
                      {" "}
                      Edit CV{" "}
                    </Button>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      className="button-cv-style-upload mt-3"
                      disabled={isBtnDisable}
                      id={isBtnDisable && "isDisable"}
                      onClick={() => uploadeCVWithUploaded(createdCvLink)}
                    >
                      {" "}
                      Apply With Created CV
                    </Button>
                  </div>
                </>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="button-cv-style mt-3"
                    onClick={() => {
                      if (isFavorite) {
                        history.push("/createCV");
                      } else {
                        setDialogStatus(true);
                      }
                    }}
                  >
                    {" "}
                    Create CV{" "}
                  </Button>
                </div>
              )}

              {/* {!createdCvId && (
                <>
                  <h6
                    className="mt-3"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    Earn 30 Reward Points
                  </h6>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "-5px",
                    }}
                  >
                    by creating your CV with Opportunity Bank
                  </p>
                </>
              )} */}
              <hr className="mb-1 hr-cv-style" />
              <h5
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "-5px",
                }}
              >
                Upload Your CV
              </h5>
              <div className="dropzone-div">
                <Dropzone
                  onDrop={handleCVOnDrop}
                  className="dropzone-size"
                  multiple={false}
                  maxSize={imageMaxSize}
                  accept={acceptedFileType}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      className="for_sm_text"
                      {...getRootProps({ className: "dropzone" })}
                      style={{ marginTop: "2rem" }}
                    >
                      <section
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <input {...getInputProps()} />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {profile && profile.cvLink && (
                            <Button className="button-cv-style">
                              Upload New CV{" "}
                            </Button>
                          )}
                        </div>

                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {profile && !profile.cvLink && (
                            <Button className="button-cv-style">
                              Upload CV
                            </Button>
                          )}
                        </div>
                      </section>
                    </div>
                  )}
                </Dropzone>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "-7px",
                    marginTop: "10px",
                  }}
                >
                  <p>Supported file format: PDF</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "-17px",
                  }}
                >
                  <p>Maximum file size: 2 MB</p>
                </div>
                <div
                  className="mt-3"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {profile && profile.cvLink && (
                    <Button
                      className="button-cv-style mb-3"
                      href={profile && API_SERVER_URL + profile.cvLink}
                      target="_blank"
                      onClick={() => {
                        console.log("VIEW CV CLICK");
                        setIsPopUpShow(false);
                      }}
                    >
                      View CV
                    </Button>
                  )}
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  {profile && profile.cvLink && (
                    <Button
                      className="button-cv-style-upload"
                      style={{ width: "auto" }}
                      onClick={() =>
                        uploadeCVWithUploaded(profile && profile.cvLink)
                      }
                    >
                      {" "}
                      Apply With Uploaded CV
                    </Button>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ backgroundColor: "#c5c4c4", color: "black" }}
                onClick={() => {
                  setIsPopUpShow(false);
                  setApplyClick(false);
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      <div className="fix">
        <CustDialog
          open={dialogStatus}
          handleClose={() => {
            setDialogStatus(false);
            history.push("/createCV");
          }}
          message="To create your CV, we are navigating away from this screen. To easily find this job later, you can add it to your Favorite List"
          disagree="No thanks!"
          agree="Add to favourite  and continue"
          handleAction={() => {
            if (!isFavorite) {
              handleAddRemoveFav(jobData, favIdx);
              // setDialogStatus(false);
              // history.push('/createCV');
            }
          }}
        />
        <Alert className="alert-position" isOpen={showAlert} color={alertClr}>
          {alertMessage}{" "}
        </Alert>
        <CvViewer
          showCV={isViewCvClick}
          setShowCv={setIsViewCvClick}
          url={API_SERVER_URL + viewCVLink}
        />

        {deviceType === "Mobile" && (
          <Drawer
            style={{ height: "70%" }}
            isVisible={isPopUpShow}
            onClose={onClose}
          >
            <h5 style={{ display: "flex", justifyContent: "center" }}>
              Create Your CV
            </h5>

            {isCvComplete ? (
              <>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    id={isBtnDisable && "isDisable"}
                    disabled={isBtnDisable}
                    onClick={() => {
                      console.log("VIEW CV CLICK");
                      showCvPdf();
                    }}
                    className="button-cv-style mt-3"
                  >
                    {" "}
                    View Created CV
                  </Button>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="button-cv-style mt-3"
                    onClick={() => {
                      setDialogStatus(true);
                      // history.push("/createCV")
                    }}
                  >
                    {" "}
                    Edit CV{" "}
                  </Button>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="button-cv-style-upload mt-3"
                    disabled={isBtnDisable}
                    id={isBtnDisable && "isDisable"}
                    onClick={() => uploadeCVWithUploaded(createdCvLink)}
                  >
                    {" "}
                    Apply With Created CV
                  </Button>
                </div>
              </>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  className="button-cv-style mt-3"
                  onClick={() => {
                    if (isFavorite) {
                      history.push("/createCV");
                    } else {
                      setDialogStatus(true);
                    }
                    // history.push("/createCV")
                  }}
                >
                  {" "}
                  Create CV{" "}
                </Button>
              </div>
            )}

            {/* {!createdCvId && (
              <>
                <h6
                  className="mt-3"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  Earn 30 Reward Points
                </h6>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "-1px",
                  }}
                >
                  by creating your CV with Opportunity Bank
                </p>
              </>
            )} */}
            <hr className="mb-1 hr-cv-style" />

            <h5 style={{ display: "flex", justifyContent: "center" }}>
              Upload Your CV
            </h5>
            <div className="dropzone-div">
              <Dropzone
                // style={{ width: '15rem !important', borderRadius:'10px !important' }}
                onDrop={handleCVOnDrop}
                className="dropzone-size"
                multiple={false}
                maxSize={imageMaxSize}
                accept={acceptedFileType}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    className="for_sm_text"
                    {...getRootProps({ className: "dropzone" })}
                  >
                    <section
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <input {...getInputProps()} />
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {profile && profile.cvLink && (
                          <Button className="button-cv-style">
                            Upload New CV{" "}
                          </Button>
                        )}
                      </div>

                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {profile && !profile.cvLink && (
                          <Button
                            className="button-cv-style"
                            onClick={() => {
                              // setUploadForFirstTime(true)
                            }}
                          >
                            Upload CV
                          </Button>
                        )}
                      </div>
                    </section>
                  </div>
                )}
              </Dropzone>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "-7px",
                  marginTop: "10px",
                }}
              >
                <p>Supported file format: PDF</p>
              </div>
              <div
                style={
                  profile && !profile.cvLink
                    ? {
                        marginBttom: "10rem",
                        display: "flex",
                        justifyContent: "center",
                      }
                    : { display: "flex", justifyContent: "center" }
                }
              >
                <p>Maximum file size: 2 MB</p>
              </div>
              {profile && profile.cvLink && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="button-cv-style mb-3"
                    // href={(profile && API_SERVER_URL + profile.cvLink)}
                    onClick={() => {
                      console.log("VIEW CV CLICK");
                      setIsPopUpShow(false);
                      setViewCVLink(profile.cvLink);
                      setIsViewCvClick(true);
                      // window.open(API_SERVER_URL + profile.cvLink, "_blank")
                    }}
                  >
                    View CV
                  </Button>
                </div>
              )}

              <div style={{ display: "flex", justifyContent: "center" }}>
                {profile && profile.cvLink && (
                  <Button
                    className="button-cv-style-upload"
                    style={{ marginBottom: "7rem", width: "auto" }}
                    onClick={() =>
                      uploadeCVWithUploaded(profile && profile.cvLink)
                    }
                  >
                    {" "}
                    Apply With Uploaded CV
                  </Button>
                )}
              </div>
            </div>
          </Drawer>
        )}

        <Modal
          className="modal-style"
          onHide={() => {
            setShow(false);
            setDetailClick(false);
            setApplyClick(false);
          }}
          show={show}
          // size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <i
                class="fa fa-exclamation-triangle"
                aria-hidden="true"
                style={{ color: "#ffc107" }}
              ></i>{" "}
              {modalHeading}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{message}</Modal.Body>
          <Modal.Footer>
            <Button
              onClick={onClick}
              style={{
                cursor: "pointer",
                borderColor: "#5ba4fb",
                backgroundColor: "#5ba4fb",
              }}
            >
              {buttonName}
            </Button>
            <Button
              className="close-btn"
              // style={{backgroundColor:'#bbb6b6', border:'none', width:'500px'}}
              onClick={() => {
                setApplyClick(false);
                setDetailClick(false);
                setShow(false);
              }}
              style={{
                cursor: "pointer",
                backgroundColor: "#95989a",
                width: "100px",
                borderColor: "#95989a",
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* <div className="container"> */}
        {data.slice(0, endPoint).map((c, idx) => {
          return (
            <RMJobCard
              onClick={() => {
                setDetailClick(true);
                setJobId(c.id);
                dispatch({
                  type: "scrollEnd/save",
                  scrollEnd: window.pageYOffset,
                });
                console.log("SCROLLLLw", window.pageYOffset);
              }}
              style={{
                margin: "2rem",
                cursor:"pointer"
              }}
              data={{
                categoryIconPath: c.categoryIconPath,
                locationNmList: c.locationNmList,
                oneLinerDescription: c.oneLinerDescription,
                compensation: c.compensation,
                contractTypeNmList: c.contractTypeNmList,
                publishOn: c.publishOn,
              }}
              onApply={() => {
                setApplyClick(true);
                setApplyId(idx);
                setFavIdx("");
                setSingleJob(c);
                console.log("iD ", idx);
                setJobId(c.id);
                setFavIdx(idx);
                setJobData(c);
                setIsFavorite(c.isFavorite);
              }}
              isApplied={c.isApplied}
              favComponent={
                <i
                  class="fa fa-star"
                  aria-hidden="true"
                  value={c.id}
                  style={
                    c.isFavorite
                      ? {
                          color: "#499cff",
                          fontSize: "2rem",
                          cursor: "pointer",
                        }
                      : { color: "black", fontSize: "2rem", cursor: "pointer" }
                  }
                  onClick={() => {
                    services.statusCheck(profile.status) &&
                      services.activityLogService(
                        ACTIVITY.CLICK_FAVORITE_JOB,
                        profile,
                        c.id
                      );
                    handleAddRemoveFav(c, idx);
                  }}
                  // onClick={() => favIcon(c, idx)}
                ></i>
              }
            >
              {isRewardAllocate && earnReward > 0 && idx === applyId && (
                <div
                  className="d-flex justify-content-center"
                  style={{
                    position: "absolute",
                    zIndex: "1500",
                    height: "fit-content",
                    top: "0px",
                    left: "0px",
                    width: "100%",
                  }}
                >
                  <RewardPopup
                    rewardMsg="Congrats! You've just earned your daily reward  for appying for 5 jobs today.Best wishes in your search for a dream job"
                    isPopupOpen={isRewardAllocate}
                    earnReward={earnReward}
                    rewardStyle="reward-rm"
                    from="list-job"
                  />
                </div>
              )}

              <Alert
                className="alert-position"
                style={{
                  position: "absolute",
                  top: "2%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: "14",
                }}
                isOpen={
                  (successMsg && idx === favIdx) ||
                  (successMsg && idx === applyId)
                }
                color={msgAlertClr}
              >
                {successMsg}
              </Alert>
            </RMJobCard>
          );
        })}
        {/* </div> */}

        {/* ----- */}
      </div>
    </React.Fragment>
  );
};

export default JobCard;
