/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./event-card.css";
import "../card/JobCard.css";
import { ACTIVITY } from "../../helpers/ACTIVITY_CONSTANTS";
import Logout from "../../logOut/Logout";
import { Alert, ModalHeader, ModalBody, Input, ModalFooter } from "reactstrap";
import { Card, Button, Modal } from "react-bootstrap";
import {
  API_IMAGE_URL,
  API_SERVER_URL,
  DEFAULT_JWT_TOKEN,
} from "../../app_constants";
import { services } from "../../helpers/utilities";
import api from "../../api";
import SingleEventCard from "./SingleEventCard";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
const EventCard = ({ data = [], endPoint }) => {
  let profile = useSelector((state) => state.profileData);

  const [detailClick, setDetailClick] = useState(false);
  const [eventId, setEventId] = useState();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  const [buttonName, setButtonName] = useState("");
  const [trainingEventId, settrainingEventId] = useState();
  const [favIdx, setFavIdx] = useState();
  const [applyId, setApplyId] = useState();
  const [isLoading, setIsLoading] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [msgAlertClr, setMsgAlertClr] = useState("success");
  const [alertClr, setAlertClr] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const history = useHistory();

  const dispatch = useDispatch();
  let auth = useSelector((state) => state.auth_token);

  const onClick = () => {
    if (profile.status === 1) {
      dispatch({ type: "setOtp_timer_value", OTP_Timer_value: 300000 });
      history.push({
        pathname: "/OTP",
        state: {
          status: "fromJoblist",
        },
      });
    } else if (profile.status === 0) {
      history.push("/signUp");
    }
  };

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setMessage("");
        setAlertClr("");
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(async () => {
    if (detailClick) {
      let activityLogDetails = {
        activityCode: "CLICK_JOB",
        deviceFcmToken: "WEB_TKN_" + profile.email,
        userId: profile.id,
        entityType: "JOB",
        entityId: trainingEventId,
        activityData: null,
      };
      console.log(activityLogDetails);
      try {
        console.log(activityLogDetails);
        let res = await api.activity.activityLog(
          activityLogDetails,
          DEFAULT_JWT_TOKEN
        );
        console.log("Activity Log Response", res);
      } catch (err) {
        console.log("Activity log err", err.response);
      }
      console.log(profile);
      if (profile.status === 1) {
        setShow(true);
        setMessage("You need to verify email first to view the event details");
        setModalHeading("Email verification needed");
        setButtonName("Verify Email Now");
      } else if (profile.status === 0) {
        setShow(true);
        setMessage("You need to sign up first to view the event details ");
        setModalHeading("Sign up needed");
        setButtonName("Sign Up Now");
      } else {
        // history.push("/jobDetail");

        history.push({
          pathname: "/eventDetails/" + trainingEventId,
        });
      }
    }
  }, [detailClick]);

  useEffect(() => {
    if (successMsg) {
      setTimeout(() => {
        setSuccessMsg("");
        setMsgAlertClr("success");
      }, 3000);
    }
  }, [successMsg]);
  const handleAddRemoveFav = (c, idx) => {
    console.log(c);
    setFavIdx(idx);
    setApplyId("");
    console.log("single training ", c);
    var status;
    var dataId = {
      TrainingEventId: c.trainingEventId,
    };

    if (profile) {
      status = profile.status;
    } else {
      status = 0;
    }

    if (profile.status === 1) {
      setShow(true);
      setMessage("You need to sign up first to add the event to Favourites");
      setModalHeading("Email verification needed");
      setButtonName("Verify Email Now");
    } else if (status === 0) {
      setShow(true);
      setMessage("You need to sign up first to add the event to Favourites");
      setModalHeading("Sign up needed");
      setButtonName("Sign Up Now");
    }

    console.log(auth, " ", dataId);
    if (status === 2) {
      // add training as fav
      if (!c.isFavourite) {
        api.event
          .isFav(dataId, auth)
          .then((result) => {
            console.log("IS FAV ", result);
            if (result.resultCode === 1) {
              setIsLoading(true);
              setSuccessMsg("This event has been added as a Favourite");
              setMsgAlertClr("success");

              dispatch({
                type: "shouldeReloadEventList/save",
                shouldeReloadEventList: true,
              });
            }
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
            if (
              !!error.response &&
              !!error.response.status &&
              error.response.status === 401
            ) {
              Logout(dispatch, history, false);
            }
            if (error.message === "Network Error") {
              setMessage("Please check your network connection!");
              setAlertClr("danger");
              setShowAlert(true);
            }
          });
      } else {
        // fav job remove from fav list
        api.event
          .isFavRemove(dataId, auth)
          .then((result) => {
            console.log("IS FAV REMOVE", result);
            if (result.resultCode === 1) {
              setIsLoading(true);
              setSuccessMsg("This Event has been removed from Favourite");
              setMsgAlertClr("success");
              dispatch({
                type: "shouldeReloadEventList/save",
                shouldeReloadEventList: true,
              });
            }
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
            if (
              !!error.response &&
              !!error.response.status &&
              error.response.status === 401
            ) {
              Logout(dispatch, history, false);
            }
            if (error.message === "Network Error") {
              setAlertMessage("Please check your network connection!");
              setAlertClr("danger");
              setShowAlert(true);
            }
          });
      }
    }
  };
  return (
    <>
      <div className="fix">
        <Modal
          className="modal-style"
          onHide={() => {
            setShow(false);
            setDetailClick(false);
          }}
          show={show}
          // size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <i
                class="fa fa-exclamation-triangle"
                aria-hidden="true"
                style={{ color: "#ffc107" }}
              ></i>{" "}
              {modalHeading}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{message}</Modal.Body>
          <Modal.Footer>
            <Button
              onClick={onClick}
              style={{
                cursor: "pointer",
                borderColor: "#5ba4fb",
                backgroundColor: "#5ba4fb",
              }}
            >
              {buttonName}
            </Button>
            <Button
              className="close-btn"
              // style={{backgroundColor:'#bbb6b6', border:'none', width:'500px'}}
              onClick={() => {
                setDetailClick(false);
                setShow(false);
              }}
              style={{
                cursor: "pointer",
                backgroundColor: "#95989a",
                width: "100px",
                borderColor: "#95989a",
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {data.slice(0, endPoint).map((c, idx) => {
          return (
            <SingleEventCard
              key={idx}
              onClick={() => {
                setDetailClick(true);
                settrainingEventId(c.trainingEventId);
                // dispatch({
                //   type: "scrollEnd/save",
                //   scrollEnd: window.pageYOffset,
                // });
                // console.log("SCROLLLLw", window.pageYOffset);
              }}
              style={{
                margin: "2rem",
              }}
              data={{
                title: c.title,
                oneLinerDescription: c.one_liner_description,
                startDate: c.start_date,
                endDate: c.end_date,
              }}
              favComponent={
                <i
                  class="fa fa-star"
                  aria-hidden="true"
                  value={c.id}
                  style={
                    c.isFavourite
                      ? {
                          color: "#499cff",
                          fontSize: "2rem",
                          cursor: "pointer",
                        }
                      : { color: "black", fontSize: "2rem", cursor: "pointer" }
                  }
                  onClick={() => {
                    console.log(c);
                    services.statusCheck(profile.status) &&
                      services.activityLogService(
                        ACTIVITY.CLICK_FAVORITE_JOB,
                        profile,
                        c.id
                      );
                    handleAddRemoveFav(c, idx);
                  }}
                  // onClick={() => favIcon(c, idx)}
                ></i>
              }
            >
              <Alert
                className="alert-position"
                style={{
                  position: "absolute",
                  top: "2%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: "14",
                }}
                isOpen={
                  (successMsg && idx === favIdx) ||
                  (successMsg && idx === applyId)
                }
                color={msgAlertClr}
              >
                {successMsg}
              </Alert>
            </SingleEventCard>
          );
        })}
      </div>
    </>
  );
};

export default EventCard;
