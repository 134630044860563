import React, { useEffect } from 'react';
import JobCard from './components/card/JobCard';
import CircularLoader from './components/circular-loader/CircularLoader';
import RMJobCard from './components/job-card/JobCard';
import ThemeButton from './components/theme-button/ThemeButton';
import ThemePopup from './components/theme-popup/ThemePopup';
import { useDeviceSize } from './helpers/customHooks';

const Sample = () => {
    const deviceSize = useDeviceSize();
    
    
    return (
        <div style={{height:"100vh",background:"white"}}>
            <ThemeButton>Primary Button</ThemeButton>
            {/* {
                deviceSize&&<ThemePopup deviceSize={deviceSize}/>
            } */}
            {/* <RMJobCard/> */}
            <CircularLoader/>
            
        </div>
    );
};

export default Sample;