import React, { useEffect } from 'react';
import Navbar from '../components/Navbar/Navbar';
import './Main_Layout.css'
import { useDispatch, useSelector } from "react-redux";
import Footer from '../components/footer/Footer';
import { useMediaQuery } from "react-responsive";
import FooterRe from '../components/footer/FooterRe';
import DisplayPopup from '../components/award-popup/DisplayPopup';


const Main_Layout = ({ component }) => {
    const scrollToTop = () =>{
        window.scrollTo(0,0)
    }
    return (
        <>
        <div className={"main_layout cust-container" }  >
            {/* <Navbar  /> */}
            {component}
            <FooterRe/>
        <button className="animated bounce scroll-to-top" onClick={scrollToTop}>
        <i class="fas fa-arrow-up"></i>
        </button>
        </div>
        </>
    );
};

export default Main_Layout;