import React, { Component } from "react";
import Reward from "react-rewards";

// import ConfigSlider from "./Slider";
import './rewardPopup.css';
import "antd/dist/antd.css";

const defaults = {
  confetti: {
    type: "confetti",
    fakingRequest: false,
    angle: 90,
    decay: 0.91,
    spread: 50,
    startVelocity: 25,
    elementCount: 100,
    elementSize: 10,
    lifetime: 200,
    zIndex: 10,
    springAnimation: true,

  },
};

export default class RewardPopup extends Component {
  state = {
    ...defaults.confetti,
    rewardPunish: "reward",
  };

  componentDidMount() {
    console.log("passValue ", this.props)

    // this.props.earnReward > 0 &&
    this.fakeRequest()

  }

  fakeRequest = () => {
    const { rewardPunish } = this.state;
    this.setState({
      fakingRequest: true,
    });
    setTimeout(() => {
      this.setState({ fakingRequest: false, success: true });
      // console.log("REF Reward", this.reward)

      // rewardPunish === "reward"
      this.reward
        &&
        this.reward.rewardMe()
    }, 800);
  };

  rewardTypeChange = e => {
    this.setState({ type: e.target.value, ...defaults[e.target.value] });
  };
  lifetimeChange = value => {
    if (isNaN(value)) return;
    this.setState({ lifetime: value });
  };
  angleChange = value => {
    if (isNaN(value)) return;
    this.setState({ angle: value });
  };
  decayChange = value => {
    if (isNaN(value)) return;
    this.setState({ decay: value });
  };
  spreadChange = value => {
    if (isNaN(value)) return;
    this.setState({ spread: value });
  };
  startVelocityChange = value => {
    if (isNaN(value)) return;
    this.setState({ startVelocity: value });
  };
  elementCountChange = value => {
    if (isNaN(value)) return;
    this.setState({ elementCount: value });
  };
  elementSizeChange = value => {
    if (isNaN(value)) return;
    this.setState({ elementSize: value });
  };
  rewardPunishChange = e => {
    this.setState({ rewardPunish: e.target.value });
  };


  render() {
    const {
      type,
      fakingRequest,
      lifetime,
      angle,
      decay,
      spread,
      startVelocity,
      elementCount,
      elementSize,
      zIndex,
      springAnimation,
    } = this.state;

    const {
      earnReward,
      isPopupOpen,
      rewardStyle,
      from,
      rewardMsg
    } = this.props

    // const disabled = rewardPunish === "punish";
    return (

      <div className='d-flex justify-content-center mt-5 mb-4' id={rewardStyle} >
        {(isPopupOpen && earnReward > 0) &&
          <div
            // style={containerStyle} 
            className={from === "list-job" ? "reward-popup-style-list" : from === "cv-create-reward" ? "reward-popup-cvCreate" : "reward-popup-style"}
          >

            <Reward
              ref={ref => {
                this.reward = ref;
              }}
              type={type}

              config={{
                lifetime,
                angle,
                decay,
                spread,
                startVelocity,
                elementCount,
                elementSize,
                zIndex,
                springAnimation
              }}
            >

            </Reward>
            <h4 className="mt-5 font-style-reward" style={{ color: '#c45a64' }}>
              {/* Reward Point Received */}
              {rewardMsg}
            </h4>

            <h2 style={{ color: 'rgb(144, 78, 149)', marginTop: '10px' }}>
              {/* 30 */}
              {earnReward > 0 && earnReward}
            </h2>

          </div>
        }
      </div>
    );
  }
}

const containerStyle = {
  // minHeight: "50%",
  width: "45%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: 40,
  borderTop: "15px solid #e96443",
  borderBottom: "15px solid #904e95",
  backgroundImage:
    "linear-gradient(#e96443, #904e95), linear-gradient(#e96443, #904e95)",
  backgroundSize: "15px 100%",
  backgroundPosition: "0 0, 100% 0",
  backgroundRepeat: "no-repeat",
  overflowY: "hidden",
  overflowX: "hidden",
  backgroundColor: 'white'
};


