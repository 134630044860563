import React, { useEffect } from 'react'
import aboutUsBanner from "../../assets/Banner/About-us/banner@3x.png";
import tabletSizeBanner from "../../assets/Banner/About_us@2x.png";
import mobileSizeBanner from "../../assets/Banner/Aboutus@1.5x.png";
import { useMediaQuery } from "react-responsive";
import ServiceCardComponent from '../../components/card/ServiceCardComponent';
import { Col, Row } from 'reactstrap';


import { AsyncImage } from '../../helpers/AsyncImage';
import { healthcare, jobs, path, scholarships, training, wellfare } from '../../assets';
import JumbotronPlaceHolder from '../../components/JumbotronPlaceHolder';
import { useDispatch } from 'react-redux';



const serviceItems = [
    {
        title:"Jobs",
        iconSrc:jobs,
        details:"Full-time, part-time, internship – different openings available"
    },
    {
        title:"Training & Event",
        iconSrc:training,
        details:"Events, workshops and online videos on different topics from job hunting to work culture in Hong Kong to learning Cantonese"
    },
    {
        title:"Scholarships",
        iconSrc:scholarships,
        details:"Check out what scholarships are in the market and how you can apply"
    },
    {
        title:"Social & Healthcare Services",
        iconSrc:healthcare,
        details:"Customised referral for you "
    },
    {
        title:"Welfare Security Information",
        iconSrc:wellfare,
        details:"Any welfare and assistance you may be eligible"
    },
    // {
    //     title:"App",
    //     iconSrc:"",
    //     details:"We are building an App version of Opportunity Bank – Stay Tuned"
    // },
]



export default function Service() {
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch({type:'setCurrentLink',currentLink:'/service'});
    window.scrollTo(0, 0);
  },[])

    const isMobileDevice = useMediaQuery({
        query: "(min-device-width: 0px) and (max-device-width: 768px)",
      });
    
      const isTabletDevice = useMediaQuery({
        query: "(min-device-width: 481) and (max-device-width: 768px)",
      });
    
      const isLaptop = useMediaQuery({
        query: "(min-device-width: 769px) ",
      });
    
    return (
        <div>
          <JumbotronPlaceHolder>

        {
            isMobileDevice &&  <AsyncImage
             src={mobileSizeBanner}
            alt="First slide"
            style={{ width: "100%", height: "100%" }}
          /> 
        }
      
        {
            isLaptop && <AsyncImage
            loading
            src={aboutUsBanner}
           alt="second slide"
           style={{ width: "100%", height: "100%" }}
         /> 
        }
         <div className="banner-details">
           <h3
             style={{ fontWeight: "400", color: "white", fontStyle: "italic" }}
           >
             Services
           </h3>
           <h1
             className="banner-title"
             style={{
               display: "flex",
               justifyContent: "flex-start",
               color: "white",
               textAlign: "start",
             }}
           >
             What We Offer
           </h1>
         </div>
   
       </JumbotronPlaceHolder>
       <div style={{background:"white"}} className="cust-padddings pt-5">
       <p className="description  pt-5 ">Find out more about the various services we offer and see if you can make use of them.</p>
       <div className=" pt-5">
       <h3 className="large-header" style={{ textAlign: "start" }}>
          Services at a glance
        </h3>
        <hr className="cust-underline " />
       </div>
       <p className="description ">Opportunity Bank seeks to push for greater transparency & higher accessibility to information</p>
       <Row  className=" d-flex justify-content-start  mb-0 p-0 mr-0 pt-5 " style={{width:"100%"}}>
            <Col xs="12 ml-0 pl-0" md="12"  lg="12 p-0 " xl="9 p-0" className="d-flex" >
              <Row className=" pl-0" style={{paddingBottom:"95px", marginLeft:"auto", marginRight:"auto",width:"100%"}}>
                {serviceItems.map((item) => {
                  return (
                    <Col xs="12" sm="12" md="6" lg="4 p-0 m-0 d-flex justify-content-center" style={{width:"100%"}}>
                        <ServiceCardComponent title={item.title} icon={item.iconSrc} description={item.details} arrow={path}/>                 
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
       </div>
       
        
        </div>
    )
}