import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import angree from '../../assets/feedback/angree.png';
import unsatisfy from '../../assets/feedback/unsatisfy.png';
import ok from '../../assets/feedback/okEmoji.png';
import satisfy from '../../assets/feedback/satisfy.png';
import happy from '../../assets/feedback/happyEmoji.png';
import './feedback.css';
import api from '../../api';
import { result } from 'lodash';
import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import Logout from '../../logOut/Logout';
import { useHistory } from 'react-router';
import { useDispatch,useSelector } from 'react-redux';

const FeedbackPopup = ({ isFeedbackOpen }) => {

  const authToken = useSelector(state => state.auth_token)

  const [modal, setModal] = useState(isFeedbackOpen);
  const [alertClr, setAlertClr] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  // const toggle = () => setModal(!modal);

  const history = useHistory();
  const dispatch = useDispatch();

  const giveFeedback = (value) => {
    var data = {
      activityCode: 'APPLY_JOB',
      feedbackRating: value
    }
    console.log("DATA FEEDBACK ", data);

    api.feedback.savefeedback(data, authToken)
      .then(result => {
        console.log("RESULTSAVE FEEDBACK ", result);
        if (result.resultCode === 1) {
          setModal(false);
          setMessage("Your feedback is submitted successfully")
          setAlertClr("success")
          setShowAlert(true)
          setTimeout(() => {
            setShowAlert(false);
            setMessage('');
            setAlertClr('')
          }, 3000);
        }
      }).catch(error => {
        console.log(error);
        if (!!error.response && !!error.response.status && error.response.status === 401) {
          Logout(dispatch, history, false);

        }
        if (error.message === 'Network Error') {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      })
  }

  return (
    <div>
      <Modal isOpen={showAlert} fade={false}
        //   toggle={toggle} 

        style={{
          height: '5rem', backgroundColor: '#bbedbb', marginTop: '12rem', borderRadius: '5px'
        }}
      >
        <ModalBody className="modal-feedback-style">
          {message}
          {/* Your feedback is submited */}
        </ModalBody>
      </Modal>


      <Modal isOpen={modal} fade={false}
        //   toggle={toggle} 
        //   className="mt-5"
        style={{
          marginTop: '10rem', height: '17rem'
        }}
      >
        <ModalHeader
          className="d-flex justify-content-start"
        // toggle={toggle}
        >
          <h5>Are you happy with your job applying experience?</h5>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-center pt-4">
            <img className="mr-3 emoji-style" onClick={() => giveFeedback(-2)} src={angree} />
            <img className="mr-3 emoji-style" onClick={() => giveFeedback(-1)} src={ok} />
            <img className="mr-3 emoji-style" onClick={() => giveFeedback(0)} src={unsatisfy} />
            <img className="mr-3 emoji-style" onClick={() => giveFeedback(1)} src={satisfy} />
            <img className="emoji-style" onClick={() => giveFeedback(2)} src={happy} />
          </div>
        </ModalBody>

      </Modal>


      {/* <Alert
                className="alert-position"
                //  isOpen={showAlert}
                color={alertClr}
            >
                {message}{" "}
            </Alert> */}
    </div >
  );
}

export default FeedbackPopup;













