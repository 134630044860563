import React from "react";
import "./search.css";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";

const Search = ({ searchText, setSearchText, placeholder }) => {
  return (
    <React.Fragment>
      <div className="m-0 p-0 ">
        <FormControl
          fullWidth
          c
          className="input-search-style ml-auto"
          variant="outlined"
        >
          <OutlinedInput
            placeholder={placeholder}
            className="search-input"
            style={{
              fontSize: "1.6rem",
              borderColor: "black",
              borderRadius: "1.7rem",
            }}
            id="outlined-adornment-amount"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            startAdornment={
              <InputAdornment position="start">
                <i class="fas fa-search"> </i>
              </InputAdornment>
            }
            // labelWidth={60}
          />
        </FormControl>
      </div>
    </React.Fragment>
  );
};

export default Search;
