/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { useParams, useHistory, Link } from "react-router-dom";
import api from "../../api";
import "./trainingDeatil.css";
import { useSelector, useDispatch } from "react-redux";
import FixBanner from "../../components/singleBanner/FixBanner";
import { Alert } from "reactstrap";
import Logout from "../../logOut/Logout";
import Loader from "react-js-loader";
import banner from "../../assets/logo/Banner@3x.png";
import Dropzone from "react-dropzone";
import {
  API_IMAGE_URL,
  API_SERVER_URL,
  DEFAULT_JWT_TOKEN,
} from "../../app_constants";
import Drawer from "react-bottom-drawer";
import CvViewer from "../../Component/forMobileCv/CvViewer";
import testlogo from "../../assets/images/human@1.5x.png";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import { makeStyles } from "@material-ui/core/styles";
import {
  defaultJobIcon,
  defaultProfileIcon,
  defaulttraining,
} from "../../assets";
import { RWebShare } from "react-web-share";
import RewardPopup from "../../components/RewardPopup";
import FeedbackPopup from "../../components/feedback/FeedbackPopup";
import { services } from "../../helpers/utilities";
import moment from "moment";
const imageMaxSize = 2097152; //bytes
const acceptedFileType = "application/pdf";
const acceptedFileTypeArray = acceptedFileType.split(",").map((item) => {
  return item.trim();
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function TrainingDetail() {
  const classes = useStyles();
  const [training, setTrainingDetail] = useState([]);

  const params = useParams();
  const [deviceType, setDeviceType] = useState();
  const [jobDetailClick, setJobDetailClick] = useState(true);
  const [aboutJobClick, setAboutJobClick] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [alertShow, setAlertShow] = useState("");
  const [applyClick, setApplyClick] = useState(false);
  const [alertClr, setAlertClr] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPopUpShow, setIsPopUpShow] = useState(false);
  const [applyMsg, setApplyMsg] = useState("");
  const [isViewCvClick, setIsViewCvClick] = useState(false);
  const [viewCVLink, setViewCVLink] = useState("");
  const [isCvComplete, setIsCvComplete] = useState(false);
  const [createdCvLink, setCreatedCvLink] = useState(null);
  const [createdCvId, setCreatedCvId] = useState(null);
  const [isBtnDisable, setIsBtnDisable] = useState(false);
  const [isRewardAllocate, setIsRewardAllocate] = useState(false);
  const [rewardMsg, setRewardMsg] = useState("");
  const [earnReward, setEarnReward] = useState(0);
  const [isFeedbackPopupOpen, setIsFeedbackPopupOpen] = useState(false);

  console.log("PARAMAS ", params);
  const dispatch = useDispatch();
  const history = useHistory();
  const authToken = useSelector((state) => state.auth_token);
  const profile = useSelector((state) => state.profileData);

  const shareData = {
    title: "New job requirement on Opportunity Bank job portal : ",
    text: `
    New job requirement on Opportunity Bank job portal : 

    Job title : ${training && training.jobTitle && training.jobTitle}
    Job category : ${training && training.categoryName && training.categoryName}
    Job one liner description : ${
      training && training.oneLinerDescription && training.oneLinerDescription
    }
    Contract type : ${
      training &&
      training.contractTypeNmList &&
      training.contractTypeNmList.map((i) => i)
    }
    Compensation : ${training && training.compensation && training.compensation}

    For more details download app 
    Android : https://play.google.com/store/apps/details?id=com.zubinfoundation.opportunitybank

    iOS : https://apps.apple.com/us/app/opportunity-bank/id1544204551
    `,
    url: "http://18.141.212.18:8080/",
  };

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setMessage("");
        setAlertClr("");
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (applyMsg) {
      setTimeout(() => {
        setApplyMsg("");
      }, 3000);
    }
  }, [applyMsg]);

  const onShare = async (e) => {
    if (navigator.share !== undefined) {
      try {
        await navigator.share(shareData);
        console.log("MDN shared successfully");
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setDeviceType("Mobile");
    } else {
      setDeviceType("Desktop");
    }

    // check is CV compleyte
    api.onlineCV
      .isCvComplete(authToken)
      .then((result) => {
        console.log("is CV comleted ", result);
        if (result.resultCode === 1) {
          setIsCvComplete(result.resultData.isComplete);
          setIsBtnDisable(result.resultData.enableGeneratePdfButton);
          setCreatedCvId(result.resultData.userCvDetails.profile.cvId);
          setCreatedCvLink(
            result.resultData.userCvDetails.profile.createdCvLink
          );
        }
      })
      .catch((error) => {
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      });
  }, []);

  useEffect(() => {
    fetchJobDetail();
  }, [params]);

  useEffect(() => {
    setTimeout(() => {
      setAlertShow(false);
    }, 2000);
  }, [alertShow]);

  useEffect(() => {
    if (isRewardAllocate) {
      setTimeout(() => {
        setIsRewardAllocate(false);
      }, 6000);
    }
  }, [isRewardAllocate]);

  const fetchJobDetail = () => {
    var trainingId = Number(params.trainingId);

    console.log("USEEFFECT CALL");
    setIsLoading(true);

    api.training
      .getTrainingById(trainingId, authToken)
      .then((result) => {
        console.log("RESULT job training", result);
        if (result.resultCode === 1) {
          setTrainingDetail(result.resultData.onlineVideoTraining);
          console.log("Reward ====> ", result.resultData.onlineVideoTraining.title
          );
          if (
            result.resultData.activityResult.rewardDetails &&
            result.resultData.activityResult.rewardDetails.newEarnedRewards > 0
          ) {
            // setIsRewardAllocate(true);
            // setRewardMsg(
            //   "Congrats! You've just earned your daily reward for viewing five different trainning today"
            // );
            // setEarnReward(
            //   result.resultData.activityResult.rewardDetails.newEarnedRewards
            // );
          }
        } else {
          setTrainingDetail([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      });
  };

  const handleAddRemoveFav = () => {
    var dataId = {
      onlineVideoTrainingId: training.TrainingId,
    };
    setIsLoading(true);
    if (!training.isFavorite) {
      api.training
        .isFav(dataId, authToken)
        .then((result) => {
          console.log("IS FAV ", result);
          if (result.resultCode === 1) {
            setMessage("This training has been added as a Favourite");
            setShowAlert(true);
            setAlertClr("success");
            fetchJobDetail();
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          if (
            !!error.response &&
            !!error.response.status &&
            error.response.status === 401
          ) {
            Logout(dispatch, history, false);
          }
          if (error.message === "Network Error") {
            setMessage("Please check your network connection!");
            setAlertClr("danger");
            setShowAlert(true);
          }
        });
    } else {
      api.training
        .isFavRemove(dataId, authToken)
        .then((result) => {
          console.log("IS FAV REMOVE", result);
          if (result.resultCode === 1) {
            setShowAlert(true);
            setAlertClr("success");
            setMessage("This training has been removed from Favourite");
            fetchJobDetail();
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          if (
            !!error.response &&
            !!error.response.status &&
            error.response.status === 401
          ) {
            Logout(dispatch, history, false);
          }
          if (error.message === "Network Error") {
            setMessage("Please check your network connection!");
            setAlertClr("danger");
            setShowAlert(true);
          }
        });
    }
  };

  const handleCVOnDrop = (files, rejectedFiles) => {
    console.log("FILE ", files);

    console.log("rejectedFiles ....", rejectedFiles);
    if (rejectedFiles && rejectedFiles.length > 0) {
      switch (rejectedFiles[0].errors[0].code) {
        case "file-invalid-type":
          setAlertClr("danger");
          setMessage("File type must be pdf");
          setShowAlert(true);
          break;

        case "file-too-large":
          setAlertClr("danger");
          setMessage("CV file size should be less than 2 MB");
          setShowAlert(true);
          break;

        default:
          break;
      }
    }

    if (rejectedFiles.length === 0) {
      console.log("REJECT FILE Length - 0");

      const data = new FormData();
      data.append("cv", files[0]);
      console.log("file 0 ", files[0]);
      console.log("dataaa", data);

      console.log(authToken);
      setIsLoading(true);
      api.jobApplication
        .uploadCV(data, authToken)
        .then((result) => {
          console.log("PATH CV ", result);
          if (result.resultCode === 1) {
            setMessage("Your CV has been uploaded successfully");
            setAlertClr("success");
            setShowAlert(true);
            dispatch({
              type: "set_profile_data",
              profileData: result.resultData.profile,
            });
            console.log(
              "result.resultData.profile.cvLink",
              result.resultData.profile.cvLink
            );
            setTimeout(() => {
              applyJob(result.resultData.profile.cvLink);
            }, 3000);
            fetchJobDetail();
          } else {
            setAlertClr("danger");
            setMessage(result.resultMsg);
            setShowAlert(true);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          if (
            !!error.response &&
            !!error.response.status &&
            error.response.status === 401
          ) {
            Logout(dispatch, history, false);
          }
          if (error.message === "Network Error") {
            setMessage("Please check your network connection!");
            setAlertClr("danger");
            setShowAlert(true);
          }
        });
      console.log("CV NEW CV ", profile.cvLink);
    }
    setIsPopUpShow(false);
  };

  const applyJob = async (CVLink) => {
    console.log("CV NEW CV ", CVLink);
    console.log("auth ", authToken);
    var data = {
      trainingId: training.id,
      cvLink: CVLink,
    };
    console.log("DTA ", data);
    setIsLoading(true);
    api.jobApplication
      .applyForJob(data, authToken)
      .then((result) => {
        if (result.resultCode === 1) {
          if (
            result.resultData.rewardDetails &&
            result.resultData.rewardDetails.newEarnedRewards > 0
          ) {
            // setTimeout(() => {
            // setIsRewardAllocate(true);
            // setRewardMsg(
            //   "Congrats! You've just earned your daily reward for appying for 5 jobs today. Best wishes in your search for a dream job"
            // );
            // setEarnReward(result.resultData.rewardDetails.newEarnedRewards);
            // }, 3000);
            setTimeout(() => {
              setMessage(
                "Your training application has been sent. Now you are one step closer to your dream job!"
              );
              setAlertClr("success");
              setShowAlert(true);
            }, 6000);
            if (
              result.resultData.feedbackDetails &&
              result.resultData.feedbackDetails.takeFeedback
            ) {
              setTimeout(() => {
                setIsFeedbackPopupOpen(true);
              }, 12000);
            }
          } else {
            setMessage(
              "Your training application has been sent. Now you are one step closer to your dream job!"
            );
            setAlertClr("success");
            setShowAlert(true);
            if (
              result.resultData.feedbackDetails &&
              result.resultData.feedbackDetails.takeFeedback
            ) {
              setTimeout(() => {
                setIsFeedbackPopupOpen(true);
              }, 4000);
            }
          }

          setShowAlert(true);
          fetchJobDetail();
        } else {
          setMessage(result.resultMsg);
          setAlertClr("danger");
          setShowAlert(true);
        }
        setApplyClick(false);
        console.log("RESULT ", result);
      })
      .catch((error) => {
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      });
  };

  const uploadeCVWithUploaded = (cv) => {
    console.log("CV NEW CV ", cv);
    setIsPopUpShow(false);
    // setCVLink(cv)
    applyJob(cv);
  };

  const onClose = React.useCallback(() => {
    setIsPopUpShow(false);
  }, []);

  const showCvPdf = () => {
    api.onlineCV.userCv(authToken).then((result) => {
      console.log("user CV ", result);
      if (result.resultCode === 1) {
        if (deviceType === "Mobile") {
          setViewCVLink(result.resultData.userCvProfile.createdCvLink);
          setIsViewCvClick(true);
        } else {
          window.open(
            API_SERVER_URL + result.resultData.userCvProfile.createdCvLink
          );
        }
      }
      setIsPopUpShow(false);
    });
  };

  return (
    <div>
      <Alert
        className="alert-position"
        //  isOpen={showAlert}
        color={alertClr}
      >
        {message}{" "}
      </Alert>
      {isRewardAllocate && earnReward > 0 && (
        <div className="reward-style-home">
          <div className="d-flex justify-content-center">
            <RewardPopup
              rewardMsg={rewardMsg}
              isPopupOpen={isRewardAllocate}
              earnReward={earnReward}
              rewardStyle="reward-position"
            />
          </div>
        </div>
      )}
      {/* <FixBanner headContent="jobs" content="Get the right job in seconds with us!" /> */}

      {isFeedbackPopupOpen && (
        <FeedbackPopup isFeedbackOpen={isFeedbackPopupOpen} />
      )}

      {deviceType === "Mobile" && (
        <Drawer
          style={{ height: "70%" }}
          isVisible={isPopUpShow}
          onClose={onClose}
        >
          <h5 style={{ display: "flex", justifyContent: "center" }}>
            Create Your CV
          </h5>

          {isCvComplete ? (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  id={isBtnDisable && "isDisable"}
                  disabled={isBtnDisable}
                  onClick={() => {
                    showCvPdf();
                  }}
                  className="button-cv-style mt-3"
                >
                  {" "}
                  View Created CV
                </Button>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  className="button-cv-style mt-3"
                  onClick={() => {
                    history.push("/createCV");
                  }}
                >
                  {" "}
                  Edit CV{" "}
                </Button>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  className="button-cv-style-upload mt-3"
                  disabled={isBtnDisable}
                  id={isBtnDisable && "isDisable"}
                  onClick={() => uploadeCVWithUploaded(createdCvLink)}
                >
                  {" "}
                  Apply With Created CV
                </Button>
              </div>
            </>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className="button-cv-style mt-3"
                onClick={() => {
                  history.push("/createCV");
                }}
              >
                {" "}
                Create CV{" "}
              </Button>
            </div>
          )}
          <hr className="mb-1 hr-cv-style" />

          <h5 style={{ display: "flex", justifyContent: "center" }}>
            Upload Your CV
          </h5>
          <div className="dropzone-div">
            <Dropzone
              onDrop={handleCVOnDrop}
              className="dropzone-size"
              multiple={false}
              maxSize={imageMaxSize}
              accept={acceptedFileType}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  className="for_sm_text"
                  {...getRootProps({ className: "dropzone" })}
                >
                  <section
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <input {...getInputProps()} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {profile && profile.cvLink && (
                        <Button className="button-cv-style">
                          Upload New CV{" "}
                        </Button>
                      )}
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {profile && !profile.cvLink && (
                        <Button className="button-cv-style">Upload CV</Button>
                      )}
                    </div>
                  </section>
                </div>
              )}
            </Dropzone>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "-7px",
                marginTop: "10px",
              }}
            >
              <p>Supported file format: PDF</p>
            </div>
            <div
              style={
                profile && !profile.cvLink
                  ? {
                      marginBttom: "10rem",
                      display: "flex",
                      justifyContent: "center",
                    }
                  : { display: "flex", justifyContent: "center" }
              }
            >
              <p>Maximum file size: 2 MB</p>
            </div>

            {profile && profile.cvLink && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  className="button-cv-style mb-3"
                  // href={(profile && API_SERVER_URL + profile.cvLink)}
                  onClick={() => {
                    setIsPopUpShow(false);
                    setViewCVLink(profile.cvLink);
                    setIsViewCvClick(true);
                  }}
                >
                  View CV
                </Button>
              </div>
            )}
            <div style={{ display: "flex", justifyContent: "center" }}>
              {profile && profile.cvLink && (
                <Button
                  className="button-cv-style-upload"
                  style={{ marginBottom: "7rem", width: "auto" }}
                  onClick={() =>
                    uploadeCVWithUploaded(profile && profile.cvLink)
                  }
                >
                  {" "}
                  Apply With Uploaded CV
                </Button>
              )}
            </div>
          </div>

          {/* <CVDropzone
            userCV={(data) => {
              console.log("child dtaa", data)
            }}
            //  acceptedFileType={acceptedFileType} 
            handleCVOnDrop={handleCVOnDrop} /> */}
        </Drawer>
      )}

      {/* PRagti code begins */}

      {/* Pragati code end */}

      <div className="job-details-main">
        <Row className="jobdetails-header" style={{ marginBottom: "5rem" }}>
          <Col lg="6" xs="12 mb-5" className="d-flex align-items-center ">
            <div className="jobdetails-icon mr-5 ">
              <img
                src={
                  training.categoryIconPath
                    ? API_IMAGE_URL + training.categoryIconPath
                    : defaulttraining
                }
              />
            </div>
            <h2>{training.title} </h2>
          </Col>
          <Col lg="6" className="d-flex justify-content-end align-items-center">
            {/* <Button className="flat-rect-btn mr-4">Save</Button> */}
            <div
              className="d-flex mr-5"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={async () => {
                services.statusCheck(profile.status) &&
                  services.activityLogService(
                    "CLICK_FAVORITE_JOB",
                    profile,
                    training.id
                  );
                handleAddRemoveFav();
              }}
            >
              <i
                className="fa fa-star mb-2"
                aria-hidden="true"
                value={training.id}
                style={
                  training.isFavorite
                    ? {
                        color: "#499cff",
                        fontSize: "2rem",
                        cursor: "pointer",
                        fontSize: "30px",
                      }
                    : {
                        color: "black",
                        fontSize: "2rem",
                        cursor: "pointer",
                        fontSize: "30px",
                      }
                }
              ></i>
              <p style={{ fontSize: "15px", color: "gray" }}>FAVOURITE</p>
            </div>

            {/* <div
              className="d-flex mr-5"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={onShare}
            >
              <i
                style={{ color: "black", fontSize: "30px", cursor: "pointer" }}
                className="fa fa-share-alt"
                aria-hidden="true"
              ></i>
              <p style={{ fontSize: "15px", marginTop: "4px", color: "gray" }}>
                SHARE
              </p>
            </div> */}
            <RWebShare
              data={shareData}
              onClick={() => console.log("shared successfully!")}
            >
              <div
                className="d-flex mr-5"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                onClick={onShare}
              >
                <i
                  style={{
                    color: "black",
                    fontSize: "30px",
                    cursor: "pointer",
                  }}
                  className="fa fa-share-alt"
                  aria-hidden="true"
                ></i>
                <p
                  style={{ fontSize: "15px", marginTop: "4px", color: "gray" }}
                >
                  SHARE
                </p>
              </div>
            </RWebShare>

            {/* {training.isApplied ? (
              <Chip
                label="Applied"
                style={{
                  background: "#ff6d00",
                  color: "white",
                  fontSize: "1.8rem",
                  padding: "2rem 1.8rem",
                }}
              />
            ) : (
              <Button
                className="rect-btn"
                onClick={async () => {
                  services.statusCheck(profile.status)&&services.activityLogService("CLICK_APPLY_JOB",profile,training.id)
                  if (!training.isApplied) {
                    setApplyClick(false);
                    setIsPopUpShow(true);
                    console.log("CLICK APLY");

                    console.log("profile ", profile);
                  } else {
                    console.log("ALREDY APPLIED");

                    setMessage("Job is already applied");
                    setAlertClr("danger");
                    setShowAlert(true);
                  }
                }}
              >
                Apply Now
              </Button>
            )} */}
          </Col>
        </Row>

        <div>
          <Row className="d-flex justify-content-center">
            <Col lg="5" xl="4" className="mb-5">
              <h4 className="jobdetails-label mb-5">TRAINING DETAILS</h4>
              <div className="jobdetails-container">
                <Row>
                  <Col
                    style={{ wordWrap: "nowrap" }}
                    className="jobdetails-title"
                  >
                    Title
                  </Col>
                  <Col className="jobdetails-value">
                    {training.title}
                    {/* {training &&
                      training.locationNmList &&
                      training.locationNmList.map((loc, idx) => {
                        console.log(training.locationNmList.length, " ", idx);
                        return (
                          <>
                            {loc}
                            {training.locationNmList.length !== idx + 1 &&
                              ", "}
                          </>
                        );
                      })} */}
                  </Col>
                </Row>
                <Row>
                  <Col className="jobdetails-title">CATEGORY</Col>
                  <Col className="jobdetails-value">
                    {training.category}
                    {/* {training &&
                      training.contractTypeNmList &&
                      training.contractTypeNmList.map((con, idx) => {
                        return (
                          <>
                            {con}
                            {training.contractTypeNmList.length !== idx + 1 &&
                              ","}
                          </>
                        );
                      })} */}
                  </Col>
                </Row>
                <Row>
                  <Col className="jobdetails-title">PRODUCED BY</Col>
                  <Col className="jobdetails-value">
                    {" "}
                    {/* {training.openPositions} */}
                    {training.producedBy}
                  </Col>
                </Row>
                <Row>
                  <Col className="jobdetails-title">LANGUAGE</Col>
                  <Col className="jobdetails-value">
                    {" "}
                    {training.videoLanguage}
                    {/* $ {training.compensation} */}
                  </Col>
                </Row>

                {/* <Row>
                  <Col className="jobdetails-title">Working Time</Col>
                  <Col className="jobdetails-value">
                    {training.workingTime}
                  </Col>
                </Row> */}
                <Row>
                  <Col className="jobdetails-title">Created On</Col>
                  <Col className="jobdetails-value">
                    {moment(training.createdOn).format("MMM Do YYYY")}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg="7" xl="8">
              <h4 className="jobdetails-label mb-5">ABOUT TRAINING</h4>
              <div className="jobdetail-content">
                <h5 className="jobdetails-title mr-5 mb-4">
                  One Liner Decription
                </h5>
                <ul className="jobdetails-value">
                  <li>{training && training.oneLinerDescription}</li>
                </ul>
                {/* <h5 className="jobdetails-title mt-5 pt-5 mr-5 mb-4">
                  Job Responsibilities
                </h5>
                {training &&
                  training.responsibilityList &&
                  training.responsibilityList.map((resp, idx) => {
                    return (
                      <ul className="jobdetails-value">
                        <li>{resp}</li>
                      </ul>
                    );
                  })} */}

                {/* <h5 className="jobdetails-title mt-5 pt-5 mr-5 mb-4">
                  Job Requirements
                </h5>
                {training &&
                  training.requirementList &&
                  training.requirementList.map((resp, idx) => {
                    return (
                      <ul className="jobdetails-value">
                        <li>{resp}</li>
                      </ul>
                    );
                  })} */}
                <Row>
                  <Col className="jobdetails-title">Youtube Video Link</Col>
                  <Col className="jobdetails-value">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={
                        training.youtubeVideoLink?.startsWith("http")
                          ? training.youtubeVideoLink
                          : `https://${training.youtubeVideoLink}`
                      }
                    >
                      {training.youtubeVideoLink}
                    </a>
                  </Col>
                </Row>
                <Row>
                  <Col className="jobdetails-title">Video Length</Col>
                  <Col className="jobdetails-value">{training.videoLength}</Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
