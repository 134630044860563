import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import "./RangeInput.css";
import { useMediaQuery } from "react-responsive";

const RangeInput = ({
  min = 0,
  max = 100,
  CouponCount,
  step =1,
  defaultValue = 0,
  rewardBalance,
  onChange = () => { }
}) => {

  const isXl = useMediaQuery({
    query: "(min-device-width: 1200px)",
  });
  const isL = useMediaQuery({ query: "(min-device-width: 1025px)" });
  const isMd = useMediaQuery({ query: "(min-width: 769px)" });
  const isSm = useMediaQuery({ query: "(min-device-width: 481px)" });
  const isXs = useMediaQuery({ query: "(min-device-width: 320px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  const inputRef = useRef();
  const [isChanging, setIsChanging] = useState(false);

  const getPercent = useMemo(
    () => (value) => ((value - min) / (max - min)) * 100,
    [max, min]
  );

  const changeInputProgressPercentStyle = useCallback(() => {
    inputRef.current.style.setProperty(
      "--webkitProgressPercent",
      `${getPercent(inputRef.current.value)}%`
    );
  }, [getPercent]);

  useEffect(()=>{
    changeInputProgressPercentStyle()
  },[defaultValue])
 

  useEffect(()=>{
    const ele = document.querySelector('.buble');
  if (ele) {
   
      ele.style.left = `${Number(defaultValue/4)}%`;
    
  }
})

useEffect(()=>{
  console.log("MAX",max)
  console.log("Min",min)
  console.log("Value",defaultValue)
})

  useEffect(() => {
    changeInputProgressPercentStyle();
    const inputElement = inputRef.current;

    const handleUpAndLeave = () => setIsChanging(false);
    const handleDown = () => setIsChanging(true);

    inputElement.addEventListener("mousemove", changeInputProgressPercentStyle);
    inputElement.addEventListener("mousedown", handleDown);
    inputElement.addEventListener("mouseup", handleUpAndLeave);
    inputElement.addEventListener("mouseleave", handleUpAndLeave);
    return () => {
      inputElement.removeEventListener(
        "mousemove",
        changeInputProgressPercentStyle
      );
      inputElement.removeEventListener("mousedown", handleDown);
      inputElement.removeEventListener("mouseup", handleUpAndLeave);
      inputElement.removeEventListener("mouseleave", handleUpAndLeave);
    };
  }, [isChanging, changeInputProgressPercentStyle]);

  useEffect(() => {
    if (!inputRef?.current) return;
    changeInputProgressPercentStyle();
  }, [inputRef, changeInputProgressPercentStyle]);

  return (
    <div className="range-input-parent">
      {/* <div className="buble">
        <p className="mt-1">{defaultValue}</p>
      </div> */}
      <input
        disabled={rewardBalance>=100?false:true}
        name="range"
        className="range"
        type="range"
        ref={inputRef}
        min={min}
        max={max}
        step={step}
        value={defaultValue}
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
        // onChange={({ target: { value: radius } }) => {
        //   onChange(radius);
        // }}
      />

      <div className="range-limit">
        <h3>{min}</h3>
        <h3>{max/100}</h3>
      </div>
    </div>
  );
};

export default RangeInput;
