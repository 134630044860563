/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import footerLogoFund from "../../assets/logo/Ffund.png";
import footerLogoChen from "../../assets/logo/Fchen.png";
import footerLogoBox from "../../assets/logo/FBox.png";
import footerLogoFIncu from "../../assets/logo/FIncu.png";
import footerLogo from "../../assets/logo/navBar2.png";
import emailLog from "../../assets/logo/email-85@3x.png";
import fbLog from "../../assets/logo/FB@3x.png";
import twitLogo from "../../assets/logo/TT@2x.png";
import instaLogo from "../../assets/logo/instagram-2@3x.png";
import linkdinLogo from "../../assets/logo/linkedin-2@3x.png";
import youtubeLogo from "../../assets/logo/youtube-3@3x.png";
import "./footer.css";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { Col, Row } from "reactstrap";
import { useSelector } from "react-redux";
import { services } from "../../helpers/utilities";
import { ACTIVITY } from "../../helpers/ACTIVITY_CONSTANTS";

export default function Footer() {
  const profile = useSelector(state => state.profileData)

  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div className="cust-footer">
        <div>
         
          <div
            style={{
              backgroundColor: "#F5F6F7",
              marginTop: "2%",
              paddingBottom: "1rem",
            }}
            className="footer-bg"
          >
            <Row
              className="pl-5 pr-5 "
              style={{ paddingTop: "55px", paddingBottom: "55px" }}
            >
              <Col lg="1"></Col>
              <Col lg="3">
                <img
                  src={footerLogo}
                  style={{
                    width: "100%",
                    maxWidth: "400px"
                  }}
                // className="ob-logo-footer"
                />
                <p className="description mt-4 mb-4" style={{ fontSize: "1.5rem", lineHeight: "2.5rem", maxWidth: "40rem" }}>
                  Consectetur adipiscing elit, sed do eiusmod  tempor incididunt
                  ut labore et dolore  magna aliqua. Ut enim ad minim veniam
                </p>
                <div
                  style={{ marginTop: "5%" }}
                  className="mb-5"
                >
                  <img src={fbLog} onClick={() => {
                    services.statusCheck(profile.status) && services.activityLogService(ACTIVITY.CLICK_MORE_FOLLOW_ON_FACEBOOK, profile);
                    window.location.replace("https://m.facebook.com/OpportunityBank4EM/");
                  }} className="social-icons mr-2" />
                  <img src={twitLogo} className="social-icons mr-2" />
                  <img src={instaLogo} onClick={() => {
                    services.statusCheck(profile.status) && services.activityLogService(ACTIVITY.CLICK_MORE_FOLLOW_ON_INSTAGRAM, profile)
                    window.location.replace("https://instagram.com/oppbank?utm_medium=copy_link");
                  }} className="social-icons mr-2" />
                  <img src={linkdinLogo} className="social-icons mr-2" />
                  <img src={youtubeLogo} className="social-icons mr-2" />
                </div>
              </Col>
              <Col lg="8 p-0" className="d-flex justify-content-center ">
                <Row className="m-0 p-0" style={{ width: "100%" }}>
                  <Col lg="4 p-0">
                    <div className="footer-link-items">
                      <p className="topHead">Company</p>
                      <p
                        className="footer-font"
                        onClick={() => {
                          history.push("/aboutUs");
                          window.scrollTo(0, 0);
                        }}
                      >
                        About Us
                      </p>
                      <p className="footer-font">Our Work</p>
                      <p className="footer-font"
                        onClick={() => {
                          services.statusCheck(profile.status) && services.activityLogService(ACTIVITY.CLICK_MORE_SETTINGS_TERMS_AND_CONDITIONS, profile)
                          history.push("/terms_conditions"); window.scrollTo(0, 0)
                        }}>Terms & Conditions</p>
                      <p className="footer-font" onClick={() => {
                        services.statusCheck(profile.status) && services.activityLogService(ACTIVITY.CLICK_MORE_SETTINGS_PRIVACY_POLICY, profile)
                        history.push("/privacy_policy");
                        window.scrollTo(0, 0)
                      }}>Privacy & Policy</p>
                      <p className="footer-font">Get Involved</p>
                      <p className="footer-font">FAQ’s</p>
                      <p className="footer-font">More Info</p>
                    </div>
                  </Col>
                  <Col lg="4 p-0">
                    <div className="footer-link-items">
                      <p className="topHead">Services</p>
                      <p className="footer-font">Job Opportunities</p>
                      <p className="footer-font">Training</p>
                      <p className="footer-font">Scholarship</p>
                      <p className="footer-font">Social Healthcare</p>
                      <p className="footer-font">Business</p>
                    </div>
                  </Col>
                  <Col lg="4 p-0" style={{ height: "20%" }} className="d-flex flex-column  justify-content-between">
                    <div className="footer-link-items ">

                      <p className="topHead">Address</p>
                      <p className="footer-font">3674 XYZ Road</p>

                      <p className="footer-font">HongKong, China</p>
                    </div>

                    <div className="d-flex pl-3">
                      <img className="mr-4 email-logo" style={{ width: "60px", height: "auto" }} src={emailLog} />
                      <div className="" >
                        <span className="footer-font" style={{ fontWeight: "500" }}>Send us an email:</span>{" "}
                        <span className="email-text">oppbank@mail.com</span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
