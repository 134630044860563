import React, { useEffect, useState } from "react";
import { Button, Form, SplitButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import api from "../../../api";
import Logout from "../../../logOut/Logout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./workExp.css";
import moment from "moment";
import { Alert } from "reactstrap";
import { escapeRegExp } from "lodash";

const WorkExpForm = ({ workExpId, setWorkFlow, workFlow, setIsFormOpen }) => {
  const [jobTitle, setJobTitle] = useState("");
  const [jobTitleErr, setJobTitleErr] = useState("");
  const [company, setCompany] = useState("");
  const [companyErr, setCompanyErr] = useState("");
  const [districtList, setDistrictList] = useState([]);
  const [districtName, setDistrictName] = useState("");
  const [districtNameErr, setDistrictNameErr] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startDateErr, setStartDateErr] = useState("");
  const [endDate, setEndDate] = useState("");
  const [message, setMessage] = useState("");
  const [alertClr, setAlertClr] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [achivementList, setAchivementList] = useState([
    { id: "achive_", achive: "" },
  ]);
  // const [responsibilityList, setResponsibilityList] = useState([{ resp: '' }]);
  const [responsibilityList, setResponsibilityList] = useState([
    { id: "resp_", resp: "" },
  ]);
  const [respErr, setRespErr] = useState("");
  const [achiveErr, setAchiveErr] = useState([{ id: "resp_", resp: "" }]);

  const authToken = useSelector((state) => state.auth_token);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    getDistrict();
    if (workExpId && workFlow === "EDIT") {
      getWorkExpToEdit();
    }
  }, []);

  const getDistrict = () => {
    api.onlineCV
      .district(authToken)
      .then((result) => {
        console.log("DISTRIC ==> ", result);
        if (result.resultCode === 1) {
          setDistrictList(result.resultData.districtList);
        } else {
          setDistrictList([]);
        }
      })
      .catch((error) => {
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      });

    console.log("PROP ", workExpId);
  };

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setMessage("");
        setAlertClr("");
        setShowAlert(false);
      }, 2000);
    }
  }, [showAlert]);

  const getWorkExpToEdit = () => {
    api.onlineCV
      .getWorkExperienceById(workExpId, authToken)
      .then((result) => {
        console.log("get edit data work exp ", result);
        if (result.resultCode === 1) {
          setJobTitle(result.resultData.workExperience.jobTitle);
          setCompany(result.resultData.workExperience.employerName);
          setDistrictName(result.resultData.workExperience.jobLocation);

          // console.log("resp  ", (result.resultData.workExperience.responsibilityList))
          // setResponsibilityList(result.resultData.workExperience.responsibilityList);
          // setAchivementList(result.resultData.workExperience.achivementList);
          var arr = [];
          var rA = result.resultData.workExperience.responsibilityList;
          rA.map((r, idx) => {
            // console.log(r)
            let obj = {
              id: "resp_" + (idx + 1),
              resp: r,
            };
            console.log("OBJ ", obj);
            arr.push(obj);
          });
          console.log("ARRR ", arr);
          setResponsibilityList(arr);

          var arrAc = [];
          var AA = result.resultData.workExperience.achivementList;
          AA.map((a, idx) => {
            // console.log(r)
            let obj = {
              id: "achive_" + (idx + 1),
              achive: a,
            };
            console.log("OBJ ", obj);
            arrAc.push(obj);
          });
          console.log("ARRR ", arrAc);
          setAchivementList(arrAc);

          var smy = new moment(
            result.resultData.workExperience.startMonthYear,
            "MMM yyyy"
          ).utc();
          setStartDate(smy._d);
          if (result.resultData.workExperience.endMonthYear !== "Present") {
            var emy = new moment(
              result.resultData.workExperience.endMonthYear,
              "MMM yyyy"
            ).utc();
            setEndDate(emy._d);
          }
          console.log("UTC emy", moment(emy._d).format("MMM yyyy"));
          console.log("UTC smy", moment(smy._d).format("MMM yyyy"));
        }
      })
      .catch((error) => {
        // setIsLoading(false)
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      });
  };

  useEffect(() => {
    console.log("END date ", endDate);
    console.log("start date ", startDate);
  }, [startDate, endDate]);

  useEffect(() => {
    console.log(responsibilityList.length);
  }, [responsibilityList]);

  const addAchivementField = () => {
    var values = JSON.parse(JSON.stringify(achivementList));
    console.log("ACHIVE ARR", values);
    if (values.length !== 0 && values[values.length - 1].achive !== "") {
      values.push({
        id: "achive_" + (values.length + 1),
        achive: "",
      });
    } else if (values.length === 0) {
      values.push({
        id: "achive_",
        achive: "",
      });
    }
    setAchivementList(values);
  };

  // const addResponsibility = (i) => {
  //   var values = JSON.parse(JSON.stringify(responsibilityList));
  //   var lastId = 'resp_' + values.length;
  //   var newId = 'resp_' + (values.length + 1);
  //   if (values[i].id === lastId) {
  //     values.push({
  //       id: newId,
  //       resp: ''
  //     });
  //   }
  //   setResponsibilityList(values)
  // }

  const addResponsibility = () => {
    var values = JSON.parse(JSON.stringify(responsibilityList));
    console.log("VALUES ", values[values.length - 1]);
    if (values[values.length - 1].resp !== "") {
      values.push({
        id: "resp_" + (values.length + 1),
        resp: "",
      });
    }
    setResponsibilityList(values);
  };

  const onChangeRespo = (idx, e) => {
    if (e.target.value) {
      setRespErr("");
    }
    console.log("EVENT ", e.target.value);
    var values = JSON.parse(JSON.stringify(responsibilityList));
    var { name, value } = e.target;
    values[idx][name] = value;

    setResponsibilityList(values);
  };

  // const onChangeRespo = (e, idx) => {
  //   console.log("EVENT ", e.target.value)
  //   var valueArr = [...responsibilityList]
  //   valueArr[idx].resp = e.target.value;
  //   setResponsibilityList(valueArr)
  //   console.log("ARR ", valueArr)
  // }

  const onChangeAchive = (idx, e) => {
    console.log("EVENT ", e.target.value);
    var values = JSON.parse(JSON.stringify(achivementList));
    var { name, value } = e.target;
    values[idx][name] = value;

    setAchivementList(values);
  };

  const saveWorkExp = () => {
    if (!jobTitle) {
      setJobTitleErr("Please enter job title");
    }
    if (!company) {
      setCompanyErr("Please enter company name");
    }
    if (!districtName) {
      setDistrictNameErr("Please select your district");
    }
    if (!startDate) {
      setStartDateErr("Please select start date");
    }
    if (responsibilityList[0].resp === "") {
      setRespErr("Please enter your job responsibility");
    }

    if (workFlow === "EDIT") {
      let data = {
        workExperienceId: workExpId,
        jobTitle: jobTitle,
        jobLocation: districtName,
        employerName: company,
        startMonthYear: moment(startDate).format("MMM yyyy"),
      };
      if (endDate) {
        data.endMonthYear = moment(endDate).format("MMM yyyy");
      }

      // to set array of string and pass it in data obj (for responsibility and ac)
      let respArr = [];
      responsibilityList.map((r, idx) => {
        if (r.resp !== "") {
          respArr[idx] = r.resp;
        }
      });
      console.log("RRRR", respArr);
      data.responsibilityList = respArr;

      if (achivementList) {
        let achiveArr = [];
        achivementList.map((a, idx) => {
          console.log("achive", a);
          if (a.achive !== "") {
            achiveArr[idx] = a.achive;
          }
        });
        console.log("RRRR", achiveArr);
        data.achivementList = achiveArr;
      }
      console.log("DATA EDIT ", data);
      if (responsibilityList[0].resp !== "") {
        api.onlineCV
          .editWorkExperience(data, authToken)
          .then((result) => {
            console.log("RESULT ", result);
            if (result.resultCode === 1) {
              setMessage("Work Experience updated successfully");
              setAlertClr("success");
              setShowAlert(true);
              setTimeout(() => {
                setWorkFlow("DISPLAY");
                setIsFormOpen(false);
                dispatch({ type: "isListReload/save", isListReload: true });
              }, 2000);
            } else {
              if (result.resultMsg === "No changes found, cannot update") {
                setMessage("Can not update, No change found");
                setAlertClr("danger");
                setShowAlert(true);
              } else {
                setMessage(result.resultMsg);
                setAlertClr("danger");
                setShowAlert(true);
              }
            }
            window.scrollTo(0, 0);
          })
          .catch((error) => {
            console.log(error);
            if (
              !!error.response &&
              !!error.response.status &&
              error.response.status === 401
            ) {
              Logout(dispatch, history, false);
            }
            if (error.message === "Network Error") {
              setMessage("Please check your network connection!");
              setAlertClr("danger");
              setShowAlert(true);
            }
          });
      }
    } else {
      var a = [];
      responsibilityList.map((r) => {
        if (r.resp !== "") {
          a.push(r.resp);
        }
      });
      console.log("arr ", a);

      var b = [];
      achivementList.map((a) => {
        if (a.achive !== "") {
          b.push(a.achive);
        }
      });
      console.log("arr ", b);

      var data = {
        jobTitle: jobTitle,
        jobLocation: districtName,
        employerName: company,
        startMonthYear: moment(startDate).format("MMM yyyy"),
        endMonthYear: endDate && moment(endDate).format("MMM yyyy"),
        achivementList: achivementList && b,
        responsibilityList: a,
      };
      console.log(data);
      if (responsibilityList[0].resp !== "") {
        api.onlineCV
          .saveWorkExperience(data, authToken)
          .then((result) => {
            console.log(result);
            if (result.resultCode === 1) {
              setMessage("Work Experience saved successfully");
              setAlertClr("success");
              setShowAlert(true);
              setTimeout(() => {
                setWorkFlow("DISPLAY");
                setIsFormOpen(false);
                dispatch({ type: "isListReload/save", isListReload: true });
              }, 2000);
            } else {
              setMessage(result.resultMsg);
              setAlertClr("danger");
              setShowAlert(true);
            }
            window.scrollTo(0, 0);
          })
          .catch((error) => {
            console.log(error);
            if (
              !!error.response &&
              !!error.response.status &&
              error.response.status === 401
            ) {
              Logout(dispatch, history, false);
            }
            if (error.message === "Network Error") {
              setMessage("Please check your network connection!");
              setAlertClr("danger");
              setShowAlert(true);
            }
          });
      }
    }
  };

  return (
    <div className="card profile-edit-container mt-5 mb-5">
      <Form className="form-style p-5" style={{ fontSize: "larger" }}>
        <Alert className="alert-position" isOpen={showAlert} color={alertClr}>
          {message}{" "}
        </Alert>
        <Form.Group>
          <Form.Label className="cust-label shiftToLeft mt-3">
            Job Title<span className="mandatory-star ml-2">*</span>
          </Form.Label>
          <Form.Control
            className="cv-inputStyle"
            style={{ backgrounfColor: "white" }}
            value={jobTitle}
            onChange={(e) => {
              setJobTitle(e.target.value);
              if (e.target.value) {
                setJobTitleErr("");
              }
            }}
            type="text"
          />
          <Form.Text
            className="font-size-vali-msg"
            style={{
              color: "red",
              display: "flex",
              justifyContent: "start",
              fontFamily: "emoji",
            }}
          >
            {jobTitleErr}
          </Form.Text>
        </Form.Group>

        <Form.Group>
          <Form.Label className="cust-label shiftToLeft">
            Your Company/Organization
            <span className="mandatory-star ml-2">*</span>
          </Form.Label>
          <Form.Control
            className="cv-inputStyle"
            style={{ backgrounfColor: "white" }}
            value={company}
            onChange={(e) => {
              setCompany(e.target.value);
              if (e.target.value) {
                setCompanyErr("");
              }
            }}
            type="text"
          />
          <Form.Text
            className="font-size-vali-msg"
            style={{
              color: "red",
              display: "flex",
              justifyContent: "start",
              fontFamily: "emoji",
            }}
          >
            {companyErr}
          </Form.Text>
        </Form.Group>

        <Form.Group>
          <Form.Label className="cust-label shiftToLeft">
            District<span className="mandatory-star ml-2">*</span>
          </Form.Label>
          <select
            style={{ backgrounfColor: "white" }}
            className="dropdown-style-wrkExp"
            value={districtName}
            onChange={(e) => {
              setDistrictName(e.target.value);
              if (e.target.value) {
                setDistrictNameErr("");
              }
            }}
          >
            <option></option>
            {districtList.map((Item, idx) => {
              return (
                <option key={idx} value={Item.locationName}>
                  {Item.locationName}
                </option>
              );
            })}
          </select>
          <Form.Text
            className="font-size-vali-msg"
            style={{
              color: "red",
              display: "flex",
              justifyContent: "start",
              fontFamily: "emoji",
            }}
          >
            {districtNameErr}
          </Form.Text>
        </Form.Group>

        <Form.Group>
          <Form.Label className="cust-label shiftToLeft">
            Start Month Year<span className="mandatory-star ml-2">*</span>
          </Form.Label>
          <DatePicker
            className="date-input"
            selected={startDate}
            onChange={(date) => {
              console.log("MON YEAR DATE ", date);
              setStartDate(date);
              if (date) {
                setStartDateErr("");
              }
            }}
            showMonthYearPicker
            dateFormat="MMM yyyy"
            maxDate={moment().toDate()}
          />
          <Form.Text
            className="font-size-vali-msg"
            style={{
              color: "red",
              display: "flex",
              justifyContent: "start",
              fontFamily: "emoji",
            }}
          >
            {startDateErr}
          </Form.Text>
        </Form.Group>

        <Form.Group>
          <Form.Label className="cust-label shiftToLeft">
            End Month Year
          </Form.Label>
          <DatePicker
            className="date-input"
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="MMM yyyy"
            showMonthYearPicker
            maxDate={moment().toDate()}
            minDate={startDate}
            endDate={startDate}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label className="cust-label shiftToLeft mt-2">
            Currently Working ?
          </Form.Label>
          <Form.Check
            className="radio-style"
            inline
            checked={endDate ? false : true}
            defaultChecked
            disabled={endDate}
            label="Yes"
            name="group1"
            type="radio"
            // id={`inline-${type}-1`}
          />
          <Form.Check
            className="radio-style"
            inline
            checked={endDate}
            disabled={!endDate}
            label="No"
            name="group1"
            type="radio"
            // id={`inline-${type}-2`}
          />
        </Form.Group>

        <Form.Group>
          <div className="d-flex" style={{ marginBottom: "-5px" }}>
            <Form.Label className="cust-label  shiftToLeft mt-2">
              Achivements(Optional)
            </Form.Label>
            <span
              onClick={addAchivementField}
              className="add-style"
              style={{ paddingTop: "10px" }}
            >
              <i class="fa fa-plus mr-2 mt-1" aria-hidden="true"></i> Add
            </span>
          </div>

          {achivementList.map((ta, idx) => (
            <>
              {/* <span onClick={() => addResponsibility(idx)} className="add-style"><i class="fa fa-plus mr-2 mt-1" aria-hidden="true"></i> Add</span> */}
              <div className="d-flex" key={idx}>
                <span>{idx + 1}. </span>
                <Form.Control
                  className="mb-3 ml-3 text-area"
                  as="textarea"
                  type="text"
                  name="achive"
                  id={ta.id}
                  maxLength="120"
                  value={ta.achive}
                  rows={1}
                  onChange={(e) => onChangeAchive(idx, e)}
                />

                <i
                  class="fa fa-trash ml-3 del-style"
                  aria-hidden="true"
                  onClick={() => {
                    var r = JSON.parse(JSON.stringify(achivementList));
                    console.log("ACHIVE LIST LENGTH", r.length);
                    // if (r.length === 0 && r[0].achive !== '') {
                    //   setAchivementList([{ id: 'achive_', achive: '' }])
                    // } else {
                    r.splice(idx, 1);
                    console.log("INDEX ", idx);
                    console.log("LIST ", r);
                    setAchivementList(r);
                    // }
                  }}
                ></i>
              </div>
              {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '-10px' }}>
                <Form.Text className="font-size-vali-msg" style={{ color: 'red', fontFamily: 'emoji' }} >
                  {achiveErr}
                </Form.Text> */}
              <Form.Text className="font-size-vali-msg mr-5 d-flex justify-content-end">
                {achivementList[idx].achive.length}/120
              </Form.Text>
              {/* </div> */}
            </>
          ))}
        </Form.Group>

        <Form.Group>
          <div className="d-flex">
            <Form.Label className="cust-label shiftToLeft">
              Responsibilities<span className="mandatory-star ml-2">*</span>
            </Form.Label>
            <span onClick={addResponsibility} className="add-style">
              <i class="fa fa-plus mr-2 mt-1" aria-hidden="true"></i> Add
            </span>
          </div>
          {responsibilityList.map((ta, idx) => (
            <>
              {/* <span onClick={() => addResponsibility(idx)} className="add-style"><i class="fa fa-plus mr-2 mt-1" aria-hidden="true"></i> Add</span> */}
              <div className="d-flex" key={idx}>
                <span>{idx + 1}. </span>
                <Form.Control
                  className="mb-3 ml-3 text-area"
                  as="textarea"
                  type="text"
                  name="resp"
                  id={ta.id}
                  maxLength="120"
                  value={ta.resp}
                  rows={1}
                  onChange={(e) => onChangeRespo(idx, e)}
                />

                <i
                  class="fa fa-trash ml-3 del-style"
                  aria-hidden="true"
                  style={
                    responsibilityList.length === 1
                      ? { color: "gray" }
                      : { color: "black" }
                  }
                  // disabled={true}
                  onClick={() => {
                    if (responsibilityList.length !== 1) {
                      var r = JSON.parse(JSON.stringify(responsibilityList));
                      r.splice(idx, 1);
                      console.log("INDEX ", idx);
                      console.log("LIST ", r);
                      setResponsibilityList(r);
                    }
                  }}
                ></i>
              </div>
              <Form.Text className="font-size-vali-msg mr-5 d-flex justify-content-end">
                {responsibilityList[idx].resp.length}/120
              </Form.Text>
            </>
          ))}

          <Form.Text
            className="font-size-vali-msg ml-4"
            style={{
              marginTop: "-15px",
              color: "red",
              display: "flex",
              justifyContent: "start",
              fontFamily: "emoji",
            }}
          >
            {respErr}
          </Form.Text>
        </Form.Group>
        <div className="d-flex justify-content-center">
          <Button
            onClick={saveWorkExp}
            className="my-cust-button mt-2 mb-5 pl-5 pt-1 pb-1 pr-5"
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default WorkExpForm;
