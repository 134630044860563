import React, { useEffect } from "react";
import "./footer.scss";
/* eslint-disable jsx-a11y/alt-text */
import { Link, useHistory } from "react-router-dom";
import footerLogoFund from "../../assets/logo/Ffund.png";
import footerLogoChen from "../../assets/logo/Fchen.png";
import footerLogoBox from "../../assets/logo/FBox.png";
import footerLogoFIncu from "../../assets/logo/FIncu.png";
import footerLogo from "../../assets/logo/navBar2.png";
import emailLog from "../../assets/logo/email-85@3x.png";
import fbLog from "../../assets/logo/FB@3x.png";
import twitLogo from "../../assets/logo/TT@2x.png";
import instaLogo from "../../assets/logo/instagram-2@3x.png";
import linkdinLogo from "../../assets/logo/linkedin-2@3x.png";
import youtubeLogo from "../../assets/logo/youtube-3@3x.png";
import "./footer.css";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { Col, Row } from "reactstrap";
import { useSelector } from "react-redux";
import { services } from "../../helpers/utilities";
import { ACTIVITY } from "../../helpers/ACTIVITY_CONSTANTS";
import { BrandLogo3 } from "../../assets/svg";
import { brandLogo } from "../../assets";

const FooterRe = () => {
  const profile = useSelector((state) => state.profileData);
  const history = useHistory();
  return (
    <>
      <hr
        style={{
          "border-top": "1px solid #ddd",
          marginTop: "40px",
          marginBottom: "8px",
        }}
      />
      <div className="footer-container">
        <div className="footer-column">
          <div>
            <img
              className="footer-logo"
              style={{
                width: "400px",
                height: "49px",
                cursor: "default",
                imageRendering: "-webkit-optimize-contrast",
              }}
              src={brandLogo}
            />
            {/* <BrandLogo3 className="footer-logo" style={{imageRendering: "-webkit-optimize-contrast" }}/> */}
          </div>
          <div
            className="description"
            style={{
              fontSize: "1.5rem",
              lineHeight: "2.5rem",
              maxWidth: "40rem",
              textAlign: "justify",
              cursor: "default",
            }}
          >
            We empower ethnic minorities in Hong Kong with opportunities and
            resources.
          </div>
          <div>
            <img
              src={fbLog}
              onClick={() => {
                services.statusCheck(profile.status) &&
                  services.activityLogService(
                    ACTIVITY.CLICK_MORE_FOLLOW_ON_FACEBOOK,
                    profile
                  );
                window.location.replace(
                  "https://m.facebook.com/OpportunityBank4EM/"
                );
              }}
              className="social-icons mr-2"
            />
            <img
              src={instaLogo}
              onClick={() => {
                services.statusCheck(profile.status) &&
                  services.activityLogService(
                    ACTIVITY.CLICK_MORE_FOLLOW_ON_INSTAGRAM,
                    profile
                  );
                window.location.replace(
                  "https://instagram.com/oppbank?utm_medium=copy_link"
                );
              }}
              className="social-icons mr-2"
            />
          </div>
        </div>
        <div className="footer-column">
          <div>Opportunity Bank</div>
          <div
            className="footer-font"
            onClick={() => {
              history.push("/aboutUs");
              window.scrollTo(0, 0);
            }}
          >
            <span>About Us</span>
          </div>

          <div
            className="footer-font"
            onClick={() => {
              services.statusCheck(profile.status) &&
                services.activityLogService(
                  ACTIVITY.CLICK_MORE_SETTINGS_TERMS_AND_CONDITIONS,
                  profile
                );
              history.push("/terms_conditions");
              window.scrollTo(0, 0);
            }}
          >
            <span>Terms & Conditions</span>
          </div>
          <div className="footer-font">
            <span
              onClick={() => {
                history.push("/ob_impact");
                window.scrollTo(0, 0);
              }}
            >
              Impact Of Opportunity Bank
            </span>
          </div>
          <div
            className="footer-font"
            onClick={() => {
              services.statusCheck(profile.status) &&
                services.activityLogService(
                  ACTIVITY.CLICK_MORE_SETTINGS_PRIVACY_POLICY,
                  profile
                );
              history.push("/privacy_policy");
              window.scrollTo(0, 0);
            }}
          >
            <span>Privacy & Policy</span>
          </div>
          <div className="footer-font">
            <span
              onClick={() => {
                window.scrollTo(0, 0);
                history.push("/contactUs");
              }}
            >
              Contact Us
            </span>
          </div>
          <div className="footer-font">
            <a
              href="https://www.opportunitybankhk.org/account-deletion"
              rel="noreferrer"
              target="_blank"
              style={{color: "#54565a"}}
            >
              Request for Account Deletion
            </a>
          </div>
          {/* <div className="footer-font">Get Involved</div>
        <div className="footer-font">FAQ’s</div>
        <div className="footer-font">More Info</div> */}
        </div>
        <div className="footer-column">
          <div>Services</div>
          <div>
            <span
              onClick={() => {
                history.push("/jobList");
                window.scrollTo(0, 0);
              }}
            >
              Job Opportunities
            </span>
          </div>
          <div>
            <span
              onClick={() => {
                history.push("/comingsoon");
                window.scrollTo(0, 0);
              }}
            >
              Training
            </span>
          </div>
          <div>
            <span
              onClick={() => {
                history.push("/comingsoon");
                window.scrollTo(0, 0);
              }}
            >
              Scholarship
            </span>
          </div>
          <div>
            <span
              onClick={() => {
                history.push("/comingsoon");
                window.scrollTo(0, 0);
              }}
            >
              Social Healthcare
            </span>
          </div>
          {/* <div>Business</div> */}
        </div>
        <div className="footer-column address-column">
          <div>
            <div>Address</div>
            <div>
              The Zubin Mahtani Gidumal Foundation Limited Unit F,
              <br /> 5th Floor, High Fashion Center No. 1 Kwai Hei Street Kwai
              Fong, HongKong, China
            </div>
          </div>
          <div>
            <div className="email-grid">
              <img className="email-logo" src={emailLog} />
              <div className="email-address">
                <p style={{ fontWeight: 600 }}>Send us an email:</p>
                <p>
                  <a href="mailto:oppbank@zubinfoundation.org">
                    oppbank@zubinfoundation.org
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterRe;
