import React, { useState, useEffect } from 'react';
import '../createCv.css'
import CareerObjective from '../careerObj/CareerObjective'
import Education from '../education/Education';


const StepTwo = ({ passFromEducation, passFromEducationPrev, updateCareerObjStep, updateEducationStep }) => {

  return (
    <div className="pb-5">
      <h3 className="d-flex justify-content-center">Career Objective</h3>
      <CareerObjective
        onCareerObjSave={(data) => {
          console.log("EDUCATION COMLETE? step", data)
          updateCareerObjStep(data)
        }}
      />
      <div style={{ height: '40px', width: '100%', backgroundColor: 'white' }}></div>
      {/* <Education  /> */}
      <Education
        nextStep={(data) => {
          passFromEducation(data);
          console.log("EDUCATION COMLETE?", data)
        }}

        previousStep={(data) => {
          passFromEducationPrev(data);
          console.log("EDUCATION COMLETE?", data)
        }}

        onEducationAction={(data) => {
          console.log("EDUCATION COMLETE? step", data)
          updateEducationStep(data)
        }}

      />
    </div>
  )
}

export default StepTwo;

