import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Col, Row, Tooltip } from "reactstrap";
import Logout from "../../../logOut/Logout";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-js-loader";
import { Button, Form } from "react-bootstrap";
import api from "../../../api";
import "./language.css";
import CustDialog from "../../../components/cust-dialog/CustDialog";

const LanguageForm = ({ nextStep, previousStep, onLanguageAction }) => {
  const defaultLanguage = [
    {
      languageName: "Cantonese(Speaking)",
      value: "CANTONESE",
      isMandatory: true,
    },
    {
      languageName: "English(Writing)",
      value: "ENGLISH_WRT",
      isMandatory: false,
    },
    {
      languageName: "English(Speaking)",
      value: "ENGLISH_SPK",
      isMandatory: false,
    },
    {
      languageName: "Chinese(Reading and Writing)",
      value: "CHINESE",
      isMandatory: false,
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [alertClr, setAlertClr] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [languageList, setLanguageList] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedLangErr, setSelectedLangErr] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionErr, setSelectedOptionErr] = useState("");
  const [userLanguageList, setUserLanguageList] = useState([]);
  const [formUserList, setformUserList] = useState(defaultLanguage);
  const [showLanguageName, setShowLanguageName] = useState("");
  const [lang, setLang] = useState("");
  const [isNewLangAdd, setIsNewLangAdd] = useState(false);
  const [isEditClick, setIsEditClick] = useState(false);
  const [dialogStatus, setDialogStatus] = useState(false);
  const [tooltipOpen, setToolTipOpen] = useState(false);
  const authToken = useSelector((state) => state.auth_token);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    getAllLanguage();
  }, []);

  const toggle = () => {
    setToolTipOpen(!tooltipOpen);
  };

  useEffect(() => {
    setTimeout(() => {
      setMessage("");
      setAlertClr("");
      setShowAlert(false);
    }, 3000);
  }, [showAlert]);

  useEffect(() => {
    let formattedList = [...defaultLanguage];
    userLanguageList.forEach((item) => {
      const index = formattedList.findIndex((i) => i.value === item.language);
      console.log("INDEX ", index);
      if (index === -1) {
        formattedList.push({ ...item, value: item.language });
      } else {
        formattedList[index] = {
          ...formattedList[index],
          ...item,
          value: item.language,
        };
      }
    });
    console.log("formattedList : ", formattedList, userLanguageList);
    setformUserList(formattedList);
  }, [userLanguageList]);

  useEffect(() => {
    setIsLoading(true);
    console.log("SLECTED LANGUAGE ", lang);
    api.onlineCV
      .getLanguageByNameOption(lang, authToken)
      .then((result) => {
        console.log("RESULT get lang with option", result);
        if (result.resultCode === 1) {
          setLanguageList(result.resultData.options);
        } else {
          setLanguageList([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      });
  }, [lang]);

  const getLangByName = () => {
    api.onlineCV
      .getLanguageByLangName(lang, authToken)
      .then((result) => {
        console.log("RESULT by lang name", result);
        if (result.resultCode === 1) {
          // setSelectedLanguage()
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      });
  };

  useEffect(() => {
    console.log("CHNAGEC", isNewLangAdd);
  }, [isNewLangAdd]);

  const getAllLanguage = () => {
    setIsLoading(true);
    api.onlineCV
      .getLanguage(authToken)
      .then((result) => {
        console.log("RESULT get lang", result);
        if (result.resultCode === 1) {
          setUserLanguageList(result.resultData.userLanguageList);
          result.resultData.userLanguageList.map((lang) => {
            if (lang.languageName === "Cantonese (Speaking)") {
              onLanguageAction("language");
            }
          });
        } else {
          setUserLanguageList([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      });
  };

  const saveLanguage = () => {
    if (!selectedLanguage) {
      setSelectedLangErr("Please enter language");
    } else {
      setSelectedLangErr("");
    }
    if (!selectedOption) {
      setSelectedOptionErr("Please select proficiency");
    } else {
      setSelectedOptionErr("");
    }
    console.log(selectedLanguage);
    var data = {
      // language: (isNewLangAdd ? selectedLanguage : lang),
      language: selectedLanguage,
      selectedOption: selectedOption,
    };
    setIsLoading(true);
    console.log("DATA LAGNUa ", data);
    api.onlineCV
      .saveLanguageByNameOption(data, authToken)
      .then((result) => {
        console.log("RESULT SAVE LANG ", result);
        if (result.resultCode === 1) {
          setMessage("Language proficiency saved successfully");
          setAlertClr("success");
          setShowAlert(true);
        } else {
          if (result.resultMsg === "Can not update. No change found!") {
            setMessage("Can not update. No change found");
            setAlertClr("danger");
            setShowAlert(true);
          } else {
            setMessage(result.resultMsg);
            setAlertClr("danger");
            setShowAlert(true);
          }
        }
        setTimeout(() => {
          setIsEditClick(false);
          setIsNewLangAdd(false);
          setSelectedOption("");
          // setLang(null)
        }, 3000);
        setIsLoading(false);
        getAllLanguage();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      });
  };

  const deleteLanguage = (language) => {
    setIsLoading(true);
    var data = {
      language: language,
    };
    api.onlineCV
      .deleLanguage(data, authToken)
      .then((result) => {
        console.log("RESULT -> DELETE LANG", result);
        if (result.resultCode === 1) {
          setMessage(result.resultMsg);
          setAlertClr("success");
          setShowAlert(true);
        } else {
          setMessage(result.resultMsg);
          setAlertClr("danger");
          setShowAlert(true);
        }
        setIsLoading(false);
        getAllLanguage();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        if (
          !!error.response &&
          !!error.response.status &&
          error.response.status === 401
        ) {
          Logout(dispatch, history, false);
        }
        if (error.message === "Network Error") {
          setMessage("Please check your network connection!");
          setAlertClr("danger");
          setShowAlert(true);
        }
      });
  };

  const handleCloseDialog = () => {
    setDialogStatus(false);
  };
  const handleActionDialog = () => {
    if (showLanguageName !== "Cantonese (Speaking)") {
      deleteLanguage(lang);
    }
  };

  return (
    <div className="form-style card profile-edit-container mt-5 p-5">
      <Alert className="alert-position" isOpen={showAlert} color={alertClr}>
        {message}{" "}
      </Alert>

      <CustDialog
        open={dialogStatus}
        handleClose={handleCloseDialog}
        handleAction={handleActionDialog}
        title={"Warning!"}
        message={`Are you sure to delete ${showLanguageName} ?`}
      />
      {isLoading && (
        <div
          style={{ zIndex: "1500" }}
          className="d-flex justify-content-center"
        >
          <Loader
            type="spinner-cub"
            bgColor={"rgb(53, 50, 50)"}
            title={"Loading..."}
            size={100}
          />
        </div>
      )}
      {formUserList.length > 0 && !isEditClick && !isNewLangAdd ? (
        <>
          <Row>
            <Col xl="4" lg="4" md="5" sm="4 mb-3" xs="6 mb-3" className="mb-4">
              <h5>
              Language
              </h5>
            </Col>
            <Col
              xl="4"
              lg="4"
              md="5"
              sm="4 mb-3"
              xs="6 mb-3"
              className="d-flex justify-content-center"
            >
              <h5>
              Proficiency
              </h5>
            </Col>
            <Col
              xl="4"
              lg="4"
              md=""
              sm="4 mb-3"
              xs="mb-3"
              className="d-flex justify-content-end"
            >
              <h5>
              Edit Proficiency
              </h5>
              <i
                class="fas fa-info-circle fa-2x ml-2"
                id="edit-info"
                style={{ color: "#4c6ef5" }}
              >
                <Tooltip
                  placement="right"
                  isOpen={tooltipOpen}
                  target="edit-info"
                  toggle={toggle}
                >
                  
                  Click on pen icon to change or select proficiency
                 
                </Tooltip>
              </i>
            </Col>
          </Row>
          {formUserList.map((ul, idx) => {
            return (
              <div className="p-3">
                <Row className="">
                  <Col
                    xl="4"
                    lg="4"
                    md="5"
                    sm="4 mb-3"
                    xs="6 mb-3"
                    className="mb-4"
                  >
                    <h5
                      className="cust-label-lang mr-5"
                      style={{ display: "flex" }}
                    >
                      {ul.isMandatory && (
                        <span className="mandatory-star">*</span>
                      )}
                      {ul.languageName}
                    </h5>
                  </Col>

                  <Col
                    xl="4"
                    lg="4"
                    md="5"
                    sm="4 mb-3"
                    xs="6 mb-3"
                    className="d-flex justify-content-center"
                  >
                    <h6 className="proficiency">
                      {ul.selectedOption ? ul.selectedOption : "-"}{" "}
                    </h6>
                  </Col>
                  <Col
                    xl="4"
                    lg="4"
                    md=""
                    sm="4 mb-3"
                    xs="mb-3"
                    className="d-flex justify-content-end"
                  >
                    <i
                      onClick={() => {
                        console.log(ul);
                        // setLang(ul.value)
                        setLang(
                          ul.languageName === ul.language ? "OTHER" : ul.value
                        );
                        setShowLanguageName(ul.languageName);
                        setSelectedOption(
                          ul.selectedOption && ul.selectedOption
                        );
                        setIsEditClick(true);
                        setSelectedLanguage(ul.value);
                      }}
                      className="fas fa-pen fa-1x icon-style mr-3"
                    ></i>
                    {/* {ul.languageName !== "Cantonese (Speaking)" && */}
                    <i
                      class="fa fa-trash icon-style ml-2"
                      aria-hidden="true"
                      style={{
                        color: ul.isMandatory === true && "gray",
                        cursor: ul.isMandatory === true && "auto",
                      }}
                      onClick={() => {
                        if (ul.isMandatory !== true) {
                          setDialogStatus(true);
                          setShowLanguageName(ul.languageName);
                          setLang(ul.value);
                          console.log(
                            "DLE ",
                            ul.languageName,
                            ul.language,
                            ul.value
                          );
                        }
                      }}
                    ></i>
                    {/* } */}
                  </Col>
                  <div className="line-lang mb-1"></div>
                </Row>
              </div>
            );
          })}
          <div className="d-flex justify-content-center mt-5">
            {/* <Button
              onClick={() => {
                previousStep(2)
              }}
              className="my-cust-button-language prev-btn-lang"
            >
              Previous
            </Button> */}
            <Button
              onClick={() => {
                setIsNewLangAdd(true);
                setLang("OTHER");
                console.log("ADD ");
              }}
              className="my-cust-button-language mr-5 ml-5"
            >
              Add New Language
            </Button>
          </div>
        </>
      ) : (
        <>
          <Form className="p-5">
            {!isNewLangAdd ? (
              <h4
                style={{
                  color: "black",
                  fontSize: "13px",
                  marginBottom: "-2px",
                  display: "inline",
                }}
                className="cust-label-lang shiftToLeft mb-5"
              >
                Language Name :{" "}
                <h6 style={{ display: "inline", fontWeight: "initial" }}>
                  {showLanguageName}
                </h6>
              </h4>
            ) : (
              <>
                <Form.Group>
                  <Form.Label
                    style={{
                      color: "black",
                      fontSize: "13px",
                      marginBottom: "-2px",
                    }}
                    className="cust-label-lang shiftToLeft"
                  >
                    Enter Language Name
                    <span className="mandatory-star ml-1">*</span>
                  </Form.Label>
                  <Form.Control
                    className="cv-inputStyle"
                    style={{ backgrounfColor: "white" }}
                    onChange={(e) => {
                      setSelectedLanguage(e.target.value);
                      if (e.target.value) {
                        setSelectedLangErr("");
                      }
                    }}
                  />
                  <Form.Text
                    className="font-size-vali-msg"
                    style={{
                      color: "red",
                      display: "flex",
                      justifyContent: "start",
                      fontFamily: "emoji",
                    }}
                  >
                    {selectedLangErr}
                  </Form.Text>
                </Form.Group>
              </>
            )}
            <Form.Group>
              <Form.Label
                style={{
                  color: "black",
                  fontSize: "13px",
                  marginBottom: "-2px",
                }}
                className="cust-label-lang shiftToLeft"
              >
                Proficiency<span className="mandatory-star ml-1">*</span>
              </Form.Label>
              <select
                style={{ backgrounfColor: "white" }}
                className="dropdown-style"
                value={selectedOption}
                onChange={(e) => {
                  setSelectedOption(e.target.value);
                  if (e.target.value) {
                    setSelectedOptionErr("");
                  }
                }}
              >
                <option></option>
                {languageList.map((Item, idx) => {
                  return (
                    <option key={idx} value={Item.proficiencyOption}>
                      {Item.proficiencyOption}
                    </option>
                  );
                })}
              </select>
              <Form.Text
                className="font-size-vali-msg"
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "start",
                  fontFamily: "emoji",
                }}
              >
                {selectedOptionErr}
              </Form.Text>
            </Form.Group>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Button className="btn-save mb-5" onClick={saveLanguage}>
                {" "}
                Save
              </Button>
            </div>
          </Form>
        </>
      )}
    </div>
  );
};

export default LanguageForm;
